import React, { useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import moment from "moment";
import {
  getOfficeHoursByOffice,
  setNewOfficeHours,
  submitOfficeHours,
  updateNewOfficeHours,
} from "../../../actions/officeSettingsActions";
import DatePicker from "react-datepicker";

export const ManageBookingTimes = (props: any) => {
  const dispatch = useDispatch();
  const office = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.office
  );
  const officeHours = useSelector(
    (state: RootStateOrAny) => state.officeSettingsReducer.officeHours
  );

  useEffect(() => {
    dispatch(getOfficeHoursByOffice(office.pk));
  }, []);

  const changeBookingTime = (day: number, key: string, value: any) => {
    dispatch(updateNewOfficeHours(day, key, value));
  };

  return (
    <div className="white-box">
      <div className="app-setting-head section-head">
        <h4 className="w-100">Hours of Operation</h4>
        <p>
          Select which times you’d like patients to be able to start booking
          COVID-19 appointments and what time you’d like to stop booking. Be
          sure to add hours for each day that your office is open. If office is
          closed a certain day, don’t add any hours.
        </p>
      </div>
      <form
        className="w-100"
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(submitOfficeHours(officeHours));
        }}
      >
        <div className="timeslot-box">
          {officeHours &&
            officeHours.map((officeDay: any) => (
              <div className="hours-slot">
                <div className="timeslot-box-row">
                  <label className="timeslot-starttime">
                    {" "}
                    {moment.weekdays(officeDay.weekday)} Closed?
                  </label>
                  <div className="timeslot-box-time-piker">
                    <div className="time-picker">
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="closed"
                          defaultChecked={officeDay.closed}
                          onChange={(e) => {
                            changeBookingTime(
                              officeDay.weekday,
                              "closed",
                              e.target.checked
                            );
                          }}
                        />
                        <span className="switch-btn" />
                      </label>
                    </div>
                  </div>
                </div>
                {!officeDay.closed && (
                  <>
                    <div className="timeslot-box-row">
                      <label className="timeslot-starttime">
                        {" "}
                        {moment.weekdays(officeDay.weekday)}:{" "}
                      </label>
                      <div className="timeslot-box-time-piker">
                        <div className="time-picker">
                          <DatePicker
                            className="form-control  form-feild"
                            showTimeSelect
                            showTimeSelectOnly
                            autoComplete="off"
                            timeIntervals={officeDay.appointmentInterval}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            name="eventTime"
                            selected={
                              officeDay.from_hour
                                ? moment(
                                    officeDay.from_hour,
                                    "hh:mm:ss"
                                  ).toDate()
                                : null
                            }
                            onChange={(date: Date) => {
                              changeBookingTime(
                                officeDay.weekday,
                                "from_hour",
                                date ? moment(date).format("HH:mm") : null
                              );
                            }}
                          />
                        </div>
                      </div>
                      <label className="timeslot-endtime"> to </label>
                      <div className="timeslot-box-time-piker">
                        <div className="time-picker">
                          <DatePicker
                            className="form-control  form-feild"
                            showTimeSelect
                            showTimeSelectOnly
                            autoComplete="off"
                            timeIntervals={officeDay.appointmentInterval}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            name="eventTime"
                            selected={
                              officeDay.to_hour
                                ? moment(officeDay.to_hour, "hh:mm:ss").toDate()
                                : null
                            }
                            onChange={(date: Date) => {
                              changeBookingTime(
                                officeDay.weekday,
                                "to_hour",
                                date ? moment(date).format("HH:mm") : null
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="timeslot-box-row">
                      <label className="timeslot-starttime"> Lunch?</label>
                      <div className="timeslot-box-time-piker">
                        <div className="time-picker">
                          <label className="switch">
                            <input
                              type="checkbox"
                              name="lunch"
                              defaultChecked={officeDay.lunch}
                              onChange={(e) => {
                                changeBookingTime(
                                  officeDay.weekday,
                                  "lunch",
                                  e.target.value
                                );
                              }}
                            />
                            <span className="switch-btn" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="timeslot-box-row">
                      <label className="timeslot-starttime">
                        {" "}
                        {moment.weekdays(officeDay.weekday)} Lunch:{" "}
                      </label>
                      <div className="timeslot-box-time-piker">
                        <div className="time-picker">
                          <DatePicker
                            className="form-control  form-feild"
                            showTimeSelect
                            showTimeSelectOnly
                            autoComplete="off"
                            timeIntervals={5}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            name="eventTime"
                            selected={
                              officeDay.lunch_start
                                ? moment(
                                    officeDay.lunch_start,
                                    "hh:mm:ss"
                                  ).toDate()
                                : null
                            }
                            onChange={(date: Date) => {
                              changeBookingTime(
                                officeDay.weekday,
                                "lunch_start",
                                date ? moment(date).format("HH:mm") : null
                              );
                            }}
                          />
                        </div>
                      </div>
                      <label className="timeslot-endtime"> to </label>
                      <div className="timeslot-box-time-piker">
                        <div className="time-picker">
                          <DatePicker
                            className="form-control  form-feild"
                            showTimeSelect
                            showTimeSelectOnly
                            autoComplete="off"
                            timeIntervals={5}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            name="eventTime"
                            selected={
                              officeDay.lunch_end
                                ? moment(
                                    officeDay.lunch_end,
                                    "hh:mm:ss"
                                  ).toDate()
                                : null
                            }
                            onChange={(date: Date) => {
                              changeBookingTime(
                                officeDay.weekday,
                                "lunch_end",
                                date ? moment(date).format("HH:mm") : null
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-100">
                      <div className="timeslot-row w-100">
                        <label className="feild-label">
                          Length of Time Slot:
                        </label>
                        <select
                          style={{ width: "30%", fontSize: "14px" }}
                          name="number"
                          className="form-control  form-feild"
                          defaultValue={officeDay.appointment_interval}
                          onChange={(e) => {
                            changeBookingTime(
                              officeDay.weekday,
                              "appointment_interval",
                              e.target.value
                            );
                          }}
                        >
                          <option value="5">5 Minutes </option>
                          <option value="10">10 Minutes </option>
                          <option value="15">15 Minutes (Default)</option>
                          <option value="20">20 Minutes</option>
                          <option value="25">25 Minutes</option>
                          <option value="30">30 Minutes</option>
                          <option value="35">35 Minutes</option>
                          <option value="40">40 Minutes</option>
                          <option value="45">45 Minutes</option>
                          <option value="50">50 Minutes</option>
                          <option value="55">55 Minutes</option>
                          <option value="60">60 Minutes</option>
                          <option value="65">65 Minutes</option>
                          <option value="70">70 Minutes</option>
                        </select>
                      </div>

                      <div className="timeslot-row w-100">
                        <label className="feild-label">
                          {" "}
                          Patients per slot:{" "}
                        </label>
                        <input
                          style={{ width: "30%", fontSize: "14px" }}
                          type="number"
                          className="form-control  form-feild"
                          defaultValue={officeDay.num_appointments_interval}
                          onChange={(e) => {
                            changeBookingTime(
                              officeDay.weekday,
                              "num_appointments_interval",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
        </div>

        <div className="timeslot-buttonbox">
          <button
            type="submit"
            className="btn btn-primary btn-sm text-capitalize"
          >
            Save changes
          </button>
        </div>
      </form>
    </div>
  );
};
