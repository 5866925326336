import React, { useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import Modal from "react-modal";
import {
  deletePriorityOfficeHour,
  resetDeletePriorityOfficeHour,
} from "../../../actions/officeSettingsActions";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "440px",
  },
};
export const PriorityBookingDeleteModal = (props: any) => {
  const dispatch = useDispatch();

  const priorityOfficeHour = useSelector(
    (state: RootStateOrAny) => state.officeSettingsReducer.priorityOfficeHour
  );

  const hasDeletedPriorityOfficeHour = useSelector(
    (state: RootStateOrAny) =>
      state.officeSettingsReducer.hasDeletedPriorityOfficeHour
  );

  const isDeletedPriorityOfficeHour = useSelector(
    (state: RootStateOrAny) =>
      state.officeSettingsReducer.isDeletedPriorityOfficeHour
  );

  useEffect(() => {
    if (hasDeletedPriorityOfficeHour) {
      props.setOpenModal(false);
      return () => {
        dispatch(resetDeletePriorityOfficeHour());
      };
    }
  }, [hasDeletedPriorityOfficeHour]);

  return (
    <Modal isOpen={true} style={customStyles}>
      <div
        id="export-data-popup"
        className=" stage4-popup fancybox-content updated-popup-form"
      >
        <form>
          <div>
            <div className="common-form">
              <div className="sub-heading common-subheading">
                <h3>Delete Priority Office Hour?</h3>
              </div>
              <div className="inner-forgot-pass inner-popup-content">
                <div className="snooze-datetime-main calendar-datetime" />
                <p>
                  Deleting this will revert back to your original Office Hour
                  times.
                </p>
                <div className="login-submit">
                  <a
                    href="#"
                    title="Cancel"
                    className="popup-btn whitebtn"
                    data-fancybox-close=""
                    onClick={(e) => {
                      e.preventDefault();
                      props.setOpenModal(false);
                    }}
                  >
                    Cancel
                  </a>
                  <button
                    type="submit"
                    title="Export Data"
                    className="popup-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(deletePriorityOfficeHour(priorityOfficeHour.pk));
                    }}
                  >
                    Delete Priority Date
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            data-fancybox-close=""
            className="fancybox-button fancybox-close-small"
            title="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1"
              viewBox="0 0 24 24"
            >
              <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
            </svg>
          </button>
        </form>
      </div>
    </Modal>
  );
};
