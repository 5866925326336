import axios from "axios";
import store from "store";
import moment from "moment";
import launchToast from "../utils";

export function resetHasCreatedRoom() {
  return {
    type: "RESET_HAS_CREATED_ROOM",
    hasCreatedRoom: false,
  };
}

export function resetParticipants() {
  return {
    type: "RESET_PARTICIPANTS",
    participants: [],
  };
}

export function resetHasCancelledMeeting() {
  return {
    type: "RESET_HAS_CANCELLED_MEETING",
    hasCancelledMeeting: false,
  };
}

export function resetMeetingsByOffice() {
  return {
    type: "RESET_MEETINGS_BY_OFFICE",
    meetings: [],
  };
}

function requestGetMeetingsByOffice() {
  return {
    type: "REQUEST_GET_MEETINGS_BY_OFFICE",
    isFetchingMeetings: true,
  };
}

function receiveGetMeetingsByOffice(meetings) {
  return {
    type: "RECEIVE_GET_MEETINGS_BY_OFFICE",
    isFetchingMeetings: false,
    meetings,
  };
}
function errorGetMeetingsByOffice(message) {
  return {
    type: "REQUEST_GET_MEETINGS_BY_OFFICE",
    isFetchingMeetings: false,
  };
}

function requestCreateMeeting() {
  return {
    type: "REQUEST_CREATE_MEETING",
    isCreatingMeeting: true,
  };
}

function receiveCreateMeeting(meeting) {
  return {
    type: "RECEIVE_CREATE_MEETING",
    isCreatingMeeting: false,
    createdMeeting: meeting,
  };
}

function errorCreateMeeting() {
  return {
    type: "ERROR_CREATE_MEETING",
    isCreatingMeeting: false,
  };
}

function requestGetMeetingByCode() {
  return {
    type: "REQUEST_GET_MEETING_BY_CODE",
    isFetchingMeeting: true,
  };
}

function receiveGetMeetingByCode(meeting) {
  return {
    type: "RECEIVE_GET_MEETING_BY_CODE",
    meeting,
    isFetchingMeeting: false,
  };
}

function errorGetMeetingByCode() {
  return {
    type: "ERROR_GET_MEETING_BY_CODE",
    isFetchingMeeting: false,
  };
}

function requestCreateVideoParticipants() {
  return {
    type: "REQUEST_CREATE_VIDEO_PARTICIPANTS",
    isCreatingVideoParticipants: true,
  };
}

function receiveCreateVideoParticipants() {
  return {
    type: "RECEIVE_CREATE_VIDEO_PARTICIPANTS",
    isCreatingVideoParticipants: false,
  };
}

function errorCreateVideoParticipants() {
  return {
    type: "ERROR_CREATE_VIDEO_PARTICIPANTS",
    isCreatingVideoParticipants: false,
  };
}

function requestGetParticipantByCode() {
  return {
    type: "REQUEST_GET_PARTICIPANT_BY_CODE",
    isFetchingParticipants: true,
  };
}

function receiveGetParticipantByCode(participants) {
  return {
    type: "RECEIVE_GET_PARTICIPANT_BY_CODE",
    isFetchingParticipants: false,
    participants,
  };
}

function errorGetParticipantByCode() {
  return {
    type: "ERROR_GET_PARTICIPANT_BY_CODE",
    isFetchingParticipants: false,
  };
}

function requestCancelMeeting() {
  return {
    type: "REQUEST_CANCEL_MEETING",
    isCancellingMeeting: true,
  };
}

function receiveCancelMeeting() {
  return {
    type: "RECEIVE_CANCEL_MEETING",
    isCancellingMeeting: false,
    hasCancelledMeeting: true,
  };
}

function errorCancelMeeting() {
  return {
    type: "ERROR_CANCEL_MEETING",
    isCancellingMeeting: false,
    hasCancelledMeeting: false,
  };
}

function requestUpdateMeeting() {
  return {
    type: "REQUEST_UPDATE_MEETING",
    isUpdatingMeeting: true,
  };
}

function receiveUpdateMeeting() {
  return {
    type: "RECEIVE_UPDATE_MEETING",
    isUpdatingMeeting: false,
  };
}

function errorUpdateMeeting() {
  return {
    type: "ERROR_UPDATE_MEETING",
    isUpdatingMeeting: false,
  };
}

export function getMeetingsByOffice(officeSlug) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/meet/video-room/office/${officeSlug}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetMeetingsByOffice());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetMeetingsByOffice(data));
      })
      .catch((err) => {
        dispatch(errorGetMeetingsByOffice(err.response));
      });
  };
}

export function createMeeting(data, officePk, admin_pk) {
  const token = store.get("id_token");
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/meet/video-room/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      name: data.title,
      patient: data.patient ? data.patient.value : null,
      public: data.public === "Yes" ? true : false,
      description: data.description,
      office: officePk,
      date_from: data.datetime,
      started_by: admin_pk,
      patient_invited: data.patientInvited,
    },
  };
  return (dispatch) => {
    dispatch(requestCreateMeeting());
    return axios(config)
      .then((res) => res.data)
      .then((res) => {
        dispatch(receiveCreateMeeting(res));
        dispatch(createVideoParticipants(data.guests, res.pk));
      })
      .catch((err) => {
        dispatch(errorCreateMeeting());
        console.log(err);
      });
  };
}

export function createVideoParticipants(guests, meetingPk) {
  // Update guests array to add
  const guestData = guests.map((guest) => {
    const o = Object.assign({}, guest);
    o.room = meetingPk;
    o.confirmed = true;
    return o;
  });

  const token = store.get("id_token");
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/meet/video-participants/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: guestData,
  };

  return (dispatch) => {
    dispatch(requestCreateVideoParticipants());
    return axios(config)
      .then((res) => {
        launchToast("Successfully created meeting");
        dispatch(receiveCreateVideoParticipants());
      })
      .catch((err) => {
        dispatch(errorCreateVideoParticipants());
        console.log(err);
      });
  };
}

export function createVideoParticipantsOnSingleMeeting(guests, meetingPk) {
  // Update guests array to add
  const guestData = guests.map((guest) => {
    const o = Object.assign({}, guest);
    o.room = meetingPk;
    o.confirmed = true;
    return o;
  });

  const token = store.get("id_token");
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/meet/video-participants/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: guestData,
  };

  return (dispatch) => {
    dispatch(requestCreateVideoParticipants());
    return axios(config)
      .then((res) => {
        dispatch(receiveCreateVideoParticipants());
      })
      .catch((err) => {
        dispatch(errorCreateVideoParticipants());
        console.log(err);
      });
  };
}

export function getMeetingByCode(meetingCode) {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/meet/video-room/${meetingCode}/`,
  };

  return (dispatch) => {
    dispatch(requestGetMeetingByCode());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetMeetingByCode(data));
      })
      .catch((err) => {
        dispatch(errorGetMeetingByCode(err.response));
        console.log(err.response);
      });
  };
}

export function getParticipantsByCode(meetingCode) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/meet/participants/${meetingCode}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetParticipantByCode());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetParticipantByCode(data));
      })
      .catch((err) => {
        dispatch(errorGetParticipantByCode(err.response));
        console.log(err.response);
      });
  };
}

export function createMeetingNow(officePk, admin) {
  const token = store.get("id_token");
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/meet/video-room/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      name: "Meeting",
      patient: null,
      public: true,
      description: "Meeting initiated by clicking Start Meeting Now",
      office: officePk,
      date_from: moment(Date.now()).toDate(),
      started_by: admin,
    },
  };
  return (dispatch) => {
    dispatch(requestCreateMeeting());
    return axios(config)
      .then((res) => res.data)
      .then((res) => {
        dispatch(receiveCreateMeeting(res));
        var win = window.open(
          `${process.env.REACT_APP_MEET_LINK}/${res.meeting_code}/?admin_code=${res.started_by_token}`,
          "_blank"
        );
      })
      .catch((err) => {
        dispatch(errorCreateMeeting());
        console.log(err);
      });
  };
}

export function cancelMeeting(meetingPk) {
  const token = store.get("id_token");
  let config = {
    method: "delete",
    url: `${process.env.REACT_APP_DEV_API}/meet/delete-video-room/${meetingPk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestCancelMeeting());
    return axios(config)
      .then((res) => {
        dispatch(receiveCancelMeeting());
        launchToast("Cancelled meeting successfully.");
      })
      .catch((err) => {
        console.log(err);
        dispatch(errorCancelMeeting());
      });
  };
}

export function deleteParticipant(participantPk) {
  const token = store.get("id_token");
  let config = {
    method: "delete",
    url: `${process.env.REACT_APP_DEV_API}/meet/delete-video-participant/${participantPk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    return axios(config)
      .then((res) => {
        launchToast(
          "Removed participant from meeting. They will be notified by email."
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function updateMeeting(meetingPk, data) {
  const token = store.get("id_token");
  let config = {
    method: "PATCH",
    url: `${process.env.REACT_APP_DEV_API}/meet/update-video-room/${meetingPk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      name: data.title,
      patient: data.patient ? data.patient.value : null,
      public: data.public === "Yes" ? true : false,
      description: data.description,
      date_from: data.datetime,
      patient_invited: data.patientInvited,
    },
  };

  return (dispatch) => {
    dispatch(requestUpdateMeeting());
    return axios(config)
      .then((res) => res.data)
      .then((resData) => {
        dispatch(receiveUpdateMeeting());

        dispatch(
          createVideoParticipantsOnSingleMeeting(data.guests, meetingPk)
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(errorUpdateMeeting());
      });
  };
}
