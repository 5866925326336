const initialState = {
  selectedTab: "",
  isFetchingOfficeHours: false,
  officeHours: [],
  priorityOfficeHours: [],
  newOfficeHours: [],
  priorityOfficeHour: null,
  isCreatingPriorityHour: false,
  hasCreatedPriorityHour: false,
  officeAdmins: [],
  isFetchingOfficeAdmins: false,
  isDeletingPriorityOfficeHour: false,
  hasDeletedPriorityOfficeHour: false,
  inviteErrorMessage: "",
};

const officeSettingsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "REQUEST_CREATE_NEW_PRIORITY_HOUR":
      return Object.assign({}, state, {
        isCreatingPriorityHour: action.isCreatingPriorityHour,
      });
    case "RECEIVE_CREATE_NEW_PRIORITY_HOUR":
      return Object.assign({}, state, {
        isCreatingPriorityHour: action.isCreatingPriorityHour,
        hasCreatedPriorityHour: action.hasCreatedPriorityHour,
      });
    case "ERROR_CREATE_NEW_PRIORITY_HOUR":
      return Object.assign({}, state, {
        isCreatingPriorityHour: action.isCreatingPriorityHour,
      });
    case "RESET_CREATE_NEW_PRIORITY_OFFICE_HOUR":
      return Object.assign({}, state, {
        isCreatingPriorityHour: action.isCreatingPriorityHour,
        hasCreatedPriorityHour: action.hasCreatedPriorityHour,
      });
    case "SET_OFFICE_SETTINGS_TAB":
      return Object.assign({}, state, {
        selectedTab: action.selectedTab,
      });
    case "REQUEST_GET_OFFICE_HOURS_BY_OFFICE":
      return Object.assign({}, state, {
        isFetchingOfficeHours: true,
      });
    case "RECEIVE_GET_OFFICE_HOURS_BY_OFFICE":
      return Object.assign({}, state, {
        officeHours: action.officeHours,
        isFetchingOfficeHours: false,
      });
    case "ERROR_GET_OFFICE_HOURS_BY_OFFICE":
      return Object.assign({}, state, {
        isFetchingOfficeHours: false,
      });
    case "REQUEST_GET_PRIORITY_OFFICE_HOURS_BY_OFFICE":
      return Object.assign({}, state, {
        isFetchingOfficeHours: true,
      });
    case "RECEIVE_GET_PRIORITY_OFFICE_HOURS_BY_OFFICE":
      return Object.assign({}, state, {
        priorityOfficeHours: action.priorityOfficeHours,
        isFetchingOfficeHours: false,
      });
    case "ERROR_GET_PRIORITY_OFFICE_HOURS_BY_OFFICE":
      return Object.assign({}, state, {
        isFetchingOfficeHours: false,
      });
    case "REQUEST_GET_PRIORITY_OFFICE_HOUR":
      return Object.assign({}, state, {
        isFetchingOfficeHours: true,
      });
    case "REQUEST_DELETE_PRIORITY_OFFICE_HOUR":
      return Object.assign({}, state, {
        isDeletingPriorityOfficeHour: true,
      });
    case "RECEIVE_DELETE_PRIORITY_OFFICE_HOUR":
      return Object.assign({}, state, {
        isDeletingPriorityOfficeHour: false,
        hasDeletedPriorityOfficeHour: true,
      });
    case "ERROR_DELETE_PRIORITY_OFFICE_HOUR":
      return Object.assign({}, state, {
        isDeletingPriorityOfficeHour: false,
        hasDeletedPriorityOfficeHour: false,
      });
    case "RESET_DELETE_PRIORITY_OFFICE_HOUR":
      return Object.assign({}, state, {
        isDeletingPriorityOfficeHour: false,
        hasDeletedPriorityOfficeHour: false,
      });
    case "RECEIVE_GET_PRIORITY_OFFICE_HOUR":
      return Object.assign({}, state, {
        priorityOfficeHour: action.priorityOfficeHour,
        isFetchingOfficeHours: false,
      });
    case "RESET_SELECT_PRIORITY_OFFICE_HOUR":
      return Object.assign({}, state, {
        priorityOfficeHour: null,
        isFetchingOfficeHours: false,
      });
    case "ERROR_GET_PRIORITY_OFFICE_HOUR":
      return Object.assign({}, state, {
        isFetchingOfficeHours: false,
      });
    case "REQUEST_GET_OFFICE_ADMINS":
      return Object.assign({}, state, {
        isFetchingOfficeAdmins: true,
      });
    case "RECEIVE_GET_OFFICE_ADMINS":
      return Object.assign({}, state, {
        isFetchingOfficeAdmins: false,
        officeAdmins: action.officeAdmins,
      });
    case "ERROR_GET_OFFICE_ADMINS":
      return Object.assign({}, state, {
        isFetchingOfficeAdmins: false,
      });
    case "SET_NEW_OFFICE_HOURS":
      return Object.assign({}, state, {
        newOfficeHours: action.newOfficeHours,
      });
    case "REQUEST_INVITE_USERS_TO_OFFICE":
      return Object.assign({}, state, {
        isFetchingInvites: true,
      });
    case "RECEIVE_INVITE_USERS_TO_OFFICE":
      return Object.assign({}, state, {
        hasSentInvite: true,
        isFetchingInvites: false,
        inviteErrorMessage: action.inviteErrorMessage,
      });
    case "ERROR_INVITE_USERS_TO_OFFICE":
      return Object.assign({}, state, {
        hasSentInvite: false,
        inviteErrorMessage: action.inviteErrorMessage,
      });
    case "UPDATE_NEW_OFFICE_HOURS":
      return Object.assign({}, state, {
        officeHours: state.officeHours.map((officeHour: any, i) =>
          i === action.day - 1
            ? { ...officeHour, [action.key]: action.value }
            : officeHour
        ),
      });
    default:
      return state;
  }
};

export default officeSettingsReducer;
