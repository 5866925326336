import React, { useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import {
  removeSymptom,
  selectSymptom,
  selectImmunization,
  removeImmunization,
} from "../../../actions/appointmentActions";

export const NewAppointmentImmunizationCheckbox = (props: any) => {
  const dispatch = useDispatch();
  const selectedSymptoms = useSelector(
    (state: RootStateOrAny) => state.appointmentReducer.selectedSymptoms
  );
  const office = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.office
  );

  return (
    <div className="checkbox-item w-100">
      <label className="checkbox-them" htmlFor={props.symptom.symptom_name}>
        <input
          type="checkbox"
          id={props.fluClinic ? props.symptom.name : props.symptom.symptom_name}
          name=""
          className="checkbox"
          checked={props.checked}
          onChange={(e) => {
            if (e.target.checked) {
              dispatch(selectImmunization(props.symptom.pk));
            } else {
              dispatch(removeImmunization(props.symptom.pk));
            }
          }}
        />
        <span className="checkbox-them-span">
          {props.fluClinic ? props.symptom.name : props.symptom.symptom_name}
        </span>
      </label>
    </div>
  );
};
