import store from "store";
import axios, { AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import moment from "moment";
import launchToast from "../utils";
import * as yup from "yup";
import { setDate } from "./appointmentActions";
import { Action } from "redux";

export function handleFormChange(name: any, value: any) {
  return {
    type: "SET_FORM_CHANGE",
    name,
    value,
  };
}
export function handleEmailFormChange(adminPk: number) {
  return {
    type: "SET_EMAIL_FORM_CHANGE",
    adminPk,
  };
}
export function setCreatedAppointment(value: any) {
  return {
    type: "SET_CREATED_APPOINTMENT_NUMBER",
    value,
  };
}

export function setForm(instance: number) {
  return {
    type: "SET_FORM",
    instance,
  };
}
export function setNotes(value: any) {
  return {
    type: "SET_NOTES",
    value,
  };
}
export function setEnableSummaryView(value: boolean) {
  return {
    type: "SET_ENABLE_SUMMARY_VIEW",
    value,
  };
}

export function updatePatientForm(number: any) {
  return {
    type: "UPDATE_PATIENT_FORMS",
    number,
  };
}
export function setNextThreeDays(threeDays: Array<any>) {
  return {
    type: "SET_NEXT_THREE_DAYS",
    threeDays,
  };
}

export function receiveFirstTimes(firstTimes: Array<string>) {
  return {
    type: "RECEIVE_FIRST_TIMES",
    firstTimes,
  };
}

export function receiveSecondTimes(secondTimes: Array<string>) {
  return {
    type: "RECEIVE_SECOND_TIMES",
    secondTimes,
  };
}

export function receiveThirdTimes(thirdTimes: Array<string>) {
  return {
    type: "RECEIVE_THIRD_TIMES",
    thirdTimes,
  };
}
export function resetPatientForms() {
  return {
    type: "RESET_PATIENT_FORMS",
  };
}

export function setEventType(value: any) {
  return {
    type: "SET_EVENT_TYPE",
    value,
  };
}
export function resetForm() {
  return {
    type: "RESET_FORM",
  };
}

export function updateFormInstance(instance: any, form: any) {
  return {
    type: "UPDATE_FORM_INSTANCE",
    instance,
    form,
  };
}

export function saveFormInstance(instance: any, name: any, value: any) {
  return {
    type: "SAVE_FORM_INSTANCE",
    instance,
    name: name,
    value: value,
  };
}

export function saveSymptom(instance: any, symptom: number) {
  return {
    type: "SAVE_SYMPTOM_INSTANCE",
    instance,
    symptom,
  };
}

export function addSymptomsToForm(instance: number, symptoms: Array<number>) {
  return {
    type: "ADD_SYMPTOMS_TO_FORM",
    instance,
    symptoms,
  };
}

export function addImmunizationsToForm(
  instance: number,
  immunizations: Array<number>
) {
  return {
    type: "ADD_IMMUNIZATIONS_TO_FORM",
    instance,
    immunizations,
  };
}

export function setAppointmentDate(newAppointmentDate: any) {
  return {
    type: "SET_APPOINTMENT_DATE",
    newAppointmentDate,
  };
}

export function setAppointmentTime(newAppointmentTime: any) {
  return {
    type: "SET_APPOINTMENT_TIME",
    newAppointmentTime,
  };
}

export function setNewAppointmentTab(newAppointmentTab: string | number) {
  return {
    type: "SET_NEW_APPOINTMENT_TAB",
    newAppointmentTab,
  };
}

export function setDependentsNumber(dependentsNumber: number) {
  return {
    type: "SET_DEPENDENTS_NUMBER",
    dependentsNumber,
  };
}

export function setDependentsOnly(dependentsOnly: boolean) {
  return {
    type: "SET_DEPENDENTS_ONLY",
    dependentsOnly,
  };
}

export function requestSubmitQuestionnaire() {
  return {
    type: "REQUEST_SUBMIT_QUESTIONNAIRE",
    isSubmittingQuestionnaire: true,
  };
}

export function receiveSubmitQuestionnaire() {
  return {
    type: "RECEIVE_SUBMIT_QUESTIONNAIRE",
    isSubmittingQuestionnaire: false,
    hasReceivedQuestionnaire: true,
  };
}

export function errorSubmitQuestionnaire() {
  return {
    type: "ERROR_SUBMIT_QUESTIONNAIRE",
    isSubmittingQuestionnaire: false,
  };
}

export function resetSubmitQuestionnaire() {
  return {
    type: "RESET_SUBMIT_QUESTIONNAIRE",
    isSubmittingQuestionnaire: false,
    hasReceivedQuestionnaire: false,
  };
}
export function validateSubmission(
  appointmentDetails: any,
  officePk: number,
  questionnaires: any
) {
  return (dispatch: Dispatch<any>) => {
    dispatch(requestSubmitQuestionnaire());
    let questionnaireSchema = yup.array().of(
      yup.object().shape({
        adminPk: yup.number(),
        patientPk: yup
          .number()
          .nullable()
          .transform((value: string, originalValue: string) =>
            originalValue.trim() === "" ? null : value
          ),
        first_name: yup.string().when(["adminPk", "patientPk"], {
          is: null,
          then: yup.string().required(),
        }),
        last_name: yup.string().when(["adminPk", "patientPk"], {
          is: null,
          then: yup.string().required(),
        }),
        phone: yup.string().when(["adminPk", "patientPk"], {
          is: null,
          then: yup.string().required(),
        }),
        email: yup.string().email(),
        ohipNum: yup.string().when(["adminPk", "patientPk"], {
          is: null,
          then: yup.string(),
        }),
        ohip_vc: yup.string().when(["adminPk", "patientPk"], {
          is: null,
          then: yup.string(),
        }),
        date_of_birth: yup.date().when(["adminPk", "patientPk"], {
          is: null,
          then: yup.date(),
        }),
        doctor: yup.string().when(["adminPk", "patientPk"], {
          is: null,
          then: yup.string(),
        }),
        address: yup.string().when(["adminPk", "patientPk"], {
          is: null,
          then: yup.string(),
        }),
        gender: yup.string().when(["adminPk", "patientPk"], {
          is: null,
          then: yup.string(),
        }),
        city: yup.string(),
        contact: yup.string(),
        contactDescription: yup.string(),
        requiredTesting: yup.string(),
        requiredTestingDescription: yup.string(),
        is_attending_school: yup.string(),
        sympton_startedfrom: yup.string(),
        studentNumber: yup.string(),
        greenshieldNumber: yup.string(),
        infoLabel: yup.string(),
        infoValue: yup.string(),
        dose: yup.string(),
      })
    );
    if (questionnaires.length === 0) {
      launchToast("Please fill out sections");
      dispatch(errorSubmitQuestionnaire());
    } else if (
      !appointmentDetails.newAppointmentDate ||
      !appointmentDetails.newAppointmentTime
    ) {
      dispatch(errorSubmitQuestionnaire());
      launchToast("Please enter a date and time for the appointment");
    } else {
      questionnaireSchema
        .validate(questionnaires)
        .then((res) => {
          if (appointmentDetails.newUser) {
            dispatch(
              submitQuestionnaire(
                appointmentDetails,
                officePk,
                questionnaires,
                appointmentDetails.newUser.adminPk,
                appointmentDetails.newUser.userPk
              )
            );
          } else {
            dispatch(
              createUserAccount(appointmentDetails, officePk, questionnaires)
            );
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(errorSubmitQuestionnaire());
          if (err.errors) {
            launchToast(err.errors[0]);
          }
        });
    }
  };
}

export function getFirstDay(officePk: number, date: string, spots: number) {
  let config: AxiosRequestConfig = {
    method: "get",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/appointment_datetimes/?office=${officePk}&date=${date}&spots=${spots}`,
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveFirstTimes(data));
      });
  };
}

export function getSecondDay(officePk: number, date: string, spots: number) {
  let config: AxiosRequestConfig = {
    method: "get",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/appointment_datetimes/?office=${officePk}&date=${date}&spots=${spots}`,
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveSecondTimes(data));
      });
  };
}

export function getThirdDay(officePk: number, date: string, spots: number) {
  let config: AxiosRequestConfig = {
    method: "get",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/appointment_datetimes/?office=${officePk}&date=${date}&spots=${spots}`,
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveThirdTimes(data));
      });
  };
}
export function createUserAccount(
  appointmentDetails: any,
  officePk: number,
  questionnaires: any
) {
  const token = store.get("id_token");

  const questionnaire = questionnaires[0];
  let config: AxiosRequestConfig = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/signup-patient/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      email: questionnaire.email,
      is_patient: true,
      patient_info: {
        office: [officePk],
        first_name: questionnaire.first_name,
        last_name: questionnaire.last_name,
        gender: questionnaire.gender,
        phone: questionnaire.phone,
        city: questionnaire.city,
        address: questionnaire.address,
        ohip_num: questionnaire.ohipNum
          ? questionnaire.ohipNum.replace(/-/gi, "")
          : null,
        ohip_vc: questionnaire.ohip_vc,
        doctor: questionnaire.doctor,
        postal_code: questionnaire.postal_code,
        date_of_birth: questionnaire.date_of_birth
          ? moment(questionnaire.date_of_birth, "MM/DD/YYYY").format(
              "YYYY-MM-DD"
            )
          : null,
        student_number: questionnaire.studentNumber,
        greenshield_number: questionnaire.greenshieldNumber,
        extra_info_label: questionnaire.infoLabel,
        extra_info_value: questionnaire.infoValue,
      },
    },
  };

  return (dispatch: Dispatch<any>) => {
    // dispatch(requestSubmitAppointment());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(
          submitQuestionnaire(
            appointmentDetails,
            officePk,
            questionnaires,
            data.pk,
            data.user.pk
          )
        );
        // dispatch(
        //   createAppointment(
        //     data.pk,
        //     appointmentDetails,
        //     officePk,
        //     questionnaire
        //   )
        // );
      })
      .catch((err) => {
        dispatch(errorSubmitQuestionnaire());
        console.log(err);
      });
  };
}

export function submitQuestionnaire(
  appointmentDetails: any,
  officePk: number,
  questionnaires: any,
  adminPk: number,
  userPk: number
) {
  return (dispatch: Dispatch<any>) => {
    questionnaires.forEach((questionnaire: any) => {
      if (questionnaire.patientPk) {
        dispatch(
          createAppointment(
            questionnaire.patientPk,
            appointmentDetails,
            officePk,
            questionnaire
          )
        );
      } else if (!questionnaire.email && !questionnaire.patientPk) {
        dispatch(
          createProfile(appointmentDetails, officePk, questionnaire, userPk)
        );
      } else {
        if (!appointmentDetails.dependentsOnly) {
          // dispatch(
          //   createProfile(appointmentDetails, officePk, questionnaire, userPk)
          // );
          console.log("dependentsonly");
          dispatch(
            createAppointment(
              adminPk,
              appointmentDetails,
              officePk,
              questionnaire
            )
          );
        }
      }
    });
  };
}

export function createProfile(
  appointmentDetails: any,
  officePk: number,
  questionnaire: any,
  adminPk: number
) {
  const token = localStorage.getItem("id_token");
  let config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/patient_create/`,
    data: {
      first_name: questionnaire.first_name,
      last_name: questionnaire.last_name,
      gender: questionnaire.gender,
      city: questionnaire.city,
      office: [officePk],
      doctor: questionnaire.doctor,
      ohip_num: questionnaire.ohipNum,
      address: questionnaire.address,
      postal_code: questionnaire.postal_code,
      phone: questionnaire.phone,
      ohip_vc: questionnaire.ohip_vc,
      school: questionnaire.school,
      date_of_birth: moment(questionnaire.date_of_birth, "MM/DD/YYYY").format(
        "YYYY-MM-DD"
      ),
      is_primary: false,
      user: adminPk,
      student_number: questionnaire.studentNumber,
      greenshield_number: questionnaire.greenshieldNumber,
      extra_info_label: questionnaire.infoLabel,
      extra_info_value: questionnaire.infoValue,
    },
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(
          createAppointment(
            data.pk,
            appointmentDetails,
            officePk,
            questionnaire
          )
        );
      })
      .catch((err) => {
        dispatch(errorSubmitQuestionnaire());
        console.log(err);
      });
  };
}

export function createAppointment(
  profilePk: number,
  appointmentDetails: any,
  officePk: number,
  questionnaire: any
) {
  const token = store.get("id_token");
  let config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/appointments/appointment_create/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      event_date: moment(appointmentDetails.newAppointmentDate).format(
        "YYYY-MM-DD"
      ),
      event_time: moment(appointmentDetails.newAppointmentTime, "LT").format(
        "HH:mm"
      ),
      office: officePk,
      patient: profilePk,
      booked_at: "admin",
      dose: questionnaire.dose,
    },
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(setCreatedAppointment(data.pk));
        if (questionnaire.immunizations) {
          dispatch(createImmunizations(questionnaire, data.pk));
        } else {
          dispatch(createCovidInfo(data.pk, questionnaire));
        }
        dispatch(
          setDate(moment(appointmentDetails.newAppointmentDate).toDate())
        );
      })
      .catch((err) => {
        dispatch(errorSubmitQuestionnaire());
        console.log(err);
      });
  };
}

export function createImmunizations(appointment: any, appointmentPk: number) {
  let config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/fluclinic/user-immunization-create/`,
    data: appointment.immunizations.map((immunization: any) => ({
      appointment: appointmentPk,
      immunization,
    })),
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(createExportFile(appointmentPk));
      });
  };
}

export function createCovidInfo(appointmentPk: number, questionnaire: any) {
  const token = store.get("id_token");
  let config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/covid/covid_info/`,
    data: {
      appointment: appointmentPk,
      sympton_startedfrom: questionnaire.sympton_startedfrom,
      contactedwith_covid: questionnaire.contact,
      contactedwith_description: questionnaire.contactDescription,
      required_testing: questionnaire.requiredTesting,
      required_testing_description: questionnaire.requiredTestingDescription,
      is_attending_school: questionnaire.is_attending_school,
      feeling_scale: questionnaire.feelingNumber,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        console.log(questionnaire);
        if (questionnaire.symptoms) {
          dispatch(
            createCovidSymptoms(questionnaire.symptoms, data.id, appointmentPk)
          );
        } else {
          dispatch(createExportFile(appointmentPk));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(errorSubmitQuestionnaire());
      });
  };
}

export function createCovidSymptoms(
  symptoms: Array<number>,
  covidInfoPk: number,
  appointmentPk: number
) {
  const token = store.get("id_token");
  let config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/covid/user_symptom_create/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: symptoms.map((symptom) => ({
      covid_info: covidInfoPk,
      symptom,
    })),
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        // launchToast("Successfully created Appointment");
        dispatch(createExportFile(appointmentPk));
      })
      .catch((err) => {
        // dispatch(errorSubmitAppointment());
        dispatch(errorSubmitQuestionnaire());
        console.log(err);
      });
  };
}

export function createExportFile(appointmentPk: number) {
  let config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/appointments/save_to_file/`,
    data: {
      appointmentPk,
    },
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        // dispatch(receiveSubmitAllData());
        dispatch(receiveSubmitQuestionnaire());
        launchToast("Appointment created successfully.");
      })
      .catch((err) => {
        dispatch(errorSubmitQuestionnaire());
      });
  };
}

function setPatientForms(number: number) {
  return {
    type: "SET_PATIENT_FORMS",
    number,
  };
}

export function removePatientForms(
  oldDependents: number,
  newDependents: number,
  patientForms: any
) {
  return (dispatch: Dispatch<any>) => {
    if (oldDependents > newDependents) {
      dispatch(setPatientForms(newDependents));
    }
  };
}

function requestGetDependents() {
  return {
    type: "REQUEST_GET_DEPENDENTS",
    isGettingDependents: true,
  };
}

function receiveGetDependents(dependents: any) {
  return {
    type: "RECEIVE_GET_DEPENDENTS",
    isGettingDependents: false,
    dependents,
  };
}

export function getDependents(userPk: number) {
  const token = store.get("id_token");
  let config: AxiosRequestConfig = {
    method: "get",
    url: `${
      process.env.REACT_APP_DEV_API
    }/get-user-dependents/?userPk=${userPk}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch: Dispatch<Action>) => {
    dispatch(requestGetDependents());
    return axios(config)
      .then((res) => {
        const patients = res.data.map((entry: any) => ({
          value: entry.pk,
          label: `${entry.first_name} ${entry.last_name}`,
        }));

        dispatch(receiveGetDependents(patients));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
