import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import archiveButton from "../../assets/images/view-archive.png";
import moment from "moment";
import ScrollToBottom from "react-scroll-to-bottom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  getMessageThread,
  uploadFileToThread,
} from "../../actions/messagingActions";
import { BounceLoader } from "react-spinners";
import closePrimary from "../../assets/images/close-primary.png";
import { ThreadMessage } from "./ThreadMessage";

function ThreadChat(props) {
  const [showDots, setShowDots] = useState(false);
  const dispatch = useDispatch();
  const text = useRef();
  const uploadedFile = useRef(null);
  const selectedThread = useSelector(
    (state) => state.messagingReducer.selectedThread,
    shallowEqual
  );
  const profile = useSelector((state) => state.authenticateUser.profile);

  const [fileUpload, setFileUpload] = useState(null);
  const [fileUploadName, setFileUploadName] = useState("");

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  function listenMessage(e) {
    const response_data = JSON.parse(e.data);
    if (response_data.type == "ping_selected_thread") {
      if (props.thread) {
        dispatch(getMessageThread(props.thread));
      }
    }
  }

  const prevThread = usePrevious(props.thread);

  useEffect(() => {
    if (prevThread !== props.thread) {
      dispatch(getMessageThread(props.thread));
    }
    props.socket.addEventListener("message", listenMessage);

    return () => {
      props.socket.removeEventListener("message", listenMessage);
      // dispatch(resetSelectedThread());
      console.log("BYE");
    };
  }, [dispatch, listenMessage, prevThread, props.socket, props.thread]);

  useEffect(() => {
    props.socket.send(
      JSON.stringify({
        type: "SET_THREAD_READ_STATUS",
        threadId: props.thread,
        read: true,
        office_slug: props.office.slug,
      })
    );
  }, [props.office.slug, props.socket, props.thread, selectedThread]);

  return selectedThread ? (
    <div class="col-7 right-sec">
      <div class="white-box inbox-info-right">
        <div class="inbox-right-head">
          <div class="inbox-hd">
            <h3>
              {selectedThread.patient__first_name}{" "}
              {selectedThread.patient__last_name}
            </h3>
            <h4>{selectedThread.subject}</h4>
          </div>
          <div class="conversations" id="archive-show3">
            <a
              href="javascript:void(0);"
              class="conversation"
              onClick={(e) => {
                e.preventDefault();
                if (showDots === true) {
                  setShowDots(false);
                } else {
                  setShowDots(true);
                }
              }}
            >
              <img src={archiveButton} alt="" />
            </a>
            <div
              class="archive-show"
              style={{ display: `${showDots ? "block" : "none"}` }}
            >
              <ul>
                <li>
                  {selectedThread.status === "followup" ? (
                    <a
                      href="#"
                      title="Follow Up"
                      class="followup"
                      onClick={(e) => {
                        e.preventDefault();
                        props.socket.send(
                          JSON.stringify({
                            type: "SET_THREAD_STATUS",
                            threadId: props.thread,
                            status: "",
                            office_slug: props.office.slug,
                          })
                        );
                        setShowDots(false);
                      }}
                    >
                      Remove
                    </a>
                  ) : (
                    <a
                      href="#"
                      title="Follow Up"
                      class="followup"
                      onClick={(e) => {
                        e.preventDefault();
                        props.socket.send(
                          JSON.stringify({
                            type: "SET_THREAD_STATUS",
                            threadId: props.thread,
                            status: "followup",
                            office_slug: props.office.slug,
                          })
                        );
                        setShowDots(false);
                      }}
                    >
                      Follow Up
                    </a>
                  )}
                </li>
                <li>
                  {selectedThread.status === "important" ? (
                    <a
                      href="#"
                      title="Remove"
                      class="important"
                      onClick={(e) => {
                        e.preventDefault();
                        props.socket.send(
                          JSON.stringify({
                            type: "SET_THREAD_STATUS",
                            threadId: props.thread,
                            status: "",
                            office_slug: props.office.slug,
                          })
                        );
                        setShowDots(false);
                      }}
                    >
                      Remove
                    </a>
                  ) : (
                    <a
                      href="#"
                      title="Important"
                      class="important"
                      onClick={(e) => {
                        e.preventDefault();
                        props.socket.send(
                          JSON.stringify({
                            type: "SET_THREAD_STATUS",
                            threadId: props.thread,
                            status: "important",
                            office_slug: props.office.slug,
                          })
                        );
                        setShowDots(false);
                      }}
                    >
                      Important
                    </a>
                  )}
                </li>
                <li>
                  <a
                    href="#"
                    title="Archive"
                    onClick={(e) => {
                      e.preventDefault();
                      props.socket.send(
                        JSON.stringify({
                          type: "SET_ARCHIVE_THREAD",
                          threadId: props.thread,
                          office_slug: props.office.slug,
                        })
                      );
                      dispatch(getMessageThread(props.thread));
                    }}
                  >
                    Archive
                  </a>
                </li>
                {/* <li>
                  <a href="#" title="Delete">
                    Delete
                  </a>
                </li> */}
                <li>
                  <Link
                    to={`/office/${props.office.slug}/patient/${
                      selectedThread.patient
                    }/`}
                  >
                    view profile
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <div class="selected-msgs">
                  <span>Selected: 2 Messages</span>
                  <a href="#" class="copy-selected-msg" title="copy">
                    Copy
                  </a>
                </div> */}

        <ScrollToBottom className="chat-content chat-with-selected-msg">
          {selectedThread.message_set.map((message) => (
            <ThreadMessage message={message} profile={profile} />
          ))}
        </ScrollToBottom>
        {fileUploadName && (
          <div class="upload-imgs-main">
            <div class="upload-imgs">
              <img
                src={closePrimary}
                className="x-button"
                alt=""
                onClick={(e) => {
                  uploadedFile.current.value = null;

                  setFileUploadName("");
                }}
              />
              <span id="custom-text">
                {fileUploadName.split(/(\\|\/)/g).pop()}
              </span>
            </div>
          </div>
        )}
        {!selectedThread.archive ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (text.current.value && !uploadedFile.current.files[0]) {
                props.socket.send(
                  JSON.stringify({
                    type: "ADD_MESSAGE_TO_THREAD",
                    message: text.current.value,
                    userId: props.profile.user.pk,
                    threadId: props.thread,
                    office_slug: props.office.slug,
                  })
                );
                text.current.value = "";
              } else if (uploadedFile.current.files[0]) {
                dispatch(
                  uploadFileToThread(
                    props.thread,
                    uploadedFile.current.files[0],
                    text.current.value,
                    props.profile.user.pk,
                    props.profile.pk
                  )
                );
                uploadedFile.current.value = null;
                text.current.value = "";
                setFileUploadName("");
              }

              // dispatch(getMessageThread(props.thread));
            }}
          >
            <div class="type-msg">
              <input
                type="text"
                placeholder="Type a message..."
                class="common-input"
                ref={text}
              />
              <div class="copy-msg">
                {/* <a href="javascript:void(0);" title="copy" class="copy"></a> */}
                {/* <div class="copy-msg-open white-box">Copy Conversation</div> */}
                <div class="attachment-msg">
                  <label htmlFor="upload-file" class="attachment fancybox" />
                  <input
                    id="upload-file"
                    type="file"
                    title="attachment"
                    style={{ display: "none" }}
                    ref={uploadedFile}
                    onChange={(e) => {
                      setFileUpload(e.target.files[0]);
                      setFileUploadName(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
        ) : (
          ""
        )}
      </div>
    </div>
  ) : (
    <BounceLoader size={60} color={"#052D64"} />
  );
}
export default ThreadChat;
