import React, { useRef } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signUpUser } from "../actions/authActions";

function SignUp() {
  const dispatch = useDispatch();
  // Refs
  const email = useRef(null);
  const password = useRef(null);
  const bio = useRef(null);

  // State variables
  const isFetching = useSelector((state) => state.authenticateUser.isFetching);
  const isAuthenticated = useSelector(
    (state) => state.authenticateUser.isAuthenticated
  );
  const errorMessage = useSelector(
    (state) => state.authenticateUser.errorMessage
  );
  const hasSignedUp = useSelector(
    (state) => state.authenticateUser.hasSignedUp
  );

  return (
    <div>
      <h1>Sign Up as Patient</h1>
      <div>
        <label htmlFor="email">Email Address</label>
        <input type="email" name="email" ref={email} />
      </div>
      <div>
        <label htmlFor="password">Password</label>
        <input type="password" name="password" ref={password} />
      </div>
      <div>
        <label htmlFor="bio">Bio</label>
        <input type="text" name="bio" ref={bio} />
      </div>
      <button
        onClick={() =>
          dispatch(
            signUpUser({
              email: email.current.value,
              password: password.current.value,
              bio: bio.current.value,
            })
          )
        }
      >
        Sign Up
      </button>
      {hasSignedUp ? <Redirect to="/login" /> : ""}
    </div>
  );
}

export default SignUp;
