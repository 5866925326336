import axios from "axios";
import store from "store";
// LOG IN USERS

function requestChangePassword() {
  return {
    type: "REQUEST_CHANGE_PASSWORD",
    isSigningUpUser: true,
    signUpMessage: "Updating your password.",
  };
}

function receiveChangePassword() {
  return {
    type: "RECEIVE_CHANGE_PASSWORD",
    signUpMessage: "Updated password successfully.",
  };
}

function errorChangePassword() {
  return {
    type: "ERROR_CHANGE_PASSWORD",
    isSigningUpUser: false,
    signUpMessage:
      "There was an error changing your password. Please try again or contact us for support.",
  };
}

function requestUpdateAdmin() {
  return {
    type: "REQUEST_UPDATE_ADMIN",
    signUpMessage: "Updating your info.",
  };
}

function receiveUpdateAdmin() {
  return {
    type: "RECEIVE_UPDATE_ADMIN",
    signUpMessage:
      "Updated your info successfully. Please click below to login.",
  };
}

function errorUpdateAdmin() {
  return {
    type: "ERROR_UPDATE_ADMIN",
    isSigningUpUser: false,
    signUpMessage:
      "We couldn't update your first and last name. Please try again or contact us for support.",
  };
}

export function userLogout() {
  return {
    type: "USER_LOGOUT",
  };
}

export const requestLogin = (creds) => {
  return {
    type: "LOGIN_USER",
    isFetching: true,
    isAuthenticating: false,
    creds,
  };
};

function receiveLogin(email) {
  return {
    type: "LOGIN_SUCCESS",
    isFetching: false,
    isAuthenticated: true,
    email: email,
  };
}

function receiveLoginError(error) {
  return {
    type: "LOGIN_FAILURE",
    isFetching: false,
    isAuthenticated: false,
    error_message: error,
  };
}

function requestLogout() {
  return {
    type: "LOGOUT_REQUEST",
    isFetching: true,
    isAuthenticated: true,
  };
}

function receiveLogout() {
  return {
    type: "LOGOUT_SUCCESS",
    isFetching: false,
    isAuthenticated: false,
    profile: {},
  };
}

function requestSignup(creds) {
  return {
    type: "SIGNUP_REQUEST",
    isFetching: true,
    isAuthenticated: false,
    creds,
  };
}

function receiveSignup() {
  return {
    type: "SIGNUP_RECEIVE",
    isFetching: false,
    isAuthenticated: false,
    hasSignedUp: true,
  };
}

function receiveSignupError(error) {
  return {
    type: "SIGNUP_ERROR",
    isFetching: false,
    isAuthenticated: false,
    errorMessage: error,
  };
}

function requestAdminProfile() {
  return {
    type: "ADMIN_PROFILE_REQUEST",
    isFetching: true,
    isAuthorized: true,
  };
}
function receiveAdminProfile(data) {
  return {
    type: "ADMIN_PROFILE_RECEIVE",
    isFetching: false,
    isAuthorized: true,
    profile: data,
  };
}

function requestGetOfficeInvites() {
  return {
    type: "REQUEST_GET_OFFICE_INVITES",
    isFetchingInvites: true,
    receivedInvites: false,
  };
}

function receiveGetOfficeInvites(invites) {
  return {
    type: "RECEIVE_GET_OFFICE_INVITES",
    isFetchingInvites: false,
    receivedInvites: true,
    invites,
  };
}

function requestResetPassword() {
  return {
    type: "REQUEST_RESET_PASSWORD",
    isResettingPassword: true,
    confirmationMessage: "",
  };
}

function receiveResetPassword() {
  return {
    type: "RECEIVE_RESET_PASSWORD",
    isResettingPassword: false,
    confirmationMessage:
      "Password reset has been sent. Check your email for instructions.",
  };
}

function errorResetPassword(message) {
  return {
    type: "RECEIVE_RESET_PASSWORD",
    isResettingPassword: false,
    confirmationMessage: message,
  };
}

function requestResetPasswordConfirm() {
  return {
    type: "REQUEST_RESET_PASSWORD_CONFIRM",
    isResettingPassword: true,
    confirmationMessage: "",
  };
}

function receiveResetPasswordConfirm() {
  return {
    type: "RECEIVE_RESET_PASSWORD_CONFIRM",
    isResettingPassword: false,
    confirmationMessage:
      "Your password was reset. Please login to start using Corigan.",
  };
}

function requestApproveAdminOfficeRequest() {
  return {
    type: "REQUEST_APPROVE_ADMIN_OFFICE_REQUEST",
    isFetchingApproval: true,
  };
}

function requestDenyAdminOfficeRequest() {
  return {
    type: "REQUEST_DENY_ADMIN_OFFICE_REQUEST",
    isFetchingApproval: true,
  };
}

function receiveApproveAdminOfficeRequest(data) {
  return {
    type: "RECEIVE_APPROVE_ADMIN_OFFICE_REQUEST",
    isFetchingApproval: false,
  };
}

function receiveDenyAdminOfficeRequest() {
  return {
    type: "RECEIVE_DENY_ADMIN_OFFICE_REQUEST",
    isFetchingApproval: false,
  };
}

function requestSingleOfficeInvite() {
  return {
    type: "REQUEST_SINGLE_OFFICE_INVITE",
    isFetchingOfficeInvite: true,
    officeInviteMessage: "",
  };
}

function receiveSingleOfficeInvite(invite) {
  return {
    type: "RECEIVE_SINGLE_OFFICE_INVITE",
    isFetchingOfficeInvite: false,
    officeInviteMessage: "Retrieved office invite.",
    officeInvite: invite,
  };
}

function errorSingleOfficeInvite(err) {
  return {
    type: "ERROR_SINGLE_OFFICE_INVITE",
    isFetchingOfficeInvite: false,
    officeInviteMessage: err,
  };
}

export function requestCreateOffice() {
  return {
    type: "REQUEST_CREATE_OFFICE",
    isCreatingOffice: true,
    hasCreatedOffice: false,
  };
}

export function receiveCreateOffice() {
  return {
    type: "RECEIVE_CREATE_OFFICE",
    isCreatingOffice: false,
    hasCreatedOffice: true,
  };
}

// SIGN UP USERS

export function loginUser(creds) {
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/login/`,
    data: {
      email: `${creds.email}`,
      password: `${creds.password}`,
    },
    headers: {
      "Content-Type": "application/json",
    },
  };

  return (dispatch) => {
    dispatch(requestLogin(creds));
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        if (!data.success) {
          console.log("we are in here");
          dispatch(receiveLoginError("something wrong happened. Try again"));
        } else {
          store.set("id_token", data.token);
          dispatch(getAdminProfile());
          dispatch(receiveLogin(data.email));
        }
      })
      .catch((err) => {
        dispatch(receiveLoginError("Something wrong happened. Try again."));
      });
  };
}

export function logoutUser() {
  return (dispatch) => {
    dispatch(requestLogout());
    store.remove("id_token");
    dispatch(receiveLogout());
  };
}

export function signUpUser(userInfo) {
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/signup/`,
    data: {
      email: userInfo.email,
      password: userInfo.password,
      is_patient: true,
      patient_info: {
        bio: userInfo.bio,
      },
    },
    headers: {
      "Content-Type": "application/json",
    },
  };
  return (dispatch) => {
    dispatch(requestSignup(userInfo));
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        if (!data.success) {
          dispatch(receiveSignupError("Sign Up failed"));
          return Promise.reject();
        } else {
          console.log(data);
          dispatch(receiveSignup());
        }
      })
      .catch((err) => console.log(err));
  };
}

export function getAdminProfile() {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/current_admin/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestAdminProfile());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveAdminProfile(data));
        // dispatch(getOfficeInvites(data.pk));
      })
      .catch((err) => {
        console.log(err);
        dispatch(userLogout());
      });
  };
}

export function createOffice(officeName, user_pk) {
  const token = store.get("id_token");
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/create_offices/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      name: officeName,
      user_pk: user_pk.toString(),
    },
  };

  return (dispatch) => {
    dispatch(requestCreateOffice());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveCreateOffice());
      })
      .then(() => {
        dispatch(getAdminProfile());
      })
      .catch((err) => console.log(err));
  };
}

export function getOfficeInvites(admin_pk) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/admin_office_requests/${admin_pk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetOfficeInvites());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetOfficeInvites(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function acceptAdminOfficeRequest(pk, admin_pk) {
  const token = store.get("id_token");
  let config = {
    method: "patch",
    url: `${process.env.REACT_APP_DEV_API}/update_admin_office_request/${pk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      is_approved: true,
    },
  };

  return (dispatch) => {
    dispatch(requestApproveAdminOfficeRequest());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveApproveAdminOfficeRequest(data));
      })
      .then(() => {
        dispatch(getAdminProfile());
        dispatch(getOfficeInvites(admin_pk));
      })
      .catch((err) => console.log(err));
  };
}

//Axios Config to deny and admin office request
export function denyAdminOfficeRequest(pk, admin_pk) {
  const token = store.get("id_token");
  let config = {
    method: "patch",
    url: `${process.env.REACT_APP_DEV_API}/update_admin_office_request/${pk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      is_declined: true,
    },
  };

  return (dispatch) => {
    dispatch(requestDenyAdminOfficeRequest());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveDenyAdminOfficeRequest(data));
      })
      .then(() => {
        dispatch(getAdminProfile());
        dispatch(getOfficeInvites(admin_pk));
      })
      .catch((err) => console.log(err));
  };
}

export function resetPassword(email) {
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/api/password_reset/`,
    data: {
      email: email,
    },
  };

  return (dispatch) => {
    dispatch(requestResetPassword());
    return axios(config)
      .then((res) => {
        dispatch(receiveResetPassword());
      })
      .catch((err) => {
        if (err.response.email) {
          dispatch(errorResetPassword(err.response.email[0]));
        } else {
          dispatch(errorResetPassword("Could not reset email."));
        }
      });
  };
}

export function resetPasswordConfirm(password, token) {
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/api/password_reset/confirm/`,
    data: {
      password: password,
      token: token,
    },
  };

  return (dispatch) => {
    dispatch(requestResetPasswordConfirm());
    return axios(config)
      .then((res) => {
        dispatch(receiveResetPasswordConfirm());
      })
      .catch((err) => {
        if (err.response.email) {
          dispatch(errorResetPassword(err.response.email[0]));
        } else {
          dispatch(errorResetPassword("Could not reset email."));
        }
      });
  };
}

export function retrieveOfficeInvite(token) {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/admin_office_request/${token}/`,
  };

  return (dispatch) => {
    dispatch(requestSingleOfficeInvite());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveSingleOfficeInvite(data));
      })
      .catch((err) => {
        dispatch(errorSingleOfficeInvite(err.response));
      });
  };
}

export function updateUserAndAcceptInvite(token, userInfo) {
  let config = {
    method: "patch",
    url: `${process.env.REACT_APP_DEV_API}/update_user_password/${
      userInfo.pk
    }/`,
    data: {
      token,
      password: userInfo.password,
    },
  };

  return (dispatch) => {
    dispatch(requestChangePassword());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveChangePassword());
        // update first and last name
        dispatch(
          updateUserFirstAndLastName(
            token,
            userInfo.firstName,
            userInfo.lastName,
            userInfo.pk
          )
        );
      })
      .catch((err) => dispatch(errorChangePassword()));
  };
}

function updateUserFirstAndLastName(token, firstName, lastName, pk) {
  let config = {
    method: "patch",
    url: `${process.env.REACT_APP_DEV_API}/update_admin/${pk}/`,
    data: {
      first_name: firstName,
      last_name: lastName,
    },
  };

  return (dispatch) => {
    dispatch(requestUpdateAdmin());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveUpdateAdmin());
      })
      .catch((err) => dispatch(errorUpdateAdmin()));
  };
}
