import React, { useEffect, useState } from "react";
import DashboardTop from "../../dashboardTop";
import { useSelector, useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getSymptomsList,
  selectSymptom,
  removeSymptom,
  getAppointment,
  resetAppointment,
  resetDeletedAppointment,
  updateUserInfo,
  resetSymptom,
  resetCovidInfo,
  resetUpdateData,
  getAppointmentsByDateTime,
  resetGetAppointmentsByDateTime,
  getAppointmentInterval,
  getImmunizationList,
  resetImmunizationData,
  selectImmunization,
  removeImmunization,
} from "../../../actions/appointmentActions";
import { BounceLoader } from "react-spinners";
import moment from "moment";
import { Redirect } from "react-router-dom";
import { CancelForm } from "./CancelForm";
import { isCompositeComponent } from "react-dom/test-utils";

export const ExistingAppointment = (props) => {
  const { control, register, handleSubmit, watch, errors } = useForm();
  const dispatch = useDispatch();
  const office = useSelector((state) => state.adminOfficeInteractions.office);
  const isFetchingAppointmentDateTimes = useSelector(
    (state) => state.appointmentReducer.isFetchingAppointmentDateTimes
  );
  const hasReceivedAppointmentDateTimes = useSelector(
    (state) => state.appointmentReducer.hasReceivedAppointmentDateTimes
  );
  const dateTimes = useSelector((state) => state.appointmentReducer.dateTimes);

  const appointmentInterval = useSelector(
    (state) => state.appointmentReducer.appointmentInterval
  );
  const [otherActions, setOtherActions] = useState(false);
  const [cancelForm, setCancelForm] = useState(false);
  const formSubmit = (data) => {
    console.log(data);
    if (office.immunization_clinic) {
      dispatch(
        updateUserInfo(
          data,
          appointment.patient.pk,
          appointment.pk,
          undefined,
          selectedSymptoms,
          selectedImmunizations
        )
      );
    } else {
      dispatch(
        updateUserInfo(
          data,
          appointment.patient.pk,
          appointment.pk,
          covidInfo.id,
          selectedSymptoms,
          selectedImmunizations
        )
      );
    }
  };

  const symptoms = useSelector((state) => state.appointmentReducer.symptoms);
  const immunizations = useSelector(
    (state) => state.newAppointmentReducer.immunizations
  );
  const selectedSymptoms = useSelector(
    (state) => state.appointmentReducer.selectedSymptoms
  );
  const selectedImmunizations = useSelector(
    (state) => state.appointmentReducer.selectedImmunizations
  );
  const isUpdatingData = useSelector(
    (state) => state.appointmentReducer.isUpdatingData
  );
  const hasUpdatedData = useSelector(
    (state) => state.appointmentReducer.hasUpdatedData
  );

  const appointment = useSelector(
    (state) => state.appointmentReducer.appointment
  );

  const covidInfo = useSelector((state) => state.appointmentReducer.covidInfo);
  const userImmunization = useSelector(
    (state) => state.appointmentReducer.userImmunization
  );

  const watchEventDate = watch("eventDate");
  const watchEventTime = watch("eventTime");
  useEffect(() => {
    dispatch(resetDeletedAppointment());
    dispatch(resetAppointment());
    if (office.immunization_clinic) {
      dispatch(getImmunizationList());
    } else {
      dispatch(getSymptomsList());
    }
    dispatch(getAppointment(window.location.href.split("/")[7], office));
    return () => {
      dispatch(resetSymptom());
      dispatch(resetAppointment());
      dispatch(resetImmunizationData());
      dispatch(resetCovidInfo());
      dispatch(resetUpdateData());
    };
  }, [dispatch, office]);

  useEffect(() => {
    if (covidInfo) {
      covidInfo.covid_info_symptoms.forEach((symptom) =>
        dispatch(selectSymptom(symptom.symptom))
      );
    }
  }, [covidInfo, dispatch]);

  // useEffect(() => {
  //   if (covidInfo) {
  //     covidInfo.covid_info_symptoms.forEach((symptom) =>
  //       dispatch(selectSymptom(symptom.symptom))
  //     );
  //   }
  // }, [, dispatch]);

  useEffect(() => {
    if (watchEventDate && watchEventTime) {
      dispatch(
        getAppointmentsByDateTime(
          props.office.pk,
          moment(watchEventDate).format("YYYY-MM-DD"),
          moment(watchEventTime).format("HH:mm:00")
        )
      );
    }
    return () => dispatch(resetGetAppointmentsByDateTime());
  }, [dispatch, props.office.pk, watchEventDate, watchEventTime]);

  const [EventDate, setEventDate] = useState();
  const [EventTime, setEventTime] = useState();

  useEffect(() => {
    if (appointment) {
      dispatch(
        getAppointmentInterval(
          props.office.pk,
          moment(appointment.event_date).format("MM-DD-YYYY")
        )
      );
    }
  }, [appointment, dispatch, props.office.pk]);

  return (
    <div>
      <DashboardTop
        title={`Edit Event`}
        back={true}
        backLink={`/office/${props.office.slug}/appointments/`}
      />
      {hasUpdatedData && (
        <Redirect to={`/office/${props.office.slug}/appointments/`} />
      )}
      <div class="">
        <div class="dash-content-wrapper">
          <div class="create-office-main">
            {appointment ? (
              <div class="white-box">
                <form onSubmit={handleSubmit(formSubmit)}>
                  <div class="event-head">
                    <h2>Event Details</h2>
                    <div class="event-btns">
                      <ul>
                        <li>
                          <a
                            href="#action"
                            title=""
                            class="other-actions"
                            onClick={(e) => {
                              e.preventDefault();
                              setOtherActions(!otherActions);
                            }}
                          >
                            <span>Other Actions</span>
                          </a>
                          {otherActions && (
                            <div class="archive-show">
                              <ul>
                                <li>
                                  <a
                                    href={appointment.appointment_doc}
                                    title="Export Data"
                                    download
                                  >
                                    Export Data
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#cancel-event-popup"
                                    title="Cancel Appointment"
                                    class="fancybox"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setCancelForm(true);
                                      // dispatch(
                                      //   deleteAppointment(
                                      //     window.location.href.split("/")[7]
                                      //   )
                                      // );
                                    }}
                                  >
                                    Cancel Appointment
                                  </a>
                                </li>
                              </ul>
                            </div>
                          )}
                        </li>
                        <li>
                          {!isUpdatingData ? (
                            <button
                              type="submit"
                              title="Save"
                              class="common-btn blue-btn"
                            >
                              {" "}
                              Save{" "}
                            </button>
                          ) : (
                            <BounceLoader color={"#052D64"} size={50} />
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="office-form app-event-details-form">
                    <div class="form-group">
                      <div class="half-group left">
                        <label>Date:</label>
                        <Controller
                          name="eventDate"
                          control={control}
                          defaultValue={moment(appointment.event_date).toDate()}
                          render={(
                            { onChange, onBlur, value, name, ref },
                            { invalid, isTouched, isDirty }
                          ) => (
                            <DatePicker
                              dateFormat="MM/dd/yyyy"
                              showMonthDropdown
                              onChange={(e) => onChange(e)}
                              autoComplete="off"
                              selected={value}
                              className="common-input"
                            />
                          )}
                        />
                      </div>
                      <div class="half-group right">
                        <label>Time:</label>
                        <Controller
                          name="eventTime"
                          control={control}
                          defaultValue={moment(appointment.event_time, "hh:mm:ss").toDate()}
                          render={(
                            { onChange, onBlur, value, name, ref },
                            { invalid, isTouched, isDirty }
                          ) => (
                            <DatePicker
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              showTimeSelect
                              className="common-input"
                              showTimeSelectOnly
                              timeIntervals={appointmentInterval}
                              onChange={(e) => onChange(e)}
                              autoComplete="off"
                              selected={value}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      {isFetchingAppointmentDateTimes && (
                        <BounceLoader color={"#052D64"} size={50} />
                      )}

                      {hasReceivedAppointmentDateTimes &&
                        (dateTimes.length > 0 ? (
                          <p>
                            There are currently {dateTimes.length} appointments
                            booked at this time.
                          </p>
                        ) : (
                          <p>No appointments are booked at this time!</p>
                        ))}
                    </div>
                    {/* <div class="form-group">
                      <div class="half-group left">
                        <label>Type:</label>
                        <select
                          class="common-input"
                          ref={register({ required: true })}
                        >
                          <option>Does not Repeat</option>
                          <option>Does not Repeat</option>
                        </select>
                      </div>
                    </div> */}
                    <div class="personal-contact-details">
                      <h5>Personal/Contact Details</h5>
                      <div class="form-group">
                        <div class="full-width">
                          <label>Preferred Name:</label>
                          <input
                            type="text"
                            class="common-input"
                            ref={register()}
                            name="preferredName"
                            defaultValue={appointment.patient.preferred_name}
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="half-group left">
                          <label>First Name:</label>
                          <input
                            type="text"
                            class="common-input"
                            ref={register({ required: true })}
                            name="firstName"
                            defaultValue={appointment.patient.first_name}
                          />
                        </div>
                        <div class="half-group right">
                          <label>Last Name:</label>
                          <input
                            type="text"
                            class="common-input"
                            ref={register({ required: true })}
                            name="lastName"
                            defaultValue={appointment.patient.last_name}
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="half-group left">
                          <label>OHIP #:</label>
                          <input
                            type="text"
                            class="common-input"
                            ref={register({ required: true })}
                            name="ohipNum"
                            maxLength="10"
                            defaultValue={appointment.patient.ohip_num}
                          />
                        </div>
                        <div class="half-group right">
                          <label>OHIP VC:</label>
                          <input
                            type="text"
                            class="common-input"
                            ref={register({ required: true })}
                            name="ohipVC"
                            maxLength="2"
                            defaultValue={appointment.patient.ohip_vc}
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="half-group left">
                          <label>Date of Birth:</label>
                          <input
                            type="text"
                            class="common-input"
                            ref={register({ required: true })}
                            name="dateOfBirth"
                            defaultValue={appointment.patient.date_of_birth}
                          />
                        </div>
                        <div class="half-group right">
                          <label>Gender:</label>
                          <select
                            class="common-input"
                            ref={register({ required: true })}
                            name="gender"
                            defaultValue={appointment.patient.gender}
                          >
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                            <option value="prefer not to say">
                              Prefer Not to Say
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="half-group left">
                          {/* <label>Email:</label>
                          <label>Phone:</label>
                          <input
                            type="text"
                            class="common-input"
                            ref={register({ required: true })}
                            name="email"
                            defaultValue={appointment.user.email}
                          /> */}
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="half-group left">
                          <label>City:</label>
                          <input
                            type="text"
                            class="common-input"
                            ref={register({ required: true })}
                            name="city"
                            defaultValue={appointment.patient.city}
                          />
                        </div>
                        <div class="half-group right">
                          <label>School:</label>
                          <input
                            type="text"
                            class="common-input"
                            ref={register()}
                            name="school"
                            defaultValue={appointment.patient.school}
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Address:</label>
                        <textarea
                          class="common-input full-width"
                          ref={register()}
                          name="address"
                          defaultValue={appointment.patient.address}
                        />
                      </div>
                      <div class="form-group">
                        <div class="half-group left">
                          <label>
                            Postal Code
                            <span style={{ color: "#2A3688" }}>*</span>:
                          </label>
                          <input
                            type="text"
                            className={`common-input ${
                              errors.postalCode ? "red-input" : ""
                            }`}
                            ref={register()}
                            name="postalCode"
                            defaultValue={appointment.patient.postal_code}
                          />
                          <p style={{ color: "red" }}>
                            {errors.postalCode && "Postal Code is required"}
                          </p>
                        </div>
                        <div class="half-group right">
                          <label>
                            Doctor / Primary Care Provider
                            <span style={{ color: "#2A3688" }}>*</span>:
                          </label>
                          <input
                            type="text"
                            className={`common-input ${
                              errors.doctor ? "red-input" : ""
                            }`}
                            ref={register()}
                            name="doctor"
                            defaultValue={appointment.patient.doctor}
                          />
                          <p style={{ color: "red" }} />
                        </div>
                      </div>
                      {office.vaccination_clinic && (
                        <>
                          <div class="form-group">
                            <div class="half-group left">
                              <label>
                                Extra Info Label
                                <span style={{ color: "#2A3688" }}>*</span>:
                              </label>
                              <select
                                name="infoLabel"
                                className={`common-input ${
                                  errors.infoLabel ? "red-input" : ""
                                }`}
                                ref={register()}
                                name="infoLabel"
                                defaultValue={
                                  appointment.patient.extra_info_label
                                }
                              >
                                <option value="">Please select option.</option>
                                <option value="Birth Certificate">
                                  Birth Certificate.
                                </option>
                                <option value="Employee ID">
                                  Employee ID.
                                </option>
                                <option value="First Nation">
                                  First Nation.
                                </option>
                                <option value="Passport">Passport.</option>
                                <option value="MRN">MRN.</option>
                                <option value="Out of Province Health Card #">
                                  Out of Province Health Card #.
                                </option>
                                <option value="Driver's License">
                                  Driver's License.
                                </option>
                              </select>
                              <p style={{ color: "red" }}>
                                {errors.postalCode && "Postal Code is required"}
                              </p>
                            </div>
                            <div class="half-group right">
                              <label>
                                Extra Info Value
                                <span style={{ color: "#2A3688" }}>*</span>:
                              </label>
                              <input
                                type="text"
                                className={`common-input ${
                                  errors.infoValue ? "red-input" : ""
                                }`}
                                ref={register()}
                                name="infoValue"
                                defaultValue={
                                  appointment.patient.extra_info_value
                                }
                              />
                              <p style={{ color: "red" }} />
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="half-group left">
                              <label>
                                Dose #
                                <span style={{ color: "#2A3688" }}>*</span>:
                              </label>
                              <input
                                type="text"
                                className={`common-input ${
                                  errors.infoLabel ? "red-input" : ""
                                }`}
                                ref={register()}
                                name="dose"
                                defaultValue={appointment.dose}
                              />
                              <p style={{ color: "red" }}>
                                {errors.dose && "Postal Code is required"}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                      {office.requires_student_number && (
                        <div class="form-group">
                          <div class="half-group left">
                            <label>
                              Student Card #
                              <span style={{ color: "#2A3688" }}>*</span>:
                            </label>
                            <input
                              type="text"
                              className={`common-input ${
                                errors.postalCode ? "red-input" : ""
                              }`}
                              ref={register()}
                              name="student_number"
                              defaultValue={appointment.patient.student_number}
                            />
                            <p style={{ color: "red" }}>
                              {errors.postalCode && "Postal Code is required"}
                            </p>
                          </div>
                          <div class="half-group right">
                            <label>
                              Green Shield Number
                              <span style={{ color: "#2A3688" }}>*</span>:
                            </label>
                            <input
                              type="text"
                              className={`common-input ${
                                errors.doctor ? "red-input" : ""
                              }`}
                              ref={register()}
                              name="greenshield_number"
                              defaultValue={
                                appointment.patient.greenshield_number
                              }
                            />
                            <p style={{ color: "red" }} />
                          </div>
                        </div>
                      )}
                      <div class="form-group">
                        <div class="half-group left">
                          <label>
                            Phone Number
                            <span style={{ color: "#2A3688" }}>*</span>:
                          </label>
                          <input
                            type="text"
                            className={`common-input ${
                              errors.phone ? "red-input" : ""
                            }`}
                            ref={register()}
                            name="phone"
                            defaultValue={appointment.patient.phone}
                          />
                          <p style={{ color: "red" }}>
                            {errors.phone && "Postal Code is required"}
                          </p>
                        </div>
                      </div>
                    </div>
                    {userImmunization && (
                      <div className="symtoms-checklist">
                        <h5>Immunizations:</h5>
                        {immunizations &&
                          immunizations.map((symptom) => (
                            <div class="form-group">
                              <input
                                type="checkbox"
                                id={symptom.name}
                                defaultChecked={userImmunization
                                  .map(
                                    (userImmunization) =>
                                      userImmunization.immunization
                                  )
                                  .includes(symptom.pk)}
                                name={symptom.name}
                                class="checkbox-custom"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    dispatch(selectImmunization(symptom.pk));
                                  } else {
                                    dispatch(removeImmunization(symptom.pk));
                                  }
                                }}
                              />
                              <label
                                for={symptom.name}
                                class="checkbox-custom-label"
                              >
                                <span>{symptom.name}</span>
                              </label>
                            </div>
                          ))}
                      </div>
                    )}
                    {covidInfo && (
                      <div class="symtoms-checklist">
                        <h5>Symptom Checklist:</h5>
                        <div class="form-group">
                          <div class="first-symptoms">
                            <label>Date of onset first symptom:</label>
                            <input
                              type="text"
                              class="common-input"
                              name="startedFrom"
                              defaultValue={covidInfo.sympton_startedfrom}
                            />
                          </div>
                        </div>
                        {symptoms &&
                          symptoms.map((symptom) => (
                            <div class="form-group">
                              <input
                                type="checkbox"
                                id={symptom.symptom_name}
                                defaultChecked={covidInfo.covid_info_symptoms
                                  .map((symptom) => symptom.symptom)
                                  .includes(symptom.pk)}
                                name={symptom.symptom_name}
                                class="checkbox-custom"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    dispatch(selectSymptom(symptom.pk));
                                  } else {
                                    dispatch(removeSymptom(symptom.pk));
                                  }
                                }}
                              />
                              <label
                                for={symptom.symptom_name}
                                class="checkbox-custom-label"
                              >
                                <span>{symptom.symptom_name}</span>
                              </label>
                            </div>
                          ))}

                        <div class="form-group other-symptoms">
                          <input
                            type="checkbox"
                            id="check16"
                            name="check-group15"
                            class="checkbox-custom"
                          />
                          <label for="check16" class="checkbox-custom-label">
                            <span>Other:</span>
                          </label>
                          <textarea class="common-input" />
                        </div>
                        <div class="form-group full-label anycontact">
                          <label>
                            Any contact with probable or confirmed cases?
                          </label>
                          <div class="p-info">
                            <div class="p-info-inner">
                              <input
                                type="radio"
                                id="contactedyes"
                                ref={register()}
                                name="contactedWithCovid"
                                value="YES"
                                defaultChecked={
                                  covidInfo.contactedwith_covid.toLowerCase() ==
                                  "yes"
                                }
                              />
                              <label for="contactedyes">
                                <span>Yes</span>
                              </label>
                            </div>
                            <div class="p-info-inner">
                              <input
                                type="radio"
                                id="contactedno"
                                ref={register()}
                                name="contactedWithCovid"
                                value="NO"
                                defaultChecked={
                                  covidInfo.contactedwith_covid.toLowerCase() ==
                                  "no"
                                }
                              />
                              <label for="contactedno">
                                <span>No</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="form-group full-label more-details">
                          <label>More Details:</label>
                          <textarea
                            class="common-input"
                            ref={register()}
                            name="contactedWithCovidDescription"
                            defaultValue={covidInfo.contactedwith_description}
                          />
                        </div>
                        <div class="form-group full-label anycontact">
                          <label>Does this patient require testing?</label>
                          <div class="p-info">
                            <div class="p-info-inner">
                              <input
                                type="radio"
                                id="requiredyes"
                                ref={register()}
                                name="required_testing"
                                value="YES"
                                defaultChecked={
                                  covidInfo.required_testing == true
                                    ? true
                                    : false
                                }
                              />
                              <label for="requiredyes">
                                <span>Yes</span>
                              </label>
                            </div>
                            <div class="p-info-inner">
                              <input
                                type="radio"
                                id="requiredno"
                                ref={register()}
                                name="required_testing"
                                value="NO"
                                defaultChecked={
                                  covidInfo.required_testing == false
                                    ? true
                                    : false
                                }
                              />
                              <label for="requiredno">
                                <span>No</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="form-group full-label more-details">
                          <label>Required Testing Details:</label>
                          <textarea
                            class="common-input"
                            ref={register()}
                            name="required_testing_description"
                            defaultValue={
                              covidInfo.required_testing_description
                            }
                          />
                        </div>
                        <div class="form-group full-label anycontact">
                          <label>Is this patient attending school?</label>
                          <div class="p-info">
                            <div class="p-info-inner">
                              <input
                                type="radio"
                                id="attendingyes"
                                ref={register()}
                                name="is_attending_school"
                                value="YES"
                                defaultChecked={
                                  covidInfo.is_attending_school == true
                                    ? true
                                    : false
                                }
                              />
                              <label for="attendingyes">
                                <span>Yes</span>
                              </label>
                            </div>
                            <div class="p-info-inner">
                              <input
                                type="radio"
                                id="attendingno"
                                ref={register()}
                                name="is_attending_school"
                                value="NO"
                                defaultChecked={
                                  covidInfo.is_attending_school == false
                                    ? true
                                    : false
                                }
                              />
                              <label for="attendingno">
                                <span>No</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </form>
                {cancelForm ? (
                  <CancelForm
                    appointmentPk={window.location.href.split("/")[7]}
                    setCancelForm={setCancelForm}
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              <BounceLoader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
