import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import {
  getAutomatedMessage,
  saveAutomatedMessage,
} from "../../../actions/appointmentActions";
import { BounceLoader } from "react-spinners";
import { CopyToClipboard } from "react-copy-to-clipboard";
import launchToast from "../../../utils";
import { AutomatedMessageEditor } from "../../appointments/automatedmessages/AutomatedMessageEditor";

export const AutomatedMessaging = (props: any) => {
  const automatedMessage = useSelector(
    (state: RootStateOrAny) => state.appointmentReducer.automatedMessage
  );
  const isFetchingAutomatedMessage = useSelector(
    (state: RootStateOrAny) =>
      state.appointmentReducer.isFetchingAutomatedMessage
  );

  const office = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.office
  );

  const dispatch = useDispatch();

  return (
    <div className="automatic-msg-main white-box">
      <h2>Automatic Message</h2>
      <div className="innermsg-info">
        <Accordion
          allowZeroExpanded={true}
          className="accordion-container"
          onChange={(e) => {
            dispatch(getAutomatedMessage(e[0], office.pk));
          }}
        >
          <AccordionItem className="set" uuid="Appointment Reminder">
            <AccordionItemHeading className="header-accordion">
              <AccordionItemButton className="header-accordion-title">
                Appointment Reminder
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="content">
              {isFetchingAutomatedMessage ? (
                <BounceLoader />
              ) : automatedMessage ? (
                <AutomatedMessageEditor />
              ) : (
                "No automated message"
              )}
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem className="set" uuid="Follow up">
            <AccordionItemHeading className="header-accordion">
              <AccordionItemButton className="header-accordion-title">
                Follow-up After Booked Appointment
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="content">
              {isFetchingAutomatedMessage ? (
                <BounceLoader />
              ) : automatedMessage ? (
                <AutomatedMessageEditor />
              ) : (
                "No automated message"
              )}
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem className="set" uuid="Cancel Appointment">
            <AccordionItemHeading className="header-accordion">
              <AccordionItemButton className="header-accordion-title">
                Cancel Appointment
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="content">
              {isFetchingAutomatedMessage ? (
                <BounceLoader />
              ) : automatedMessage ? (
                <AutomatedMessageEditor />
              ) : (
                "No automated message"
              )}
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem className="set" uuid="Recurring Appointment">
            <AccordionItemHeading className="header-accordion">
              <AccordionItemButton className="header-accordion-title">
                Recurring Appointment
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="content">
              {isFetchingAutomatedMessage ? (
                <BounceLoader />
              ) : automatedMessage ? (
                <AutomatedMessageEditor />
              ) : (
                "No automated message"
              )}
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>

        <div className="new-response">
          {/* <a
                    href="#"
                    title="Add New"
                    className="common-btn blue-btn addnew"
                  >
                    <span> + &nbsp;Add New</span>
                  </a> */}
          <div className="tag-templates">
            <h5>Tag Templates </h5>
            <ul>
              <li>
                {" "}
                <CopyToClipboard text={`{{first_name}}`}>
                  <span
                    className="selected-text"
                    onClick={(e) => {
                      launchToast(
                        "First Name template tag copied to clipboard."
                      );
                    }}
                  >
                    First Name
                  </span>
                </CopyToClipboard>
              </li>
              <li
                onClick={(e) => {
                  launchToast("Last Name template tag copied to clipboard.");
                }}
              >
                <CopyToClipboard text={`{{last_name}}`}>
                  <span className="selected-text">Last Name</span>
                </CopyToClipboard>
              </li>
              <li
                onClick={(e) => {
                  launchToast(
                    "Appointment time template tag copied to clipboard."
                  );
                }}
              >
                <CopyToClipboard text={`{{event_time}}`}>
                  <span className="selected-text">Appointment Time</span>
                </CopyToClipboard>
              </li>
              <li
                onClick={(e) => {
                  launchToast(
                    "Appointment Date template tag copied to clipboard."
                  );
                }}
              >
                <CopyToClipboard text={`{{event_date}}`}>
                  <span className="selected-text">Appointment Date</span>
                </CopyToClipboard>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
