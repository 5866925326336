import React from "react";
import moment from "moment";
import { useDispatch } from 'react-redux'
import { downloadFileAttachment } from "../../actions/messagingActions";

type ThreadMessageProps = {
  message: any;
  profile: any;
};

export const ThreadMessage = ({ message, profile }: ThreadMessageProps) => {

  const dispatch = useDispatch();
  return (
    <div
      className={`chat-text ${message.sent_by_name !== `${profile.first_name} ${profile.last_name}`
        ? "left-side"
        : "right-side"
        } ${message.image ? "screenshot-download" : ""}`}
    >
      <div
        className={`chatbox ${message.sent_by_name !== `${profile.first_name} ${profile.last_name}`
          ? "grey-bx"
          : "dark-blue"
          }`}
      >
        {message.image && (
          <a href={message.image} onClick={e => {
            e.preventDefault();
            dispatch(downloadFileAttachment(message.pk))
          }}>
            {message.image.split("/").slice(-1)[0]}
          </a>
        )}
        <p>{message.message}</p>
      </div>
      <span className="chat-time">
        {moment(message.datetime).fromNow()} |{" "}
        {message.admin_profile
          ? `${message.admin_profile.first_name} ${message.admin_profile.last_name
            }`
            ? message.patient_profile
            : `${message.patient_profile.first_name} ${message.patient_profile.last_name
            }`
          : message.sent_by_name}
        {message.sent_by_type === "admin" && (
          <span className="read-receipt">
            {message.patient_read && (
              <svg
                width="9"
                height="6"
                viewBox="0 0 9 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 3L3.5 5.5L8 1"
                  stroke="#343642"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
            {message.patient_read ? "Read" : "Sent"}
          </span>
        )}
      </span>
    </div>
  );
};
