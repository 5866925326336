import store from "store";
import axios, { AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import moment from "moment";
import launchToast from "../utils";
import { setDate } from "./appointmentActions";
import { Action } from "redux";

function requestGetAppointmentsBooked() {
  return {
    type: "REQUEST_GET_APPOINTMENTS_BOOKED",
    isFetchingAppointmentsBooked: true,
  };
}
function receiveGetAppointmentsBooked(appointmentsBooked: any) {
  return {
    type: "RECEIVE_GET_APPOINTMENTS_BOOKED",
    isFetchingAppointmentsBooked: false,
    appointmentsBooked,
  };
}
function errorGetAppointmentsBooked() {
  return {
    type: "ERROR_GET_APPOINTMENTS_BOOKED",
    isFetchingAppointmentsBooked: false,
  };
}

export function getAppointmentsBooked(officePk: number) {
  const token = localStorage.getItem("id_token");
  let config: AxiosRequestConfig = {
    method: "GET",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/get-appointment-stats/?office=${officePk}`,
  };

  return (dispatch: Dispatch<Action>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetAppointmentsBooked(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function receiveGetAvailableAppointments(availableAppointments: any) {
  return {
    type: "RECEIVE_GET_AVAILABLE_APPOINTMENTS",
    availableAppointments,
  };
}

export function getAvailableAppointments(officePk: number) {
  let config: AxiosRequestConfig = {
    method: "GET",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/get-available-appointments/?office=${officePk}`,
  };

  return (dispatch: Dispatch<Action>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetAvailableAppointments(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function receiveGetUpcomingBooked(upcomingBooked: any) {
  return {
    type: "RECEIVE_GET_UPCOMING_BOOKED",
    upcomingBooked,
  };
}

export function getUpcomingBooked(officePk: number) {
  let config: AxiosRequestConfig = {
    method: "GET",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/get-upcoming-booked/?office=${officePk}`,
  };

  return (dispatch: Dispatch<Action>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetUpcomingBooked(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function receiveGetAgeDemographics(ageDemographics: any) {
  return {
    type: "RECEIVE_GET_AGE_DEMOGRAPHICS",
    ageDemographics,
  };
}

export function getAgeDemographics(officePk: number) {
  let config: AxiosRequestConfig = {
    method: "GET",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/get-age-demographics/?office=${officePk}`,
  };

  return (dispatch: Dispatch<Action>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetAgeDemographics(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
