import React, { useRef, useState } from "react";
import DashboardTop from "../dashboardTop";
import LeftBar from "../../components/leftBar";
import General from "./steps/General";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "./Sidebar";
import HoursOfOperation from "./steps/HoursOfOperation";
import Users from "./steps/Users";
import TermsAndCondition from "./steps/TermsAndCondition";
import OfficeCreated from "./steps/OfficeCreated";
const AddOffice = () => {
  const [step, setStep] = useState(1);
  const adminOfficeInteractions = useSelector(
    (state) => state.adminOfficeInteractions
  );
  const generalForm = useRef();
  const menuSetting = adminOfficeInteractions.menuSetting;
  const stepsHandler = (value) => {
    setStep(() => value);
  };
  return (
    <div>
      <div
        className={`settings-main ${
          menuSetting === "close" ? "close-menu" : ""
        }`}
      >
        <LeftBar />
        <div class="rightbar">
          {/* {step < 3 ? (
            <DashboardTop title={`New Office`} back={true} />
          ) : (
            <DashboardTop title={`New Event`} back={true} />
          )} */}
          <div class="middle-content main-content-wrapper">
            <div class="d-flex main-appointment-page add-office-page">
              <div class="col-7 app-setting-right">
                <div className="white-box">
                  {step != 5 ? (
                    <div className="tabber-group">
                      <div className={`${step == 1 ? "active" : ""} item `}>
                        <span>General</span>
                      </div>
                      <div className={`${step == 2 ? "active" : ""} item `}>
                        <span>Hours of Operation</span>
                      </div>
                      <div className={`${step == 3 ? "active" : ""} item `}>
                        <span>Users</span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {step == 1 && <General stepsHandler={stepsHandler} />}
                  {step == 2 && (
                    <HoursOfOperation stepsHandler={stepsHandler} />
                  )}
                  {step == 3 && <Users stepsHandler={stepsHandler} />}
                  {step == 4 && (
                    <TermsAndCondition stepsHandler={stepsHandler} />
                  )}
                  {step == 5 && <OfficeCreated />}
                </div>
              </div>
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOffice;
