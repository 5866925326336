import moment from "moment";

const initialState = {
  openChat: false,
  isFetchingInternalMessageThreads: false,
  internalMessageThreads: [],
  adminThread: null,
  isFetchingAdminThreadMessages: false,
  threadMessages: [],
};

const internalChatReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "OPEN_CHAT":
      return Object.assign({}, state, {
        openChat: action.openChat,
      });
    case "REQUEST_GET_INTERNAL_MESSAGE_THREADS":
      return Object.assign({}, state, {
        isFetchingInternalMessageThreads: true,
      });
    case "RECEIVE_GET_INTERNAL_MESSAGE_THREADS":
      return Object.assign({}, state, {
        isFetchingInternalMessageThreads: false,
        internalMessageThreads: action.internalMessageThreads,
      });
    case "ERROR_GET_INTERNAL_MESSAGE_THREADS":
      return Object.assign({}, state, {
        isFetchingInternalMessageThreads: false,
      });
    case "REQUEST_GET_ADMIN_THREAD_MESSAGES":
      return Object.assign({}, state, {
        isFetchingAdminThreadMessages: true,
      });
    case "RECEIVE_GET_ADMIN_THREAD_MESSAGES":
      return Object.assign({}, state, {
        isFetchingAdminThreadMessages: false,
        threadMessages: action.threadMessages,
      });
    case "ERROR_GET_ADMIN_THREAD_MESSAGES":
      return Object.assign({}, state, {
        isFetchingAdminThreadMessages: false,
      });
    case "SELECT_ADMIN_THREAD":
      return Object.assign({}, state, {
        adminThread: action.adminThread,
      });
    case "RESET_THREAD":
      return Object.assign({}, state, {
        adminThread: null,
        threadMessages: [],
      });
    case "ADD_NEW_MESSAGE_TO_CHAT":
      return Object.assign({}, state, {
        threadMessages: [...state.threadMessages, action.chatMessage],
      });

    default:
      return state;
  }
};

export default internalChatReducer;
