import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  submitSOAPNote,
  submitTriageNote,
  submitGuestTriageNote,
} from "../../actions/waitingRoomActions";

function TriageQuestion({
  triageQuestions,
  symptom,
  triage,
  isRegisteredPatient,
}) {
  const dispatch = useDispatch();
  const patient = useSelector((state) => state.waitingRoom.selectedUser.pk);
  // const [choices, setChoices] = useState(
  //   triageQuestions.symptom_question.map((question) => ({
  //     question: question.pk,
  //     soap_choice_soap_question: [],
  //   }))
  // );

  // const addChoice = (questionId, choice) => {
  //   setChoices(
  //     choices.map((question) => {
  //       if (question.question === questionId) {
  //         return {
  //           ...question,
  //           soap_choice_soap_question: [
  //             ...question.soap_choice_soap_question,
  //             choice,
  //           ],
  //         };
  //       }
  //       return question;
  //     })
  //   );
  // };

  // const removeChoice = (questionId, choice) => {
  //   setChoices(
  //     choices.map((question) => {
  //       if (question.question === questionId) {
  //         return {
  //           ...question,
  //           soap_choice_soap_question: question.soap_choice_soap_question.filter(
  //             (c) => c !== choice
  //           ),
  //         };
  //       }
  //       return question;
  //     })
  //   );
  // };

  const subjective = useRef();
  const objective = useRef();
  const assessment = useRef();
  const plan = useRef();

  return (
    <div class="patients-info-row">
      <div class="patients-title">
        <h4>Subjective</h4>
      </div>
      <div class="patients-info">
        <div>
          <ul>
            <li>
              <label></label>
              <div class="p-info">
                <div>
                  <label className="input-label">
                    <span className="input-span"></span>
                  </label>
                  <textarea
                    type="text"
                    name="radio-group"
                    className="form-input"
                    // onFocus={(e) => {
                    //   removeChoice(
                    //     question.pk,
                    //     `${choice.name}: ${e.target.value}`
                    //   );
                    // }}
                    ref={subjective}
                  ></textarea>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="patients-title">
        <h4>Objective</h4>
      </div>
      <div class="patients-info">
        <div>
          <ul>
            <li>
              <label></label>
              <div class="p-info">
                <div>
                  <label className="input-label">
                    <span className="input-span"></span>
                  </label>
                  <textarea
                    type="text"
                    name="radio-group"
                    className="form-input"
                    // onFocus={(e) => {
                    //   removeChoice(
                    //     question.pk,
                    //     `${choice.name}: ${e.target.value}`
                    //   );
                    // }}
                    ref={objective}
                  ></textarea>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="patients-title">
        <h4>Assessment</h4>
      </div>
      <div class="patients-info">
        <div>
          <ul>
            <li>
              <label></label>
              <div class="p-info">
                <div>
                  <label className="input-label">
                    <span className="input-span"></span>
                  </label>
                  <textarea
                    type="text"
                    name="radio-group"
                    className="form-input"
                    // onFocus={(e) => {
                    //   removeChoice(
                    //     question.pk,
                    //     `${choice.name}: ${e.target.value}`
                    //   );
                    // }}
                    ref={assessment}
                  ></textarea>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="patients-title">
        <h4>Plan</h4>
      </div>
      <div class="patients-info">
        <div>
          <ul>
            <li>
              <label></label>
              <div class="p-info">
                <div>
                  <label className="input-label">
                    <span className="input-span"></span>
                  </label>
                  <textarea
                    type="text"
                    name="radio-group"
                    className="form-input"
                    // onFocus={(e) => {
                    //   removeChoice(
                    //     question.pk,
                    //     `${choice.name}: ${e.target.value}`
                    //   );
                    // }}
                    ref={plan}
                  ></textarea>
                </div>
              </div>
            </li>
          </ul>
        </div>
        {isRegisteredPatient ? (
          <button
            className="blue-btn common-btn"
            onClick={() => {
              dispatch(
                submitTriageNote(
                  triage,
                  {
                    subjective: subjective.current.value,
                    objective: objective.current.value,
                    assessment: assessment.current.value,
                    plan: plan.current.value,
                  },
                  patient
                )
              );
            }}
          >
            Submit
          </button>
        ) : (
          <button
            className="blue-btn common-btn"
            onClick={() => {
              dispatch(
                submitGuestTriageNote(
                  triage,
                  {
                    subjective: subjective.current.value,
                    objective: objective.current.value,
                    assessment: assessment.current.value,
                    plan: plan.current.value,
                  },
                  patient
                )
              );
            }}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
}

export default TriageQuestion;
