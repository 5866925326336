import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashboardTop from "../dashboardTop";
import { getOfficePatient } from "../../actions/adminOfficeActions";
import Avatar from "react-avatar";
import { getAppointmentsByOfficeAndPatient } from "../../actions/appointmentActions";
import moment from "moment";
import { Link } from "react-router-dom";

function PatientProfile(props) {
  const dispatch = useDispatch();
  const patientPk = window.location.href.split("/")[6];

  const office = useSelector((state) => state.adminOfficeInteractions.office);
  const patient = useSelector((state) => state.adminOfficeInteractions.patient);

  useEffect(() => {
    dispatch(getOfficePatient(patientPk));
  }, [dispatch, patientPk]);

  useEffect(() => {
    if (patient) {
      dispatch(getAppointmentsByOfficeAndPatient(patient.pk));
    }
  }, [dispatch, patient]);

  const isFetchingPatient = useSelector(
    (state) => state.adminOfficeInteractions.isFetchingPatient
  );

  const patientAppointments = useSelector(
    (state) => state.appointmentReducer.patientAppointments
  );
  return !isFetchingPatient ? (
    patient ? (
      <div>
        <DashboardTop title="Patient > Details" />
        <div class="middle-content main-content-wrapper">
          <div class="patients-name">
            <h3>
              {patient.first_name} {patient.last_name}
            </h3>
          </div>

          <div class="profile-infomation">
            <div class="col-6 col-left">
              <div class="white-box profile-info-main">
                <div class="profile-imgs">
                  {/* <img src="images/brenda.svg" alt=""> */}
                  <Avatar
                    name={`${patient.first_name} ${patient.last_name}`}
                    round={true}
                    style={{ width: "100%" }}
                  />
                  <div class="profile-hover">
                    {/* <span><img src="images/edit-white.svg" alt="" /> </span> */}
                  </div>
                </div>
                <div class="prfl-info">
                  <h5>Personal</h5>
                  <div class="profile-info">
                    <label>Email:</label>
                    <span>{patient.user ? patient.user.email : ""}</span>
                  </div>
                  <div class="profile-info">
                    <label>Preferred Name:</label>
                    <span>{patient.first_name}</span>
                  </div>

                  <div class="profile-info">
                    <label>Date of Birth:</label>
                    <span>{patient.date_of_birth}</span>
                  </div>
                  <div class="profile-info">
                    <label>Gender:</label>
                    <span>{patient.gender}</span>
                  </div>
                  <div class="profile-info">
                    <label>Health Card #:</label>
                    <span>
                      {patient.ohip_num} {patient.ohip_vc}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-right inbox-appoint">
              {/* <div class="white-box profile-info-main inbox-bx">
                <div class="prfl-info">
                  <h5>Inbox </h5>
                  <div class="profile-info active-resolved">
                    <label>Active:</label>
                    <span>1</span>
                  </div>
                  <div class="profile-info">
                    <label>Follow Up:</label>
                    <span>0</span>
                  </div>
                  <div class="profile-info">
                    <label>Important:</label>
                    <span>0</span>
                  </div>
                  <div class="profile-info active-resolved">
                    <label>Resolved</label>
                    <span>3</span>
                  </div>
                </div>
              </div> */}
              <div class="appointments-bx">
                <div class="white-box profile-info-main">
                  <div class="prfl-info">
                    <h5>Appointments</h5>
                    {/* <div class="profile-info active-resolved">
                      <label>Walk-Ins:</label>
                      <span>3</span>
                    </div>
                    <div class="profile-info active-resolved">
                      <label>Meeting Room:</label>
                      <span>2</span>
                    </div> */}
                    <div class="profile-info">
                      <label>Appointments:</label>
                      <span>{patientAppointments.length}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {patientAppointments.length > 0 && (
            <div class="white-box profile-info-main account-info">
              <div class="prfl-info">
                <h5>Upcoming Appointments</h5>
                <div class="linked-accounts">
                  {patientAppointments.map((appointment) => (
                    <Link
                      to={`/office/${office.slug}/appointments/appointment/${appointment.pk
                        }`}
                    >
                      <div class="acc-row">
                        <label>
                          {moment(appointment.event_date).format("LL")}{" "}
                          {moment(appointment.event_time, "HH:mm").format("LT")}
                        </label>
                        <span>Appointment at {appointment.office.name}</span>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
              {/* <div class="prfl-info right">
                <h5>Contact Information </h5>
                <div class="profile-info">
                  <label>Phone:</label>
                  <span>
                    <a href="tel:5195555555" title="callus">
                      519-555-5555
                    </a>
                  </span>
                </div>
                <div class="profile-info">
                  <label>Email:</label>
                  <span>
                    <a href="mailto:brenda.padiglia@live.ca" title="mailus">
                      brenda.padiglia@live.ca
                    </a>
                  </span>
                </div>
              </div> */}
            </div>
          )}
          {/* <div class="patients-details-table">
            <h4>Patient Details</h4>
            <div class="white-box">
              <table>
                <tr>
                  <th>Date of Birth:</th>
                  <td>{patient.date_of_birth}</td>
                </tr>
                <tr>
                  <th>Gender:</th>
                  <td>{patient.gender}</td>
                </tr>
                <tr>
                  <th>Phone:</th>
                  <td>
                    <a href={`tel:${patient.phone}`} title="callus">
                      {patient.phone}
                    </a>
                  </td>
                </tr>
                <tr>
                  <th>Email:</th>
                  <td>
                    <a href={`mailto:${patient.user.email}`} title="mailus">
                      {patient.user.email}
                    </a>
                  </td>
                </tr>
                <tr>
                  <th>Address:</th>
                  <td>{patient.address}</td>
                </tr>
                <tr>
                  <th>OHIP #:</th>
                  <td>{patient.ohip_num}</td>
                </tr>
                <tr>
                  <th>OHIP VC:</th>
                  <td>{patient.ohip_vc}</td>
                </tr>
              </table>
            </div>
          </div> */}
        </div>
      </div>
    ) : (
        ""
      )
  ) : (
      ""
    );

  // return (
  //   <div>
  //     test
  //     <h1>
  //       Patient Profile for {patient.first_name} {patient.last_name}
  //     </h1>
  //     <ul>
  //       <li>{patient.gender}</li>
  //       <li>{patient.date_of_birth}</li>
  //       <li>{patient.user.email}</li>
  //       <li>{patient.phone}</li>
  //     </ul>
  //   </div>
  // );
}

export default PatientProfile;
