import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getSocketConnection,
  reconnectSocketConnection,
} from "../../actions/adminOfficeActions";

import QueueItem from "./QueueItem";
import FlipMove from "react-flip-move";

const Queue = (props) => {
  const dispatch = useDispatch();
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {}, [currentTime]);

  const users = useSelector((state) => state.waitingRoom.users);

  const socket = useSelector((state) => state.adminOfficeInteractions.socket);
  const socketStatus = useSelector(
    (state) => state.adminOfficeInteractions.socketStatus
  );

  return socketStatus === "connected" ? (
    <table>
      <FlipMove
        typeName={null}
        enterAnimation="fade"
        leaveAnimation="accordionVertical"
      >
        {users
          ? users.map((user) => (
              <QueueItem
                user={user}
                socket={socket}
                office={props.office}
                currentAdmin={props.currentAdmin}
                adminEmail={props.adminEmail}
              />
            ))
          : ""}
      </FlipMove>
    </table>
  ) : (
    <p
      onClick={() =>
        dispatch(
          reconnectSocketConnection(props.office.slug, props.currentAdmin)
        )
      }
    >
      Disconnected. Click here to reconnect.
    </p>
  );
};

// onClick={() => dispatch(requestLogin())}

export default Queue;
