import React from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { setOfficeSettingsTab } from "../../actions/officeSettingsActions";
import { SettingsTabItem } from "./SettingsTabItem";

export const SettingsTabs = (props: any) => {
  const dispatch = useDispatch();
  const selectedTab = useSelector(
    (state: RootStateOrAny) => state.officeSettingsReducer.selectedTab
  );
  return (
    <div className="col-3 app-setting-left">
      <div className="white-box">
        <div className="app-settings-main">
          <h4>
            {props.appointmentSettings
              ? "Appointment Settings"
              : "Office Settings"}
          </h4>
          {props.appointmentSettings ? (
            <ul>
              <SettingsTabItem
                active={selectedTab === "automaticMessaging"}
                tabDispatchName="automaticMessaging"
                tabName="Automatic Messaging"
              />
              <SettingsTabItem
                active={selectedTab === "manageBookingTimes"}
                tabDispatchName="manageBookingTimes"
                tabName="Manage Booking Times"
              />
              <SettingsTabItem
                active={selectedTab === "priorityBookingTimes"}
                tabDispatchName="priorityBookingTimes"
                tabName="Override Booking times"
              />
            </ul>
          ) : (
            <React.Fragment>
              <ul>
                <SettingsTabItem
                  active={selectedTab === "profile"}
                  tabDispatchName="profile"
                  tabName="Settings"
                />
                <SettingsTabItem
                  active={selectedTab === "users"}
                  tabDispatchName="users"
                  tabName="Users"
                />
                {/* <SettingsTabItem
                  active={selectedTab === "patients"}
                  tabDispatchName="patients"
                  tabName="Patients"
                /> */}
                {/* <SettingsTabItem
                  active={selectedTab === "account"}
                  tabDispatchName="account"
                  tabName="Account"
                /> */}
              </ul>
              <hr className="settings-line" />
              <h4>Account</h4>
              <ul>
                {/* <SettingsTabItem
                  active={selectedTab === "myPlan"}
                  tabDispatchName="myPlan"
                  tabName="My Plan"
                />
                <SettingsTabItem
                  active={selectedTab === "payments"}
                  tabDispatchName="payments"
                  tabName="Payments"
                /> */}
                <SettingsTabItem
                  active={selectedTab === "support"}
                  tabDispatchName="support"
                  tabName="Support"
                />
                <SettingsTabItem
                  active={selectedTab === "policies"}
                  tabDispatchName="policies"
                  tabName="Policies"
                />
              </ul>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
