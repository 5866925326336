import React, { forwardRef } from "react";
import {
  setRightToChat,
  getWaitingPatient,
  getWaitingGuestPatient,
  getTriageForm,
  getGuestTriageForm,
  disconnectFromRoom,
  resetChannelName,
  clearVideoParticipants,
  getVideoName,
  resetVideoRoomName,
  enableVideoAndAudioPermissions,
  leaveActiveCall,
  resetLocalParticipant,
} from "../../actions/waitingRoomActions";
import { BounceLoader } from "react-spinners";
import launchToast from "../../utils";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const QueueItem = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const room = useSelector((state) => state.waitingRoom.room);
  const unreadThreads = useSelector((state) => state.waitingRoom.unreadThreads);
  const selectedUser = useSelector((state) => state.waitingRoom.selectedUser);
  const isFetchingVideoToken = useSelector(
    (state) => state.waitingRoom.isFetchingVideoToken
  );
  const videoToken = useSelector((state) => state.waitingRoom.videoToken);
  return (
    <tr ref={ref}>
      {props.user.admins__first_name ? (
        <th
          class="withshort-name"
          onClick={() => {
            if (selectedUser === null) {
              if (props.user.patient__pk) {
                // ! REGISTERED PATIENT
                dispatch(getWaitingPatient(props.user.patient__pk));
                dispatch(getTriageForm(props.user.patient__pk));
              } else {
                // ! NON-REGISTERED PATIENT
                dispatch(getWaitingGuestPatient(props.user.guest_patient__pk));
                dispatch(getGuestTriageForm(props.user.guest_patient__pk));
              }
            } else {
              if (props.user.patient__pk) {
                // ! REGISTERED PATIENT
                dispatch(getWaitingPatient(props.user.patient__pk));
                dispatch(getTriageForm(props.user.patient__pk));
              } else {
                // ! NON-REGISTERED PATIENT
                dispatch(getWaitingGuestPatient(props.user.guest_patient__pk));
                dispatch(getGuestTriageForm(props.user.guest_patient__pk));
              }
            }
          }}
        >
          <div class="short-name">
            {props.user.admins__first_name.charAt(0)}
            {props.user.admins__last_name.charAt(0)}{" "}
          </div>
          <span>
            {props.user.patient__pk
              ? `${props.user.patient__first_name} ${props.user.patient__last_name}`
              : `${props.user.guest_patient__name}`}
          </span>
        </th>
      ) : (
        <th
          onClick={() => {
            if (selectedUser === null) {
              if (props.user.patient__pk) {
                // ! REGISTERED PATIENT
                dispatch(getWaitingPatient(props.user.patient__pk));
                dispatch(getTriageForm(props.user.patient__pk));
              } else {
                // ! NON-REGISTERED PATIENT
                dispatch(getWaitingGuestPatient(props.user.guest_patient__pk));
                dispatch(getGuestTriageForm(props.user.guest_patient__pk));
              }
            } else {
              if (props.user.patient__pk) {
                // ! REGISTERED PATIENT
                dispatch(getWaitingPatient(props.user.patient__pk));
                dispatch(getTriageForm(props.user.patient__pk));
              } else {
                // ! NON-REGISTERED PATIENT
                dispatch(getWaitingGuestPatient(props.user.guest_patient__pk));
                dispatch(getGuestTriageForm(props.user.guest_patient__pk));
              }
            }
          }}
        >
          {props.user.patient__pk
            ? `${props.user.patient__first_name} ${props.user.patient__last_name}`
            : `${props.user.guest_patient__name}`}
        </th>
      )}
      <td>{moment(props.user.datetime).format("LT")}</td>
      {props.user.status === "waiting" ? (
        <td className="status-picker">
          {/* <span class="waititng-dots dots-orange"></span> Waiting |{" "}
                  {moment(currentTime).diff(moment(user.datetime), "minutes")}{" "}
                  minutes */}
          {/* <span class="waititng-dots dots-orange"></span> */}
          <Select
            options={[
              { value: "waiting", label: "Waiting" },
              { value: "Ready for Nurse", label: "Ready for Nurse" },
              { value: "Ready for HCP", label: "Ready for HCP" },
            ]}
            components={animatedComponents}
            className="react-select-container"
            classNamePrefix="react-select"
            value={{
              value: props.user.call_state,
              label: props.user.call_state,
            }}
            placeholder="Set status"
            onChange={(option) => {
              if (props.user.patient__pk) {
                props.socket.send(
                  JSON.stringify({
                    type: "ADMIN_CHANGE_STATUS",
                    patient: props.user.patient__pk,
                    office: props.office.slug,
                    is_patient: false,
                    status: option.value,
                    guest_patient: props.user.guest_patient__pk,
                  })
                );
              } else {
                props.socket.send(
                  JSON.stringify({
                    type: "ADMIN_CHANGE_STATUS",
                    patient: props.user.patient__pk,
                    office: props.office.slug,
                    is_patient: false,
                    status: option.value,
                    guest_patient: props.user.guest_patient__pk,
                  })
                );
              }
            }}
          />
          {/* <span class="waititng-dots dots-green"></span>In Call */}
        </td>
      ) : (
        <td className="status-picker">
          <Select
            options={[
              { value: "waiting", label: "Waiting" },
              { value: "Ready for Nurse", label: "Ready for Nurse" },
              { value: "Ready for HCP", label: "Ready for HCP" },
            ]}
            components={animatedComponents}
            className="react-select-container"
            classNamePrefix="react-select"
            value={{
              value: props.user.call_state,
              label: props.user.call_state,
            }}
            placeholder="Set status"
            onChange={(option) => {
              props.socket.send(
                JSON.stringify({
                  type: "ADMIN_CHANGE_STATUS",
                  patient: props.user.patient__pk,
                  office: props.office.slug,
                  is_patient: false,
                  status: option.value,
                  guest_patient: props.user.guest_patient__pk,
                })
              );
            }}
          />
        </td>
      )}
      {props.user.admins__pk === props.currentAdmin ? (
        <td class="calls end-call">
          {!isFetchingVideoToken ? (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                let socket_config = JSON.stringify({
                  type: "ADMIN_LEAVE_CALL",
                  patient: props.user.patient__pk,
                  admin: props.currentAdmin,
                  office: props.office.slug,
                  is_patient: false,
                });
                dispatch(clearVideoParticipants());
                dispatch(disconnectFromRoom(room, props.socket, socket_config));
                dispatch(leaveActiveCall());
                dispatch(resetVideoRoomName());
                dispatch(resetChannelName());
              }}
            >
              <span>Put On Hold</span>
            </a>
          ) : (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                if (selectedUser === null) {
                  dispatch(getWaitingPatient(props.user.patient__pk));
                  dispatch(getTriageForm(props.user.patient__pk));
                  // dispatch(
                  //   getChannelName(
                  //     user.patient__pk,
                  //     props.office.pk,
                  //     client
                  //   )
                  // );
                } else {
                  dispatch(getWaitingPatient(props.user.patient__pk));
                  dispatch(getTriageForm(props.user.patient__pk));
                  // dispatch(
                  //   getChannelName(
                  //     user.patient__pk,
                  //     props.office.pk,
                  //     client
                  //   )
                  // );
                }
              }}
            >
              <span>
                <BounceLoader color={"#052D64"} size={15} />
              </span>
            </a>
          )}
        </td>
      ) : props.user.admins__pk === null ? (
        <td class="calls">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (videoToken) {
                launchToast(
                  "You must leave your current call in order to join another one."
                );
              } else {
                props.socket.send(
                  JSON.stringify({
                    type: "ADMIN_JOINING_CALL",
                    patient_type: props.user.patient__pk
                      ? "registered"
                      : "guest",
                    patient: props.user.patient__pk,
                    admin: props.currentAdmin,
                    office: props.office.slug,
                    is_patient: false,
                    guest_patient: props.user.guest_patient__pk,
                  })
                );

                let socket_config = JSON.stringify({
                  type: "ADMIN_JOIN_CALL",
                  patient_type: props.user.patient__pk ? "registered" : "guest",
                  patient: props.user.patient__pk,
                  admin: props.currentAdmin,
                  office: props.office.slug,
                  is_patient: false,
                  guest_patient: props.user.guest_patient__pk,
                });
                dispatch(
                  getVideoName(
                    props.user.patient__pk,
                    props.office.pk,
                    props.adminEmail,
                    props.socket,
                    socket_config,
                    props.user.guest_patient__pk
                  )
                );
                dispatch(enableVideoAndAudioPermissions());
              }
            }}
          >
            <span>Join Call</span>
          </a>
        </td>
      ) : (
        <td class="calls">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{
              opacity: "0.6",
            }}
          >
            <span
              style={{
                cursor: "not-allowed",
              }}
            >
              Call in Progress
            </span>
          </a>
        </td>
      )}
      <td class="msg-patient">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            if (selectedUser === null) {
              if (props.user.patient__pk) {
                // ! REGISTERED PATIENT
                dispatch(getWaitingPatient(props.user.patient__pk));
                dispatch(getTriageForm(props.user.patient__pk));
              } else {
                // ! NON-REGISTERED PATIENT
                dispatch(getWaitingGuestPatient(props.user.guest_patient__pk));
                dispatch(getGuestTriageForm(props.user.guest_patient__pk));
              }
            } else {
              if (props.user.patient__pk) {
                // ! REGISTERED PATIENT
                dispatch(getWaitingPatient(props.user.patient__pk));
                dispatch(getTriageForm(props.user.patient__pk));
              } else {
                // ! NON-REGISTERED PATIENT
                dispatch(getWaitingGuestPatient(props.user.guest_patient__pk));
                dispatch(getGuestTriageForm(props.user.guest_patient__pk));
              }
            }
            dispatch(setRightToChat());
          }}
        >
          {unreadThreads.includes(props.user.patient__pk) ? (
            <div className="red-circle"></div>
          ) : (
            ""
          )}
        </a>
      </td>
      <td class="patient-file">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            if (selectedUser === null) {
              if (props.user.patient__pk) {
                // ! REGISTERED PATIENT
                dispatch(getWaitingPatient(props.user.patient__pk));
                dispatch(getTriageForm(props.user.patient__pk));
              } else {
                // ! NON-REGISTERED PATIENT
                dispatch(getWaitingGuestPatient(props.user.guest_patient__pk));
                dispatch(getGuestTriageForm(props.user.guest_patient__pk));
              }
            } else {
              if (props.user.patient__pk) {
                // ! REGISTERED PATIENT
                dispatch(getWaitingPatient(props.user.patient__pk));
                dispatch(getTriageForm(props.user.patient__pk));
              } else {
                // ! NON-REGISTERED PATIENT
                dispatch(getWaitingGuestPatient(props.user.guest_patient__pk));
                dispatch(getGuestTriageForm(props.user.guest_patient__pk));
              }
            }
          }}
        ></a>
      </td>
      {/* <td class="gear-file">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            if (selectedUser === null) {
              dispatch(getWaitingPatient(props.user.patient__pk));
              dispatch(getTriageForm(props.user.patient__pk));
              // dispatch(
              //   getChannelName(
              //     user.patient__pk,
              //     props.office.pk,
              //     client
              //   )
              // );
              dispatch(setRightToChat());
            } else {
              dispatch(getWaitingPatient(props.user.patient__pk));
              dispatch(getTriageForm(props.user.patient__pk));
              // dispatch(
              //   getChannelName(
              //     user.patient__pk,
              //     props.office.pk,
              //     client
              //   )
              // );
            }
          }}
        ></a>
      </td> */}
      <td class="close-waiting">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            props.socket.send(
              JSON.stringify({
                type: "ADMIN_END_WAITING",
                patient: props.user.patient__pk,
                admin: props.currentAdmin,
                office: props.office.slug,
                patient_type: props.user.patient__pk ? "registered" : "guest",
                guest_patient: props.user.guest_patient__pk,
                is_patient: false,
              })
            );
          }}
        ></a>
      </td>
    </tr>
  );
});

export default QueueItem;
