import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setSearchQuery,
  setSearchResultsActive,
  setViewType,
  setSearchResults,
  setActiveTypeVisit,
} from "../../../actions/appointmentActions";
const AppointmentSearch = (props) => {
  const dispatch = useDispatch();
  const searchQuery = useSelector(
    (state) => state.appointmentReducer.searchQuery
  );
  const searchResults = useSelector(
    (state) => state.appointmentReducer.searchResults
  );

  const searchResultsActive = useSelector(
    (state) => state.appointmentReducer.searchResultsActive
  );

  const activeTypeOfVisit = useSelector(
    (state) => state.appointmentReducer.activeTypeOfVisit
  );
  const searchRef = useRef();
  const [searchList, setSearchList] = useState([]);
  const appointments = useSelector(
    (state) => state.appointmentReducer.appointments
  );
  const handleSearch = (e) => {
    const value = e.target.value;
    dispatch(setSearchQuery(value));
    const filterList =
      appointments.length &&
      appointments.filter((item, index) => {
        return item.title.toLowerCase().includes(searchQuery.toLowerCase());
      });
    setSearchList(filterList);
  };

  return (
    <>
      <input
        type="text"
        value={searchQuery}
        className={`${
          searchQuery.trim().length > 0 ? "show_icon" : ""
        } form-feild w-100`}
        placeholder="Search"
        onFocus={props.focus}
        onChange={(e) => handleSearch(e)}
        style={{ backgroundColor: `${searchResultsActive ? "#F2F2F1" : ""}` }}
      />
      <div
        className="cross_icon"
        onClick={() => {
          dispatch(setSearchQuery(""));
          if (searchResults.length) {
            dispatch(setSearchResults([]));
          }
          dispatch(setSearchResultsActive(false));
          dispatch(setViewType("colour"));
        }}
      ></div>
      <div
        className={`${searchQuery.trim().length > 0 ? "show" : ""} search-list`}
        ref={searchRef}
      >
        {searchList.length
          ? searchList.map((item, index) => {
              return (
                <div
                  key={item.id}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(setSearchQuery(item.title));
                    searchRef.current.classList.remove("show");
                    const filterList = appointments.filter((appointment, i) => {
                      return appointment.title
                        .toLowerCase()
                        .includes(item.title.toLowerCase());
                    });
                    dispatch(setSearchResults(filterList));
                    dispatch(setSearchResultsActive(true));
                    dispatch(setViewType("search"));
                  }}
                >
                  {item.title}
                </div>
              );
            })
          : ""}
      </div>
    </>
  );
};

export default AppointmentSearch;
