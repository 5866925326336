import React from "react";
import DashboardTop from "../dashboardTop";
import { AppointmentCalendar } from "./calendar/AppointmentCalendar";
import { Link } from "react-router-dom";

export const Appointments = (props) => {
  return (
    <div>
      <DashboardTop title={`Appointments`} appointments={true} />
      <div className="middle-content main-content-wrapper">
        <div className="d-flex">
          <div className="white-box calendar-main">
            <AppointmentCalendar office={props.office} />
          </div>
        </div>
      </div>
    </div>
  );
};
