import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getPastAppointments,
  resetPageNum,
  resetPastAppointments,
  getSearchPastAppointments,
} from "../actions/adminOfficeActions";
import DashboardTop from "./dashboardTop";
import { BounceLoader } from "react-spinners";
import moment from "moment";
import AsyncSelect from "react-select/async";
import { getSearchPatients } from "../actions/messagingActions";
import makeAnimated from "react-select/animated";

function PastAppointments(props) {
  const dispatch = useDispatch();
  const pastAppointmentRequestNumber = useSelector(
    (state) => state.adminOfficeInteractions.pastAppointmentRequestNumber
  );

  useEffect(() => {
    dispatch(
      getPastAppointments(props.office.pk, pastAppointmentRequestNumber)
    );

    return () => {
      dispatch(resetPageNum());
      dispatch(resetPastAppointments());
    };
  }, []);

  const isFetchingPastAppointments = useSelector(
    (state) => state.adminOfficeInteractions.isFetchingPastAppointments
  );
  const history = useHistory();
  const pastAppointments = useSelector(
    (state) => state.adminOfficeInteractions.pastAppointments
  );
  const animatedComponents = makeAnimated();

  return (
    <div>
      <DashboardTop title={`Past Appointments`} />
      <div class="search-form-main">
        <form action="#" class="search-form">
          <AsyncSelect
            loadOptions={(input, callback) => {
              getSearchPastAppointments(input, callback, props.currentOffice);
            }}
            components={animatedComponents}
            className="react-select-container"
            classNamePrefix="react-select"
            onChange={(option) =>
              history.push({
                pathname: `/office/${props.office.slug}/past-appointment/${option.value}/`,
                appointment: option.value,
              })
            }
          />

          <input type="submit" class="fa fa-search" value="" />
        </form>
      </div>
      <div class="middle-content main-content-wrapper">
        <div class="patients-table appointment-table">
          <table>
            <tr class="no-border">
              <th>
                <input
                  id="check1"
                  class="checkbox-custom"
                  name="check1"
                  type="checkbox"
                />
                <label for="check1" class="checkbox-custom-label">
                  <span>Name</span>
                </label>
              </th>
              <th>Date</th>
              <th>Length of Appt</th>
            </tr>
            {pastAppointments.length !== 0
              ? pastAppointments.map((appointment) => (
                  <tr
                    onClick={() =>
                      history.push({
                        pathname: `/office/${props.office.slug}/past-appointment/${appointment.pk}/`,
                        appointment: appointment.pk,
                      })
                    }
                  >
                    <td>
                      <input
                        id="check2"
                        class="checkbox-custom"
                        name="check2"
                        type="checkbox"
                      />
                      <label for="check2" class="checkbox-custom-label">
                        {appointment.patient ? (
                          <span>
                            {appointment.patient.first_name}{" "}
                            {appointment.patient.last_name}
                          </span>
                        ) : appointment.guest_patient ? (
                          appointment.guest_patient.name
                        ) : (
                          "N/A"
                        )}
                      </label>
                    </td>
                    <td>{moment(appointment.date).format("MM-DD-YYYY")}</td>
                    {appointment.triage ? (
                      <td>
                        {moment(appointment.date).diff(
                          moment(appointment.triage.date_submitted),
                          "minutes"
                        )}{" "}
                        Minutes
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                ))
              : ""}
          </table>
        </div>
        <div class="loadmore">
          <a
            href="#"
            title="loadmore"
            onClick={(e) => {
              e.preventDefault();
              dispatch(
                getPastAppointments(
                  props.office.pk,
                  pastAppointmentRequestNumber
                )
              );
            }}
          ></a>
        </div>
      </div>
    </div>
  );
}

export default PastAppointments;
