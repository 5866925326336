import React, { forwardRef } from "react";
import moment from "moment";

import { getMessageThread } from "../../actions/messagingActions";

const Thread = forwardRef((props, ref) => (
  <div
    ref={ref}
    className={`new-msg-info ${
      props.selectedThread === props.keyId ? "active" : ""
    }`}
    onClick={() => {
      // props.dispatch(getMessageThread(props.thread.pk));
      console.log("whats up");
      props.setThread(props.thread.pk);
      props.setNewThread(false);
    }}
  >
    <div class="new-msg-info-inner active">
      {!props.thread.status ? (
        <h3>
          {props.thread.patient__first_name} {props.thread.patient__last_name}
        </h3>
      ) : props.thread.status === "important" ? (
        <div class="important-msg">
          <h3>
            {props.thread.patient__first_name} {props.thread.patient__last_name}
          </h3>
        </div>
      ) : props.thread.status === "followup" ? (
        <div class="followup-msg">
          <h3>
            {props.thread.patient__first_name} {props.thread.patient__last_name}
          </h3>
        </div>
      ) : (
        ""
      )}
      {props.thread.office_read ? "" : <span class="msg-dots"></span>}
      <h4>
        {props.thread.subject}{" "}
        <span>{moment(props.thread.datetime).fromNow()}</span>
      </h4>
    </div>
  </div>
));

export default Thread;
