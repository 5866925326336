import React, { useEffect, useState, useRef } from "react";

import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { addNewMessageToChat } from "../../../actions/internalChatActions";

import { InternalMessageForm } from "./InternalMessageForm";

import AsyncSelect from "react-select/async";
import { getSearchAdmins } from "../../../actions/messagingActions";

export const AdminNewThread: React.FC<any> = (props: any) => {
  const dispatch = useDispatch();

  const socket = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.socket
  );
  const profile = useSelector(
    (state: RootStateOrAny) => state.authenticateUser.profile
  );

  const [selectedAdmin, setSelectedAdmin] = useState(null);

  const formInput = useRef<any>();
  const office = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.office
  );

  return (
    <div className="inbox-info-right">
      <div className="inbox-right-head">
        <div className="inbox-hd">
          <h3>
            <AsyncSelect
              loadOptions={(input, callback) => {
                getSearchAdmins(input, callback, office.slug, profile.pk);
              }}
              placeholder={"To:"}
              onChange={(e: any) => {
                setSelectedAdmin(e.value);
              }}
            />
          </h3>
        </div>
      </div>

      <div className="type-msg">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            socket.send(
              JSON.stringify({
                type: "ADMIN_CREATE_ADMIN_THREAD",
                body: formInput.current.value,
                office: office.pk,
                admin: profile.pk,
                selectedAdmin: selectedAdmin,
              })
            );

            formInput.current.value = "";
            props.openNewChat(false);
          }}
        >
          <input
            type="text"
            placeholder="Type a message and hit enter."
            className="common-input"
            ref={formInput}
          />
        </form>
      </div>
    </div>
  );
};
