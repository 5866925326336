import React, { useEffect, useState } from "react";
import DashboardTop from "../../dashboardTop";
import { useSelector, useDispatch } from "react-redux";
import newAppointmentReducer from "../../../reducers/newAppointmentReducer";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getSymptomsList,
  selectSymptom,
  removeSymptom,
  createUserAccount,
  getUserEmail,
  createAppointment,
  addUserToOffice,
  resetSubmittedAppointment,
  resetSymptom,
  getAppointmentsByDateTime,
  resetGetAppointmentsByDateTime,
  getAppointmentInterval,
  resetNewUser,
} from "../../../actions/appointmentActions";
import { resetPatientForms } from "../../../actions/newAppointmentActions";
import { BounceLoader } from "react-spinners";
import InputMask from "react-input-mask";
import { Redirect } from "react-router-dom";
import moment from "moment";
import DropDown from "../../../assets/images/dropdown-arrow.svg";
import { NewAppointmentSidebar } from "./NewAppointmentSidebar";
import { NewAppointmentDetails } from "./NewAppointmentDetails";
import { NewAppointmentPatient } from "./NewAppointmentPatient";

export const NewAppointment = (props) => {
  const { control, register, handleSubmit, watch, errors } = useForm();
  const dispatch = useDispatch();
  const formSubmit = (data) => {
    console.log(data);

    if (newUser) {
      dispatch(
        addUserToOffice(data, newUser, props.office.pk, selectedSymptoms)
      );
    } else {
      dispatch(createUserAccount(data, props.office.pk, selectedSymptoms));
    }
  };

  const symptoms = useSelector((state) => state.appointmentReducer.symptoms);
  const isSubmittingAppointment = useSelector(
    (state) => state.appointmentReducer.isSubmittingAppointment
  );
  const appointmentInterval = useSelector(
    (state) => state.appointmentReducer.appointmentInterval
  );
  const hasSubmittedAppointment = useSelector(
    (state) => state.appointmentReducer.hasSubmittedAppointment
  );
  const selectedSymptoms = useSelector(
    (state) => state.appointmentReducer.selectedSymptoms
  );
  const newUser = useSelector((state) => state.appointmentReducer.newUser);
  const isFetchingAppointmentDateTimes = useSelector(
    (state) => state.appointmentReducer.isFetchingAppointmentDateTimes
  );
  const hasReceivedAppointmentDateTimes = useSelector(
    (state) => state.appointmentReducer.hasReceivedAppointmentDateTimes
  );
  const dateTimes = useSelector((state) => state.appointmentReducer.dateTimes);

  const [emailAddress, setEmailAddress] = useState("");

  const watchEventDate = watch("eventDate");
  const watchEventTime = watch("eventTime");

  const newAppointmentTab = useSelector(
    (state) => state.newAppointmentReducer.newAppointmentTab
  );

  useEffect(() => {
    if (emailAddress) {
      const isNewUser = setTimeout(() => {
        dispatch(getUserEmail(emailAddress));
      }, 500);
      return () => clearTimeout(isNewUser);
    }
  }, [dispatch, emailAddress]);

  useEffect(() => {
    return () => {
      dispatch(resetSubmittedAppointment());
      dispatch(resetSymptom());
      dispatch(resetNewUser());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSymptomsList());
    return () => {
      dispatch(resetPatientForms());
    };
  }, [dispatch]);

  useEffect(() => {
    if (watchEventDate && watchEventTime) {
      dispatch(
        getAppointmentsByDateTime(
          props.office.pk,
          moment(watchEventDate).format("YYYY-MM-DD"),
          moment(watchEventTime).format("HH:mm:00")
        )
      );
    }
    return () => dispatch(resetGetAppointmentsByDateTime());
  }, [dispatch, props.office.pk, watchEventDate, watchEventTime]);

  useEffect(() => {
    if (watchEventDate) {
      dispatch(
        getAppointmentInterval(
          props.office.pk,
          moment(watchEventDate).format("MM-DD-YYYY")
        )
      );
    }
  }, [dispatch, props.office.pk, watchEventDate]);
  return (
    <div>
      <DashboardTop
        title={`New Event`}
        back={true}
        backLink={`/office/${props.office.slug}/appointments/`}
      />
      {hasSubmittedAppointment && (
        <Redirect to={`/office/${props.office.slug}/appointments/`} />
      )}
      <div class="middle-content main-content-wrapper">
        <div class="d-flex main-appointment-page">
          <div class="col-3 app-setting-left">
            <NewAppointmentSidebar />
          </div>
          <div class="col-7 app-setting-right">
            {newAppointmentTab === "detail" ? (
              <NewAppointmentDetails />
            ) : newAppointmentTab === "admin" ? (
              <NewAppointmentPatient instance={0} />
            ) : (
              <NewAppointmentPatient instance={newAppointmentTab} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
