import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Close from "../../assets/images/close.png";
import InviteMembers from "./InviteMembers";
import { createOfficeAndInviteUsers } from "../../actions/adminOfficeActions";

function CreateNewOffice(props) {
  const [count, setCount] = useState(1);
  const [officeName, setOfficeName] = useState("");
  const [object, addObject] = useState([
    {
      email_sent_to: "",
      sent_by: props.doctorProfile.pk,
    },
  ]);

  const handleChange = (event, i) => {
    let newArr = [...object];
    newArr[i] = {
      email_sent_to: event.target.value,
      sent_by: props.doctorProfile.pk,
    };
    addObject(newArr);
  };

  const hasSentInvite = useSelector(
    (state) => state.adminOfficeInteractions.hasSentInvite
  );
  const dispatch = useDispatch();

  return (
    <div id="create-new-office" class="create-new-office-popup">
      <div class="form-main create-office-form big-popup">
        <div class="forgot-pass-form common-form box-with-shadow">
          <div class="sub-heading">
            <h3>Create Office</h3>
            <div class="close-box">
              <a
                href="#"
                title=""
                class="fancybox-button fancybox-close-small"
                data-fancybox-close=""
              >
                <img src={Close} alt="" />
              </a>
            </div>
          </div>
          <div class="inner-forgot-pass">
            <form>
              <div class="office-name">
                <label>Office Name:</label>
                <input
                  type="text"
                  placeholder=""
                  class="common-input"
                  value={officeName}
                  onChange={(e) => setOfficeName(e.target.value)}
                />
              </div>
              {[...Array(count)].map((e, i) => (
                <InviteMembers
                  key={i}
                  emailObject={object[i]}
                  handleChange={handleChange}
                  instance={i}
                />
              ))}
              <div class="add-another">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setCount(count + 1);
                    addObject((oldObjects) => [
                      ...oldObjects,
                      {
                        email_sent_to: "",
                      },
                    ]);
                  }}
                >
                  <span>Add Another</span>
                </button>
              </div>
              <div class="login-submit">
                {/* <input
                  type="submit"
                  class="sky-btn common-btn"
                  value="Create Office + Send Invitations"
                /> */}
                {!hasSentInvite ? (
                  <button
                    className="sky-btn common-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(
                        createOfficeAndInviteUsers(
                          officeName,
                          props.doctorProfile.user.pk,
                          object
                        )
                      );
                    }}
                  >
                    Create Office + Send Invitations
                  </button>
                ) : (
                  <button
                    className="sky-btn common-btn"
                    disabled
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Created Successfully 👍
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

// onClick={() => dispatch(requestLogin())}

export default CreateNewOffice;
