import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import moment from "moment";
import {
  getOfficeHoursByOffice,
  resetSelectPriorityOfficeHour,
  setNewOfficeHours,
  submitOfficeHours,
  updateNewOfficeHours,
  updatePriorityOfficeHour,
} from "../../../actions/officeSettingsActions";
import DatePicker from "react-datepicker";
import { BounceLoader } from "react-spinners";
import { Controller, useForm } from "react-hook-form";

export const EditPriorityBookingTime = (props: any) => {
  const dispatch = useDispatch();
  const office = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.office
  );
  const priorityOfficeHour = useSelector(
    (state: RootStateOrAny) => state.officeSettingsReducer.priorityOfficeHour
  );

  // useEffect(() => {
  //   if (officeHours.length > 0) {
  //     dispatch(setNewOfficeHours(officeHours));
  //   }
  // }, [officeHours]);
  const [startHour, setStartHour] = useState(
    moment(priorityOfficeHour.from_hour, "hh:mm:ss").toDate()
  );
  const [endHour, setEndHour] = useState(
    moment(priorityOfficeHour.to_hour, "hh:mm:ss").toDate()
  );
  const [startDate, setStartDate] = useState(
    moment(priorityOfficeHour.date).toDate()
  );

  const changeBookingTime = (day: number, key: string, value: any) => {
    dispatch(updateNewOfficeHours(day, key, value));
  };
  const { control, register, handleSubmit, watch, errors } = useForm();

  const formSubmit = (data: any) => {
    dispatch(updatePriorityOfficeHour(data, priorityOfficeHour.pk));
  };
  const watchClosed = watch("closed");
  const watchLunch = watch("lunch");

  useEffect(() => {
    return () => {
      dispatch(resetSelectPriorityOfficeHour());
    };
  }, []);

  return (
    <div className="white-box">
      <div className="app-setting-head section-head">
        <h4 className="w-100">Hours of Operation</h4>
        <p>
          Select which times you’d like patients to be able to start booking
          appointments and what time you’d like to stop booking. Be sure to add
          hours for each day that your office is open. If office is closed a
          certain day, don’t add any hours.
        </p>
      </div>
      {priorityOfficeHour ? (
        <form className="w-100" onSubmit={handleSubmit(formSubmit)}>
          <div className="timeslot-box">
            <div className="timeslot-box-row">
              <label className="timeslot-starttime"> Closed?</label>
              <div className="timeslot-box-time-piker">
                <div className="time-picker">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="closed"
                      defaultChecked={priorityOfficeHour.closed}
                      ref={register()}
                    />
                    <span className="switch-btn" />
                  </label>
                </div>
              </div>
            </div>
            <div className="timeslot-box-row">
              <label className="timeslot-starttime">Date</label>
              <div className="timeslot-box-time-piker">
                <div className="time-picker">
                  <Controller
                    name="date"
                    control={control}
                    defaultValue={startDate}
                    render={(
                      { onChange, onBlur, value, name, ref },
                      { invalid, isTouched, isDirty }
                    ) => (
                      <DatePicker
                        className="form-control  form-feild"
                        autoComplete="off"
                        onChange={(e) => onChange(e)}
                        selected={value}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            {!watchClosed && (
              <>
                <div className="timeslot-box-row">
                  <label className="timeslot-starttime">Hours</label>
                  <div className="timeslot-box-time-piker">
                    <div className="time-picker">
                      <Controller
                        name="from_hour"
                        control={control}
                        defaultValue={moment(
                          priorityOfficeHour.from_hour,
                          "hh:mm:ss"
                        ).toDate()}
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <DatePicker
                            className="form-control  form-feild"
                            showTimeSelect
                            showTimeSelectOnly
                            autoComplete="off"
                            timeIntervals={
                              priorityOfficeHour.appointmentInterval
                            }
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            selected={value}
                            onChange={(e) => onChange(e)}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <label className="timeslot-endtime"> to </label>
                  <div className="timeslot-box-time-piker">
                    <div className="time-picker">
                      <Controller
                        name="to_hour"
                        control={control}
                        defaultValue={moment(
                          priorityOfficeHour.to_hour,
                          "hh:mm:ss"
                        ).toDate()}
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <DatePicker
                            className="form-control  form-feild"
                            showTimeSelect
                            showTimeSelectOnly
                            autoComplete="off"
                            timeIntervals={
                              priorityOfficeHour.appointmentInterval
                            }
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            selected={value}
                            onChange={(e) => onChange(e)}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="timeslot-box-row">
                  <label className="timeslot-starttime"> Lunch?</label>
                  <div className="timeslot-box-time-piker">
                    <div className="time-picker">
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="lunch"
                          defaultChecked={priorityOfficeHour.lunch}
                          ref={register()}
                        />
                        <span className="switch-btn" />
                      </label>
                    </div>
                  </div>
                </div>
                {watchLunch && (
                  <div className="timeslot-box-row">
                    <label className="timeslot-starttime">Lunch Hours:</label>
                    <div className="timeslot-box-time-piker">
                      <div className="time-picker">
                        <Controller
                          name="lunch_start"
                          control={control}
                          defaultValue={moment(
                            priorityOfficeHour.lunch_start,
                            "hh:mm:ss"
                          ).toDate()}
                          render={(
                            { onChange, onBlur, value, name, ref },
                            { invalid, isTouched, isDirty }
                          ) => (
                            <DatePicker
                              className="form-control  form-feild"
                              showTimeSelect
                              showTimeSelectOnly
                              autoComplete="off"
                              timeIntervals={
                                priorityOfficeHour.appointmentInterval
                              }
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              selected={value}
                              onChange={(e) => onChange(e)}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <label className="timeslot-endtime"> to </label>
                    <div className="timeslot-box-time-piker">
                      <div className="time-picker">
                        <Controller
                          name="lunch_end"
                          control={control}
                          defaultValue={moment(
                            priorityOfficeHour.lunch_end,
                            "hh:mm:ss"
                          ).toDate()}
                          render={(
                            { onChange, onBlur, value, name, ref },
                            { invalid, isTouched, isDirty }
                          ) => (
                            <DatePicker
                              className="form-control  form-feild"
                              showTimeSelect
                              showTimeSelectOnly
                              autoComplete="off"
                              timeIntervals={
                                priorityOfficeHour.appointmentInterval
                              }
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              selected={value}
                              onChange={(e) => onChange(e)}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="w-100">
                  <div className="timeslot-row w-100">
                    <label className="feild-label">Length of Time Slot:</label>
                    <select
                      ref={register()}
                      name="appointment_interval"
                      className="select-jquery-ui"
                      defaultValue={priorityOfficeHour.appointment_interval}
                    >
                      <option value="5">5 Minutes </option>
                      <option value="10">10 Minutes </option>
                      <option value="15">15 Minutes (Default)</option>
                      <option value="20">20 Minutes</option>
                      <option value="25">25 Minutes</option>
                      <option value="30">30 Minutes</option>
                      <option value="35">35 Minutes</option>
                      <option value="40">40 Minutes</option>
                      <option value="45">45 Minutes</option>
                      <option value="50">50 Minutes</option>
                      <option value="55">55 Minutes</option>
                      <option value="60">60 Minutes</option>
                      <option value="65">65 Minutes</option>
                      <option value="70">70 Minutes</option>
                    </select>
                  </div>

                  <div className="timeslot-row w-100">
                    <label className="feild-label"> Patients per slot: </label>
                    <input
                      ref={register()}
                      name="num_appointments_interval"
                      type="number"
                      className="select-jquery-ui"
                      defaultValue={
                        priorityOfficeHour.num_appointments_interval
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="timeslot-buttonbox">
            <button
              type="submit"
              className="btn btn-primary btn-sm text-capitalize"
            >
              Save changes
            </button>
          </div>
        </form>
      ) : (
        <BounceLoader />
      )}
    </div>
  );
};
