import React, { useEffect } from "react";
import closeCal from "../../../assets/images/popup_cross_icon.png";
import edit from "../../../assets/images/popup_edit_icon.png";
import printIcon from "../../../assets/images/print_icon.png";
import toggle from "../../../assets/images/toggle-icon.png";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import onClickOutside from "react-onclickoutside";
import {
  setPrinters,
  deleteReservedSpot,
  getAppointmentExportFile,
  getAppointmentLabelLink,
  getAppointmentReqFile,
} from "../../../actions/appointmentActions";
import axios from "axios";

const clickOutsideConfig = {
  handleClickOutside: () => Popup.handleClickOutside,
};

const Popup = (props) => {
  const history = useHistory();
  const office = useSelector((state) => state.adminOfficeInteractions.office);
  const viewType = useSelector((state) => state.appointmentReducer.viewType);
  const printers = useSelector((state) => state.appointmentReducer.printers);
  const eventTypes = useSelector(
    (state) => state.appointmentReducer.eventTypes
  );
  const dispatch = useDispatch();
  useEffect(() => {
    // window.dymo.label.framework.init();
    // const printers = window.dymo.label.framework.getPrinters();
    // console.log(printers);
    // dispatch(setPrinters(printers));
  }, []);
  const selectedEvent = (value) => {
    return eventTypes.find((evenT) => {
      return evenT.name == value;
    });
  };
  Popup.handleClickOutside = () => props.setPopup(false);
  return (
    <>
      {viewType == "colour" ? (
        <div
          className={`colour_view_popup appointment-popups ${
            moment(props.event.event.event_date).day() == 5 ? "popup-left" : ""
          }`}
        >
          <div class="top-info">
            <h4>{props.event.title}</h4>
            <div class="set-options">
              <ul>
                <li>
                  {!props.reserved && (
                    <a
                      href="#"
                      title="Edit"
                      className="edit"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(
                          `/office/${office.slug}/appointments/appointment/${
                            props.event.event.pk
                          }`
                        );
                      }}
                    >
                      <span>Edit</span>
                      <img src={edit} alt="" class="mCS_img_loaded" />
                    </a>
                  )}
                </li>
                {/* <li>
              <a href="#" title="">
                <img src={toggle} alt="" class="mCS_img_loaded" />
              </a>
            </li> */}
                <li>
                  <a
                    href="#"
                    title=""
                    class="closepopup"
                    onClick={(e) => {
                      e.preventDefault();
                      props.setPopup(false);
                    }}
                  >
                    <img src={closeCal} alt="" class="mCS_img_loaded" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {!props.reserved &&
            (props.event.event.event_type ? (
              <div className="mflex spb ">
                <div
                  className="event-type-text mflex alc"
                  style={{
                    backgroundColor:
                      selectedEvent(props.event.event.event_type) &&
                      selectedEvent(props.event.event.event_type).color,
                  }}
                >
                  <img
                    src={
                      selectedEvent(props.event.event.event_type) &&
                      selectedEvent(props.event.event.event_type).imgWhite
                    }
                  />
                  {props.event.event.event_type}
                </div>
              </div>
            ) : (
              ""
            ))}
          <div className="mflex spb ">
            <div>
              {" "}
              <p>
                {moment(props.event.event.start).format("MMMM DD")}
                <br />
                {moment(props.event.event.start).format("hh:mm a")}
              </p>
            </div>
            <div
              className={`${
                !props.reserved ? "prints-type" : "delete-reserved"
              } `}
            >
              <ul>
                {!props.reserved ? (
                  printers && (
                    <li>
                      <a
                        href="#"
                        title="Print Label"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(
                            getAppointmentLabelLink(
                              props.event.event.pk,
                              printers
                            )
                          );
                        }}
                      >
                        <img src={printIcon} alt="" />
                      </a>
                    </li>
                  )
                ) : (
                  <li>
                    <a
                      href="#"
                      title="Print Label"
                      onClick={(e) => {
                        dispatch(
                          deleteReservedSpot(props.event.event.pk, office.pk)
                        );
                        props.setPopup(false);
                      }}
                    >
                      <span>Delete Reserved Spot</span>
                    </a>
                  </li>
                )}
                {!props.reserved && (
                  <li>
                    <a
                      href="#"
                      title="Print Form"
                      onClick={(e) => {
                        e.preventDefault();
                        if (office.has_req_forms) {
                          dispatch(getAppointmentReqFile(props.event.event.pk));
                        } else {
                          dispatch(
                            getAppointmentExportFile(props.event.event.pk)
                          );
                        }
                      }}
                    >
                      <span>Print Form</span>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`simple_view_popup appointment-popups ${
            moment(props.event.appointment.event_date).day() == 5
              ? "popup-left"
              : ""
          }`}
        >
          <div class="top-info">
            <h4>{props.event.appointment.title}</h4>
            <div class="set-options">
              <ul>
                <li>
                  {!props.reserved && (
                    <a
                      href="#"
                      title="Edit"
                      className="edit"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(
                          `/office/${office.slug}/appointments/appointment/${
                            props.event.appointment.pk
                          }`
                        );
                      }}
                    >
                      <span>Edit</span>
                      <img src={edit} alt="" class="mCS_img_loaded" />
                    </a>
                  )}
                </li>
                {/* <li>
           <a href="#" title="">
             <img src={toggle} alt="" class="mCS_img_loaded" />
           </a>
         </li> */}
                <li>
                  <a
                    href="#"
                    title=""
                    class="closepopup"
                    onClick={(e) => {
                      e.preventDefault();
                      props.setPopup(false);
                    }}
                  >
                    <img src={closeCal} alt="" class="mCS_img_loaded" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {!props.reserved &&
            (props.event.appointment.event_type ? (
              <div class="mflex spb ">
                <div
                  className="event-type-text mflex alc"
                  style={{
                    backgroundColor:
                      selectedEvent(props.event.appointment.event_type) &&
                      selectedEvent(props.event.appointment.event_type).color,
                  }}
                >
                  <img
                    src={
                      selectedEvent(props.event.appointment.event_type) &&
                      selectedEvent(props.event.appointment.event_type).imgWhite
                    }
                  />
                  {props.event.appointment.event_type}
                </div>
              </div>
            ) : (
              ""
            ))}
          <div className="mflex spb ">
            <div>
              {" "}
              <p>
                {moment(props.event.appointment.start).format("MMMM, dddd DD")}
                <br />
                {moment(props.event.appointment.start).format("hh:mm a")}
              </p>
            </div>
            <div
              className={`${
                !props.reserved ? "prints-type" : "delete-reserved"
              } `}
            >
              <ul>
                {!props.reserved ? (
                  printers && (
                    <li>
                      <a
                        href="#"
                        title="Print Label"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(
                            getAppointmentLabelLink(
                              props.event.appointment.pk,
                              printers
                            )
                          );
                        }}
                      >
                        <img src={printIcon} alt="" />
                      </a>
                    </li>
                  )
                ) : (
                  <li>
                    <a
                      href="#"
                      title="Print Label"
                      onClick={(e) => {
                        dispatch(
                          deleteReservedSpot(
                            props.event.appointment.pk,
                            office.pk
                          )
                        );
                        props.setPopup(false);
                      }}
                    >
                      <span>Delete Reserved Spot</span>
                    </a>
                  </li>
                )}
                {/* {!props.reserved && (
         <li>
           <a
             href="#"
             title="Print Form"
             onClick={(e) => {
               e.preventDefault();
               if (office.has_req_forms) {
                 dispatch(getAppointmentReqFile(props.event.event.pk));
               } else {
                 dispatch(getAppointmentExportFile(props.event.event.pk));
               }
             }}
           >
             <span>Print Form</span>
           </a>
         </li>
       )} */}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Popup.prototype = {};

export default onClickOutside(Popup, clickOutsideConfig);
