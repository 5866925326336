import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AddOffice from "../patientList/patientList";
import {
  getPatientList,
  resetPageNum,
  resetPatientList,
} from "../../actions/adminOfficeActions";
import { getSearchPatients } from "../../actions/messagingActions";
import { BounceLoader } from "react-spinners";
import DashboardTop from "../../components/dashboardTop";
import moment from "moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import AsyncSelect from "react-select/async";

function PatientList(props) {
  // const dispatch = useDispatch();
  const patientsHaveLoaded = useSelector(
    (state) => state.adminOfficeInteractions.patientsHaveLoaded
  );
  const dispatch = useDispatch();
  const office = useSelector((state) => state.adminOfficeInteractions.office);
  const history = useHistory();
  const patients = useSelector(
    (state) => state.adminOfficeInteractions.patientList
  );
  // recycled from pastAppointments
  const pastAppointmentRequestNumber = useSelector(
    (state) => state.adminOfficeInteractions.pastAppointmentRequestNumber
  );

  useEffect(() => {
    dispatch(getPatientList(props.office.pk, pastAppointmentRequestNumber));

    return () => {
      dispatch(resetPageNum());
      dispatch(resetPatientList());
    };
  }, []);
  // console.log(props);

  // const onPatientClick = pk => {
  //   console.log("hi");
  //   return <Redirect push to={`/office/${props.office.slug}/patient/${pk}/`} />;
  // };
  const animatedComponents = makeAnimated();
  return (
    <div>
      <DashboardTop title={`Patients`} />
      {/* <Link
        to="/add-office"
        className="common-btn blue-btn"
        style={{ marginTop: 12, marginLeft: 20 }}
      >
        Add Office
      </Link> */}
      <div class="tabbing-main">
        <ul>
          <li>
            <Link
              to={`/office/${props.currentOffice}/patient-list`}
              class="active-tab"
            >
              Active
            </Link>
          </li>
          <li>
            <Link
              to={
                office.availability === "private"
                  ? `/office/${props.currentOffice}/office-requests`
                  : `/office/${props.currentOffice}/patient-list`
              }
              class="requested"
              style={{
                opacity: office.availability === "private" ? "1" : "0.6",
                cursor:
                  office.availability === "public" ? "not-allowed" : "pointer",
              }}
            >
              Requested {office.availability === "private" ? <span /> : ""}
            </Link>
          </li>
        </ul>
      </div>
      <div class="search-form-main">
        <form action="#" class="search-form">
          {/* https://stackoverflow.com/a/50607226/2809411 */}
          <AsyncSelect
            loadOptions={(input, callback) => {
              getSearchPatients(input, callback, props.currentOffice);
            }}
            components={animatedComponents}
            className="react-select-container"
            classNamePrefix="react-select"
            onChange={(option) =>
              history.push({
                pathname: `/office/${props.office.slug}/patient/${
                  option.value
                }/`,
                patient: option.value,
              })
            }
          />
          {/* <input
            placeholder=" Search…"
            class="form-control"
            name="s"
            type="search"
          /> */}
          <input type="submit" class="fa fa-search" value="" />
        </form>
      </div>
      <div class="middle-content main-content-wrapper">
        <div class="patients-table">
          <table>
            <tr class="no-border">
              <th>Name</th>
              <th>Gender</th>
              <th>D.O.B.</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Registered</th>
            </tr>
            {patients.length === 0 ? (
              <BounceLoader size={60} color={"#052D64"} />
            ) : (
              patients.map((patient) => (
                <tr
                  onClick={() =>
                    history.push({
                      pathname: `/office/${props.office.slug}/patient/${
                        patient.pk
                      }/`,
                      patient: patient.pk,
                    })
                  }
                  style={{ cursor: "pointer" }}
                >
                  <td>
                    {patient.first_name} {patient.last_name}
                  </td>
                  <td>{patient.gender}</td>
                  <td>{patient.date_of_birth}</td>
                  <td>{patient.user.email}</td>
                  <td>{patient.phone}</td>
                  <td>
                    {moment(patient.user.date_joined).format("MMMM Do YYYY")}
                  </td>
                </tr>
              ))
            )}
          </table>
        </div>
        <div class="loadmore">
          <a
            href="#"
            title="loadmore"
            onClick={(e) => {
              e.preventDefault();
              dispatch(
                getPatientList(props.office.pk, pastAppointmentRequestNumber)
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default PatientList;
