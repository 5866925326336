import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import blankProfile from "../assets/images/user.png";
import { userLogout } from "../actions/authActions";
import { WaitingRoomSettings } from "./WaitingRoomSettings";
import SettingWhite from "../assets/images/setting-white.png";
import PrintWhite from "../assets/images/header_print_icon.png";
import DownloadWhite from "../assets/images/header_download_icon.png";
import MessageWhite from "../assets/images/header_message_icon.png";
import settingswheel from "../assets/images/setting-white.png";
import EditWhite from "../assets/images/popup_edit_icon.png";
import Modal from "react-modal";
import { exportData, ExportData } from "./appointments/exportData";
import { PrintData } from "./appointments/calendar/PrintData";
import { openChatWindow } from "../actions/internalChatActions";

function DashboardTop(props) {
  const profile = useSelector((state) => state.authenticateUser.profile);
  const [menuProfile, setMenuProfile] = useState("close");
  const [waitingSettings, setWaitingSettings] = useState(false);
  const [appointmentOpen, setAppointmentOpen] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const socket = useSelector((state) => state.adminOfficeInteractions.socket);
  const doctorProfile = useSelector((state) => state.authenticateUser.profile);
  const office = useSelector((state) => state.adminOfficeInteractions.office);

  return (
    <div
      className={`dashboard-top ${
        props.appointments ? "calendar-headings" : ""
      }`}
    >
      <div
        className={`dashboard-title ${props.back ? "title-goback" : ""} ${
          props.appointments ? "with-right-icon" : ""
        }`}
      >
        {props.backLink ? (
          <Link to={props.backLink}>
            <h2>{props.title}</h2>
          </Link>
        ) : props.appointments ? (
          <div>
            <h2>{props.title}</h2>
            <div className="appointment-setting-popup">
              <a
                href="#"
                title=""
                onClick={(e) => {
                  e.preventDefault();
                  history.push(
                    `/office/${office.slug}/appointments/appointment-settings`
                  );
                }}
              >
                <img src={settingswheel} alt="" />
              </a>

              {openExport && (
                <ExportData openExport setOpenExport={setOpenExport} />
              )}

              {openPrint && <PrintData openPrint setOpenPrint={setOpenPrint} />}

              <div
                id="print-data-popup"
                class="display-none full-height stage4-popup"
              >
                <div class="vertical-middle form-main">
                  <div class="common-form">
                    <div class="sub-heading common-subheading">
                      <h3>PRINT DATA</h3>
                    </div>
                    <div class="inner-forgot-pass inner-popup-content">
                      <h4>Select a Timeframe</h4>
                      <div class="snooze-datetime-main calendar-datetime">
                        <div class="snooze-date-time datetime-main form-group">
                          <label class="select-dt">Select Date(s):</label>
                          <div class="office-time o-date">
                            <input
                              type="text"
                              class="form-control datetimepicker-input"
                              id="datetimepicker1"
                              datetimepicker-input=""
                              data-toggle="datetimepicker"
                              data-target="#datetimepicker1"
                              placeholder="09/13/2020"
                            />
                          </div>
                          <span class="to">to</span>
                          <div class="office-time">09/14/2020</div>
                        </div>
                      </div>
                      <div class="print-checbk">
                        <label>Print:</label>
                        <span class="form-group">
                          <input
                            type="checkbox"
                            id="check1"
                            name="check-group"
                            class="checkbox-custom"
                          />
                          <label for="check1" class="checkbox-custom-label">
                            <span>Label</span>
                          </label>
                        </span>
                        <span class="form-group">
                          <input
                            type="checkbox"
                            id="check2"
                            name="check-group1"
                            class="checkbox-custom"
                          />
                          <label for="check2" class="checkbox-custom-label">
                            <span>form</span>
                          </label>
                        </span>
                      </div>
                      <div class="login-submit">
                        <a
                          href="#"
                          title="Cancel"
                          class="common-btn border-btn"
                          data-fancybox-close=""
                        >
                          Cancel
                        </a>
                        <a
                          href="#"
                          title="Print Data"
                          class="common-btn blue-btn"
                        >
                          Print Data
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="appointment-setting-popup border">
              <a
                onClick={(e) => {
                  e.preventDefault();

                  setOpenPrint(true);
                }}
              >
                <img src={PrintWhite} />
              </a>
            </div>
            <div className="appointment-setting-popup border">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setOpenExport(true);
                  setAppointmentOpen(false);
                }}
              >
                <img src={DownloadWhite} />
              </a>
            </div>
            <div className="appointment-setting-popup border">
              <Link
                to={`/office/${office.slug}/export-files`}
                class="download-data"
              >
                {" "}
                <img src={EditWhite} />
              </Link>
            </div>
            <div className="new-event-btn">
              <a
                href="#"
                title="+New Appointment"
                class="common-btn blue-btn"
                onClick={(e) => {
                  e.preventDefault();

                  history.push("new-appointment/");
                }}
              >
                + New Appointment
              </a>
            </div>
          </div>
        ) : props.rightIcon ? (
          <div style={{ position: "relative" }}>
            <h2>
              {props.title}
              <a
                href="#"
                title=""
                style={{
                  position: "relative",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setWaitingSettings(!waitingSettings);
                }}
              >
                <img src={SettingWhite} alt="" />
              </a>
            </h2>
            {waitingSettings ? <WaitingRoomSettings /> : ""}
          </div>
        ) : (
          <h2>{props.title}</h2>
        )}
      </div>

      <div class="headrright pull-right">
        {" "}
        <div class="user-profile-right">
          {/* <div class="search-questions">
            <div
              class="search-main"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <input type="submit" class="search-submit" value="" />
              <input type="text" class="search-text" placeholder="Search" />
            </div>
          </div> */}
          {Object.keys(office).length !== 0 && office.constructor === Object && (
            <div className="message-wrap">
              <div className="appointment-setting-popup border">
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(openChatWindow(true));
                  }}
                >
                  <img src={MessageWhite} />
                </a>
              </div>
            </div>
          )}
          <span>
            {profile.role == "doctor" ? "Dr. " : ""} {profile.first_name}{" "}
            {profile.last_name}
          </span>
          <a
            href="#"
            title="user-profile"
            class="username"
            onClick={(e) => {
              e.preventDefault();
              setMenuProfile(menuProfile == "close" ? "open" : "close");
            }}
          >
            {" "}
            {profile.first_name.charAt(0)}
            {profile.last_name.charAt(0)}
            <div class="notification">
              <img src="images/Notification.svg" alt="" />
            </div>
          </a>
          <div
            id="user-info"
            style={{ display: menuProfile == "close" ? "none" : "block" }}
          >
            <ul>
              <li class="no-border-top">
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    socket.close();
                    history.push("/dashboard/");
                  }}
                >
                  Leave Office
                </a>
              </li>
              <li>
                <a
                  href="#"
                  title="Log out"
                  onClick={(e) => {
                    e.preventDefault();
                    localStorage.removeItem("id_token");
                    dispatch(userLogout());
                    socket.close();
                    history.push("/");
                  }}
                >
                  Log out
                </a>
              </li>
              {office.slug && (
                <li>
                  <a
                    href="#"
                    title="Log out"
                    onClick={(e) => {
                      history.push(`/office/${office.slug}/settings`);
                    }}
                  >
                    Office Settings
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

// onClick={() => dispatch(requestLogin())}

export default DashboardTop;
