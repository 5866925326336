import React, { useRef } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

type InternalMessageFormProps = {
  adminThread: number;
};

export const InternalMessageForm = (props: InternalMessageFormProps) => {
  const socket = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.socket
  );

  const profile = useSelector(
    (state: RootStateOrAny) => state.authenticateUser.profile
  );

  const formInput = useRef<any>();

  return (
    <div className="type-msg">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (formInput.current.value) {
            socket.send(
              JSON.stringify({
                type: "ADD_MESSAGE_TO_ADMIN_THREAD",
                threadId: props.adminThread,
                message: formInput.current.value,
                admin: profile.pk,
              })
            );
            formInput.current.value = "";
          }
        }}
      >
        <input
          type="text"
          placeholder="Type a message and hit enter."
          className="common-input"
          ref={formInput}
        />
      </form>
    </div>
  );
};
