import moment from "moment";

const initialState = {
  newAppointmentTab: "detail",
  dependentsNumber: 0,
  newAppointmentDate: null,
  newAppointmentTime: null,
  patientForm: {},
  patientForms: [],
  isSubmittingQuestionnaire: false,
  dependentsOnly: false,
  hasReceivedQuestionnaire: false,
  dependents: [],
  isGettingDependents: [],
  immunizations: [],
  isFetchingImmunizations: false,
  threeDays: [],
  firstTimes: [],
  secondTimes: [],
  thirdTimes: [],
  eventType: "",
  summaryEnable: false,
  notes: "",
  createdAppointmentNumber: null,
};

const newAppointmentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_EMAIL_FORM_CHANGE":
      return Object.assign({}, state, {
        patientForm: {
          ...state.patientForm,
          first_name: "  ",
          last_name: "  ",
          phone: "  ",
          ohipNum: "  ",
          ohip_vc: "  ",
          date_of_birth: moment(),
          doctor: "  ",
          address: "  ",
          gender: "  ",
          adminPk: action.adminPk,
        },
      });
    case "SET_CREATED_APPOINTMENT_NUMBER":
      return Object.assign({}, state, {
        createdAppointmentNumber: action.value,
      });
    case "SET_EVENT_TYPE":
      return Object.assign({}, state, {
        eventType: action.value,
      });
    case "SET_NOTES":
      return Object.assign({}, state, {
        notes: action.value,
      });
    case "SET_ENABLE_SUMMARY_VIEW":
      return Object.assign({}, state, {
        summaryEnable: action.value,
      });

    case "REQUEST_IMMUNIZATION_LIST":
      return Object.assign({}, state, {
        isFetchingImmunizations: true,
      });
    case "RECEIVE_IMMUNIZATION_LIST":
      return Object.assign({}, state, {
        isFetchingImmunizations: false,
        immunizations: action.immunizations,
      });
    case "ERROR_IMMUNIZATION_LIST":
      return Object.assign({}, state, {
        isFetchingImmunizations: false,
      });
    case "REQUEST_GET_DEPENDENTS":
      return Object.assign({}, state, {
        isGettingDependents: true,
      });
    case "RECEIVE_FIRST_TIMES":
      return Object.assign({}, state, {
        firstTimes: action.firstTimes,
      });
    case "RECEIVE_SECOND_TIMES":
      return Object.assign({}, state, {
        secondTimes: action.secondTimes,
      });
    case "RECEIVE_THIRD_TIMES":
      return Object.assign({}, state, {
        thirdTimes: action.thirdTimes,
      });
    case "SET_NEXT_THREE_DAYS":
      return Object.assign({}, state, {
        threeDays: action.threeDays,
      });
    case "RECEIVE_GET_DEPENDENTS":
      return Object.assign({}, state, {
        isGettingDependents: false,
        dependents: action.dependents,
      });
    case "REQUEST_SUBMIT_QUESTIONNAIRE":
      return Object.assign({}, state, {
        isSubmittingQuestionnaire: true,
      });
    case "RECEIVE_SUBMIT_QUESTIONNAIRE":
      return Object.assign({}, state, {
        isSubmittingQuestionnaire: false,
        hasReceivedQuestionnaire: true,
      });
    case "ERROR_SUBMIT_QUESTIONNAIRE":
      return Object.assign({}, state, {
        isSubmittingQuestionnaire: false,
      });
    case "RESET_SUBMIT_QUESTIONNAIRE":
      return Object.assign({}, state, {
        isSubmittingQuestionnaire: false,
        hasReceivedQuestionnaire: false,
      });
    case "SET_DEPENDENTS_ONLY":
      return Object.assign({}, state, {
        dependentsOnly: action.dependentsOnly,
      });
    case "RESET_FORM":
      return Object.assign({}, state, {
        patientForm: {},
      });
    case "RESET_PATIENT_FORMS":
      return Object.assign({}, state, {
        patientForms: [],
        patientForm: {},
        newAppointmentTab: "detail",
        dependentsNumber: 0,
        newAppointmentDate: null,
        newAppointmentTime: null,
        dependentsOnly: false,
        immunizations: [],
        threeDays: [],
        firstTimes: [],
        secondTimes: [],
        thirdTimes: [],
        eventType: "",
        summaryEnable: false,
        notes: "",
        createdAppointmentNumber: null,
      });
    case "SET_FORM":
      return Object.assign({}, state, {
        patientForm: state.patientForms[action.instance]
          ? state.patientForms[action.instance]
          : {},
      });
    case "UPDATE_FORM_INSTANCE":
      return Object.assign({}, state, {
        patientForms: state.patientForms.map((form, index) => {
          if (index === action.instance) {
            return action.form;
          }
          return form;
        }),
      });
    case "SAVE_FORM_INSTANCE":
      return Object.assign({}, state, {
        patientForms: Object.assign([], state.patientForms, {
          [action.instance]: {
            ...state.patientForm,
            [action.name]: action.value,
          },
        }),
      });
    case "ADD_SYMPTOMS_TO_FORM":
      return Object.assign({}, state, {
        patientForm: {
          ...(state.patientForms[action.instance] as {}),
          symptoms: action.symptoms,
        },
      });

    case "ADD_IMMUNIZATIONS_TO_FORM":
      return Object.assign({}, state, {
        patientForm: {
          ...state.patientForm,
          immunizations: action.immunizations,
        },
      });
    case "SET_APPOINTMENT_DATE":
      return Object.assign({}, state, {
        newAppointmentDate: action.newAppointmentDate,
      });
    case "SET_APPOINTMENT_TIME":
      return Object.assign({}, state, {
        newAppointmentTime: action.newAppointmentTime,
      });
    case "SET_NEW_APPOINTMENT_TAB":
      return Object.assign({}, state, {
        newAppointmentTab: action.newAppointmentTab,
      });
    case "SET_DEPENDENTS_NUMBER":
      return Object.assign({}, state, {
        dependentsNumber: action.dependentsNumber,
      });
    case "SET_FORM_CHANGE":
      return Object.assign({}, state, {
        patientForm: {
          ...state.patientForm,
          [action.name]: action.value,
        },
      });
    case "SET_PATIENT_FORMS":
      return Object.assign({}, state, {
        patientForms: state.patientForms.filter(
          (form, index) => index <= action.number
        ),
      });
    case "UPDATE_PATIENT_FORMS":
      return Object.assign({}, state, {
        patientForms: state.patientForms.filter(
          (form, index) => index != action.number
        ),
      });
    default:
      return state;
  }
};

export default newAppointmentReducer;
