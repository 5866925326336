import axios from "axios";
import store from "store";

function requestChatConnection() {
  return {
    type: "REQUEST_CHAT_CONNECTION",
    isConnectingToChat: true,
  };
}

function receiveChatConnection() {
  return {
    type: "RECEIVE_CHAT_CONNECTION",
    isConnectingToChat: false,
    hasConnectedToChat: true,
  };
}

export function getChatConnection(email) {
  const token = store.get("id_token");
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/doctor_twilio_auth/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      email: email,
    },
  };
  return (dispatch) => {
    dispatch(requestChatConnection());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        store.set("chat_token", data);
        receiveChatConnection();
      });
  };
}
