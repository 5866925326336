import axios from "axios";
import launchToast from "../utils";
import store from "store";
import Video from "twilio-video";

import {
  attachTracks,
  detachTracks,
  stopTracks,
} from "../utils/trackFunctions";
const Chat = require("twilio-chat");

export function resetLocalParticipant() {
  return {
    type: "RESET_LOCAL_PARTICIPANT",
  };
}

export function setLocalParticipant(localParticipant) {
  return {
    type: "SET_LOCAL_PARTICIPANT",
    localParticipant,
  };
}

export function setVideoDevice(device) {
  return {
    type: "SET_VIDEO_DEVICE",
    videoDevice: device,
  };
}

export function setAudioDevice(device) {
  return {
    type: "SET_AUDIO_DEVICE",
    audioDevice: device,
  };
}

function setWaitingPatients(users) {
  return {
    type: "SET_WAITING_PATIENTS",
    users: users,
  };
}

function requestChatToken() {
  return {
    type: "REQUEST_CHAT_TOKEN",
    isFetchingToken: true,
  };
}

function receiveChatToken(token) {
  return {
    type: "RECEIVE_CHAT_TOKEN",
    isFetchingToken: false,
    chat_token: token,
  };
}

function requestGetWaitingPatient() {
  return {
    type: "REQUEST_GET_WAITING_PATIENT",
    isFetchingPatient: true,
    selectedUser: null,
  };
}

function receiveGetWaitingPatient(patient) {
  return {
    type: "RECEIVE_GET_WAITING_PATIENT",
    isFetchingPatient: false,
    selectedUser: patient,
  };
}

export function resetSelectedUser() {
  return {
    type: "RESET_SELECTED_USER",
    selectedUser: null,
  };
}

function requestCreateTwilioClient() {
  return {
    type: "REQUEST_CREATE_TWILIO_CLIENT",
    isCreatingClient: true,
  };
}

function receiveCreateTwilioClient(client) {
  return {
    type: "RECEIVE_CREATE_TWILIO_CLIENT",
    isCreatingClient: false,
    hasCreatedClient: true,
    client: client,
  };
}

function requestDeleteTwilioClient() {
  return {
    type: "REQUEST_DELETE_TWILIO_CLIENT",
  };
}

function receiveDeleteTwilioClient() {
  return {
    type: "RECEIVE_DELETE_TWILIO_CLIENT",
  };
}

function errorDeleteTwilioClient() {
  return {
    type: "ERROR_DELETE_TWILIO_CLIENT",
  };
}

// EXPORTED METHODS
function createChatClient(token) {
  return new Promise((resolve, reject) => {
    resolve(Chat.Client.create(token));
  });
}

function deleteChatClient(client) {
  return new Promise((resolve, reject) => {
    resolve(client.shutdown());
  });
}

function requestGetChatRoom() {
  return {
    type: "REQUEST_GET_CHAT_ROOM",
    fetchingChatRoom: true,
  };
}

function receiveGetChatRoom(chatRoom) {
  return {
    type: "RECEIVE_GET_CHAT_ROOM",
    fetchingChatRoom: false,
    chatRoom,
  };
}

function requestGetVideoChatRoomToken() {
  return {
    type: "REQUEST_GET_VIDEO_CHAT_ROOM_TOKEN",
    isFetchingVideoToken: true,
  };
}

function receiveGetVideoChatRoomToken(token) {
  return {
    type: "RECEIVE_GET_VIDEO_CHAT_ROOM_TOKEN",
    isFetchingVideoToken: false,
    videoToken: token,
  };
}

function errorGetVideoChatRoomToken() {
  return {
    type: "ERROR_GET_VIDEO_CHAT_ROOM_TOKEN",
    isFetchingVideoToken: false,
    errorMessage:
      "Couldn't receive Video Room Token. Please check your internet connection.",
  };
}

export function joiningActiveCall(patient) {
  return {
    type: "JOINING_ACTIVE_CALL",
    joiningPatient: patient,
  };
}

function setJoinActiveCall(patient, admin, allActiveCalls) {
  return {
    type: "SET_JOIN_ACTIVE_CALL",
    activeCall: patient,
    activeAdmin: admin,
    allActiveCalls,
    joiningPatient: null,
  };
}

function setLeaveActiveCall(patient) {
  return {
    type: "SET_LEAVE_ACTIVE_CALL",
    activeCall: null,
  };
}

function setGetActiveCall(activeCalls) {
  return {
    type: "SET_GET_ACTIVE_CALL",
    activeCalls: activeCalls,
  };
}

function setVideoRoomAction(room) {
  return {
    type: "SET_VIDEO_ROOM",
    room,
  };
}

function setChatChannelAction(chatRoom) {
  return {
    type: "SET_CHAT_CHANNEL",
    chatRoom,
  };
}

function requestJoinChannel() {
  return {
    type: "REQUEST_JOIN_CHANNEL",
    isJoiningChannel: true,
    joinChannelErrorMessage: "",
  };
}

export function setMessages(obj) {
  return {
    type: "SET_MESSAGES",
    obj: obj,
  };
}

function receiveJoinChannel(chatChannel) {
  return {
    type: "RECEIVE_JOIN_CHANNEL",
    isJoiningChannel: false,
    chatChannel: chatChannel,
  };
}

function errorJoinChannel(errorMessage) {
  return {
    type: "ERROR_JOIN_CHANNEL",
    isJoiningChannel: false,
    joinChannelErrorMessage: errorMessage,
  };
}

function requestGetChannelName() {
  return {
    type: "REQUEST_GET_CHANNEL_NAME",
    isFetchingChannelName: true,
  };
}

function receivedGetChannelName(name) {
  return {
    type: "RECEIVED_GET_CHANNEL_NAME",
    isFetchingChannelName: false,
    channelName: name,
  };
}

function requestGetTriageForm() {
  return {
    type: "REQUEST_GET_TRIAGE_FORM",
    isFetchingTriageForm: true,
    triageQuestions: null,
  };
}

function receiveGetTriageForm(triage) {
  return {
    type: "RECEIVE_GET_TRIAGE_FORM",
    isFetchingTriageForm: false,
    triageForm: triage,
  };
}

export function resetRoom() {
  return {
    type: "RESET_ROOM",
    room: null,
    videoToken: null,
  };
}

function setUpdateActiveCalls(allActiveCalls) {
  return {
    type: "SET_UPDATE_ACTIVE_CALLS",
    allActiveCalls,
  };
}

function requestUpdateTriageNotes() {
  return {
    type: "REQUEST_UPDATE_TRIAGE_NOTES",
    isUpdatingTriageNote: true,
    updateTriageNoteMessage: "",
  };
}

function receiveUpdateTriageNotes() {
  return {
    type: "RECEIVE_UPDATE_TRIAGE_NOTES",
    isUpdatingTriageNote: false,
    updateTriageNoteMessage: "Updated Triage note successfully.",
  };
}

function errorUpdateTriageNotes() {
  return {
    type: "ERROR_UPDATE_TRIAGE_NOTES",
    isUpdatingTriageNote: false,
    updateTriageNoteMessage: "Failed to update Triage Note.",
  };
}

function requestGetTriageQuestions() {
  return {
    type: "REQUEST_GET_TRIAGE_QUESTIONS",
    fetchingTriageQuestions: true,
  };
}

function receiveGetTriageQuestions(triageQuestions) {
  return {
    type: "RECEIVE_GET_TRIAGE_QUESTIONS",
    fetchingTriageQuestions: false,
    triageQuestions,
  };
}

function requestGetVideoName() {
  return {
    type: "REQUEST_GET_VIDEO_NAME",
    isFetchingVideoName: true,
  };
}

function receiveGetVideoName(videoName) {
  return {
    type: "RECEIVE_GET_VIDEO_NAME",
    isFetchingVideoName: false,
    videoChannelName: videoName,
  };
}

function requestSubmitSOAPNote() {
  return {
    type: "REQUEST_SUBMIT_SOAP_NOTE",
    isSubmittingSOAPNote: true,
  };
}

function receiveSubmitSOAPNote() {
  return {
    type: "RECEIVE_SUBMIT_SOAP_NOTE",
    isSubmittingSOAPNote: false,
  };
}

function requestUpdateSOAPNote() {
  return {
    type: "REQUEST_UPDATE_SOAP_NOTE",
    isUpdatingSOAPNote: true,
  };
}

function receiveUpdateSOAPNote() {
  return {
    type: "RECEIVE_UPDATE_SOAP_NOTE",
    isUpdatingSOAPNote: false,
  };
}

function requestSubmitTriageNote() {
  return {
    type: "REQUEST_SUBMIT_TRIAGE_NOTE",
    isSubmittingTriageNote: true,
  };
}

function receiveSubmitTriageNote() {
  return {
    type: "RECEIVE_SUBMIT_TRIAGE_NOTE",
    isSubmittingTriageNote: false,
  };
}

function errorSubmitTriageNote() {
  return {
    type: "ERROR_SUBMIT_TRIAGE_NOTE",
    isSubmittingTriageNote: false,
  };
}

function requestUpdateTriageForm() {
  return {
    type: "REQUEST_UPDATE_TRIAGE_FORM",
    isUpdatingTriageNote: true,
  };
}

function receiveUpdateTriageForm() {
  return {
    type: "RECEIVE_UPDATE_TRIAGE_FORM",
    isUpdatingTriageNote: false,
  };
}

function errorUpdateTriageForm() {
  return {
    type: "ERROR_UPDATE_TRIAGE_FORM",
    isUpdatingTriageNote: false,
  };
}

function requestGetWaitingRoomThread() {
  return {
    type: "REQUEST_GET_WAITING_ROOM_THREAD",
    waitingRoomThread: null,
    isFetchingWaitingRoomThread: true,
  };
}

function receiveGetWaitingRoomThread(thread) {
  return {
    type: "RECEIVE_GET_WAITING_ROOM_THREAD",
    waitingRoomThread: thread,
    isFetchingWaitingRoomThread: false,
  };
}

function errorGetWaitingRoomThread(err) {
  return {
    type: "ERROR_GET_WAITING_ROOM_THREAD",
    isFetchingWaitingRoomThread: false,
    threadErr: err,
  };
}

export function resetVideoRoomName() {
  return {
    type: "RESET_VIDEO_ROOM_NAME",
    videoName: null,
  };
}

export function setVideoParticipants(participant) {
  return {
    type: "SET_VIDEO_PARTICIPANTS",
    participant,
  };
}

export function removeVideoParticipants(participant) {
  return {
    type: "REMOVE_VIDEO_PARTICIPANT",
    participant,
  };
}

export function clearVideoParticipants() {
  return {
    type: "CLEAR_VIDEO_PARTICIPANTS",
    participants: [],
  };
}

export function resetChannelName() {
  return {
    type: "RESET_CHANNEL_NAME",
    channelName: null,
  };
}

export function setVideoTracks(videoTracks) {
  return {
    type: "SET_VIDEO_TRACKS",
    videoTracks,
  };
}

export function setAudioTracks(audioTracks) {
  return {
    type: "SET_VIDEO_TRACKS",
    audioTracks,
  };
}

export function removeVideoTracks(videoTrack) {
  return {
    type: "REMOVE_VIDEO_TRACK",
    videoTrack,
  };
}

export function removeAudioTracks(audioTrack) {
  return {
    type: "REMOVE_AUDIO_TRACK",
    audioTrack,
  };
}

export function resetVideoTracks() {
  return {
    type: "RESET_VIDEO_TRACKS",
    videoTracks: [],
  };
}

export function resetAudioTracks() {
  return {
    type: "RESET_AUDIO_TRACKS",
    audioTracks: [],
  };
}

export function resetMessages() {
  return {
    type: "RESET_MESSAGES",
    messages: [],
  };
}

export function getOldMessages(messages) {
  return {
    type: "GET_OLD_MESSAGES",
    messages: messages,
  };
}

export function getWaitingPatients(users) {
  return (dispatch) => {
    dispatch(setWaitingPatients(users));
  };
}

export function getChatToken(email) {
  const token = store.get("id_token");
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/doctor_twilio_auth/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      email: email,
    },
  };
  return (dispatch) => {
    dispatch(requestChatToken());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveChatToken(data));
      });
  };
}

export function getWaitingPatient(pk) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/patient/${pk}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetWaitingPatient());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetWaitingPatient(data));
      })
      .catch((err) => console.log(err));
  };
}

export function getWaitingGuestPatient(pk) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/guest_patient/${pk}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetWaitingPatient());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetWaitingPatient(data));
      })
      .catch((err) => console.log(err));
  };
}

export function createTwilioClient() {
  const token = store.get("chat_token");
  return (dispatch) => {
    dispatch(requestCreateTwilioClient());
    createChatClient(token)
      .then((client) => {
        dispatch(receiveCreateTwilioClient(client));
      })
      .catch((err) => console.log(err));
  };
}

export function deleteTwilioClient(client) {
  return (dispatch) => {
    dispatch(requestDeleteTwilioClient());
    deleteChatClient(client)
      .then((res) => {
        dispatch(receiveDeleteTwilioClient());
      })
      .catch((err) => {
        console.log(err);
        dispatch(errorDeleteTwilioClient(err));
      });
  };
}

export function getChatRoom(user_pk, office_pk, email) {
  const token = store.get("id_token");

  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/appointments/chat/${user_pk}/${office_pk}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetChatRoom());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetChatRoom(data.chat_name));
        dispatch(getVideoChatRoomToken(data.chat_name, email));
      })
      .catch((err) => console.log(err));
  };
}

export function getVideoChatRoomToken(chatRoom, email, socket, socket_config) {
  // Socket is to show that we connected to the room successfully.

  const token = store.get("id_token");

  let config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/video_token/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      email: email,
      chatRoom: chatRoom,
    },
  };
  console.log(`WHATS UP:   ${chatRoom}`);

  return (dispatch) => {
    dispatch(requestGetVideoChatRoomToken());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetVideoChatRoomToken(data));
        socket.send(socket_config);
      })
      .catch((err) => {
        console.log(err);
        dispatch(errorGetVideoChatRoomToken());
      });
  };
}

export function joinActiveCall(patient, admin, allActiveCalls) {
  return (dispatch) => {
    dispatch(setJoinActiveCall(patient, admin, allActiveCalls));
  };
}

export function updateActiveCalls(allActiveCalls) {
  return (dispatch) => {
    dispatch(setUpdateActiveCalls(allActiveCalls));
  };
}

export function leaveActiveCall() {
  return (dispatch) => {
    dispatch(setLeaveActiveCall());
  };
}

export function receiveGetAllUnreadMessages(users) {
  return {
    type: "RECEIVE_GET_ALL_UNREAD_MESSAGES",
    unreadThreads: users,
  };
}

export function getActiveCalls(activeCalls) {
  return (dispatch) => {
    dispatch(setGetActiveCall(activeCalls));
  };
}

export function setVideoRoom(room) {
  return (dispatch) => {
    dispatch(setVideoRoomAction(room));
  };
}

export function setChatChannel(chatRoom) {
  return (dispatch) => {
    dispatch(setChatChannelAction(chatRoom));
  };
}

export function getChannelName(user_pk, office_pk, client) {
  let token = store.get("id_token");
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/appointments/chat/${user_pk}/${office_pk}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(requestGetChannelName());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receivedGetChannelName(data.chat_name));
        dispatch(joinChannel(client, data.chat_name));
      });
  };
}

export function getVideoName(
  user_pk,
  office_pk,
  adminEmail,
  socket,
  socket_config,
  guest_user_pk
) {
  let token = store.get("id_token");
  let config;
  if (user_pk) {
    config = {
      method: "get",
      url: `${process.env.REACT_APP_DEV_API}/appointments/video-chat/${user_pk}/${office_pk}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  } else {
    config = {
      method: "get",
      url: `${process.env.REACT_APP_DEV_API}/appointments/guest-video-chat/${guest_user_pk}/${office_pk}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
  return (dispatch) => {
    dispatch(requestGetVideoName());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetVideoName(data[0].chat_name));
        return data[0].chat_name;
      })
      .then((chat_name) => {
        dispatch(
          getVideoChatRoomToken(chat_name, adminEmail, socket, socket_config)
        );
      })
      .catch((err) => console.log(err));
  };
}

export function joinChannel(chatClient, chat_name) {
  return (dispatch) => {
    dispatch(requestJoinChannel());
    dispatch(resetMessages());
    chatClient
      .getSubscribedChannels()
      .then((chat_room) => {
        chatClient
          .getChannelByUniqueName(chat_name)
          .then((chatChannel) => {
            if (chatChannel.state.status !== "joined") {
              chatChannel
                .join()
                .then(() => {
                  dispatch(setMessages({ body: "Joining general channel..." }));
                })
                .catch(() => Error("Could not join general channel."));
            }

            return chatChannel;
          })
          .then((chatChannel) => {
            dispatch(receiveJoinChannel(chatChannel));
          })
          .catch((err) => {
            console.log(err);
            errorJoinChannel(err);
          });
      })
      .catch((err) => {
        errorJoinChannel(err);
      });
  };
}

export function getTriageForm(patientPK) {
  let token = store.get("id_token");
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/appointments/latest-triage/${patientPK}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetTriageForm());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetTriageForm(data));
      })
      .catch((err) => console.log(err));
  };
}

export function getGuestTriageForm(patientPK) {
  let token = store.get("id_token");
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/appointments/latest-guest-triage/${patientPK}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetTriageForm());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetTriageForm(data));
      })
      .catch((err) => console.log(err));
  };
}

export function disconnectFromRoom(room, socket, socket_config) {
  console.log(room);

  room.localParticipant.tracks.forEach((publication) => {
    try {
      room.localParticipant.unpublishTrack(publication.track);

      // publication.track.stop();
      const attachedElements = publication.track.detach();
      publication.track.stop();
      console.log("unsubscribed from: " + publication.track);
      attachedElements.forEach((element) => element.remove());
    } catch (err) {
      console.log(err);
    }
  });
  console.log(room.localParticipant.tracks);
  // room.localParticipant.tracks.forEach((track) => track.track.stop());
  try {
    room.disconnect();
    socket.send(socket_config);
  } catch (err) {
    console.log(err);
  }

  // localstream.getTracks()[0].stop();
  return (dispatch) => {
    dispatch(resetRoom());
  };
}

export function updateTriageNotes(triage_pk, nurseNotes) {
  let token = store.get("id_token");
  const config = {
    method: "patch",
    url: `${process.env.REACT_APP_DEV_API}/appointments/triage-form/${triage_pk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      admin_notes: nurseNotes,
    },
  };

  return (dispatch) => {
    dispatch(requestUpdateTriageNotes());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveUpdateTriageNotes());
      })
      .catch((err) => {
        console.log(err.response);
        dispatch(errorUpdateTriageNotes());
      });
  };
}

export function getTriageQuestions(symptom) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/appointments/triage-questions/${symptom}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetTriageQuestions());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetTriageQuestions(data));
      })
      .catch((err) => console.log(err));
  };
}

export function submitSOAPNote(symptom, patient, triage, questions) {
  const token = store.get("id_token");
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/appointments/soap-note/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      symptom,
      patient,
      triage,
      soap_question_soap_note: questions,
    },
  };

  return (dispatch) => {
    dispatch(requestSubmitSOAPNote());
    return axios(config)
      .then((res) => {
        launchToast("Successfully saved SOAP note");
        dispatch(receiveSubmitSOAPNote());
        dispatch(getTriageForm(patient));
      })
      .catch((err) => {
        console.log(err);
        launchToast(`Error creating SOAP note: ${err.response}`);
      });
  };
}

export function updateSOAPNote(symptom, patient, triage, questions) {
  const token = store.get("id_token");
  let config = {
    method: "patch",
    url: `${process.env.REACT_APP_DEV_API}/appointments/soap-note-update/${triage}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      symptom,
      patient,
      soap_question_soap_note: questions,
    },
  };

  return (dispatch) => {
    dispatch(requestUpdateSOAPNote());
    return axios(config)
      .then((res) => {
        launchToast("Successfully saved SOAP note");
        dispatch(receiveUpdateSOAPNote());
        dispatch(getTriageForm(patient));
      })
      .catch((err) => {
        console.log(err);
        launchToast(`Error creating SOAP note: ${err.response}`);
      });
  };
}

export function submitTriageNote(triage, triageObj, patient) {
  const token = store.get("id_token");
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/appointments/triage-submit/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      triage,
      subjective: triageObj.subjective,
      objective: triageObj.objective,
      assessment: triageObj.assessment,
      plan: triageObj.plan,
    },
  };

  return (dispatch) => {
    dispatch(requestSubmitTriageNote());
    return axios(config)
      .then((res) => {
        launchToast("Successfully saved SOAP note");
        dispatch(receiveSubmitTriageNote());
        dispatch(getTriageForm(patient));
      })
      .catch((err) => {
        launchToast(`Error creating Triage Note: ${err.response}`);
        dispatch(errorSubmitTriageNote());
      });
  };
}

export function submitGuestTriageNote(triage, triageObj, patient) {
  const token = store.get("id_token");
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/appointments/triage-submit/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      triage,
      subjective: triageObj.subjective,
      objective: triageObj.objective,
      assessment: triageObj.assessment,
      plan: triageObj.plan,
    },
  };

  return (dispatch) => {
    dispatch(requestSubmitTriageNote());
    return axios(config)
      .then((res) => {
        launchToast("Successfully saved SOAP note");
        dispatch(receiveSubmitTriageNote());
        dispatch(getGuestTriageForm(patient));
      })
      .catch((err) => {
        launchToast(`Error creating Triage Note: ${err.response}`);
        dispatch(errorSubmitTriageNote());
      });
  };
}

export function updateTriageForm(triageQuestionPk, triageObj, patient) {
  const token = store.get("id_token");
  let config = {
    method: "patch",
    url: `${process.env.REACT_APP_DEV_API}/appointments/triage-update/${triageQuestionPk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      subjective: triageObj.subjective,
      assessment: triageObj.assessment,
      objective: triageObj.objective,
      plan: triageObj.plan,
    },
  };

  return (dispatch) => {
    dispatch(requestUpdateTriageForm());
    return axios(config)
      .then((res) => {
        launchToast("Successfully updated triage form.");
        dispatch(receiveUpdateTriageForm());
        dispatch(getTriageForm(patient));
      })
      .catch((err) => {
        dispatch(errorUpdateTriageForm());
      });
  };
}

export function updateGuestTriageForm(triageQuestionPk, triageObj, patient) {
  const token = store.get("id_token");
  let config = {
    method: "patch",
    url: `${process.env.REACT_APP_DEV_API}/appointments/triage-update/${triageQuestionPk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      subjective: triageObj.subjective,
      assessment: triageObj.assessment,
      objective: triageObj.objective,
      plan: triageObj.plan,
    },
  };

  return (dispatch) => {
    dispatch(requestUpdateTriageForm());
    return axios(config)
      .then((res) => {
        launchToast("Successfully updated triage form.");
        dispatch(receiveUpdateTriageForm());
        dispatch(getGuestTriageForm(patient));
      })
      .catch((err) => {
        dispatch(errorUpdateTriageForm());
      });
  };
}

export function getWaitingRoomThread(patientPk, officeSlug, uniqueCode) {
  const token = store.get("id_token");
  let config;
  if (uniqueCode) {
    config = {
      method: "get",
      url: `${process.env.REACT_APP_DEV_API}/appointments/guest-waiting-thread/${patientPk}/${officeSlug}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  } else {
    config = {
      method: "get",
      url: `${process.env.REACT_APP_DEV_API}/appointments/waiting-thread/${patientPk}/${officeSlug}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }

  return (dispatch) => {
    dispatch(requestGetWaitingRoomThread());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetWaitingRoomThread(data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(errorGetWaitingRoomThread(err.response));
      });
  };
}

export function setRightToChat() {
  return {
    type: "SET_RIGHT_TO_CHAT",
  };
}

export function setRightToTriage() {
  return {
    type: "SET_RIGHT_TO_TRIAGE",
  };
}

function setDevices(devices) {
  return {
    type: "SET_DEVICES",
    devices,
  };
}

function setVideoDevices(devices) {
  return {
    type: "SET_VIDEO_DEVICES",
    devices,
  };
}

function setAudioDevices(devices) {
  return {
    type: "SET_AUDIO_DEVICES",
    devices,
  };
}

export function setLocalVideoTrack(localVideoTrack) {
  return {
    type: "SET_LOCAL_VIDEO_TRACK",
    localVideoTrack,
  };
}

export function setLocalAudioTrack(localAudioTrack) {
  return {
    type: "SET_LOCAL_AUDIO_TRACK",
    localAudioTrack,
  };
}

function setEnabledPermissions(enabledPermissions) {
  return {
    type: "SET_ENABLED_PERMISSIONS",
    enabledPermissions,
  };
}

export function resetTracks() {
  return {
    type: "RESET_TRACKS",
  };
}

export function stopVideoAndAudio(
  localParticipant,
  localVideoTrack,
  localAudioTrack
) {
  return (dispatch) => {
    console.log(localVideoTrack);
    detachTracks([localVideoTrack, localAudioTrack]);
    stopTracks([localVideoTrack, localAudioTrack]);
    if (localParticipant) {
      localParticipant.unpublishTracks([localVideoTrack, localAudioTrack]);
    }
    dispatch(resetTracks());
  };
}

export function enableVideoAndAudioPermissions() {
  return async (dispatch) => {
    await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    const devices = await navigator.mediaDevices.enumerateDevices();

    dispatch(setDevices(devices));

    dispatch(
      setVideoDevices(devices.filter((device) => device.kind === "videoinput"))
    );
    dispatch(
      setAudioDevices(devices.filter((device) => device.kind === "audioinput"))
    );

    const videoDevice = devices.filter(
      (device) => device.kind === "videoinput"
    )[0].deviceId;

    const audioDevice = devices.filter(
      (device) => device.kind === "audioinput"
    )[0].deviceId;

    dispatch(createLocalTracks(videoDevice, audioDevice));
  };
}

function createLocalTracks(videoId, audioId) {
  return async (dispatch) => {
    const localVideoTrack = await Video.createLocalVideoTrack({
      deviceId: {
        exact: videoId,
      },
    });

    dispatch(setLocalVideoTrack(localVideoTrack));

    const localAudioTrack = await Video.createLocalAudioTrack({
      deviceId: {
        exact: audioId,
      },
    });

    dispatch(setLocalAudioTrack(localAudioTrack));
    dispatch(setVideoDevice(videoId));
    dispatch(setAudioDevice(audioId));

    dispatch(setEnabledPermissions(true));
  };
}

export function changeVideoInput(deviceId, localVideoTrack, localParticipant) {
  return async (dispatch) => {
    detachTracks([localVideoTrack]);
    stopTracks([localVideoTrack]);

    if (localParticipant) {
      localParticipant.unpublishTracks([localVideoTrack]);
    }

    const createdTrack = await Video.createLocalVideoTrack({
      deviceId: {
        exact: deviceId,
      },
    });

    dispatch(setLocalVideoTrack(createdTrack));
    dispatch(setVideoDevice(deviceId));

    if (localParticipant) {
      localParticipant.publishTrack(createdTrack);
    }
  };
}

export function changeAudioInput(deviceId, localAudioTrack, localParticipant) {
  return async (dispatch) => {
    detachTracks([localAudioTrack]);
    stopTracks([localAudioTrack]);
    if (localParticipant) {
      localParticipant.unpublishTracks([localAudioTrack]);
    }

    const createdTrack = await Video.createLocalAudioTrack({
      deviceId: {
        exact: deviceId,
      },
    });

    dispatch(setLocalAudioTrack(createdTrack));
    dispatch(setAudioDevice(deviceId));

    if (localParticipant) {
      localParticipant.publishTrack(createdTrack);
    }
  };
}

export function connectToCall(
  localVideoTrack,
  localAudioTrack,
  profile,
  office,
  socket,
  videoChannelName,
  videoToken
) {
  return async (dispatch) => {
    try {
      console.log("CONNECTING NOW");
      console.log([localVideoTrack, localAudioTrack]);
      const room = await Video.connect(videoToken, {
        name: videoChannelName,
        video: { height: 720, frameRate: 24, width: 1280 },
        audio: true,
        tracks: [localVideoTrack, localAudioTrack],

        bandwidthProfile: {
          video: {
            mode: "collaboration",
            maxTracks: 10,
            dominantSpeakerPriority: "standard",
            renderDimensions: {
              high: { height: 1080, width: 1920 },
              standard: { height: 720, width: 1280 },
              low: { height: 176, width: 144 },
            },
          },
        },
        dominantSpeaker: true,
        preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
        networkQuality: { local: 1, remote: 1 },
      });

      dispatch(setVideoRoom(room));
      dispatch(setLocalParticipant(room.localParticipant));
    } catch (err) {
      console.error(`${err}`);
      launchToast(
        "Error connecting you to waiting room. Perhaps your video or audio settings haven't been allowed."
      );
      dispatch(resetRoom());
      dispatch(leaveActiveCall());
      dispatch(clearVideoParticipants());
      dispatch(resetVideoRoomName());
      dispatch(resetChannelName());
      detachTracks([localVideoTrack, localAudioTrack]);
      stopTracks([localVideoTrack, localAudioTrack]);
      socket.send(
        JSON.stringify({
          type: "ADMIN_LEAVE_CALL",
          admin: profile.pk,
          office: office.slug,
          is_patient: false,
        })
      );
    }
  };
}
