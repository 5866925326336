import React from "react";

export const Policies: React.FC = (props: any) => {
  return (
    <div className="white-box">
      <div className="app-setting-head section-head">
        <h4
          className="w-100"
          style={{ marginBottom: "30px", paddingBottom: "10px" }}
        >
          Policies
        </h4>
        <hr style={{ marginTop: "30px" }} />
        <a
          style={{
            color: "blue",
          }}
          href="https://getcorigan.ca/wp-content/uploads/2020/08/23876453-v4-Bluewater-Privacy-Policy-1-converted.pdf"
          target="_blank"
        >
          Privacy Policy
        </a>
        <br />
        <a
          style={{
            color: "blue",
          }}
          href="https://getcorigan.ca/wp-content/uploads/2020/08/23875342-v3-Bluewater-Terms-Conditions-Patients-1-converted.pdf"
          target="_blank"
        >
          Terms and Conditions
        </a>
      </div>
    </div>
  );
};
