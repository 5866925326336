import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import launchToast from "../utils";
import DashboardTop from "./dashboardTop";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getPastAppointment } from "../actions/adminOfficeActions";

function PastAppointment(props) {
  const dispatch = useDispatch();

  const appointmentPk = props.location.appointment;
  const appointment = useSelector(
    (state) => state.adminOfficeInteractions.pastAppointment
  );

  useEffect(() => {
    dispatch(getPastAppointment(appointmentPk));
  }, []);

  useEffect(() => {
    if (appointment) {
      setCopiedText(
        appointment.patient
          ? `${
              appointment.office.name
            } Visit on Corigan \n----------------------------------\nDate:                ${
              appointment.date
            }\nAttending Doctor:\t ???\nPatient Name: \t     ${
              appointment.patient.first_name
            } ${appointment.patient.last_name}\nAddress:\t         ${
              appointment.patient.address
            }\nOHIP Number:\t     ${
              appointment.patient.ohip_num
            }\nOHIP VC: \t         ${
              appointment.patient.ohip_vc
            }\nDate of Birth:\t     ${
              appointment.patient.date_of_birth
            }\nPhone:\t             ${
              appointment.patient.phone
            }\nEmail:\t             ${
              appointment.patient.user.email
            }\nGender:\t             ${
              appointment.patient.gender
            }\n----------------------------------\nComplaint:\t${
              appointment.triage.symptoms
                ? appointment.triage.symptoms.name
                : ""
            }\nAdditional Info:\t${appointment.triage.patient_notes}\n${
              appointment.triage.soap_note_triage
                ? `${appointment.triage.soap_note_triage.soap_question_soap_note
                    .map(
                      (item) =>
                        `${
                          item.question.name
                        }\t ${item.soap_choice_soap_question
                          .map((choice) => `${choice.answer}`)
                          .join(" ")}\n`
                    )
                    .join("")}`
                : appointment.triage.triage_questions
                ? `Subjective\n${appointment.triage.triage_questions.subjective}\nObjective\n${appointment.triage.triage_questions.objective}\nAssessment:\n${appointment.triage.triage_questions.assessment}\nPlan\n${appointment.triage.triage_questions.plan}`
                : ""
            }`
          : appointment.guest_patient
          ? `${
              appointment.office.name
            } Visit on Corigan \n----------------------------------\nDate:                ${
              appointment.date
            }\nAttending Doctor:\t ???\nPatient Name: \t     ${
              appointment.guest_patient.name
            } \n----------------------------------\nComplaint:\t${
              appointment.triage.symptoms
                ? appointment.triage.symptoms.name
                : ""
            }\nAdditional Info:\t${appointment.triage.patient_notes}\n${
              appointment.triage.soap_note_triage
                ? `${appointment.triage.soap_note_triage.soap_question_soap_note
                    .map(
                      (item) =>
                        `${
                          item.question.name
                        }\t ${item.soap_choice_soap_question
                          .map((choice) => `${choice.answer}`)
                          .join(" ")}\n`
                    )
                    .join("")}`
                : appointment.triage.triage_questions
                ? `Subjective\n${appointment.triage.triage_questions.subjective}\nObjective\n${appointment.triage.triage_questions.objective}\nAssessment:\n${appointment.triage.triage_questions.assessment}\nPlan\n${appointment.triage.triage_questions.plan}`
                : ""
            }`
          : ""
      );
    }
  }, [appointment]);

  const isFetchingPastAppointment = useSelector(
    (state) => state.adminOfficeInteractions.isFetchingPastAppointment
  );

  const copyText = useRef(null);

  const [copiedText, setCopiedText] = useState();

  return !isFetchingPastAppointment ? (
    appointment ? (
      <div>
        <DashboardTop title={`Past Appointments`} />
        <div class="middle-content main-content-wrapper">
          <div class="patients-export-option">
            <div class="patients-name">
              <h3>
                {appointment.patient
                  ? `${appointment.patient.first_name} ${appointment.patient.last_name}`
                  : `${appointment.guest_patient.name}`}
              </h3>
            </div>
            <CopyToClipboard text={copiedText}>
              <div class="exports">
                <a
                  href="#"
                  title="export"
                  class="common-btn sky-btn"
                  onClick={(e) => {
                    e.preventDefault();

                    launchToast("Copied Triage note to clipboard");
                  }}
                >
                  <span>export</span>
                </a>
              </div>
            </CopyToClipboard>
          </div>
          <div class="left-sec">
            <div class="waiting-title">
              <h3>Assessment</h3>
            </div>
            <div class="patients-info-main white-box past-appoint-data">
              <div class="patients-info-row">
                <div class="patients-title">
                  <h4>Patient</h4>
                </div>
                <div class="patients-info">
                  <p>
                    <strong>Symptom: </strong>
                    {appointment.triage.symptoms
                      ? appointment.triage.symptoms.name
                      : ""}
                    <label>Notes:</label>
                    <div className="p-info n-notes">
                      <div className="patients-data">
                        {appointment.triage.patient_notes}
                      </div>
                    </div>
                  </p>
                </div>
              </div>
              <div class="patients-info-row">
                <div class="patients-title">
                  <h4>Admin/Nurse</h4>
                </div>
                {appointment.triage.soap_note_triage ? (
                  <div class="patients-info">
                    <label>Notes:</label>
                    <div class="p-info n-notes">
                      <div class="patients-data">
                        {appointment.triage.admin_notes}
                        <div class="physical-exam">
                          <strong></strong>
                          {appointment.triage.soap_note_triage ? (
                            <ul>
                              <li>
                                <label>1. Symptoms:</label>
                                {appointment.triage.soap_note_triage.soap_question_soap_note
                                  .filter(
                                    (question) =>
                                      question.question.category ===
                                      "subjective"
                                  )
                                  .map((item) => (
                                    <div class="p-info">
                                      <label>{item.question.name}</label>
                                      {item.soap_choice_soap_question.map(
                                        (choice) => (
                                          <div>
                                            <input
                                              type="radio"
                                              id={choice.answer}
                                              name="radio-group"
                                            />
                                            <label for={choice.answer}>
                                              <span>{choice.answer}</span>
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ))}
                              </li>
                              <li>
                                <label>2. Objective:</label>
                                {appointment.triage.soap_note_triage.soap_question_soap_note
                                  .filter(
                                    (question) =>
                                      question.question.category === "objective"
                                  )
                                  .map((item) => (
                                    <div class="p-info">
                                      <label>{item.question.name}</label>
                                      {item.soap_choice_soap_question.map(
                                        (choice) => (
                                          <div>
                                            <input
                                              type="radio"
                                              id={choice.answer}
                                              name="radio-group"
                                            />
                                            <label for={choice.answer}>
                                              <span>{choice.answer}</span>
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ))}
                              </li>
                              <li>
                                <label>3. Assessment:</label>
                                {appointment.triage.soap_note_triage.soap_question_soap_note
                                  .filter(
                                    (question) =>
                                      question.question.category ===
                                      "assessment"
                                  )
                                  .map((item) => (
                                    <div class="p-info">
                                      <label>{item.question.name}</label>
                                      {item.soap_choice_soap_question.map(
                                        (choice) => (
                                          <div>
                                            <input
                                              type="radio"
                                              id={choice.answer}
                                              name="radio-group"
                                            />
                                            <label for={choice.answer}>
                                              <span>{choice.answer}</span>
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ))}
                              </li>
                              <li>
                                <label>4. Plan:</label>
                                {appointment.triage.soap_note_triage.soap_question_soap_note
                                  .filter(
                                    (question) =>
                                      question.question.category === "plan"
                                  )
                                  .map((item) => (
                                    <div class="p-info">
                                      <label>{item.question.name}</label>
                                      {item.soap_choice_soap_question.map(
                                        (choice) => (
                                          <div>
                                            <input
                                              type="radio"
                                              id={choice.answer}
                                              name="radio-group"
                                            />
                                            <label for={choice.answer}>
                                              <span>{choice.answer}</span>
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ))}
                              </li>
                            </ul>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : appointment.triage.triage_questions ? (
                  <div class="patients-info">
                    <label>Notes:</label>
                    <div class="p-info n-notes">
                      <div class="patients-data">
                        {appointment.triage.admin_notes}
                        <div class="physical-exam">
                          <strong></strong>
                          {appointment.triage.triage_questions ? (
                            <ul>
                              <li>
                                <label>1. Subjective:</label>
                                <div className="p-info display-linebreaks">
                                  {
                                    appointment.triage.triage_questions
                                      .subjective
                                  }
                                </div>
                              </li>
                              <li>
                                <label>2. Objective:</label>
                                <div className="p-info display-linebreaks">
                                  {
                                    appointment.triage.triage_questions
                                      .objective
                                  }
                                </div>
                              </li>
                              <li>
                                <label>3. Assessment:</label>
                                <div className="p-info display-linebreaks">
                                  {
                                    appointment.triage.triage_questions
                                      .assessment
                                  }
                                </div>
                              </li>
                              <li>
                                <label>4. Plan:</label>
                                <div className="p-info display-linebreaks">
                                  {appointment.triage.triage_questions.plan}
                                </div>
                              </li>
                            </ul>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div class="patients-info-row">
                <div class="patients-title">{/* <h4>Doctor</h4> */}</div>
                {/* <div class="patients-info">
                <label>Notes:</label>
                <div class="p-info n-notes">
                  <div class="patients-data">
                    <div class="physical-exam">
                      <ul>
                        <li>
                          <strong> Most Likely:</strong> Common Cold/Viral
                        </li>
                        <li>Conservative treatment is advised.</li>
                        <li>Advil, Tylenol PRN.</li>
                        <li> Keep hydrated.</li>
                        <li>
                          Follow up with family doctor or go to Emerg if
                          concerne
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
          <div class="right-sec">
            <div class="waiting-title">
              <h3>Appointment Information</h3>
            </div>
            <div class="white-box patients-info-main appointment-data">
              <div class="patients-info">
                <p>
                  Length of Appt:{" "}
                  {moment(appointment.date).diff(
                    moment(appointment.triage.date_submitted),
                    "minutes"
                  )}{" "}
                  Minutes
                </p>
                {/* <div class="p-info">
                <input type="radio" id="radio3" name="radio-group" />
                <label for="radio3">
                  <span>Doctor Code 01</span>
                </label>
              </div>
              <div class="p-info">
                <input type="radio" id="radio4" name="radio-group" />
                <label for="radio4">
                  <span>Doctor Code 02</span>
                </label>
              </div> */}
                <p>Date: {moment(appointment.date).format("MM-DD-YYYY")}</p>
                {/* {appointment.triage.soap_note_triage ? ( */}
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      "no appointment"
    )
  ) : (
    "loading"
  );
}

export default PastAppointment;
