import React, { useRef, useState, useEffect } from "react";
import DashboardTop from "../../dashboardTop";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { useSelector, useDispatch } from "react-redux";
import {
  getAutomatedMessage,
  saveAutomatedMessage,
} from "../../../actions/appointmentActions";
import { BounceLoader } from "react-spinners";
import { CopyToClipboard } from "react-copy-to-clipboard";
import launchToast from "../../../utils";
import { AutomatedMessageEditor } from "./AutomatedMessageEditor";

export const AutomatedMessages = (props) => {
  const automatedMessage = useSelector(
    (state) => state.appointmentReducer.automatedMessage
  );
  const isFetchingAutomatedMessage = useSelector(
    (state) => state.appointmentReducer.isFetchingAutomatedMessage
  );
  const dispatch = useDispatch();
  return (
    <div>
      <DashboardTop
        title={`Edit Automatic Messages`}
        back={true}
        backLink={`/office/${props.office.slug}/appointments/`}
      />

      <div class="middle-content main-content-wrapper">
        <div class="d-flex">
          <div class="col-7 automatic-msg-col">
            <div class="automatic-msg-main white-box">
              <h2>Automatic Message</h2>
              <div class="innermsg-info">
                <Accordion
                  allowZeroExpanded={true}
                  className="accordion-container"
                  onChange={(e) => {
                    dispatch(getAutomatedMessage(e[0], props.office.pk));
                  }}
                >
                  <AccordionItem className="set" uuid="Appointment Reminder">
                    <AccordionItemHeading className="header-accordion">
                      <AccordionItemButton className="header-accordion-title">
                        Appointment Reminder
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="content">
                      {isFetchingAutomatedMessage ? (
                        <BounceLoader />
                      ) : automatedMessage ? (
                        <AutomatedMessageEditor />
                      ) : (
                        "No automated message"
                      )}
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className="set" uuid="Follow up">
                    <AccordionItemHeading className="header-accordion">
                      <AccordionItemButton className="header-accordion-title">
                        Follow-up After Booked Appointment
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="content">
                      {isFetchingAutomatedMessage ? (
                        <BounceLoader />
                      ) : automatedMessage ? (
                        <AutomatedMessageEditor />
                      ) : (
                        "No automated message"
                      )}
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className="set" uuid="Cancel Appointment">
                    <AccordionItemHeading className="header-accordion">
                      <AccordionItemButton className="header-accordion-title">
                        Cancel Appointment
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="content">
                      {isFetchingAutomatedMessage ? (
                        <BounceLoader />
                      ) : automatedMessage ? (
                        <AutomatedMessageEditor />
                      ) : (
                        "No automated message"
                      )}
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className="set" uuid="Recurring Appointment">
                    <AccordionItemHeading className="header-accordion">
                      <AccordionItemButton className="header-accordion-title">
                        Recurring Appointment
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="content">
                      {isFetchingAutomatedMessage ? (
                        <BounceLoader />
                      ) : automatedMessage ? (
                        <AutomatedMessageEditor />
                      ) : (
                        "No automated message"
                      )}
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>

                <div class="new-response">
                  {/* <a
                    href="#"
                    title="Add New"
                    class="common-btn blue-btn addnew"
                  >
                    <span> + &nbsp;Add New</span>
                  </a> */}
                  <div class="tag-templates">
                    <h5>Tag Templates </h5>
                    <ul>
                      <li>
                        {" "}
                        <CopyToClipboard text={`{{first_name}}`}>
                          <span
                            class="selected-text"
                            onClick={(e) => {
                              launchToast(
                                "First Name template tag copied to clipboard."
                              );
                            }}
                          >
                            First Name
                          </span>
                        </CopyToClipboard>
                      </li>
                      <li>
                        <CopyToClipboard text={`{{last_name}}`}>
                          <span
                            class="selected-text"
                            onClick={(e) => {
                              launchToast(
                                "Last Name template tag copied to clipboard."
                              );
                            }}
                          >
                            Last Name
                          </span>
                        </CopyToClipboard>
                      </li>
                      <li>
                        <CopyToClipboard text={`{{event_time}}`}>
                          <span
                            class="selected-text"
                            onClick={(e) => {
                              launchToast(
                                "Appointment time template tag copied to clipboard."
                              );
                            }}
                          >
                            Appointment Time
                          </span>
                        </CopyToClipboard>
                      </li>
                      <li>
                        <CopyToClipboard
                          text={`{{event_date}}`}
                          onClick={(e) => {
                            launchToast(
                              "Appointment Date template tag copied to clipboard."
                            );
                          }}
                        >
                          <span class="selected-text">Appointment Date</span>
                        </CopyToClipboard>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
