import React, { useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import s from "./UpcomingBooked.module.scss";
import arrowUp from "../../../assets/images/arrowup.png";
import arrowDown from "../../../assets/images/arrowdown.png";
import { getUpcomingBooked } from "../../../actions/dashboardActions";
import { BounceLoader } from "react-spinners";

export const UpcomingBooked = (props: any) => {
  const dispatch = useDispatch();
  const office = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.office
  );
  const upcomingBooked = useSelector(
    (state: RootStateOrAny) => state.dashboardReducer.upcomingBooked
  );
  useEffect(() => {
    dispatch(getUpcomingBooked(office.pk));
  }, []);
  return (
    <div className={s.whiteBox}>
      {upcomingBooked ? (
        <div>
          <div className="row">
            <div className="col-6">
              <ul>
                <li>
                  {upcomingBooked.upcoming ? (
                    <p>{upcomingBooked.upcoming}</p>
                  ) : (
                    ""
                  )}
                </li>
                <li>
                  {upcomingBooked.upcoming > upcomingBooked.previous ? (
                    <img src={arrowUp} alt="" />
                  ) : (
                    <img src={arrowDown} alt="" />
                  )}
                </li>
              </ul>
            </div>
            <div className="col-6">
              <p className={s.infoRight}>
                {Math.abs(upcomingBooked.upcoming - upcomingBooked.previous)}{" "}
                {upcomingBooked.upcoming > upcomingBooked.previous
                  ? "more"
                  : "less"}{" "}
                from prior week
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p className={s.upcomingTitle}>Upcoming Booked Appointments</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className={s.lastWeekRecapContainer}>
                <h3 className={s.lastWeekTitle}>LAST WEEK RECAP</h3>

                <p>{upcomingBooked.previous} Appointments Booked</p>
                <hr />
                <p>{upcomingBooked.cancelled} Cancellations</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <BounceLoader />
      )}
    </div>
  );
};
