import axios from "axios";
import store from "store";
import fileDownload from 'js-file-download'

function requestGetMessageThreads() {
  return {
    type: "REQUEST_GET_MESSAGE_THREADS",
    isGettingMessageThreads: true,
    message: "",
  };
}

export function receiveGetMessageThreads(threads) {
  return {
    type: "RECEIVE_GET_MESSAGE_THREADS",
    isGettingMessageThreads: false,
    threads,
  };
}

function errorReceiveGetMessageThreads(err) {
  return {
    type: "ERROR_GET_MESSAGE_THREADS",
    isGettingMessageThreads: false,
    message: `${err}`,
  };
}

function requestGetMessageThread() {
  return {
    type: "REQUEST_GET_MESSAGE_THREAD",
    isFetchingMessageThread: true,
    isFetchingMessageMessage: "",
  };
}

function receiveGetMessageThread(thread) {
  return {
    type: "RECEIVE_GET_MESSAGE_THREAD",
    isFetchingMessageThread: false,
    selectedThread: thread,
  };
}

function errorGetMessageThread(err) {
  return {
    type: "ERROR_GET_MESSAGE_THREAD",
    isFetchingMessageThread: false,
    isFetchingMessageMessage: `${err.response}`,
  };
}

function requestAddMessageToThread() {
  return {
    type: "REQUEST_ADD_MESSAGE_TO_THREAD",
    isAddingMessageToThread: true,
    isAddingMessageToThreadMessage: "",
  };
}

function receiveAddMessageToThread() {
  return {
    type: "RECEIVE_ADD_MESSAGE_TO_THREAD",
    isAddingMessageToThread: false,
  };
}
function errorAddMessageToThread(err) {
  return {
    type: "ERROR_ADD_MESSAGE_TO_THREAD",
    isAddingMessageToThread: false,
    isAddingMessageToThreadMessage: `${err}`,
  };
}

function searchEntries(entries) {
  return {
    type: "SEARCH_ENTRIES",
    searchEntries: entries,
  };
}

function requestGetArchivedThreads() {
  return {
    type: "REQUEST_GET_ARCHIVED_THREADS",
    isFetchingArchivedThreads: true,
    archivedThreadsErrorMessage: "",
  };
}

function receiveGetArchivedThreads(threads) {
  return {
    type: "RECEIVE_GET_ARCHIVED_THREADS",
    isFetchingArchivedThreads: false,
    archivedThreads: threads,
  };
}

function errorGetArchivedThreads(err) {
  return {
    type: "ERROR_GET_ARCHIVED_THREADS",
    isFetchingArchivedThreads: false,
    archivedThreadsErrorMessage: err,
  };
}

export function getMessageThreads(officeSlug) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API
      }/messaging/list_office_threads/${officeSlug}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetMessageThreads());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetMessageThreads(data));
      })
      .catch((err) => {
        dispatch(errorReceiveGetMessageThreads(err.response));
      });
  };
}

export function getMessageThread(threadId) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API
      }/messaging/message_thread/${threadId}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetMessageThread());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetMessageThread(data));
      })
      .catch((err) => {
        dispatch(errorGetMessageThread(err));
      });
  };
}

export function addMessageToThread(message, userId, messageThreadId) {
  const token = store.get("id_token");
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/messaging/add_message/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      message_thread: messageThreadId,
      sent_by: userId,
      message,
    },
  };

  return (dispatch) => {
    dispatch(requestAddMessageToThread());
    axios(config)
      .then((res) => {
        dispatch(receiveAddMessageToThread());

        // dispatch(getMessageThread(messageThreadId));
      })
      .catch((err) => {
        dispatch(errorAddMessageToThread(err.response));
      });
  };
}

export function getSearchedThreads(query, pk) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API
      }/messaging/search_office_threads/${pk}/${query}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetMessageThreads());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(searchThreads(data));
      })
      .catch((err) => {
        dispatch(errorReceiveGetMessageThreads(err.response));
      });
  };
}


export function searchThreads(searchedThreads) {
  return {
    type: "SEARCH_THREADS",
    searchedThreads,
  };
}

export function searchArchivedThreads(query) {
  return {
    type: "SEARCH_ARCHIVED_THREADS",
    query,
  };
}

export function resetSearch() {
  return {
    type: "RESET_SEARCH",
  };
}

export function resetSelectedThread() {
  return {
    type: "RESET_SELECTED_THREAD",
  };
}

export function getSearchPatients(input, callback, office) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API
      }/messaging/search_patients/?office=${office}&query=${input}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  axios(config)
    .then((res) => {
      const patients = res.data.map((entry) => ({
        value: entry.pk,
        label: `${entry.first_name} ${entry.last_name} (${entry.date_of_birth && entry.date_of_birth})`,
      }));
      console.log(patients);
      callback(patients);
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getSearchAdmins(input, callback, office, adminPk) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API
      }/messaging/get-admins-in-office/?office=${office}&query=${input}&adminPK=${adminPk}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  axios(config)
    .then((res) => {
      const admins = res.data.map((entry) => ({
        value: entry.pk,
        label: `${entry.first_name} ${entry.last_name}`,
      }));

      callback(admins);
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getArchivedThreads(officeSlug) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API
      }/messaging/list_office_archived_threads/${officeSlug}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetArchivedThreads());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetArchivedThreads(data));
      })
      .catch((err) => {
        dispatch(errorGetArchivedThreads(err.response));
      });
  };
}

export function uploadFileToThread(
  threadPk,
  fileUpload,
  message,
  userId,
  adminPk
) {
  const token = store.get("id_token");

  const bodyFormData = new FormData();

  bodyFormData.set("message_thread", threadPk);
  bodyFormData.set("sent_by", userId);
  bodyFormData.set("sent_by_type", "admin");
  bodyFormData.set("admin_profile", adminPk);
  bodyFormData.set("message", message);
  bodyFormData.append("image", fileUpload);

  let config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/messaging/file_attach/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    data: bodyFormData,
  };

  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(getMessageThread(threadPk));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}


export function downloadFileAttachment(message) {
  const token = store.get("id_token");

  // Define our web request configuration

  let config = {
    method: "GET",
    url: `${process.env.REACT_APP_DEV_API}/messaging/download-file-attachment/${message}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {

        fetch(data.url)
          .then(res => res.blob())
          .then(blob => {
            fileDownload(blob, data.file_name)
          })
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", data.file_name); //or any other extension
        // document.body.appendChild(link);
        // link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
