import axios from "axios";
import store from "store";
import launchToast from "../utils";

function requestGetBulletinPosts() {
  return {
    type: "REQUEST_GET_BULLETIN_POSTS",
    isFetchingBulletinPosts: true,
  };
}

function receiveGetBulletinPosts(posts) {
  return {
    type: "RECEIVE_GET_BULLETIN_POSTS",
    isFetchingBulletinPosts: false,
    bulletinPosts: posts,
  };
}

function errorGetBulletinPosts() {
  return {
    type: "ERROR_GET_BULLETIN_POSTS",
    isFetchingBulletinPosts: false,
  };
}

function requestCreateBulletinPost() {
  return {
    type: "REQUEST_CREATE_BULLETIN_POST",
    isCreatingBulletinPost: true,
    hasCreatedBulletinPost: false,
    createBulletinPostError: "",
  };
}

function receiveCreateBulletinPost() {
  return {
    type: "RECEIVE_CREATE_BULLETIN_POST",
    isCreatingBulletinPost: false,
    hasCreatedBulletinPost: true,
  };
}

function errorCreateBulletinPost() {
  return {
    type: "ERROR_CREATE_BULLETIN_POST",
    isCreatingBulletinPost: false,
    createBulletinPostError: "There was an error creating this post.",
  };
}

export function resetHasCreatedBulletin() {
  return {
    type: "RESET_HAS_CREATED_BULLETIN",
    hasCreatedBulletin: false,
  };
}

export function getBulletinPosts(officePk) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/bulletin/posts/${officePk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetBulletinPosts());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetBulletinPosts(data));
      })
      .catch((err) => {
        launchToast(
          "Error retrieving posts. Please check your internet connection"
        );
        dispatch(errorGetBulletinPosts(err.response));
      });
  };
}

export function createBulletinPost(data, htmlContent, office, adminPk) {
  const token = store.get("id_token");
  let config = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/bulletin/create-post/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      title: data.title,
      message: htmlContent,
      email_office: data.emailPatient,
      office,
      posted_by: adminPk,
    },
  };

  return (dispatch) => {
    dispatch(requestCreateBulletinPost());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveCreateBulletinPost());
        if (data.emailPatient) {
          launchToast(
            "Bulletin Message has been posted. All patients will be notified through email."
          );
        } else {
          launchToast("Bulletin Message has been posted.");
        }
      })
      .catch((err) => {
        dispatch(errorCreateBulletinPost());
      });
  };
}
