import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  searchThreads,
  resetSearch,
  getArchivedThreads,
  searchArchivedThreads,
} from "../../actions/messagingActions";
import FlipMove from "react-flip-move";
import Thread from "./Thread";
import ArchivedThread from "./ArchivedThread";

function ArchivedThreads(props) {
  const messageThreads = useSelector(
    (state) => state.messagingReducer.messageThreads
  );
  const searchedMessageThreads = useSelector(
    (state) => state.messagingReducer.searchedMessageThreads
  );

  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const office = useSelector((state) => state.adminOfficeInteractions.office);

  const socket = useSelector((state) => state.adminOfficeInteractions.socket);
  const [showArchive, setShowArchive] = useState(false);

  const archivedThreads = useSelector(
    (state) => state.messagingReducer.archivedThreads
  );

  const isFetchingArchivedThreads = useSelector(
    (state) => state.messagingReducer.isFetchingArchivedThreads
  );

  useEffect(() => {
    dispatch(getArchivedThreads(office.slug));
  }, []);

  return (
    <div class="col-3">
      <div class="eql-height">
        <div class="white-box inbox-info-left">
          <div class="inbox-archive">
            <ul>
              <li class="archive-main" id="archive1">
                <a
                  href="#"
                  title=""
                  class="archives-inbox conversation"
                  onClick={(e) => {
                    e.preventDefault();
                    if (showArchive === true) {
                      setShowArchive(false);
                    } else {
                      setShowArchive(true);
                    }
                  }}
                ></a>
                <div
                  class="archive-show"
                  style={{ display: `${showArchive ? "block" : "none"}` }}
                >
                  <ul>
                    <li>
                      <a
                        href="#"
                        title="View Archive"
                        onClick={(e) => {
                          e.preventDefault();
                          props.setThreadToggle("threads");
                        }}
                      >
                        View Active Threads
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                {!props.newThread ? (
                  <a
                    href=""
                    title=""
                    class="inbox-icon"
                    onClick={(e) => {
                      e.preventDefault();
                      props.setThread(null);
                      props.setNewThread(true);
                    }}
                  ></a>
                ) : (
                  ""
                )}
              </li>
            </ul>
          </div>
          <form
            action="#"
            class="search-form"
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              placeholder=" Search…"
              class="form-control"
              name="s"
              type="search"
              style={{ paddingTop: "15px" }}
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value !== "") {
                  dispatch(searchArchivedThreads(e.target.value));
                } else {
                  dispatch(resetSearch());
                }
              }}
            />
            <input type="submit" class="fa fa-search" value="" />
          </form>
          <div class="inbox-msgs">
            <div class="new-msg-info-main d-flex flex-wrap">
              <div class="custom-scrl-content mCustomScrollbar">
                <div class="inbox-archive-link">
                  <a
                    href="#"
                    title="Archive"
                    class="inbox-archv"
                    onClick={(e) => {
                      e.preventDefault();
                      props.setThreadToggle("threads");
                    }}
                  >
                    Archive
                  </a>
                </div>
                {searchedMessageThreads.length == 0 ? (
                  archivedThreads.length > 0 ? (
                    <FlipMove duration={500}>
                      {archivedThreads.map((thread) => (
                        <ArchivedThread
                          key={thread.pk}
                          thread={thread}
                          socket={socket}
                          office={office}
                          dispatch={dispatch}
                          setThread={props.setThread}
                          setNewThread={props.setNewThread}
                          selectedThread={props.thread}
                          keyId={thread.pk}
                        />
                      ))}
                    </FlipMove>
                  ) : (
                    ""
                  )
                ) : (
                  <FlipMove duration={500}>
                    {searchedMessageThreads.map((thread) => (
                      <ArchivedThread
                        key={thread.pk}
                        thread={thread}
                        socket={socket}
                        office={office}
                        dispatch={dispatch}
                        setThread={props.setThread}
                        setNewThread={props.setNewThread}
                      />
                    ))}
                  </FlipMove>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArchivedThreads;
