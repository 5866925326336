import axios from "axios";
import store from "store";
import moment from "moment";
import launchToast from "../utils";

export function setDate(selectedDate) {
  return {
    type: "SET_CALENDAR_DATE",
    date: selectedDate,
  };
}
export function setView(view) {
  return {
    type: "SET_CALENDAR_VIEW",
    view,
  };
}

export function setViewType(viewtype) {
  return {
    type: "SET_VIEW_TYPE",
    viewtype,
  };
}
export function setSearchQuery(value) {
  return {
    type: "SET_SEARCH_QUERY",
    value,
  };
}
export function setSearchResults(value) {
  return {
    type: "SET_SEARCH_RESULTS",
    value,
  };
}
export function setFilterResults(value) {
  return {
    type: "SET_FILTER_RESULTS",
    value,
  };
}

export function setActiveTypeVisit(name) {
  return {
    type: "SET_ACTIVE_TYPE_VISIT",
    name,
  };
}
export function setSearchResultsActive(value) {
  return {
    type: "SET_SEARCH_RESULTS_ACTIVE",
    value,
  };
}
export function setFilterResultsActive(value) {
  return {
    type: "SET_FILTER_RESULTS_ACTIVE",
    value,
  };
}

export function setAppointmentNotes(value) {
  return {
    type: "SET_APPOINTMENT_NOTES",
    value,
  };
}

function requestGetAppointmentsByDateTime() {
  return {
    type: "REQUEST_GET_APPOINTMENTS_BY_DATETIME",
    isFetchingAppointmentDateTimes: true,
    hasReceivedAppointmentDateTimes: false,
  };
}

function receiveGetAppointmentsByDateTime(dateTimes) {
  return {
    type: "RECEIVE_GET_APPOINTMENTS_BY_DATETIME",
    isFetchingAppointmentDateTimes: false,
    hasReceivedAppointmentDateTimes: true,
    dateTimes,
  };
}

function errorGetAppointmentsByDateTime() {
  return {
    type: "ERROR_GET_APPOINTMENTS_BY_DATETIME",
    isFetchingAppointmentDateTimes: false,
    hasReceivedAppointmentDateTimes: false,
  };
}

function requestUpdateData() {
  return {
    type: "REQUEST_UPDATE_DATA",
    isUpdatingData: true,
  };
}

function receiveUpdateData() {
  return {
    type: "RECEIVE_UPDATE_DATA",
    isUpdatingData: false,
    hasUpdatedData: true,
  };
}

function errorUpdateData() {
  launchToast("Error updating data");
  return {
    type: "ERROR_UPDATE_DATA",
    isUpdatingData: false,
    hasUpdatedData: false,
  };
}

export function resetUpdateData() {
  return {
    type: "RESET_UPDATE_DATA",
  };
}

export function resetGetAppointmentsByDateTime() {
  return {
    type: "RESET_GET_APPOINTMENTS_BY_DATETIME",
    isFetchingAppointmentDateTimes: false,
    hasReceivedAppointmentDateTimes: false,
  };
}

export function setPrinters(printers) {
  return {
    type: "SET_PRINTERS",
    printers: printers,
  };
}

function setNewUser(pk) {
  return {
    type: "SET_NEW_USER",
    newUser: pk,
  };
}

function requestSaveAutomatedMessage() {
  return {
    type: "REQUEST_SAVE_AUTOMATED_MESSAGE",
    isSavingAutomatedMessage: true,
  };
}

function receiveSaveAutomatedMessage() {
  return {
    type: "RECEIVE_SAVE_AUTOMATED_MESSAGE",
    isSavingAutomatedMessage: false,
  };
}

function errorSaveAutomatedMessage() {
  return {
    type: "ERROR_SAVE_AUTOMATED_MESSAGE",
    isSavingAutomatedMessage: false,
  };
}

function requestGetAppointments() {
  return {
    type: "REQUEST_GET_APPOINTMENTS",
    isFetchingAppointments: true,
  };
}

function receiveGetAppointments(appointments) {
  return {
    type: "RECEIVE_GET_APPOINTMENTS",
    appointments,
  };
}

function receiveGetReservedSpots(reservedSpots) {
  return {
    type: "RECEIVE_GET_RESERVED_SPOTS",
    reservedSpots,
  };
}

export function resetAppointment() {
  return {
    type: "RESET_APPOINTMENT",
  };
}

export function resetAppointments() {
  return {
    type: "RESET_APPOINTMENTS",
  };
}

export function resetCovidInfo() {
  return {
    type: "RESET_COVID_INFO",
  };
}

export function resetImmunizationData() {
  return {
    type: "RESET_IMMUNIZATION_DATA",
  };
}

function requestSymptomsList() {
  return {
    type: "REQUEST_SYMPTOMS_LIST",
    isFetchingSymptoms: true,
  };
}

function receiveSymptomsList(symptoms) {
  return {
    type: "RECEIVE_SYMPTOMS_LIST",
    isFetchingSymptoms: false,
    symptoms,
  };
}
function errorSymptomsList() {
  return {
    type: "ERROR_SYMPTOMS_LIST",
    isFetchingSymptoms: false,
  };
}

function requestGetAppointment() {
  return {
    type: "REQUEST_GET_APPOINTMENT",
    isFetchingAppointment: true,
  };
}

function receiveGetAppointment(appointment) {
  return {
    type: "RECEIVE_GET_APPOINTMENT",
    isFetchingAppointment: false,
    appointment,
  };
}

function receiveGetCovidInfo(covidInfo) {
  return {
    type: "RECEIVE_GET_COVID_INFO",
    covidInfo,
  };
}

function receiveGetUserImmunizationData(userImmunization) {
  return {
    type: "RECEIVE_GET_USER_IMMUNIZATION_DATA",
    userImmunization,
  };
}

export function selectSymptom(symptomPk) {
  return {
    type: "SELECT_SYMPTOM",
    symptomPk,
  };
}

export function removeSymptom(symptomPk) {
  return {
    type: "REMOVE_SYMPTOM",
    symptomPk,
  };
}

export function selectImmunization(immunizationPk) {
  return {
    type: "SELECT_IMMUNIZATION",
    immunizationPk,
  };
}

export function removeImmunization(immunizationPk) {
  return {
    type: "REMOVE_IMMUNIZATION",
    immunizationPk,
  };
}

export function resetSymptom() {
  return {
    type: "RESET_SYMPTOM",
    symptom: [],
  };
}
export function resetImmunizations() {
  return {
    type: "RESET_IMMUNIZATIONS",
  };
}
export function resetNewUser() {
  return {
    type: "RESET_NEW_USER",
    newUser: null,
  };
}

function requestDeleteAppointment() {
  return {
    type: "REQUEST_DELETE_APPOINTMENT",
    isDeletingAppointment: true,
  };
}

function receiveDeleteAppointment() {
  return {
    type: "RECEIVE_DELETE_APPOINTMENT",
    isDeletingAppointment: false,
    hasDeletedAppointment: true,
  };
}

export function resetDeletedAppointment() {
  return {
    type: "RESET_DELETE_APPOINTMENT",
    hasDeletedAppointment: false,
  };
}

function requestGetAutomatedMessage() {
  return {
    type: "REQUEST_AUTOMATED_MESSAGE",
    isFetchingAutomatedMessage: true,
  };
}

function receiveGetAutomatedMessage(automatedMessage) {
  return {
    type: "RECEIVE_AUTOMATED_MESSAGE",
    automatedMessage,
    isFetchingAutomatedMessage: false,
  };
}

function errorGetAutomatedMessage(error) {
  return {
    type: "ERROR_AUTOMATED_MESSAGE",
    isFetchingAutomatedMessage: false,
    automatedMessageError: error,
  };
}

function requestSubmitAppointment() {
  return {
    type: "REQUEST_SUBMIT_APPOINTMENT",
    isSubmittingAppointment: true,
  };
}

function receiveSubmitAppointment() {
  return {
    type: "RECEIVE_SUBMIT_APPOINTMENT",
    isSubmittingAppointment: false,
    hasSubmittedAppointment: true,
  };
}

//Action Flags for Simple List View

function requestGetSimpleAppointments() {
  return {
    type: "REQUEST_GET_SIMPLE_APPOINTMENTS",
    isFetchingSimpleAppointments: true,
  };
}

function receiveGetSimpleAppointments(simpleAppointments) {
  return {
    type: "RECEIVE_GET_SIMPLE_APPOINTMENTS",
    simpleAppointments,
  };
}

function errorSubmitAppointment() {
  launchToast("There was an error submitting the data.");
  return {
    type: "ERROR_SUBMIT_APPOINTMENT",
    isSubmittingAppointment: false,
  };
}

export function resetSubmittedAppointment() {
  return {
    type: "RESET_SUBMITTED_APPOINTMENT",
    hasSubmittedAppointment: false,
  };
}

export function getAppointments(officePk, date) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/appointment_list/${officePk}/?start=${moment(date).format(
      "YYYY-MM-DD"
    )}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetAppointments());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        const appointments = data.map((appointment) => ({
          id: appointment.pk,
          title: `${appointment.patient.first_name} ${
            appointment.patient.last_name
          }`,
          start: moment(
            `${appointment.event_date} ${appointment.event_time}`
          ).toDate(),
          end: moment(`${appointment.event_date} ${appointment.event_time}`)
            .add(15, "minutes")
            .toDate(),
          ...appointment,
        }));

        dispatch(receiveGetAppointments(appointments));
      })
      .catch((err) => console.log(err));
  };
}
export function appointmentFilterResults() {
  return (dispatch, getState) => {
    const appointments = getState().appointmentReducer.appointments;
    const activeTypeOfVisit = getState().appointmentReducer.activeTypeOfVisit;
    const filterList = appointments.filter((appointment) => {
      return appointment.event_type == activeTypeOfVisit;
    });
    dispatch(setFilterResults(filterList));
  };
}
export function getWeeklyAppointments(officePk, date) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/weekly_appointment_list/${officePk}/?start=${moment(
      date
    ).format("YYYY-MM-DD")}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetAppointments());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        const appointments = data.map((appointment) => ({
          id: appointment.pk,
          title: `${appointment.patient.first_name} ${
            appointment.patient.last_name
          }`,
          start: moment(
            `${appointment.event_date} ${appointment.event_time}`
          ).toDate(),
          end: moment(`${appointment.event_date} ${appointment.event_time}`)
            .add(15, "minutes")
            .toDate(),
          ...appointment,
        }));

        dispatch(receiveGetAppointments(appointments));
      })
      .catch((err) => console.log(err));
  };
}

export function getDailyAppointments(officePk, date, appointmentInterval) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/daily_appointment_list/${officePk}/?start=${moment(
      date
    ).format("YYYY-MM-DD")}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch, getState) => {
    dispatch(requestGetAppointments());
    return axios(config)
      .then((res) => res.data)

      .then((data) => {
        console.log(data);
        const uniqueUser = data
          .map((appointment) => {
            if (appointment.patient == null)
              return console.log("Patient Deleted");
            else return appointment.patient.user;
          })
          .filter((item, index, arr) => {
            return arr.indexOf(item) === index;
          });
        const uniqueUserLen = uniqueUser.length;
        const totalLen = 65 + uniqueUserLen;
        const usersData = [];
        if (uniqueUserLen) {
          for (let i = 65; i < totalLen; i++) {
            var str = String.fromCharCode(i);
            usersData.push({
              id: uniqueUser[i - 65],
              char: str,
            });
          }
        }
        dispatch(setUserID(usersData));

        const appointments = data.map((appointment) => {
          if (appointment.patient == null)
            return {
              title: `${
                appointment.patient
                  ? appointment.patient.first_name
                  : "Patient Deleted"
              }`,
            };
          else
            return {
              id: appointment.pk,
              title: `${
                appointment.patient
                  ? appointment.patient.first_name
                  : "No Name Entered"
              } 
            ${appointment.patient ? appointment.patient.last_name : ""}`,
              start: moment(
                `${appointment.event_date} ${appointment.event_time}`
              ).toDate(),
              end: moment(`${appointment.event_date} ${appointment.event_time}`)
                .add(appointmentInterval, "minutes")
                .toDate(),
              ...appointment,
              userPk: appointment.user,
            };
        });

        const activeTypeOfVisit = getState().appointmentReducer
          .activeTypeOfVisit;
        const searchQuery = getState().appointmentReducer.searchQuery;
        const searchResultsShow = getState().appointmentReducer
          .searchResultsShow;

        // if (searchQuery.trim().length) {
        //   const filterList = appointments.filter((item, i) => {
        //     return item.title.toLowerCase().includes(searchQuery.toLowerCase());
        //   });
        //   dispatch(setSearchResults(filterList));
        // }

        // if (activeTypeOfVisit) {
        //   const filterList = appointments.filter((item) => {
        //     return item.event_type == activeTypeOfVisit;
        //   });
        //   dispatch(receiveGetAppointments(filterList));
        // } else if (searchResultsShow) {
        //   const filterList = appointments.filter((item, i) => {
        //     return item.title.toLowerCase().includes(searchQuery.toLowerCase());
        //   });
        //   console.log(filterList);
        //   dispatch(receiveGetAppointments(filterList));
        // } else {
        dispatch(receiveGetAppointments(appointments));
        // }
      })
      .catch((err) => console.log(err));
  };
}

export function getReservedSpots(officePk) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/reserved_spots/${officePk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    // dispatch(requestGetReservedSpots());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        const reservedTimes = data.map((reservedTime, index) => ({
          id: `${index}-reserve`,
          title: `Reserved Time`,
          start: moment(reservedTime.start).toDate(),
          end: moment(reservedTime.end).toDate(),
          type: "reserved",
          pk: reservedTime.pk,
        }));
        dispatch(receiveGetReservedSpots(reservedTimes));
      });
  };
}

export function getSymptomsList() {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/covid/symptom_list/`,
  };

  return (dispatch) => {
    dispatch(requestSymptomsList());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveSymptomsList(data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(errorSymptomsList());
      });
  };
}

function requestImmunizationList() {
  return {
    type: "REQUEST_IMMUNIZATION_LIST",
    isFetchingImmunizations: true,
  };
}

function receiveImmunizationList(immunizations) {
  return {
    type: "RECEIVE_IMMUNIZATION_LIST",
    isFetchingImmunizations: false,
    immunizations,
  };
}
function errorImmunizationList() {
  return {
    type: "RECEIVE_IMMUNIZATION_LIST",
    isFetchingImmunizations: false,
  };
}

export function getImmunizationList() {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/fluclinic/immunization-list/`,
  };

  return (dispatch) => {
    dispatch(requestImmunizationList());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveImmunizationList(data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(errorImmunizationList());
      });
  };
}

export function getAppointment(appointmentPk, office) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/appointment_details/${appointmentPk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetAppointment());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetAppointment(data));
        if (office.immunization_clinic) {
          dispatch(getUserImmunization(appointmentPk));
        } else {
          dispatch(getCovidInfo(appointmentPk));
        }
      });
  };
}

export function getUserImmunization(appointmentPk) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${
      process.env.REACT_APP_DEV_API
    }/fluclinic/user-immunization-receive/${appointmentPk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetUserImmunizationData(data));
      });
  };
}

export function getCovidInfo(appointmentPk) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${
      process.env.REACT_APP_DEV_API
    }/covid/covid_info_retrieve/${appointmentPk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetCovidInfo(data[0]));
      });
  };
}

// Create User
// Create Create Appointment
// Create COVID Info
// Create COVID Symptoms

export function createUserAccount(formData, officePk, symptoms) {
  const token = store.get("id_token");
  console.log(moment(formData.dateOfBirth, "MM/DD/YYYY").format("YYYY-MM-DD"));

  console.log(formData.dateOfBirth);

  let config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/signup-patient/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      email: formData.email,
      is_patient: true,
      office: [officePk],
      patient_info: {
        first_name: formData.firstName,
        last_name: formData.lastName,
        gender: formData.gender,
        phone: formData.phone,
        address: formData.address,
        ohip_num: formData.ohipNum.replace(/-/gi, ""),
        ohip_vc: formData.ohipVC,
        doctor: formData.doctor,
        postal_code: formData.postalCode,
        date_of_birth: moment(formData.dateOfBirth, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        ),
        city: formData.city,
      },
    },
  };

  return (dispatch) => {
    dispatch(requestSubmitAppointment());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(createAppointment(formData, data.pk, officePk, symptoms));
      })
      .catch((err) => {
        dispatch(errorSubmitAppointment());
        console.log(err);
      });
  };
}

export function addUserToOffice(formData, userPk, officePk, symptoms) {
  const token = store.get("id_token");
  let config = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/add-to-office/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      userPk,
      officePk,
    },
  };

  return (dispatch) => {
    dispatch(requestSubmitAppointment());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(createAppointment(formData, userPk, officePk, symptoms));
      })
      .catch((err) => {
        dispatch(errorSubmitAppointment());
        console.log(err);
      });
  };
}

export function createAppointment(formData, userPk, officePk, symptoms) {
  const token = store.get("id_token");

  let config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/appointments/appointment_create/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      event_date: moment(formData.eventDate).format("YYYY-MM-DD"),
      event_time: moment(formData.eventTime).format("HH:mm"),
      office: officePk,
      user: userPk,
      event_type: formData.eventType,
      booked_at: "admin",
    },
  };

  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(createCovidInfo(data.pk, formData, symptoms));
        dispatch(setDate(moment(formData.eventDate)));
        dispatch();
      })
      .catch((err) => {
        dispatch(errorSubmitAppointment());
        console.log(err);
      });
  };
}

export function createCovidInfo(appointmentPk, formData, symptoms) {
  const token = store.get("id_token");
  let config = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/covid/covid_info/`,
    data: {
      appointment: appointmentPk,
      sympton_startedfrom: formData.startedFrom ? formData.startedFrom : null,
      contactedwith_covid: formData.contactedWithCovid,
      contactedwith_description: formData.contactedWithCovidDescription,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(createCovidSymptoms(symptoms, data.id, appointmentPk));
      })
      .catch((err) => {
        dispatch(errorSubmitAppointment());
        console.log(err);
      });
  };
}

export function createCovidSymptoms(symptoms, covidInfoPk, appointmentPk) {
  const token = store.get("id_token");
  let config = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/covid/user_symptom_create/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: symptoms.map((symptom) => ({
      covid_info: covidInfoPk,
      symptom,
    })),
  };

  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        // launchToast("Successfully created Appointment");
        dispatch(createExportFile(appointmentPk));
      })
      .catch((err) => {
        dispatch(errorSubmitAppointment());
        console.log(err);
      });
  };
}

export function createExportFile(appointmentPk) {
  let config = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/appointments/save_to_file/`,
    data: {
      appointmentPk,
    },
  };

  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveSubmitAppointment());
        launchToast("Successfully created Appointment");
      })
      .catch((err) => {
        dispatch(errorSubmitAppointment());
      });
  };
}

export function exportData(start, end, officePk) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/csvexport/?office_id=${officePk}&start_date=${moment(
      start
    ).format("YYYY-MM-DD")}&end_date=${moment(end).format(
      "YYYY-MM-DD"
    )}&format=csv`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "download.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => console.log(err));
  };
}

export function exportImmunizationData(start, end, officePk) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/immunization-csv-export/?office_id=${officePk}&start_date=${moment(
      start
    ).format("YYYY-MM-DD")}&end_date=${moment(end).format(
      "YYYY-MM-DD"
    )}&format=csv`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "download.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => console.log(err));
  };
}

export function exportStudentData(start, end, officePk) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/student-csv-export/?office_id=${officePk}&start_date=${moment(
      start
    ).format("YYYY-MM-DD")}&end_date=${moment(end).format(
      "YYYY-MM-DD"
    )}&format=csv`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "download.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => console.log(err));
  };
}

export function exportVaccinationData(start, end, officePk) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/vaccination-csv-export/?office_id=${officePk}&start_date=${moment(
      start
    ).format("YYYY-MM-DD")}&end_date=${moment(end).format(
      "YYYY-MM-DD"
    )}&format=csv`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "download.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => console.log(err));
  };
}

export function deleteAppointment(pk, sendEmail) {
  const token = store.get("id_token");
  let config = {
    method: "DELETE",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/appointment_delete/${pk}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      sendEmail: sendEmail == "yes" ? true : false,
    },
  };

  return (dispatch) => {
    dispatch(requestDeleteAppointment());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveDeleteAppointment());
      });
  };
}

export function getAutomatedMessage(automatedMessageType, officePk) {
  const token = store.get("id_token");
  let config = {
    method: "GET",
    url: `${
      process.env.REACT_APP_DEV_API
    }/messaging/automated_message/${officePk}/${automatedMessageType}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetAutomatedMessage());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetAutomatedMessage(data[0]));
      })
      .catch((err) => {
        dispatch(errorGetAutomatedMessage(err.response));
      });
  };
}

export function saveAutomatedMessage(pk, message) {
  const token = store.get("id_token");
  let config = {
    method: "PATCH",
    url: `${
      process.env.REACT_APP_DEV_API
    }/messaging/save_automated_message/${pk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      message,
    },
  };

  return (dispatch) => {
    dispatch(requestSaveAutomatedMessage());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        launchToast("Updated Field Successfully!");
        dispatch(receiveSaveAutomatedMessage());
      })
      .catch((err) => {
        launchToast(`Failed to save automated message. ${err.response}`);
        dispatch(errorSaveAutomatedMessage());
      });
  };
}

function requestPrintData() {
  return {
    type: "REQUEST_PRINT_DATA",
    isPrintingData: true,
  };
}

function receivePrintData() {
  return {
    type: "RECEIVE_PRINT_DATA",
    isPrintingData: false,
  };
}

function errorPrintData() {
  launchToast("There was an error printing your data.");
  return {
    type: "ERROR_PRINT_DATA",
    isPrintingData: false,
  };
}

export function printData(start_date, end_date, officePk) {
  const token = store.get("id_token");
  let config = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/appointments/get_export_files/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      start_date: moment(start_date).format("YYYY-MM-DD"),
      end_date: moment(end_date).format("YYYY-MM-DD"),
      office: officePk,
    },
  };

  return (dispatch) => {
    dispatch(requestPrintData());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        // const url = data;
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", "download.zip"); //or any other extension
        // document.body.appendChild(link);
        // link.click();
        dispatch(receivePrintData());
        launchToast(
          "Data is being prepared for you. To view, go to Download Data or click here."
        );
      })
      .catch((err) => {
        dispatch(errorPrintData());
        console.log(err);
      });
  };
}

export function getUserEmail(email) {
  const token = store.get("id_token");
  let config = {
    method: "GET",
    url: `${process.env.REACT_APP_DEV_API}/check-email/${email}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        if (data.length > 0) {
          dispatch(
            setNewUser({
              adminPk: data[0].pk,
              userPk: data[0].user,
            })
          );
        } else {
          dispatch(setNewUser(null));
        }
      })
      .catch((err) => {
        dispatch(setNewUser(null));
      });
  };
}

export function printLabels(start_date, end_date, officePk, printers) {
  const token = store.get("id_token");
  let config = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/appointments/get_labels/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      start_date: moment(start_date).format("YYYY-MM-DD"),
      end_date: moment(end_date).format("YYYY-MM-DD"),
      office: officePk,
    },
  };

  return async (dispatch) => {
    const links = await axios(config).then((res) => res.data);

    if (links) {
      links.forEach((link) => {
        axios
          .get(link)
          .then((res) => res.data)
          .then((data) => {
            const labelXML = window.dymo.label.framework.openLabelXml(data);

            window.dymo.label.framework.printLabel(
              printers[0].name,
              null,
              labelXML
            );
            console.log(labelXML);
          });
      });
    }
  };
}

function requestCreateReservedSpot() {
  return {
    type: "REQUEST_CREATE_RESERVED_SPOT",
    isCreatingReservedSpot: true,
  };
}

function receiveCreateReservedSpot() {
  return {
    type: "RECEIVE_CREATE_RESERVED_SPOT",
    isCreatingReservedSpot: false,
  };
}

export function createReservedSpot(start, end, office) {
  const token = store.get("id_token");
  let config = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/appointments/create-reserved/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      start,
      end,
      office,
    },
  };

  return (dispatch) => {
    dispatch(requestCreateReservedSpot());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveCreateReservedSpot());
        dispatch(getReservedSpots(office));
        launchToast("Successfully created reserved spot!");
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function deleteReservedSpot(pk, officePk) {
  const token = store.get("id_token");
  let config = {
    method: "DELETE",
    url: `${process.env.REACT_APP_DEV_API}/appointments/delete-reserved/${pk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(getReservedSpots(officePk));
      });
  };
}

export function getAppointmentsByDateTime(officePk, startDate, startTime) {
  const token = store.get("id_token");
  let config = {
    method: "GET",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/get-appointments-by-datetime/?office=${officePk}&event_date=${startDate}&event_time=${startTime}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      office: officePk,
      startDate,
      startTime,
    },
  };

  return (dispatch) => {
    dispatch(requestGetAppointmentsByDateTime());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetAppointmentsByDateTime(data));
      })
      .catch((err) => {
        dispatch(errorGetAppointmentsByDateTime());
        console.log(err);
      });
  };
}

// Save User
// Save Appointment
// Save COVID INFO
// Save Symptoms
// Export Files

export function updateUserInfo(
  formData,
  userPk,
  appointmentPk,
  covidInfoPk,
  symptomsList,
  selectedImmunizations
) {
  const token = store.get("id_token");
  let config = {
    method: "PATCH",
    url: `${process.env.REACT_APP_DEV_API}/update_patient_profile/${userPk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      first_name: formData.firstName,
      last_name: formData.lastName,
      gender: formData.gender,
      phone: formData.phone ? formData.phone : null,
      address: formData.address ? formData.address : null,
      ohip_num: formData.ohipNum,
      ohip_vc: formData.ohipVC,
      doctor: formData.doctor,
      postal_code: formData.postalCode,
      date_of_birth: moment(formData.dateOfBirth, "YYYY/MM/DD").format(
        "YYYY-MM-DD"
      ),
      city: formData.city,
      greenshield_number: formData.greenshield_number,
      student_number: formData.student_number,
      extra_info_label: formData.infoLabel,
      extra_info_value: formData.infoValue,
    },
  };

  return (dispatch) => {
    dispatch(requestUpdateData());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(
          saveAppointmentInfo(
            appointmentPk,
            formData,
            covidInfoPk,
            symptomsList,
            selectedImmunizations
          )
        );
      })
      .catch((err) => {
        dispatch(errorUpdateData());
      });
  };
}

function saveAppointmentInfo(
  appointmentPk,
  formData,
  covidInfoPk,
  symptomsList,
  selectedImmunizations
) {
  const token = store.get("id_token");
  let config = {
    method: "PATCH",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/update-appointment/${appointmentPk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      event_date: moment(formData.eventDate).format("YYYY-MM-DD"),
      event_time: moment(formData.eventTime).format("HH:mm"),
      dose: formData.dose,
    },
  };

  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        if (covidInfoPk) {
          dispatch(
            saveCOVIDInfo(formData, covidInfoPk, appointmentPk, symptomsList)
          );
        } else if (selectedImmunizations) {
          dispatch(saveImmunizations(selectedImmunizations, appointmentPk));
        } else {
          dispatch(updateExportFile(appointmentPk));
        }
      })
      .catch((err) => {
        dispatch(errorUpdateData());
      });
  };
}

function saveCOVIDInfo(formData, covidPk, appointmentPk, symptomsList) {
  const token = store.get("id_token");
  let config = {
    method: "PATCH",
    url: `${process.env.REACT_APP_DEV_API}/covid/update_covid_info/${covidPk}/`,
    data: {
      sympton_startedfrom: formData.startedFrom ? formData.startedFrom : null,
      contactedwith_covid: formData.contactWithCovid,
      contactedwith_description: formData.contactedWithCovidDescription,
      required_testing: formData.required_testing,
      required_testing_description: formData.required_testing_description,
      required_testing_description: formData.required_testing_description,
      is_attending_school: formData.is_attending_school,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(saveSymptoms(symptomsList, covidPk, appointmentPk));
        // dispatch(updateExportFile(appointmentPk));
      })
      .catch((err) => {
        dispatch(errorUpdateData());
      });
  };
}

function saveImmunizations(selectedImmunizations, appointmentPk) {
  const token = store.get("id_token");
  let config = {
    method: "POST",
    url: `${
      process.env.REACT_APP_DEV_API
    }/fluclinic/update-user-immunizations/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      appointmentPk,
      selectedImmunizations,
    },
  };

  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(updateExportFile(appointmentPk));
        // launchToast("Updated successfully.");
        // dispatch(receiveUpdateData());
      })
      .catch((err) => {
        dispatch(errorUpdateData());
      });
  };
}

function saveSymptoms(selectedSymptoms, covidPk, appointmentPk) {
  const token = store.get("id_token");
  let config = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/covid/update_symptoms/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      covidPk,
      selectedSymptoms,
    },
  };

  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(updateExportFile(appointmentPk));
        // launchToast("Updated successfully.");
        // dispatch(receiveUpdateData());
      })
      .catch((err) => {
        dispatch(errorUpdateData());
      });
  };
}

export function updateExportFile(appointmentPk) {
  let config = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/appointments/update_to_file/`,
    data: {
      appointmentPk,
    },
  };
  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((res) => {
        // dispatch()
        launchToast("Updated successfully.");
        dispatch(receiveUpdateData());
      })
      .catch((err) => {
        dispatch(errorUpdateData());
      });
  };
}

export function getAppointmentExportFile(appointmentPk) {
  const token = store.get("id_token");
  let config = {
    method: "GET",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/get-appointment-link/?appointment=${appointmentPk}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    return axios(config).then((response) => {
      console.log(response.data);
      const url = response.data;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.docx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };
}

export function getExportFile(exportFilePk) {
  const token = store.get("id_token");
  let config = {
    method: "GET",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/get-export-file/?export_file=${exportFilePk}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    return axios(config).then((response) => {
      console.log(response.data);
      const url = response.data;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.zip"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };
}

export function getAppointmentReqFile(appointmentPk) {
  const token = store.get("id_token");
  let config = {
    method: "GET",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/get-appointment-req-link/?appointment=${appointmentPk}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    return axios(config).then((response) => {
      console.log(response.data);
      const url = response.data;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.pdf"); //or any other extension
      document.body.appendChild(link);
      console.log(link);
      link.click();
    });
  };
}

export function getAppointmentLabelLink(appointmentPk, printers) {
  const token = store.get("id_token");
  let config = {
    method: "GET",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/get-appointment-label/?appointment=${appointmentPk}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        const labelXML = window.dymo.label.framework.openLabelXml(data);

        window.dymo.label.framework.printLabel(
          printers[0].name,
          null,
          labelXML
        );
      });
  };
}

function requestGetAppointmentByOfficeAndPatient() {
  return {
    type: "REQUEST_GET_APPOINTMENT_BY_OFFICE_AND_PATIENT",
    isRetrievingPatientAppointments: true,
  };
}

function receiveGetAppointmentByOfficeAndPatient(patientAppointments) {
  return {
    type: "RECEIVE_GET_APPOINTMENT_BY_OFFICE_AND_PATIENT",
    isRetrievingPatientAppointments: false,
    patientAppointments,
  };
}

export function resetPatientAppointments() {
  return {
    type: "RESET_PATIENT_APPOINTMENTS",
  };
}

export function getAppointmentsByOfficeAndPatient(patientPk) {
  const token = store.get("id_token");
  let config = {
    method: "GET",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/patient-appointments/${patientPk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetAppointmentByOfficeAndPatient());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetAppointmentByOfficeAndPatient(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function receiveGetAppointmentInterval(appointmentInterval) {
  return {
    type: "RECEIVE_GET_APPOINTMENT_INTERVAL",
    appointmentInterval,
  };
}

export function getAppointmentInterval(officePk, day) {
  const token = store.get("id_token");
  let config = {
    method: "GET",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/get-office-hour-intervals/${officePk}/${day}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetAppointmentInterval(data));
      });
  };
}

function requestGetExportFilesList() {
  return {
    type: "REQUEST_GET_EXPORT_FILES_LIST",
    isFetchingExportFiles: true,
  };
}

function setUserID(users) {
  return {
    type: "SET_USER_ID",
    users,
  };
}

function receiveGetExportFilesList(exportFiles) {
  return {
    type: "RECEIVE_GET_EXPORT_FILES_LIST",
    exportFiles,
    isFetchingExportFiles: false,
  };
}

function errorGetExportFilesList() {
  return {
    type: "ERROR_GET_EXPORT_FILES_LIST",
    isFetchingExportFiles: false,
  };
}

export const resetGetExportFilesList = () => ({
  type: "RESET_GET_EXPORT_FILES_LIST",
});

export function getExportFilesList(officePk) {
  const token = store.get("id_token");

  let config = {
    method: "get",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/export-files/${officePk}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetExportFilesList());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetExportFilesList(data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(errorGetExportFilesList());
      });
  };
}

//Function to recieve Ordered List of Appointments for Simple View
export function getSimpleAppointments(officePk, date) {
  //Get authentication from client
  const token = store.get("id_token");
  //Defining request to be sent to backend
  let config = {
    method: "get",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/get-simple-list-view/${officePk}/?start=${moment(
      date
    ).format("YYYY-MM-DD")}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  //Send request, receive data from Django
  return (dispatch) => {
    dispatch(requestGetSimpleAppointments());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        //Associate retrieved appointment model with patient user profile
        const uniqueUser = data
          .map((simpleAppointment) => simpleAppointment.patient.user)
          .filter((item, index, arr) => {
            return arr.indexOf(item) === index;
          });
        const uniqueUserLen = uniqueUser.length;
        const totalLen = 65 + uniqueUserLen;
        const usersData = [];
        if (uniqueUserLen) {
          for (let i = 65; i < totalLen; i++) {
            var str = String.fromCharCode(i);
            usersData.push({
              id: uniqueUser[i - 65],
              char: str,
            });
          }
        }
        dispatch(setUserID(usersData));
        //Populate appointmentItem component with data received from backend
        const simpleAppointments = data.map((simpleAppointment, index) => ({
          id: simpleAppointment.pk,
          title: `${
            simpleAppointment.patient
              ? simpleAppointment.patient.first_name
              : "No Name Entered"
          } ${
            simpleAppointment.patient ? simpleAppointment.patient.last_name : ""
          }`,
          start: moment(
            `${simpleAppointment.event_date} ${simpleAppointment.event_time}`
          ).toDate(),
          end: moment(
            `${simpleAppointment.event_date} ${simpleAppointment.event_time}`
          )
            // .add(appointmentInterval, "minutes")
            .toDate(),
          ...simpleAppointment,
          userPk: simpleAppointment.user,
        }));
        //Set simpleAppointments in state
        dispatch(receiveGetSimpleAppointments(simpleAppointments));
      });
  };
}
