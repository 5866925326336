import React from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { setOfficeSettingsTab } from "../../actions/officeSettingsActions";

type SettingsTabItemProps = {
  active: boolean;
  tabDispatchName:
    | "automaticMessaging"
    | "manageBookingTimes"
    | "generalInformation"
    | "priorityBookingTimes"
    | "new-priority-tab"
    | "edit-priority-tab"
    | "profile"
    | "patients"
    | "users"
    | "account"
    | "myPlan"
    | "payments"
    | "support"
    | "policies";
  tabName: string;
};

export const SettingsTabItem: React.FC<any> = (props: SettingsTabItemProps) => {
  const dispatch = useDispatch();
  return (
    <li className={props.active ? "active" : ""}>
      <a
        href="#"
        title="Automatic Messaging"
        onClick={(e) => {
          e.preventDefault();
          dispatch(setOfficeSettingsTab(props.tabDispatchName));
        }}
      >
        {props.tabName}
      </a>
    </li>
  );
};
