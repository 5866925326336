import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Popup from "./Popup";

export const Event = (props) => {
  const { event, title } = props;
  const [popup, setPopup] = useState(false);
  const users = useSelector((state) => state.appointmentReducer.users);
  const eventTypes = useSelector(
    (state) => state.appointmentReducer.eventTypes
  );
  const selectedEvent = eventTypes.filter((item) => {
    return item.name == event.event_type;
  });
  const selectedUser =
    users &&
    users
      .filter((item) => {
        if (event.patient) {
          return item.id == event.patient.user;
        }
      })
      .map((item) => {
        return item.char;
      });
  return (
    <div className={``}>
      <div
        title={title}
        className={`rbc-event ${event.type ? "reserved" : ""} ${
          event.booked_at == "patient" ? "booked_patient" : ""
        }`}
        style={
          selectedEvent.length
            ? { backgroundColor: `${selectedEvent[0].color}` }
            : null
        }
        onClick={() => setPopup(!popup)}
      >
        <div class="rbc-event-label" style={{ textTransform: "capitalize" }}>
          {event.type == "reserved"
            ? title
            : selectedUser && selectedUser.join("") + " - " + title}
        </div>
        <div class="rbc-event-content event-stuff">
          {moment(event.start).format("hh:mm a")} -{" "}
          {moment(event.end).format("hh:mm a")}
        </div>
      </div>
      {popup && (
        <Popup
          event={props}
          setPopup={setPopup}
          reserved={event.type ? true : false}
        />
      )}
    </div>
  );
};
