import React from "react";
import { useSelector } from "react-redux";
const Sidebar = () => {
  const newOfficeDetails = useSelector(
    (state) => state.adminOfficeInteractions.newOfficeDetails
  );
  const officeHoursOfOperation = useSelector(
    (state) => state.adminOfficeInteractions.officeHoursOfOperation
  );
  const officeNewEvents = useSelector(
    (state) => state.adminOfficeInteractions.officeNewEvents
  );
  const hoursHandler = () => {
    const list = officeHoursOfOperation.days.every((item) => {
      return item.daystart !== "";
    });
    return list;
  };
  const usersHandler = () => {
    const list =
      officeNewEvents.users &&
      officeNewEvents.users.every((item) => {
        return item.full_name !== "";
      });
    return list;
  };
  const phoneNumber = (x) => {
    return x.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  };
  return (
    <div class="col-3 app-setting-left">
      <div className="white-box">
        <div class="inner-header">
          <h5> Office Summary </h5>
          {newOfficeDetails && (
            <div className="order-summary-list">
              <div className="item">
                <div className="label">Office Name:</div>
                <div className="label-content">{newOfficeDetails.office}</div>
              </div>
              <div className="item">
                <div className="label">Type of Office:</div>
                <div className="label-content">
                  {newOfficeDetails.type_of_office}
                </div>
              </div>
              <div className="item">
                <div className="label">Location:</div>
                <div className="label-content">
                  {newOfficeDetails.address}, {newOfficeDetails.city},{" "}
                  {newOfficeDetails.postal_code}
                </div>
              </div>
              {newOfficeDetails.phone && (
                <div className="item">
                  <div className="label">Phone:</div>
                  <div className="label-content">
                    {phoneNumber(newOfficeDetails.phone)}
                  </div>
                </div>
              )}
              {hoursHandler() && (
                <div className="item">
                  <div className="label">Hours:</div>
                  <div className="label-content">Added</div>
                </div>
              )}
              {usersHandler() && (
                <div className="item users">
                  <div className="item-inner">
                    <div className="label">Users:</div>
                    <div className="label-content">
                      {officeNewEvents.users.length}
                    </div>
                  </div>
                  <div className="item-inner">
                    <div className="label" />
                    <div className="label-content">
                      <div className="users-list">
                        {officeNewEvents.users.map((item, index) => {
                          return <div key={index}>{item.full_name}</div>;
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
