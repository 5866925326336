import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getCurrentOffice,
  getSocketConnection,
} from "../actions/adminOfficeActions";
import { getChatConnection } from "../actions/chatActions";
import { Link, Route, Switch, Redirect, useHistory } from "react-router-dom";
import PatientList from "../components/patientList/patientList";
import OfficeRequests from "../components/patientList/officeRequests";
import PatientProfile from "../components/patientList/patientProfile";
import WaitingRoom from "../components/waitingRoom/WaitingRoom";
import { MeetingRoom } from "../components/meetingroom/MeetingRoom";
import { SingleMeetingRoom } from "../components/meetingroom/SingleMeetingRoom";
import LeftBar from "../components/leftBar";
import PastAppointments from "../components/pastAppointments";
import Inbox from "../components/messaging/Inbox";
import PastAppointment from "../components/pastAppointment";
import QueueSound from "../assets/images/queue.mp3";
import launchToast from "../utils";
import { Appointments } from "../components/appointments/Appointments";
import { NewAppointment } from "../components/appointments/calendar/NewAppointment";
// import NewAppointment from "../components/appointments/newappointment/NewAppointment";
import { ExistingAppointment } from "../components/appointments/calendar/ExistingAppointment";
import { AutomatedMessages } from "../components/appointments/automatedmessages/AutomatedMessages";
import { Bulletin } from "../components/bulletin/Bulletin";
import { NewPost } from "../components/bulletin/NewPost";
import { ExistingPost } from "../components/bulletin/ExistingPost";
import { ExportFiles } from "../components/appointments/exportfiles/exportfiles";
import { SettingsContainer } from "../components/OfficeSettings/SettingsContainer";
import { Dashboard } from "../components/Dashboard/Dashboard";
import { InternalChat } from "../components/InternalChat/InternalChat";

let audio = new Audio(QueueSound);

function Office(props) {
  const history = useHistory();
  const adminOfficeInteractions = useSelector(
    (state) => state.adminOfficeInteractions
  );
  const socket = adminOfficeInteractions.socket;
  const profile = useSelector((state) => state.authenticateUser.profile);
  const openChat = useSelector((state) => state.internalChatReducer.openChat);
  useEffect(() => {
    try {
      window.dymo.label.framework.init();
    } catch (err) {
      return;
    }
  }, []);

  // const email = profile.user.email;

  const dispatch = useDispatch();

  const currentOffice = props.match.params.slug;
  useEffect(() => {
    if (profile.user === undefined) {
      history.push("/dashboard");
    } else {
      dispatch(getCurrentOffice(props.match.params.slug));
      dispatch(getSocketConnection(props.match.params.slug, profile.pk));
      dispatch(getChatConnection(profile.user.email));
    }
  }, [dispatch, history, profile.pk, profile.user, props.match.params.slug]);

  // useEffect(() => {
  //   if (socket) {
  //     const getWaitingRoomPatientsInterval = setInterval(() => {
  //       // dispatch(getReservedSpots(props.office.pk));
  //       socket.send(
  //         JSON.stringify({
  //           type: "GET_ALL_WAITING_ROOM_PATIENTS",
  //         })
  //       );
  //     }, 5000);
  //     return () => clearInterval(getWaitingRoomPatientsInterval);
  //   }
  // }, [socket]);
  const office = adminOfficeInteractions.office;
  const isFetchingOffice = adminOfficeInteractions.isFetchingOffice;

  const socketStatus = adminOfficeInteractions.socketStatus;
  const videoToken = useSelector((state) => state.waitingRoom.videoToken);

  // useEffect(() => {
  //   if (socketStatus != "connected") {
  //     const reconnect = setInterval(function () {
  //       dispatch(getSocketConnection(props.match.params.slug, profile.pk));
  //     }, 10000);
  //     return () => clearInterval(reconnect);
  //   }
  // }, [socketStatus]);

  function listenSound(e) {
    const response_data = JSON.parse(e.data);
    if (response_data.type == "play_sound") {
      if (!videoToken && office.waiting_room_alert) {
        audio.play();
      }
    }
  }

  useEffect(() => {
    if (socket) {
      socket.addEventListener("message", listenSound);

      return () => socket.removeEventListener("message", listenSound);
    }
  }, [socket, videoToken, office, listenSound]);

  const menuSetting = adminOfficeInteractions.menuSetting;
  return profile.user ? (
    <div
      className={`settings-main ${menuSetting === "close" ? "close-menu" : ""}`}
    >
      <LeftBar isAuthenticated currentOffice={office.slug} />
      <div class="rightbar">
        {!isFetchingOffice ? (
          <>
            <Switch>
              <Route path={`/office/${currentOffice}/dashboard`} exact>
                <Dashboard office={office} currentOffice={currentOffice} />
              </Route>
              <Route path={`/office/${currentOffice}/patient-list`} exact>
                <PatientList office={office} currentOffice={currentOffice} />
              </Route>
              <Route path={`/office/${currentOffice}/office-requests`} exact>
                <OfficeRequests office={office} currentOffice={currentOffice} />
              </Route>
              <Route path={`/office/${currentOffice}/inbox`} exact>
                <Inbox office={office} currentOffice={currentOffice} />
              </Route>
              <Route path={`/office/${currentOffice}/private-inbox`} exact>
                <Inbox office={office} currentOffice={currentOffice} />
              </Route>
              <Route
                path={`/office/${currentOffice}/waiting-room`}
                exact
                component={WaitingRoom}
              />
              <Route path={`/office/${currentOffice}/meeting-room`} exact>
                <MeetingRoom office={office} />
              </Route>
              <Route
                path={`/office/${currentOffice}/meeting-room/:pk`}
                exact
                component={(props) => (
                  <SingleMeetingRoom {...props} office={office} />
                )}
              />

              <Route path={`/office/${currentOffice}/past-appointments`} exact>
                <PastAppointments
                  office={office}
                  currentOffice={currentOffice}
                />
              </Route>
              <Route
                path={`/office/${currentOffice}/past-appointment/:pk/`}
                exact
                component={PastAppointment}
              />
              <Route
                path={`/office/${currentOffice}/patient/:pk/`}
                exact
                component={PatientProfile}
              />
              <Route path={`/office/${currentOffice}/appointments/`} exact>
                <Appointments {...props} office={office} />
              </Route>
              <Route
                path={`/office/${currentOffice}/appointments/new-appointment/`}
                exact
              >
                <NewAppointment {...props} office={office} />
              </Route>
              <Route
                path={`/office/${currentOffice}/appointments/appointment/:pk`}
                exact
              >
                <ExistingAppointment {...props} office={office} />
              </Route>
              <Route
                path={`/office/${currentOffice}/appointments/automated-messages`}
                exact
              >
                <AutomatedMessages {...props} office={office} />
              </Route>
              <Route path={`/office/${currentOffice}/bulletin`} exact>
                <Bulletin {...props} office={office} />
              </Route>
              <Route path={`/office/${currentOffice}/bulletin/new-post`} exact>
                <NewPost {...props} office={office} />
              </Route>
              <Route
                path={`/office/${currentOffice}/bulletin/:pk/`}
                exact
                component={ExistingPost}
              />
              <Route path={`/office/${currentOffice}/export-files`} exact>
                <ExportFiles {...props} office={office} />
              </Route>
              <Route
                path={`/office/${currentOffice}/appointments/appointment-settings`}
                exact
              >
                <SettingsContainer
                  {...props}
                  office={office}
                  appointmentSettings
                />
              </Route>
              <Route path={`/office/${currentOffice}/settings`} exact>
                <SettingsContainer {...props} office={office} />
              </Route>
            </Switch>
            {openChat && <InternalChat />}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  ) : (
    "LOADING"
  );
}

export default Office;
