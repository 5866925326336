import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import launchToast from "../../utils";
import VideoCall from "./VideoCall";
import Queue from "./Queue";
import DashboardTop from "../dashboardTop";
import RightSection from "./rightSection";
import { getWaitingRoomStatus } from "../../actions/adminOfficeActions";
import {
  enableVideoAndAudioPermissions,
  stopVideoAndAudio,
} from "../../actions/waitingRoomActions";

const WaitingRoom = (props) => {
  const history = useHistory();

  const adminOfficeInteractions = useSelector(
    (state) => state.adminOfficeInteractions
  );

  const activeAdmin = useSelector((state) => state.waitingRoom.activeAdmin);

  const videoToken = useSelector((state) => state.waitingRoom.videoToken);

  const adminPK = useSelector((state) => state.authenticateUser.profile.pk);
  const email = useSelector(
    (state) => state.authenticateUser.profile.user.email
  );
  const socket = useSelector((state) => state.adminOfficeInteractions.socket);
  const office = useSelector((state) => state.adminOfficeInteractions.office);

  const client = useSelector((state) => state.waitingRoom.client);
  const localVideoTrack = useSelector(
    (state) => state.waitingRoom.localVideoTrack
  );
  const localAudioTrack = useSelector(
    (state) => state.waitingRoom.localAudioTrack
  );
  const localParticipant = useSelector(
    (state) => state.waitingRoom.localParticipant
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!office.allow_waiting_room) {
      launchToast(
        "You have disabled your waiting room. To allow patients to access your waiting room, please edit your office settings."
      );
    }
  }, []);

  useEffect(() => {
    socket.send(
      JSON.stringify({
        type: "ADMIN_JOIN_WAITING_ROOM",
        is_patient: false,
        office: office.slug,
      })
    );
    dispatch(getWaitingRoomStatus(office.slug));
  }, []);

  // useEffect(() => {

  // }, []);

  // useEffect(() => {
  //   if (localVideoTrack && localAudioTrack) {
  //     return () =>
  //       dispatch(
  //         stopVideoAndAudio(localParticipant, localVideoTrack, localAudioTrack)
  //       );
  //   }
  // }, [localVideoTrack, localAudioTrack]);

  return (
    <div class="waittingroom-main">
      <div class="rightbar full-height" style={{ paddingLeft: 0 }}>
        <DashboardTop title={"Waiting Room"} rightIcon={true} />
        <div class="middle-content main-content-wrapper">
          <div class="waiting-title">
            <h3>Queue</h3>
          </div>
          <div class="d-flex">
            <div class="left-sec">
              <div class="waiting-table white-box">
                <div class="custom-scrl-content mCustomScrollbar">
                  <div class="table-scroll">
                    <Queue
                      office={adminOfficeInteractions.office}
                      adminEmail={email}
                      socket={socket}
                      currentAdmin={adminPK}
                      activeAdmin={activeAdmin}
                    />
                  </div>
                </div>
              </div>
              <div class="video-call-content">
                {videoToken && localVideoTrack && localAudioTrack ? (
                  <VideoCall />
                ) : (
                  ""
                )}
              </div>
            </div>
            <RightSection />
          </div>
        </div>
      </div>
    </div>
  );

  // return (
  //   <div>
  //     <div>
  //       {/* Implement video chat here so we can pass data down to video call and triage/chat */}
  //       <h1>QUEUE</h1>
  //       <Queue waitingPatients={waitingRoom.users} />
  //     </div>
  //     <div>
  //       <Chat />
  //     </div>
  //     <div>
  //       <VideoCall />
  //     </div>
  //   </div>
  // );
};

// // onClick={() => dispatch(requestLogin())}
// WaitingRoom.whyDidYouRender = {
//   logOnDifferentValues: true,
// };

export default WaitingRoom;
