import React from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  exportData,
  exportImmunizationData,
  exportStudentData,
  exportVaccinationData,
} from "../../actions/appointmentActions";
import { createPortal } from "react-dom";

export const ExportData = (props) => {
  const { control, register, handleSubmit, watch, errors } = useForm();
  const office = useSelector((state) => state.adminOfficeInteractions.office);
  const dispatch = useDispatch();
  const formSubmit = (data) => {
    if (office.immunization_clinic) {
      dispatch(exportImmunizationData(data.start, data.end, office.pk));
    } else if (office.requires_student_number) {
      dispatch(exportStudentData(data.start, data.end, office.pk));
    } else if (office.vaccination_clinic) {
      dispatch(exportVaccinationData(data.start, data.end, office.pk));
    } else {
      if (office.pk === 70 || office.pk === 105) {
        dispatch(exportVaccinationData(data.start, data.end, office.pk));
      } else {
        dispatch(exportData(data.start, data.end, office.pk));
      }
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      maxHeight: "100vh",
      marginRight: "-50%",
      overflowY: "auto",
      overflow: "hidden",
      transform: "translate(-50%, -50%)",
      padding: 0,
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
    },
  };

  const CalendarContainer = ({ children }) =>
    children
      ? createPortal(
        React.cloneElement(children, {
          className: "datetimepicker-input react-datepicker-popper",
        }),
        document.body
      )
      : null;

  return (
    <Modal isOpen={true} style={customStyles}>
      <div
        id="export-data-popup"
        className=" stage4-popup fancybox-content updated-popup-form"
        style={{
          display: "inline-block",
          width: "464px",
          height: "389px",
          overflowX: "hidden",
        }}
      >
        <form onSubmit={handleSubmit(formSubmit)}>
          <div>
            <div className="common-form">
              <div className="sub-heading common-subheading">
                <h3>Export Data</h3>
              </div>
              <div className="inner-forgot-pass inner-popup-content">
                <h4>Select a Timeframe</h4>
                <div className="snooze-datetime-main calendar-datetime">
                  <div className="snooze-date-time datetime-main form-group">
                    <label className="select-dt">Select Date(s):</label>
                    <div className="office-time o-date">
                      <Controller
                        render={({ onChange, value }) => (
                          <DatePicker
                            className="form-control datetimepicker-input"
                            placeholder="Enter Date"
                            onChange={(date) => onChange(date)}
                            dateFormat="MM-dd-yy"
                            showMonthDropdown
                            selected={value}
                            popperContainer={CalendarContainer}
                          />
                        )}
                        name="start"
                        control={control}
                      />
                    </div>
                    <span className="to">to</span>
                    <div className="office-time o-date">
                      <Controller
                        render={({ onChange, value }) => (
                          <DatePicker
                            className="form-control datetimepicker-input"
                            placeholder="Enter Date"
                            onChange={(date) => onChange(date)}
                            dateFormat="MM-dd-yy"
                            showMonthDropdown
                            popperContainer={CalendarContainer}
                            selected={value}
                          />
                        )}
                        name="end"
                        control={control}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="print-checbk">
                  <label>Type of Data:</label>
                  <div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="appointments_created"
                        name="appointments_created"
                        className="checkbox-custom"
                        value="label"
                        ref={register()}
                      />
                      <label
                        htmlFor="appointments_created"
                        className="checkbox-custom-label"
                      >
                        <span>Date of Appointment Created</span>
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="appointments_date_time"
                        name="appointments_date_time"
                        className="checkbox-custom"
                        value="label"
                        ref={register()}
                      />
                      <label
                        htmlFor="appointments_date_time"
                        className="checkbox-custom-label"
                      >
                        <span>Date & Time</span>
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="appointments_type"
                        name="appointments_type"
                        className="checkbox-custom"
                        value="label"
                        ref={register()}
                      />
                      <label
                        htmlFor="appointments_type"
                        className="checkbox-custom-label"
                      >
                        <span>Type of Appointment</span>
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="appointments_triage"
                        name="appointments_triage"
                        className="checkbox-custom"
                        value="label"
                        ref={register()}
                      />
                      <label
                        htmlFor="appointments_triage"
                        className="checkbox-custom-label"
                      >
                        <span>Triage Information</span>
                      </label>
                    </div>
                  </div>
                </div> */}
                <div className="login-submit">
                  <a
                    href="#"
                    title="Cancel"
                    className="popup-btn whitebtn"
                    data-fancybox-close=""
                    onClick={(e) => {
                      e.preventDefault();
                      props.setOpenExport(false);
                    }}
                  >
                    Cancel
                  </a>
                  <button
                    type="submit"
                    title="Export Data"
                    className="popup-btn"
                  >
                    Export Data
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            data-fancybox-close=""
            className="fancybox-button fancybox-close-small"
            title="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1"
              viewBox="0 0 24 24"
            >
              <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
            </svg>
          </button>
        </form>
      </div>
    </Modal>
  );
};
