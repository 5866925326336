import axios, { AxiosRequestConfig } from "axios";
import moment from "moment-timezone";
import { Dispatch } from "react";
import { Action } from "redux";
import store from "store";
import launchToast from "../utils";

export const setOfficeSettingsTab = (
  selectedTab:
    | "automaticMessaging"
    | "manageBookingTimes"
    | "generalInformation"
    | "priorityBookingTimes"
    | "new-priority-tab"
    | "edit-priority-tab"
    | "profile"
    | "patients"
    | "users"
    | "account"
    | "myPlan"
    | "payments"
    | "support"
    | "policies"
) => {
  return {
    type: "SET_OFFICE_SETTINGS_TAB",
    selectedTab,
  };
};

export function setNewOfficeHours(newOfficeHours: any) {
  return {
    type: "SET_NEW_OFFICE_HOURS",
    newOfficeHours,
  };
}

export function updateNewOfficeHours(day: number, key: string, value: any) {
  return {
    type: "UPDATE_NEW_OFFICE_HOURS",
    day,
    key,
    value,
  };
}

function requestGetOfficeHoursByOffice() {
  return {
    type: "REQUEST_GET_OFFICE_HOURS_BY_OFFICE",
    isFetchingOfficeHours: true,
  };
}

function receiveGetOfficeHoursByOffice(officeHours: Array<any>) {
  return {
    type: "RECEIVE_GET_OFFICE_HOURS_BY_OFFICE",
    isFetchingOfficeHours: false,
    officeHours,
  };
}

function errorGetOfficeHoursByOffice() {
  return {
    type: "ERROR_GET_OFFICE_HOURS_BY_OFFICE",
    isFetchingOfficeHours: false,
  };
}

export function getOfficeHoursByOffice(officePk: number) {
  const token = store.get("id_token");
  let config: AxiosRequestConfig = {
    method: "GET",
    url: `${process.env.REACT_APP_DEV_API}/get-office-hours/${officePk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch: Dispatch<Action>) => {
    dispatch(requestGetOfficeHoursByOffice());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetOfficeHoursByOffice(data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(errorGetOfficeHoursByOffice());
      });
  };
}

export function submitOfficeHours(newOfficeHours: any) {
  const token = store.get("id_token");
  let config: AxiosRequestConfig = {
    method: "PATCH",
    url: `${process.env.REACT_APP_DEV_API}/update-office-hours/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: newOfficeHours,
  };

  return (dispatch: Dispatch<Action>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        launchToast("Office Hours Successfully Updated");
      })
      .catch((err) => {
        launchToast("Error Updating Office Hours");
        console.log(err);
      });
  };
}

export function updatePriorityOfficeHour(data: any, pk: number) {
  const token = store.get("id_token");
  let config: AxiosRequestConfig = {
    method: "PATCH",
    url: `${process.env.REACT_APP_DEV_API}/update-priority-hour/${pk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      date: moment(data.date).format("YYYY-MM-DD"),
      from_hour: moment(data.from_hour).format("hh:mm:ss"),
      to_hour: moment(data.to_hour).format("hh:mm:ss"),
      lunch_start: moment(data.lunch_start).format("hh:mm:ss"),
      lunch_end: moment(data.lunch_end).format("hh:mm:ss"),
      closed: data.closed,
      lunch: data.lunch,
    },
  };

  return (dispatch: Dispatch<Action>) => {
    return axios(config)
      .then((res) => res.data)
      .then(() => {
        launchToast("Successfully saved office hour");
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function requestGetPriorityOfficeHoursByOffice() {
  return {
    type: "REQUEST_GET_PRIORITY_OFFICE_HOURS_BY_OFFICE",
    isFetchingOfficeHours: true,
  };
}

function receiveGetPriorityOfficeHoursByOffice(
  priorityOfficeHours: Array<any>
) {
  return {
    type: "RECEIVE_GET_PRIORITY_OFFICE_HOURS_BY_OFFICE",
    isFetchingOfficeHours: false,
    priorityOfficeHours,
  };
}

function errorGetPriorityOfficeHoursByOffice() {
  return {
    type: "ERROR_GET_PRIORITY_OFFICE_HOURS_BY_OFFICE",
    isFetchingOfficeHours: false,
  };
}

export function getPriorityOfficeHoursByOffice(officePk: number) {
  const token = store.get("id_token");
  let config: AxiosRequestConfig = {
    method: "GET",
    url: `${
      process.env.REACT_APP_DEV_API
    }/get-priority-office-hours/${officePk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch: Dispatch<Action>) => {
    dispatch(requestGetPriorityOfficeHoursByOffice());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetPriorityOfficeHoursByOffice(data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(errorGetPriorityOfficeHoursByOffice());
      });
  };
}

function requestGetPriorityOfficeHour() {
  return {
    type: "REQUEST_GET_PRIORITY_OFFICE_HOUR",
    isFetchingOfficeHours: true,
  };
}

function receiveGetPriorityOfficeHour(priorityOfficeHour: any) {
  return {
    type: "RECEIVE_GET_PRIORITY_OFFICE_HOUR",
    isFetchingOfficeHours: false,
    priorityOfficeHour,
  };
}

function errorGetPriorityOfficeHour() {
  return {
    type: "ERROR_GET_PRIORITY_OFFICE_HOUR",
    isFetchingOfficeHours: false,
  };
}

export function selectPriorityOfficeHour(priorityOfficeHour: any) {
  return {
    type: "RECEIVE_GET_PRIORITY_OFFICE_HOUR",
    priorityOfficeHour,
  };
}

export function resetSelectPriorityOfficeHour() {
  return {
    type: "RESET_SELECT_PRIORITY_OFFICE_HOUR",
  };
}

function requestCreateNewPriorityOfficeHour() {
  return {
    type: "REQUEST_CREATE_NEW_PRIORITY_HOUR",
    isCreatingPriorityHour: true,
  };
}

function receiveCreateNewPriorityOfficeHour() {
  return {
    type: "RECEIVE_CREATE_NEW_PRIORITY_HOUR",
    isCreatingPriorityHour: false,
    hasCreatedPriorityHour: true,
  };
}

function errorCreateNewPriorityOfficeHour() {
  return {
    type: "ERROR_CREATE_NEW_PRIORITY_HOUR",
    isCreatingPriorityHour: false,
  };
}

export function resetCreateNewPriorityOfficeHour() {
  return {
    type: "RESET_CREATE_NEW_PRIORITY_OFFICE_HOUR",
    isCreatingPriorityHour: false,
    hasCreatedPriorityHour: false,
  };
}

export function createNewPriorityOfficeHour(officePk: number, data: any) {
  const token = store.get("id_token");
  let config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/create-priority-office-hour/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      office: officePk,
      ...data,
    },
  };

  return (dispatch: Dispatch<Action>) => {
    dispatch(requestCreateNewPriorityOfficeHour());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        launchToast("Successfully created Overwrite!");
        dispatch(receiveCreateNewPriorityOfficeHour());
      })
      .catch((err) => {
        dispatch(errorCreateNewPriorityOfficeHour());
        console.log(err);
      });
  };
}

function requestSettingsUpdateOffice() {
  return {
    type: "REQUEST_SETTINGS_UPDATE_OFFICE",
    isUpdatingOffice: true,
  };
}

function receiveSettingsUpdateOffice() {
  return {
    type: "RECEIVE_SETTINGS_UPDATE_OFFICE",
    isUpdatingOffice: false,
  };
}

function errorSettingsUpdateOffice() {
  return {
    type: "ERROR_SETTINGS_UPDATE_OFFICE",
    isUpdatingOffice: false,
  };
}

export function updateOffice(officePk: number, data: any) {
  const token = store.get("id_token");
  let config: AxiosRequestConfig = {
    method: "PATCH",
    url: `${process.env.REACT_APP_DEV_API}/office_update/${officePk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  return (dispatch: Dispatch<Action>) => {
    dispatch(requestSettingsUpdateOffice());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        launchToast(
          "Updated office successfully. To view changes, please refresh the page."
        );
        dispatch(receiveSettingsUpdateOffice());
      })
      .catch((err) => {
        dispatch(errorSettingsUpdateOffice());
      });
  };
}

function requestGetOfficeAdmins() {
  return {
    type: "REQUEST_GET_OFFICE_ADMINS",
    isFetchingOfficeAdmins: true,
  };
}

function receiveGetOfficeAdmins(officeAdmins: Array<Object>) {
  return {
    type: "RECEIVE_GET_OFFICE_ADMINS",
    isFetchingOfficeAdmins: false,
    officeAdmins,
  };
}

function errorGetOfficeAdmins() {
  return {
    type: "ERROR_GET_OFFICE_ADMINS",
    isFetchingOfficeAdmins: false,
  };
}

export function getOfficeAdmins(officePk: number) {
  const token = store.get("id_token");
  let config: AxiosRequestConfig = {
    method: "GET",
    url: `${process.env.REACT_APP_DEV_API}/office-admins/${officePk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch: Dispatch<Action>) => {
    dispatch(requestGetOfficeAdmins());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetOfficeAdmins(data));
      })
      .catch((err) => {
        dispatch(errorGetOfficeAdmins());
      });
  };
}

function requestDeletePriorityOfficeHour() {
  return {
    type: "REQUEST_DELETE_PRIORITY_OFFICE_HOUR",
    isDeletingPriorityOfficeHour: true,
  };
}
function receiveDeletePriorityOfficeHour() {
  return {
    type: "RECEIVE_DELETE_PRIORITY_OFFICE_HOUR",
    isDeletingPriorityOfficeHour: false,
    hasDeletedPriorityOfficeHour: true,
  };
}

function errorDeletePriorityOfficeHour() {
  return {
    type: "ERROR_DELETE_PRIORITY_OFFICE_HOUR",
    isDeletingPriorityOfficeHour: false,
    hasDeletedPriorityOfficeHour: false,
  };
}

export function resetDeletePriorityOfficeHour() {
  return {
    type: "RESET_DELETE_PRIORITY_OFFICE_HOUR",
    isDeletingPriorityOfficeHour: false,
    hasDeletedPriorityOfficeHour: false,
  };
}

export function deletePriorityOfficeHour(priorityOfficeHour: number) {
  const token = store.get("id_token");
  let config: AxiosRequestConfig = {
    method: "DELETE",
    url: `${
      process.env.REACT_APP_DEV_API
    }/delete-priority-office-hour/${priorityOfficeHour}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch: Dispatch<Action>) => {
    dispatch(requestDeletePriorityOfficeHour());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveDeletePriorityOfficeHour());
      })
      .catch((err) => {
        dispatch(errorDeletePriorityOfficeHour());
      });
  };
}

function requestInviteUsersToOffice() {
  return {
    type: "REQUEST_INVITE_USERS_TO_OFFICE",
    isFetchingInvites: true,
  };
}

function receiveInviteUsersToOffice() {
  return {
    type: "RECEIVE_INVITE_USERS_TO_OFFICE",
    isFetchingInvites: false,
    hasSentInvite: true,
    inviteErrorMessage: "",
  };
}

//Adding an Error Action when Inviting a admin fails
function errorInviteUsersToOffice() {
  return {
    type: "ERROR_INVITE_USERS_TO_OFFICE",
    hasSentInvite: false,
    inviteErrorMessage:
      " ERROR: Email address of new admin is already registered as a patient.",
  };
}

export function inviteUsersToOffice(
  admins: any,
  officePk: number,
  profilePk: number
) {
  const token = store.get("id_token");

  let config: AxiosRequestConfig = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/create_office_request/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      admins,
      officePk,
      profilePk,
    },
  };

  return (dispatch: Dispatch<any>) => {
    dispatch(requestInviteUsersToOffice());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        launchToast("Successfully invited new admins.");
        dispatch(receiveInviteUsersToOffice());
      })
      .catch((err) => {
        dispatch(errorInviteUsersToOffice());
      });
  };
}

export function removeAdminFromOffice(adminEmail: string, officePk: number) {
  const token = store.get("id_token");

  let config: AxiosRequestConfig = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/remove-from-office/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      adminEmail,
      officePk,
    },
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => {
        launchToast("Successfully removed admin from office");
      })
      .catch((err) => {
        launchToast("There was an error removing admin from office");
      });
  };
}
