import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Close from "../../assets/images/close.png";
import { addUserToOffice } from "../../actions/adminOfficeActions";
import { BounceLoader } from "react-spinners";

function AddMember(props) {
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  const isSendingOfficeRequest = useSelector(
    (state) => state.adminOfficeInteractions.isSendingOfficeRequest
  );
  const officeRequestMessage = useSelector(
    (state) => state.adminOfficeInteractions.officeRequestMessage
  );
  return (
    <div id="create-new-office" class="create-new-office-popup">
      <div class="form-main create-office-form big-popup">
        <div class="forgot-pass-form common-form box-with-shadow">
          <div class="sub-heading">
            <h3>Invite User to Office</h3>
            <div class="close-box">
              <a
                href="#"
                title=""
                class="fancybox-button fancybox-close-small"
                data-fancybox-close=""
              >
                <img src={Close} alt="" />
              </a>
            </div>
          </div>
          <div class="inner-forgot-pass">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                dispatch(
                  addUserToOffice(email, props.profile.pk, props.office)
                );
                setEmail("");
              }}
            >
              <div class="office-name" style={{ marginTop: "20px" }}>
                <label>Email:</label>
                <input
                  type="text"
                  placeholder=""
                  class="common-input"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>

              <p>{officeRequestMessage ? officeRequestMessage : ""}</p>
              <div class="login-submit">
                {!isSendingOfficeRequest ? (
                  <button className="sky-btn common-btn">Send Invite</button>
                ) : (
                  <BounceLoader color={"#052D64"} size={35} />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddMember;
