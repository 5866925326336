import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { createStore, applyMiddleware } from "redux";
import allReducer from "./reducers";
import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";
import * as Sentry from "@sentry/browser";

const createStoreWithMiddleware = applyMiddleware(thunkMiddleware)(createStore);

Sentry.init({
  dsn:
    "https://d670ce5ae28b436fa125ea26eead6edc@o293182.ingest.sentry.io/5197361",
});
const store = createStoreWithMiddleware(
  allReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// const whyDidYouRender = require("@welldone-software/why-did-you-render");
// whyDidYouRender(React, {
//   trackAllPureComponents: true,
//   trackExtraHooks: [[require("react-redux"), "useSelector"]],
// });

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.unregister();
