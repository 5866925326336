import React, { useEffect } from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { BounceLoader } from "react-spinners";
import { useHistory } from "react-router-dom";
import {
  resetSubmitQuestionnaire,
  setNewAppointmentTab,
  submitQuestionnaire,
  validateSubmission,
} from "../../../actions/newAppointmentActions";
import newAppointmentReducer from "../../../reducers/newAppointmentReducer";

export const NewAppointmentSidebar = (props: any) => {
  const dispatch = useDispatch();
  const newAppointmentTab = useSelector(
    (state: RootStateOrAny) => state.newAppointmentReducer.newAppointmentTab
  );
  const dependentsNumber = useSelector(
    (state: RootStateOrAny) => state.newAppointmentReducer.dependentsNumber
  );

  const newAppointmentTime = useSelector(
    (state: RootStateOrAny) => state.newAppointmentReducer.newAppointmentTime
  );
  const newAppointmentDate = useSelector(
    (state: RootStateOrAny) => state.newAppointmentReducer.newAppointmentDate
  );
  const isSubmittingQuestionnaire = useSelector(
    (state: RootStateOrAny) =>
      state.newAppointmentReducer.isSubmittingQuestionnaire
  );
  const newUser = useSelector(
    (state: RootStateOrAny) => state.appointmentReducer.newUser
  );

  const patientForms = useSelector(
    (state: RootStateOrAny) => state.newAppointmentReducer.patientForms
  );
  const office = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.office
  );
  const history = useHistory();
  const dependentsOnly = useSelector(
    (state: RootStateOrAny) => state.newAppointmentReducer.dependentsOnly
  );
  const hasReceivedQuestionnaire = useSelector(
    (state: RootStateOrAny) =>
      state.newAppointmentReducer.hasReceivedQuestionnaire
  );

  useEffect(() => {
    if (hasReceivedQuestionnaire) {
      const delaySearch = setTimeout(() => {
        history.push(`/office/${office.slug}/appointments/`);
      }, 1500);
      return () => {
        clearTimeout(delaySearch);
        dispatch(resetSubmitQuestionnaire());
      };
    }
  }, [dispatch, hasReceivedQuestionnaire, history, office.slug]);

  return (
    <div className="sidebar-with-dropdown w-100">
      <div className="white-box">
        <div className="app-settings-main">
          <h4>Patient details</h4>
          <ul>
            <li className={newAppointmentTab === "detail" ? "active" : ""}>
              <a
                href="#details"
                title="Appointment Details"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setNewAppointmentTab("detail"));
                }}
              >
                Appointment Details
              </a>
            </li>
            <li>
              <a
                href="#admin"
                title="Patient (Admin)"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setNewAppointmentTab("admin"));
                }}
              >
                Patient (Admin)
              </a>
            </li>
            {dependentsNumber != 0 &&
              Array.from({ length: dependentsNumber }, (x, i) => i + 1).map(
                (number) => (
                  <li>
                    <a
                      href="#"
                      title="Patient (Admin)"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(setNewAppointmentTab(number));
                      }}
                    >
                      Dependent {number}
                    </a>
                  </li>
                )
              )}
          </ul>
        </div>
      </div>

      <div className="sidebar-dropdownbox">
        {!isSubmittingQuestionnaire ? (
          <a
            href="#submit"
            className="btn btn-primary btn-lg text-capitalize"
            onClick={(e) => {
              e.preventDefault();
              dispatch(
                validateSubmission(
                  {
                    newAppointmentDate,
                    newAppointmentTime,
                    dependentsOnly,
                    newUser,
                  },
                  office.pk,
                  patientForms
                )
              );
            }}
          >
            {" "}
            Book appointment{" "}
          </a>
        ) : (
          <BounceLoader />
        )}
        <div className="dropdown-menu"></div>
      </div>
    </div>
  );
};
