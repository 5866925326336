import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  getMessageThreads,
  getMessageThread,
  addMessageToThread,
  searchThreads,
  resetSearch,
  receiveGetMessageThreads,
  resetSelectedThread,
  getSearchedThreads,
} from "../../actions/messagingActions";
import FlipMove from "react-flip-move";
import Thread from "./Thread";

function Threads(props) {
  const messageThreads = useSelector(
    (state) => state.messagingReducer.messageThreads
  );
  const searchedMessageThreads = useSelector(
    (state) => state.messagingReducer.searchedMessageThreads
  );

  const [modalIsOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const office = useSelector((state) => state.adminOfficeInteractions.office);
  const profile = useSelector((state) => state.authenticateUser.profile);
  const socket = useSelector((state) => state.adminOfficeInteractions.socket);
  const [showArchive, setShowArchive] = useState(false);

  const doSearch = (evt, officePk) => {
    if (timeout) clearTimeout(timeout)
    let timeout = setTimeout(() => {
      dispatch(getSearchedThreads(evt, officePk));
    }, 3000)
  }

  return (
    <div class="col-3">
      <div class="eql-height">
        <div class="white-box inbox-info-left">
          <div class="inbox-archive">
            <ul>
              <li class="archive-main" id="archive1">
                <a
                  href="#"
                  title=""
                  class="archives-inbox conversation"
                  onClick={(e) => {
                    e.preventDefault();
                    if (showArchive === true) {
                      setShowArchive(false);
                    } else {
                      setShowArchive(true);
                    }
                  }}
                ></a>
                <div
                  class="archive-show"
                  style={{ display: `${showArchive ? "block" : "none"}` }}
                >
                  <ul>
                    <li>
                      <a
                        href="#"
                        title="View Archive"
                        onClick={(e) => {
                          e.preventDefault();
                          props.setThreadToggle("archived");
                        }}
                      >
                        View Archive
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                {!props.newThread ? (
                  <a
                    href=""
                    title=""
                    class="inbox-icon"
                    onClick={(e) => {
                      e.preventDefault();
                      props.setThread(null);
                      props.setNewThread(true);
                    }}
                  ></a>
                ) : (
                  ""
                )}
              </li>
            </ul>
          </div>
          <form
            action="#"
            class="search-form"
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              placeholder=" Search…"
              class="form-control"
              name="s"
              type="search"
              style={{ paddingTop: "15px" }}
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value !== "") {
                  doSearch(e.target.value, office.pk)
                } else {
                  dispatch(resetSearch());
                }
              }}
            />
            <input type="submit" class="fa fa-search" value="" />
          </form>
          <div class="inbox-msgs">
            <div class="new-msg-info-main d-flex flex-wrap">
              <div class="custom-scrl-content mCustomScrollbar">
                {/* <div class="inbox-archive-link">
                        <a href="#" title="Archive" class="inbox-archv">
                          Archive
                        </a>
                      </div> */}
                {searchedMessageThreads.length == 0 ? (
                  messageThreads.length > 0 ? (
                      messageThreads.map((thread) => (
                        <Thread
                          key={thread.pk}
                          thread={thread}
                          socket={socket}
                          office={office}
                          dispatch={dispatch}
                          setThread={props.setThread}
                          setNewThread={props.setNewThread}
                          selectedThread={props.thread}
                          keyId={thread.pk}
                        />
                      ))
                  ) : (
                    ""
                  )
                ) : (
                  
                    searchedMessageThreads.map((thread) => (
                      <Thread
                        key={thread.pk}
                        thread={thread}
                        socket={socket}
                        office={office}
                        dispatch={dispatch}
                        setThread={props.setThread}
                        setNewThread={props.setNewThread}
                        selectedThread={props.thread}
                      />
                    ))
                  
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Threads;
