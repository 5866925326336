import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Video from "twilio-video";
import {
  setVideoRoom,
  disconnectFromRoom,
  setVideoParticipants,
  removeVideoParticipants,
  resetVideoRoomName,
  leaveActiveCall,
  resetChannelName,
  clearVideoParticipants,
  resetRoom,
  changeVideoInput,
  changeAudioInput,
  setLocalParticipant,
  connectToCall,
  resetLocalParticipant,
  setLocalVideoTrack,
  setLocalAudioTrack,
} from "../../actions/waitingRoomActions";
import Participant from "./chat/Participant";
import launchToast from "../../utils";
import Mic from "../../assets/images/Mic.png";
import Vid from "../../assets/images/Video.png";
import Expand from "../../assets/images/expand.png";
import Toggle from "../../assets/images/toggle.png";
import Fullscreen from "react-full-screen";
import EndCall from "../../assets/images/endcall.png";
import LocalParticipant from "./LocalParticipant";
import Modal from "react-modal";
import {
  attachTracks,
  detachTracks,
  stopTracks,
} from "../../utils/trackFunctions";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function VideoCall(props) {
  // const [videoRoom, setVideoRoom] = useState(null);

  const dispatch = useDispatch();
  const participants = useSelector((state) => state.waitingRoom.participants);
  const chatRoom = useSelector((state) => state.waitingRoom.channelName);
  const videoChannelName = useSelector(
    (state) => state.waitingRoom.videoChannelName
  );
  const videoToken = useSelector((state) => state.waitingRoom.videoToken);
  const room = useSelector((state) => state.waitingRoom.room);
  const socket = useSelector((state) => state.adminOfficeInteractions.socket);
  const office = useSelector((state) => state.adminOfficeInteractions.office);
  const profile = useSelector((state) => state.authenticateUser.profile);

  const localVideoTrack = useSelector(
    (state) => state.waitingRoom.localVideoTrack
  );
  const localAudioTrack = useSelector(
    (state) => state.waitingRoom.localAudioTrack
  );
  const localParticipant = useSelector(
    (state) => state.waitingRoom.localParticipant
  );
  const videoDevices = useSelector((state) => state.waitingRoom.videoDevices);
  const videoDevice = useSelector((state) => state.waitingRoom.videoDevice);
  const audioDevices = useSelector((state) => state.waitingRoom.audioDevices);
  const audioDevice = useSelector((state) => state.waitingRoom.audioDevice);
  const [isOpen, setIsOpen] = useState(false);
  const [isMute, setIsMute] = useState(false);
  const [isVideoMute, setIsVideoMute] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    if (room) {
      const participantConnected = (participant) => {
        console.log(`participant: ${participant}`);
        dispatch(setVideoParticipants(participant));
        // participant.on("trackSubscriptionFailed", (error, localTrack) => {
        //   console.log("Failed to publish LocalTrack %s:", localTrack.id);
        //   console.log(error.message);
        //   // => Failed to publish LocalTrack XXX:
        //   // => No supported codec
        // });
      };
      const participantDisconnected = (participant) => {
        // dispatch(disconnectFromRoom(room));
        dispatch(removeVideoParticipants(participant));
        // dispatch(leaveActiveCall());
        // dispatch(clearVideoParticipants());
        // dispatch(resetVideoRoomName());
        // dispatch(resetChannelName());
        // socket.send(
        //   JSON.stringify({
        //     type: "ADMIN_LEAVE_CALL",
        //     admin: profile.pk,
        //     office: office.slug,
        //     is_patient: false,
        //   })
        // );
      };
      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.on("participantReconnecting", (remoteParticipant) => {
        launchToast("Participant is attempting to reconnect.");
        /* Update the RemoteParticipant UI here */
      });
      room.on("disconnected", (room) => {
        dispatch(disconnectFromRoom(room));
        dispatch(leaveActiveCall());
        dispatch(clearVideoParticipants());
        dispatch(resetVideoRoomName());
        dispatch(resetChannelName());
        dispatch(resetLocalParticipant());
        socket.send(
          JSON.stringify({
            type: "ADMIN_LEAVE_CALL",
            admin: profile.pk,
            office: office.slug,
            is_patient: false,
          })
        );
      });

      room.participants.forEach(participantConnected);

      return () => {
        dispatch(setLocalVideoTrack(null));
        dispatch(setLocalAudioTrack(null));
        dispatch(resetLocalParticipant());
      };
    }
  }, [room]);
  // useEffect(() => {
  //   return () => {
  //     dispatch(resetRoom());
  //     dispatch(leaveActiveCall());
  //     dispatch(clearVideoParticipants());
  //     dispatch(resetVideoRoomName());
  //     dispatch(resetChannelName());
  //     detachTracks([localVideoTrack, localAudioTrack]);
  //     stopTracks([localVideoTrack, localAudioTrack]);
  //     socket.send(
  //       JSON.stringify({
  //         type: "ADMIN_LEAVE_CALL",
  //         admin: profile.pk,
  //         office: office.slug,
  //         is_patient: false,
  //       })
  //     );
  //   };
  // }, []);
  useEffect(() => {
    if (videoToken && videoChannelName) {
      console.log("video token and videochannelname");
      dispatch(
        connectToCall(
          localVideoTrack,
          localAudioTrack,
          profile,
          office,
          socket,
          videoChannelName,
          videoToken
        )
      );
    }
  }, [videoToken, videoChannelName]);

  const videoRef = useRef();

  const remoteParticipants = participants.map((participant) => (
    <Participant
      key={participant.sid}
      participant={participant}
      isRemote={true}
    />
  ));

  const muteMicrophone = (room, mute) => {
    if (!mute) {
      room.localParticipant.audioTracks.forEach((publication) => {
        publication.track.disable();
      });
      setIsMute(true);
    } else {
      room.localParticipant.audioTracks.forEach((publication) => {
        publication.track.enable();
      });
      setIsMute(false);
    }
  };

  const muteVideo = (room, mute) => {
    if (!mute) {
      room.localParticipant.videoTracks.forEach((publication) => {
        publication.track.disable();
      });
      setIsVideoMute(true);
    } else {
      room.localParticipant.videoTracks.forEach((publication) => {
        publication.track.enable();
      });
      setIsVideoMute(false);
    }
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        style={customStyles}
        onRequestClose={() => setIsOpen(false)}
      >
        <div class="login-form forgot-pass-form common-form">
          <div class="sub-heading">
            <h3>Video Settings</h3>
            <div class="close-box">
              <a
                href="#"
                title=""
                class="fancybox-button fancybox-close-small"
                data-fancybox-close=""
              >
                <img src="images/close.png" alt="" />
              </a>
            </div>
          </div>
          <div class="inner-forgot-pass inner-popup-content">
            <div class="video-setting">
              <div class="source-main">
                <div class="source left-s">
                  <h4>Video Source</h4>
                  <select
                    defaultValue={videoDevice}
                    onChange={(e) => {
                      dispatch(
                        changeVideoInput(
                          e.target.value,
                          localVideoTrack,
                          localParticipant
                        )
                      );
                    }}
                  >
                    {videoDevices
                      ? videoDevices.map((device) => (
                          <option value={device.deviceId}>
                            {device.label}
                          </option>
                        ))
                      : ""}
                  </select>
                  <div class="source right-s">
                    <h4>Audio Source</h4>
                    <select
                      defaultValue={audioDevice}
                      onChange={(e) => {
                        dispatch(
                          changeAudioInput(
                            e.target.value,
                            localAudioTrack,
                            localParticipant
                          )
                        );
                      }}
                    >
                      {audioDevices
                        ? audioDevices.map((device) => (
                            <option value={device.deviceId}>
                              {device.label}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div class="waiting-title">
        <h3>Video Call</h3>
      </div>
      {/* New Video Element Start */}
      <Fullscreen
        enabled={isFullscreen}
        onChange={(isFull) => setIsFullscreen(isFull)}
      >
        <div
          className={`video-call-element ${
            isFullscreen ? "full-height-call" : ""
          }`}
          id="video-call-element"
        >
          <div className="remote-participant">{remoteParticipants}</div>
          <div className="local-participant">
            {room ? (
              <div>
                {/* {console.log(room.localParticipant.)} */}
                <LocalParticipant
                  key={room.localParticipant.sid}
                  participant={room.localParticipant}
                  isLocal={true}
                  isRemote={false}
                />
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(disconnectFromRoom(room));
                    dispatch(leaveActiveCall());
                    dispatch(clearVideoParticipants());
                    dispatch(resetVideoRoomName());
                    dispatch(resetChannelName());
                    socket.send(
                      JSON.stringify({
                        type: "ADMIN_LEAVE_CALL",
                        admin: profile.pk,
                        office: office.slug,
                        is_patient: false,
                      })
                    );
                  }}
                >
                  <span>End Call</span>
                </a>
              </div>
            ) : (
              "LOADING"
            )}
          </div>
          <div className="video-area"></div>
          <div className="bottom-area">
            <div className="absolute-center">
              <ul>
                <li>
                  {!isMute ? (
                    <div
                      className="circle"
                      onClick={() => muteMicrophone(room, isMute)}
                    >
                      <img src={Mic} alt="" />
                    </div>
                  ) : (
                    <div
                      className="circle red"
                      onClick={() => muteMicrophone(room, isMute)}
                    >
                      <img src={Mic} alt="" />
                    </div>
                  )}
                </li>
                <li>
                  {!isVideoMute ? (
                    <div
                      className="circle"
                      onClick={() => muteVideo(room, isVideoMute)}
                    >
                      <img src={Vid} alt="" style={{ marginTop: "12px" }} />
                    </div>
                  ) : (
                    <div
                      className="circle red"
                      onClick={() => muteVideo(room, isVideoMute)}
                    >
                      <img src={Vid} alt="" style={{ marginTop: "12px" }} />
                    </div>
                  )}
                </li>
                <li>
                  <div
                    className="circle red"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(disconnectFromRoom(room));
                      dispatch(leaveActiveCall());
                      dispatch(clearVideoParticipants());
                      dispatch(resetVideoRoomName());
                      dispatch(resetChannelName());
                      dispatch(resetLocalParticipant());
                      socket.send(
                        JSON.stringify({
                          type: "ADMIN_LEAVE_CALL",
                          admin: profile.pk,
                          office: office.slug,
                          is_patient: false,
                        })
                      );
                    }}
                  >
                    <img src={EndCall} alt="" style={{ width: "70%" }} />
                  </div>
                </li>
              </ul>
              <ul className="expand-list">
                <li>
                  <div
                    className="circle"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    <img src={Toggle} alt="" />
                  </div>
                </li>
                <li>
                  {!isFullscreen ? (
                    <div
                      className="circle"
                      onClick={() => {
                        setIsFullscreen(true);
                      }}
                    >
                      <img src={Expand} alt="" />
                    </div>
                  ) : (
                    <div
                      className="circle red"
                      onClick={() => {
                        setIsFullscreen(false);
                      }}
                    >
                      <img src={Expand} alt="" />
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Fullscreen>

      {/* New Video Element End */}
      <div class="videcall-img" id="video-area">
        {/* <video autoPlay={true} ref={videoRef}></video> */}
        {/* <button onClick={() => videoRoom.disconnect()}>
          DISCONNECT FROM CALL NOW
        </button> */}
      </div>
    </div>
  );
}

export default VideoCall;
