import React, { useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import {
  getInternalMessages,
  resetThread,
  addNewMessageToChat,
} from "../../../actions/internalChatActions";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { AdminSingleMessage } from "./AdminSingleMessage";
import { InternalMessageForm } from "./InternalMessageForm";
import { SocketIndicator } from "../../office/socketIndicator";
// @ts-ignore
import ScrollToBottom from "react-scroll-to-bottom";

export const AdminMessageChat: React.FC<any> = (props: any) => {
  const dispatch = useDispatch();
  const threadMessages = useSelector(
    (state: RootStateOrAny) => state.internalChatReducer.threadMessages
  );
  const isFetchingAdminThreadMessages = useSelector(
    (state: RootStateOrAny) =>
      state.internalChatReducer.isFetchingAdminThreadMessages
  );
  const adminThread = useSelector(
    (state: RootStateOrAny) => state.internalChatReducer.adminThread
  );
  const profile = useSelector(
    (state: RootStateOrAny) => state.authenticateUser.profile
  );

  const socket = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.socket
  );

  function listenMessage(e: any) {
    const response_data = JSON.parse(e.data);
    if (response_data.type === "receive_new_message") {
      dispatch(addNewMessageToChat(JSON.parse(response_data.threads)));
    }
  }

  useEffect(() => {
    if (adminThread) {
      dispatch(getInternalMessages(adminThread.pk));
    }
  }, [adminThread]);

  useEffect(() => {
    if (adminThread) {
      socket.addEventListener("message", listenMessage);
      return () => {
        socket.removeEventListener("message", listenMessage);
      };
    }
  }, [adminThread]);

  return (
    <div className="inbox-info-right">
      <div className="inbox-right-head">
        <div className="inbox-hd">
          <h4>{adminThread.subject}</h4>
        </div>
      </div>
      <div className="chat-days-info-main">
        <div className="msg-datetime">
          {moment(props.timestamp).format("LLL")}
        </div>
        <div
          className="custom-scrl-content mCustomScrollbar"
          style={{ overflow: "hidden" }}
        >
          <ScrollToBottom className="chat-content chat-with-selected-msg internal-messaging">
            {isFetchingAdminThreadMessages && threadMessages.length === 0 ? (
              <SkeletonTheme color="#F8F8FD" highlightColor="#9CA9E2">
                <Skeleton
                  count={5}
                  height={33}
                  duration={4}
                  style={{ marginTop: "6px" }}
                />
              </SkeletonTheme>
            ) : threadMessages.length !== 0 ? (
              threadMessages.map((threadMessage: any) => (
                <AdminSingleMessage
                  message={threadMessage.message}
                  timestamp={threadMessage.datetime}
                  adminProfile={threadMessage.admin_name}
                  sentByUser={threadMessage.sent_by === profile.pk}
                />
              ))
            ) : (
              "No Messages"
            )}
          </ScrollToBottom>
        </div>
      </div>
      <InternalMessageForm adminThread={adminThread.pk} />
    </div>
  );
};
