import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  retrieveOfficeInvite,
  updateUserAndAcceptInvite,
} from "../actions/authActions";
import { BounceLoader } from "react-spinners";

function AcceptInvite() {
  const dispatch = useDispatch();
  const officeInvite = useSelector(
    (state) => state.authenticateUser.officeInvite
  );
  const isFetchingOfficeInvite = useSelector(
    (state) => state.authenticateUser.isFetchingOfficeInvite
  );
  const isSigningUpUser = useSelector(
    (state) => state.authenticateUser.isSigningUpUser
  );
  const signUpMessage = useSelector(
    (state) => state.authenticateUser.signUpMessage
  );
  const hasSignedUpUser = useSelector(
    (state) => state.authenticateUser.hasSignedUpUser
  );

  useEffect(() => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const token = url.searchParams.get("invite");
    dispatch(retrieveOfficeInvite(token));
  }, []);

  const firstName = useRef();
  const lastName = useRef();
  const password1 = useRef();
  const password2 = useRef();

  return (
    <div class="login-form-main full-height">
      <div class="form-bg">
        <div class="vertical-middle form-main">
          <div class="form-logo-main"></div>
          <div class="register-form common-form">
            <div class="form-head">
              <h2>Registering New Account</h2>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const url_string = window.location.href;
                const url = new URL(url_string);
                const token = url.searchParams.get("invite");
                if (password1.current.value !== password2.current.value) {
                  return;
                } else {
                  dispatch(
                    updateUserAndAcceptInvite(token, {
                      firstName: firstName.current.value,
                      lastName: lastName.current.value,
                      password: password2.current.value,
                      pk: officeInvite.received_by,
                    })
                  );
                }
              }}
            >
              <div class="office-invited">
                <p>
                  <label>Office you’ve been invited to:</label>
                </p>

                <p>
                  {!isFetchingOfficeInvite ? (
                    officeInvite ? (
                      <input
                        type="text"
                        placeholder={officeInvite.office.name}
                        class="common-input"
                        readOnly
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <div class="passwords-main">
                <p>
                  <input
                    type="text"
                    placeholder="First Name"
                    class="common-input"
                    ref={firstName}
                  />
                </p>
                <p>
                  <input
                    type="text"
                    placeholder="Last Name"
                    class="common-input"
                    ref={lastName}
                  />
                </p>
                <p>
                  <input
                    type="password"
                    placeholder="Password"
                    class="common-input"
                    ref={password1}
                  />
                </p>
                <p>
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    class="common-input"
                    ref={password2}
                  />
                </p>
                <p>{signUpMessage ? signUpMessage : ""}</p>

                <div class="login-submit">
                  {!isSigningUpUser && !hasSignedUpUser ? (
                    <input
                      type="submit"
                      value="Create Account"
                      class="blue-btn common-btn"
                    />
                  ) : hasSignedUpUser ? (
                    <Link to="/">
                      <button className="blue-btn common-btn">Login</button>
                    </Link>
                  ) : (
                    <BounceLoader color={"#052D64"} size={30} />
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcceptInvite;
