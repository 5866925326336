import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/images/coriganlogo.svg";
import Menu from "../assets/images/menu.svg";
import { changeMenu } from "../actions/adminOfficeActions";
import { SocketIndicator } from "./office/socketIndicator";
function LeftBar(props) {
  const videoToken = useSelector((state) => state.waitingRoom.videoToken);
  const location = useLocation();
  const dispatch = useDispatch();
  const menuSetting = useSelector(
    (state) => state.adminOfficeInteractions.menuSetting
  );

  const [openInbox, setOpenInbox] = useState(false);
  return (
    <div>
      {!props.isAuthenticated ? (
        <div
          className={`leftbar ${menuSetting === "close" ? "menu-open" : ""}`}
        >
          <a href="index.html" title="" class="logo">
            <img src={Logo} alt="" style={{ width: "100%" }} />
          </a>
        </div>
      ) : (
        <div
          className={`leftbar ${menuSetting === "close" ? "menu-open" : ""}`}
        >
          <a
            href="index.html"
            title=""
            class="logo"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <img
              src={Logo}
              alt=""
              style={{ width: "100%", maxWidth: "150px", height: "30px" }}
            />
          </a>
          <span
            class="menu-arrow"
            onClick={(e) => {
              e.preventDefault();
              if (menuSetting === "open") {
                dispatch(changeMenu("close"));
              } else {
                dispatch(changeMenu("open"));
              }
            }}
          >
            <img src={Menu} alt="" />
          </span>
          <div class="left-menu">
            <ul>
              <li>
                <Link
                  to={`/office/${props.currentOffice}/dashboard`}
                  className={`patients dashboard ${
                    location.pathname.includes("dashboard")
                      ? "hover-active"
                      : ""
                  }`}
                  title="dashboard"
                >
                  <span>
                    <b>Dashboard</b>
                  </span>
                </Link>
              </li>
              <li>
                {!videoToken ? (
                  <Link
                    to={`/office/${props.currentOffice}/patient-list`}
                    className={`patients ${
                      location.pathname.includes("patient")
                        ? "hover-active"
                        : ""
                    }`}
                    title="patients"
                  >
                    <span>
                      <b>Patients</b>
                    </span>
                  </Link>
                ) : (
                  <a
                    href="#"
                    className={`patient ${
                      location.pathname.includes("patients")
                        ? "hover-active"
                        : ""
                    }`}
                    title="patients"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <span>
                      <b>Patients</b>
                    </span>
                  </a>
                )}
              </li>
              <li
                className={`${
                  location.pathname.includes("patients") ? "active" : ""
                } inbox-menu-main`}
              >
                <Link
                  to={`/office/${props.currentOffice}/inbox`}
                  className={`inbox active ${
                    location.pathname.includes("inbox") ? "hover-active" : ""
                  }`}
                  title="Inbox"
                >
                  <span>
                    <b>Inbox</b>
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to={`/office/${props.currentOffice}/appointments/`}
                  className="appointments active"
                >
                  <span>
                    <b>Appointments</b>
                  </span>
                </Link>
              </li>
              {/* <li>
                <Link
                  to={`/office/${props.currentOffice}/bulletin`}
                  className="bulletin active"
                >
                  <span>
                    <b>Bulletin</b>
                  </span>
                </Link>
              </li> */}
            </ul>
          </div>

          <div class="waiting-room">
            <Link
              to={`/office/${props.currentOffice}/meeting-room`}
              class={`meeting-link ${
                location.pathname.includes("meeting-room") ? "active" : ""
              }`}
              title="Meeting Room"
            >
              <span>
                <b>Meeting Room</b>
              </span>
            </Link>
            <Link
              to={`/office/${props.currentOffice}/waiting-room`}
              class={`waiting-room ${
                location.pathname.includes("waiting") ? "active" : ""
              }`}
              title="Waiting Room"
            >
              <span>
                <b>Dr. Waiting Room</b>
              </span>
            </Link>
          </div>
          <div class="past-appointments">
            {!videoToken ? (
              <Link
                to={`/office/${props.currentOffice}/past-appointments`}
                class="past-app"
                title="Past Appointments"
              >
                <span>
                  <b>Past Appointments</b>
                </span>
              </Link>
            ) : (
              <a
                href="#"
                class="past-app"
                title="Past Appointments"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <span>
                  <b>Past Appointments</b>
                </span>
              </a>
            )}
          </div>

          <div className="socket-indicator-container">
            <SocketIndicator />
          </div>
          {/* <div class="feedback">
            <a
              href="https://form.asana.com?hash=86d6ed1e6113b58804bcb6aec4d6907d38c3b71fe82b6bddf622c04b073ee4f8&id=1178579960206885"
              target="_blank"
            >
              <span>Leave Feedback</span>
            </a>
          </div> */}
        </div>
      )}
    </div>
  );
}

// onClick={() => dispatch(requestLogin())}

export default LeftBar;
