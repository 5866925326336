import launchToast from "../utils";
const initialState = {
  hasEnabledPermissions: false,
  devices: [],
  videoDevices: [],
  audioDevices: [],
  localVideoTrack: null,
  localAudioTrack: null,
  users: [],
  isFetchingPatient: false,
  selectedUser: null,
  isCreatingClient: false,
  client: null,
  fetchingChatRoom: false,
  chatRoom: null,
  isFetchingVideoToken: false,
  videoToken: null,
  activeCall: null,
  activeAdmin: null,
  room: null,
  chatRoom: null,
  isJoiningChannel: false,
  chatChannel: null,
  joinChannelErrorMessage: "",
  messages: [],
  isFetchingChannelName: false,
  channelName: null,
  isFetchingTriageForm: false,
  triageForm: null,
  activeCalls: [],
  allActiveCalls: [],
  participants: [],
  isFetchingVideoName: false,
  videoChannelName: null,
  // Triage
  isUpdatingTriageNote: true,
  updateTriageNoteMessage: "",
  videoTracks: [],
  audioTracks: [],
  fetchingTriageQuestions: false,
  triageQuestions: null,
  joiningPatient: null,
  hasCreatedClient: false,
  waitingRoomThread: null,
  isFetchingWaitingRoomThread: false,
  theadErr: "",
  unreadThreads: [],
  rightSetting: "triage",
};

const waitingRoom = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_TRACKS":
      return Object.assign({}, state, {
        localVideoTrack: null,
        localAudioTrack: null,
        localParticipant: null,
      });
    case "SET_DEVICES":
      return Object.assign({}, state, {
        devices: action.devices,
      });
    case "SET_LOCAL_PARTICIPANT":
      return Object.assign({}, state, {
        localParticipant: action.localParticipant,
      });
    case "RESET_LOCAL_PARTICIPANT":
      return Object.assign({}, state, {
        localParticipant: null,
      });
    case "SET_VIDEO_DEVICES":
      return Object.assign({}, state, {
        videoDevices: action.devices,
      });
    case "SET_AUDIO_DEVICES":
      return Object.assign({}, state, {
        audioDevices: action.devices,
      });
    case "SET_LOCAL_VIDEO_TRACK":
      return Object.assign({}, state, {
        localVideoTrack: action.localVideoTrack,
      });
    case "SET_LOCAL_AUDIO_TRACK":
      return Object.assign({}, state, {
        localAudioTrack: action.localAudioTrack,
      });
    case "SET_ENABLED_PERMISSIONS":
      return Object.assign({}, state, {
        hasEnabledPermissions: action.enabledPermissions,
      });
    case "SET_WAITING_PATIENTS":
      return Object.assign({}, state, {
        users: action.users,
      });
    case "RECEIVE_GET_ALL_UNREAD_MESSAGES":
      return Object.assign({}, state, {
        unreadThreads: action.unreadThreads.map((thread) => thread.patient__pk),
      });
    case "REQUEST_GET_WAITING_PATIENT":
      return Object.assign({}, state, {
        isFetchingPatient: true,
      });
    case "RECEIVE_GET_WAITING_PATIENT":
      return Object.assign({}, state, {
        isFetchingPatient: false,
        selectedUser: action.selectedUser,
      });
    case "RESET_SELECTED_USER":
      return Object.assign({}, state, {
        selectedUser: null,
      });
    case "REQUEST_CREATE_TWILIO_CLIENT":
      return Object.assign({}, state, {
        isCreatingClient: true,
      });
    case "RECEIVE_CREATE_TWILIO_CLIENT":
      return Object.assign({}, state, {
        isCreatingClient: false,
        hasCreatedClient: true,
        client: action.client,
      });
    case "RECEIVE_DELETE_TWILIO_CLIENT":
      return Object.assign({}, state, {
        isCreatingClient: false,
        hasCreatedClient: false,
        client: null,
      });
    case "REQUEST_GET_WAITING_ROOM_THREAD":
      return Object.assign({}, state, {
        isFetchingWaitingRoomThread: true,
        theadErr: "",
      });
    case "RECEIVE_GET_WAITING_ROOM_THREAD":
      return Object.assign({}, state, {
        waitingRoomThread: action.waitingRoomThread,
        isFetchingWaitingRoomThread: false,
      });
    case "ERROR_GET_WAITING_ROOM_THREAD":
      return Object.assign({}, state, {
        isFetchingWaitingRoomThread: false,
        threadErr: action.threadErr,
      });

    case "REQUEST_GET_CHAT_ROOM":
      return Object.assign({}, state, {
        fetchingChatRoom: true,
      });
    case "RECEIVE_GET_CHAT_ROOM":
      return Object.assign({}, state, {
        fetchingChatRoom: false,
        chatRoom: action.chatRoom,
      });
    case "REQUEST_GET_VIDEO_CHAT_ROOM_TOKEN":
      return Object.assign({}, state, {
        isFetchingVideoToken: true,
      });
    case "RECEIVE_GET_VIDEO_CHAT_ROOM_TOKEN":
      return Object.assign({}, state, {
        isFetchingVideoToken: false,
        videoToken: action.videoToken,
      });
    case "ERROR_GET_VIDEO_CHAT_ROOM_TOKEN":
      launchToast(action.errorMessage);
      return Object.assign({}, state, {
        isFetchingVideoToken: false,
      });
    case "JOINING_ACTIVE_CALL":
      return Object.assign({}, state, {
        joiningPatient: action.joiningPatient,
      });
    case "SET_JOIN_ACTIVE_CALL":
      return Object.assign({}, state, {
        activeCall: action.activeCall,
        activeAdmin: action.activeAdmin,
        allActiveCalls: action.allActiveCalls,
      });
    case "SET_UPDATE_ACTIVE_CALLS":
      return Object.assign({}, state, {
        allActiveCalls: action.allActiveCalls,
      });
    case "SET_LEAVE_ACTIVE_CALL":
      return Object.assign({}, state, {
        activeCall: null,
        activeAdmin: null,
      });
    case "SET_LEAVE_ACTIVE_CALL":
      return Object.assign({}, state, {
        activeCalls: action.activeCalls,
      });
    case "SET_VIDEO_ROOM":
      return Object.assign({}, state, {
        room: action.room,
      });
    case "SET_CHAT_CHANNEL":
      return Object.assign({}, state, {
        chatRoom: action.chatRoom,
      });
    case "REQUEST_JOIN_CHANNEL":
      return Object.assign({}, state, {
        isJoiningChannel: true,
      });
    case "RECEIVE_JOIN_CHANNEL":
      return Object.assign({}, state, {
        isJoiningChannel: false,
        chatChannel: action.chatChannel,
      });
    case "ERROR_JOIN_CHANNEL":
      launchToast(action.joinChannelErrorMessage);
      return Object.assign({}, state, {
        isJoiningChannel: false,
        joinChannelErrorMessage: action.joinChannelErrorMessage,
      });
    case "SET_MESSAGES":
      return Object.assign({}, state, {
        messages: [...state.messages, action.obj],
      });
    case "SET_VIDEO_PARTICIPANTS":
      return Object.assign({}, state, {
        participants: [...state.participants, action.participant],
      });
    case "REMOVE_VIDEO_PARTICIPANT":
      return Object.assign({}, state, {
        participants: state.participants.filter(
          (p) => p !== action.participant
        ),
      });
    case "CLEAR_VIDEO_PARTICIPANTS":
      return Object.assign({}, state, {
        participants: [],
      });
    case "RESET_MESSAGES":
      console.log("messages reset");
      return Object.assign({}, state, {
        messages: [],
      });
    case "GET_OLD_MESSAGES":
      return Object.assign({}, state, {
        messages: action.messages,
      });
    case "REQUEST_GET_CHANNEL_NAME":
      return Object.assign({}, state, {
        isFetchingChannelName: true,
      });
    case "RECEIVED_GET_CHANNEL_NAME":
      return Object.assign({}, state, {
        isFetchingChannelName: false,
        channelName: action.channelName,
      });
    case "RESET_CHANNEL_NAME":
      return Object.assign({}, state, {
        channelName: null,
      });
    case "REQUEST_GET_TRIAGE_FORM":
      return Object.assign({}, state, {
        isFetchingTriageForm: true,
        triageQuestions: null,
      });
    case "RECEIVE_GET_TRIAGE_FORM":
      return Object.assign({}, state, {
        isFetchingTriageForm: false,
        triageForm: action.triageForm,
      });
    case "RESET_ROOM":
      return Object.assign({}, state, {
        room: null,
        videoToken: null,
      });
    case "REQUEST_UPDATE_TRIAGE_NOTES":
      return Object.assign({}, state, {
        isUpdatingTriageNote: true,
        updateTriageNoteMessage: "",
      });
    case "RECEIVE_UPDATE_TRIAGE_NOTES":
      launchToast(action.updateTriageNoteMessage);
      return Object.assign({}, state, {
        isUpdatingTriageNote: false,
        updateTriageNoteMessage: action.updateTriageNoteMessage,
      });
    case "ERROR_UPDATE_TRIAGE_NOTES":
      launchToast(action.updateTriageNoteMessage);
      return Object.assign({}, state, {
        isUpdatingTriageNote: false,
        updateTriageNoteMessage: action.updateTriageNoteMessage,
      });
    case "REQUEST_GET_VIDEO_NAME":
      return Object.assign({}, state, {
        isFetchingVideoName: true,
      });
    case "RECEIVE_GET_VIDEO_NAME":
      return Object.assign({}, state, {
        isFetchingVideoName: false,
        videoChannelName: action.videoChannelName,
      });
    case "RESET_VIDEO_ROOM_NAME":
      return Object.assign({}, state, {
        videoChannelName: null,
      });
    case "SET_VIDEO_TRACKS":
      return Object.assign({}, state, {
        videoTracks: [...state.videoTracks, action.videoTracks],
      });
    case "SET_AUDIO_TRACKS":
      return Object.assign({}, state, {
        audioTracks: [...state.audioTracks, action.audioTracks],
      });
    case "REMOVE_VIDEO_TRACK":
      return Object.assign({}, state, {
        videoTracks: state.videoTracks.filter((v) => v !== action.videoTrack),
      });
    case "REMOVE_AUDIO_TRACK":
      return Object.assign({}, state, {
        audioTracks: state.audioTracks.filter((a) => a !== action.audioTrack),
      });
    case "RESET_VIDEO_TRACKS":
      return Object.assign({}, state, {
        videoTracks: [],
      });
    case "REQUEST_GET_TRIAGE_QUESTIONS":
      return Object.assign({}, state, {
        fetchingTriageQuestions: true,
      });
    case "RECEIVE_GET_TRIAGE_QUESTIONS":
      return Object.assign({}, state, {
        fetchingTriageQuestions: false,
        triageQuestions: action.triageQuestions,
      });
    case "RESET_AUDIO_TRACKS":
      return Object.assign({}, state, {
        audioTracks: [],
      });
    case "SET_RIGHT_TO_CHAT":
      return Object.assign({}, state, {
        rightSetting: "chat",
      });
    case "SET_RIGHT_TO_TRIAGE":
      return Object.assign({}, state, {
        rightSetting: "triage",
      });
    default:
      return state;
  }
};

export default waitingRoom;
