import React from "react";
import moment from "moment";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { selectAdminThread } from "../../../actions/internalChatActions";

type AdminMessageThreadProps = {
  pk: number;
  threadName: string;
  timestamp: string;
  read: boolean;
  threadObject: Object;
};

export const AdminMessageThread: React.FC<any> = (
  props: AdminMessageThreadProps
) => {
  const dispatch = useDispatch();

  const profile = useSelector(
    (state: RootStateOrAny) => state.authenticateUser.profile
  );
  return (
    <div
      className="new-msg-info"
      onClick={(e) => {
        e.preventDefault();
        dispatch(selectAdminThread(props.threadObject));
      }}
    >
      <div className="new-msg-info-inner active">
        <div className="msg-infos">
          <h3>
            {props.threadName == `${profile.first_name} ${profile.last_name}`
              ? "New Message"
              : props.threadName}
          </h3>
          <span className="msg-dots" />
          <h4>
            <span>{moment(props.timestamp).format("LLL")}</span>
          </h4>
        </div>
      </div>
    </div>
  );
};
