import React, { useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";

import { NewAppointmentCheckbox } from "./NewAppointmentCheckbox";
import {
  addSymptomsToForm,
  saveFormInstance,
  addImmunizationsToForm,
} from "../../../actions/newAppointmentActions";

export const NewAppointmentSymptoms = (props: any) => {
  const dispatch = useDispatch();
  const selectedSymptoms = useSelector(
    (state: RootStateOrAny) => state.appointmentReducer.selectedSymptoms
  );
  const selectedImmunizations = useSelector(
    (state: RootStateOrAny) => state.appointmentReducer.selectedImmunizations
  );
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    if (selectedSymptoms.length > 0) {
      dispatch(addSymptomsToForm(props.instance, selectedSymptoms));
      dispatch(saveFormInstance(props.instance, "symptoms", selectedSymptoms));
    }
  }, [dispatch, selectedSymptoms]);

  return (
    props.symptoms &&
    props.symptoms.map((symptom: any) => (
      <NewAppointmentCheckbox
        key={symptom.pk}
        fluClinic={props.fluClinic}
        symptom={symptom}
        instance={props.instance}
        onClick={() => forceUpdate()}
        checked={
          props.patientForm.symptoms
            ? props.patientForm.symptoms
                .map((patientSymptom: any) => patientSymptom)
                .includes(symptom.pk)
            : false
        }
      />
    ))
  );
};
