const initialState = {
  isCreatingMessageThread: false,
  creatingMessageThreadMessage: "",
  isAddingMessageToThread: false,
  isAddingMessageToThreadMessage: "",
  isGettingMessageThreads: false,
  isGettingMessageMessage: "",
  messageThreads: [],
  searchedMessageThreads: [],
  isFetchingMessageThread: true,
  isFetchingMessageMessage: "",
  selectedThread: null,
  isFetchingArchivedThreads: false,
  archivedThreadsErrorMessage: "",
  archivedThreads: [],
};

const messagingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_CREATE_MESSAGE_THREAD":
      return Object.assign({}, state, {
        isCreatingMessageThread: true,
        creatingMessageThreadMessage: "",
      });
    case "RECEIVE_CREATE_MESSAGE_THREAD":
      return Object.assign({}, state, {
        isCreatingMessageThread: false,
      });
    case "ERROR_CREATE_MESSAGE_THREAD":
      return Object.assign({}, state, {
        isCreatingMessageThread: false,
        creatingMessageThreadMessage: action.creatingMessageThreadMessage,
      });
    case "REQUEST_ADD_MESSAGE_TO_THREAD":
      return Object.assign({}, state, {
        isAddingMessageToThread: true,
        isAddingMessageToThreadMessage: "",
      });
    case "RECEIVE_ADD_MESSAGE_TO_THREAD":
      return Object.assign({}, state, {
        isAddingMessageToThread: false,
      });
    case "ERROR_ADD_MESSAGE_TO_THREAD":
      return Object.assign({}, state, {
        isAddingMessageToThread: false,
        isAddingMessageToThreadMessage: action.isAddingMessageToThreadMessage,
      });
    case "REQUEST_GET_MESSAGE_THREADS":
      return Object.assign({}, state, {
        isGettingMessageThreads: true,
        message: "",
      });
    case "RECEIVE_GET_MESSAGE_THREADS":
      return Object.assign({}, state, {
        isGettingMessageThreads: false,
        messageThreads: action.threads,
      });
    case "ERROR_GET_MESSAGE_THREADS":
      return Object.assign({}, state, {
        isGettingMessageThreads: false,
        isGettingMessageMessage: action.message,
      });
    case "REQUEST_GET_MESSAGE_THREAD":
      return Object.assign({}, state, {
        isFetchingMessageThread: true,
        isFetchingMessageMessage: "",
      });
    case "RECEIVE_GET_MESSAGE_THREAD":
      return Object.assign({}, state, {
        isFetchingMessageThread: false,
        selectedThread: action.selectedThread,
      });
    case "ERROR_GET_MESSAGE_THREAD":
      return Object.assign({}, state, {
        isFetchingMessageThread: false,
        isFetchingMessageMessage: action.isFetchingMessageMessage,
      });
    case "ADD_TO_END_OF_MESSAGES":
      return Object.assign({}, state, {
        selectedThread: {
          ...state.selectedThread,
          message_set: [...state.selectedThread.message_set, action.message],
        },
      });
    case "SEARCH_THREADS":
      return Object.assign({}, state, {
        searchedMessageThreads: action.searchedThreads
      });
    case "SEARCH_ARCHIVED_THREADS":
      return Object.assign({}, state, {
        searchedMessageThreads: state.archivedThreads.filter((obj) =>
          obj.subject.includes(action.query)
        ),
      });
    case "RESET_SEARCH":
      return Object.assign({}, state, {
        searchedMessageThreads: [],
      });
    case "RESET_SELECTED_THREAD":
      return Object.assign({}, state, {
        selectedThread: null,
      });
    case "SEARCH_ENTRIES":
      return Object.assign({}, state, {
        searchEntries: action.searchEntries,
      });
    case "REQUEST_GET_ARCHIVED_THREADS":
      return Object.assign({}, state, {
        isFetchingArchivedThreads: true,
        archivedThreadsErrorMessage: "",
      });
    case "RECEIVE_GET_ARCHIVED_THREADS":
      return Object.assign({}, state, {
        isFetchingArchivedThreads: false,
        archivedThreads: action.archivedThreads,
      });
    case "ERROR_GET_ARCHIVED_THREADS":
      return Object.assign({}, state, {
        isFetchingArchivedThreads: false,
        archivedThreadsErrorMessage: action.archivedThreadsErrorMessage,
      });

    default:
      return state;
  }
};
export default messagingReducer;
