import React from "react";
import s from "./UpcomingListItem.module.scss";
import carIcon from "../../../../assets/images/caricon.png";
import moment from "moment";
import { useHistory } from "react-router-dom";

export const UpcomingListItem = (props: any) => {
  const history = useHistory();
  return (
    <div
      className={s.listItem}
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        e.preventDefault();
        history.push(
          `/office/${props.office.slug}/appointments/appointment/${
            props.appointment.pk
          }`
        );
      }}
    >
      <ul>
        <li className={`${s.icon} ${s.singleItem}`}>
          <img src={carIcon} alt="" />
        </li>
        <li className={`${s.name} ${s.singleItem}`}>
          <p>{props.appointment.title}</p>
        </li>
        <li className={`${s.time} ${s.singleItem}`}>
          <p>{moment(props.appointment.start).format("LLL")} </p>
        </li>
      </ul>
    </div>
  );
};
