import React, { useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";

import { NewAppointmentCheckbox } from "./NewAppointmentCheckbox";
import {
  addSymptomsToForm,
  saveFormInstance,
  addImmunizationsToForm,
} from "../../../actions/newAppointmentActions";
import { NewAppointmentImmunizationCheckbox } from "./NewAppointmentImmunizationCheckbox";
import { resetImmunizations } from "../../../actions/appointmentActions";

export const NewAppointmentImmunizations = (props: any) => {
  const dispatch = useDispatch();
  console.log(props.patientForm);
  const selectedImmunizations = useSelector(
    (state: RootStateOrAny) => state.appointmentReducer.selectedImmunizations
  );
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    if (selectedImmunizations.length > 0) {
      dispatch(addImmunizationsToForm(props.instance, selectedImmunizations));
      dispatch(
        saveFormInstance(props.instance, "immunizations", selectedImmunizations)
      );
    }
  }, [dispatch, selectedImmunizations]);

  // useEffect(() => {
  //   return () => {};
  // }, [props.instance, selectedImmunizations]);

  return (
    props.symptoms &&
    props.symptoms.map((symptom: any) => (
      <NewAppointmentImmunizationCheckbox
        key={symptom.pk}
        fluClinic={props.fluClinic}
        symptom={symptom}
        instance={props.instance}
        onClick={() => forceUpdate()}
        checked={
          props.patientForm.immunizations
            ? props.patientForm.immunizations
                .map((patientSymptom: any) => patientSymptom)
                .includes(symptom.pk)
            : false
        }
      />
    ))
  );
};
