import React from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";

import "react-datepicker/dist/react-datepicker.css";

import { createPortal } from "react-dom";
import { deleteAppointment } from "../../../actions/appointmentActions";

export const CancelForm = (props) => {
  const { control, register, handleSubmit, watch, errors } = useForm();
  const office = useSelector((state) => state.adminOfficeInteractions.office);
  const dispatch = useDispatch();
  const formSubmit = (data) => {
    dispatch(deleteAppointment(props.appointmentPk, data.sendEmail));
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      maxHeight: "100vh",
      marginRight: "-50%",
      overflowY: "auto",
      overflow: "hidden",
      transform: "translate(-50%, -50%)",
      padding: 0,
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
    },
  };

  return (
    <Modal isOpen={true} style={customStyles}>
      <div
        id="cancel-event-popup"
        className="display-none fancybox-content   updated-popup-form new-update-popup"
        style={{
          display: "inline-block",
          width: "524px",
          height: "350px",
          overflowX: "hidden",
        }}
      >
        <div>
          <div className="login-form forgot-pass-form common-form">
            <div className="sub-heading common-subheading">
              <h3>Cancel Appointment</h3>
            </div>
            <form onSubmit={handleSubmit(formSubmit)}>
              <div className="inner-popup-content cancel-event-popup">
                <div className="form-head forgot-pass-head">
                  <h4>Appointment will be cancelled.</h4>
                </div>
                <p className="description">
                  If you’d like to cancel your event, please select whether or
                  not you’d like to send a message to patient. If yes, select a
                  default message below.
                </p>
                <div className="p-info">
                  <div className="p-info-inner">
                    <input
                      type="radio"
                      id="radio11"
                      name="sendEmail"
                      value="yes"
                      defaultChecked={true}
                      ref={register({ required: true })}
                    />
                    <label for="radio11">
                      <span>Yes, send message.</span>
                    </label>
                  </div>
                  <div className="p-info-inner">
                    <input
                      type="radio"
                      id="radio12"
                      name="sendEmail"
                      value="no"
                      ref={register({ required: true })}
                    />
                    <label for="radio12">
                      <span>No, do not send message.</span>
                    </label>
                  </div>
                </div>
                <div class="message-wrap">
                  <select ref={register()} class="form-feild w-100 selectbox">
                    <option>- Choose Default Message -</option>
                  </select>
                </div>
                <div className="login-submit">
                  <a
                    href="#"
                    title="Cancel"
                    className="popup-btn whitebtn mr-15"
                    data-fancybox-close=""
                    onClick={(e) => {
                      e.preventDefault();
                      props.setCancelForm(false);
                    }}
                  >
                    Cancel
                  </a>
                  <button type="submit" title="Continue" className="popup-btn">
                    Continue
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <button
          type="button"
          data-fancybox-close=""
          className="fancybox-button fancybox-close-small"
          title="Close"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1"
            viewBox="0 0 24 24"
          >
            <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path>
          </svg>
        </button>
      </div>
    </Modal>
  );
};
