import React, { useRef } from "react";
import { Redirect, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signUpUser, resetPassword } from "../actions/authActions";
import { BounceLoader } from "react-spinners";

function ForgotPassword() {
  const dispatch = useDispatch();
  // Refs
  const email = useRef(null);

  const isResettingPassword = useSelector(
    (state) => state.authenticateUser.isResettingPassword
  );

  const confirmationMessage = useSelector(
    (state) => state.authenticateUser.confirmationMessage
  );

  return (
    <div class="login-form-main full-height">
      <div class="form-bg">
        <div class="vertical-middle form-main">
          <div class="form-logo-main">
            <a href="#" title="My Docs" class="form-logo">
              <img src="images/logo.png" alt="" />
            </a>
          </div>
          <div class="login-form forgot-pass-form common-form">
            <Link to="/">
              <div class="sub-heading with-arrow">
                <h3>Log In</h3>
              </div>
            </Link>
            <div class="inner-forgot-pass">
              <div class="form-head forgot-pass-head">
                <h2>Forgot your password?</h2>
                <p>
                  Enter your email and we’ll send you a link to reset your
                  password.
                </p>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  dispatch(resetPassword(email.current.value));
                }}
              >
                <p>
                  <input
                    type="email"
                    placeholder="Email"
                    class="common-input"
                    ref={email}
                  />
                </p>
                <p>{confirmationMessage ? confirmationMessage : ""}</p>
                <div class="login-submit">
                  {!isResettingPassword ? (
                    <input
                      type="submit"
                      value="Send New Password"
                      class="blue-btn common-btn"
                    />
                  ) : (
                    <BounceLoader color={"#052D64"} size={50} />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
