import React, { useEffect } from "react";
import DashboardTop from "../../dashboardTop";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import {
  getAppointmentExportFile,
  getExportFile,
  getExportFilesList,
  resetGetExportFilesList,
} from "../../../actions/appointmentActions";
import moment from "moment";
import { BounceLoader, ClipLoader, FadeLoader } from "react-spinners";
import { Bounce } from "react-toastify";

export const ExportFiles = (props: any) => {
  const dispatch = useDispatch();
  const office = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.office
  );
  const exportFiles = useSelector(
    (state: RootStateOrAny) => state.appointmentReducer.exportFiles
  );
  const isFetchingExportFiles = useSelector(
    (state: RootStateOrAny) => state.appointmentReducer.isFetchingExportFiles
  );

  useEffect(() => {
    dispatch(getExportFilesList(office.pk));
    const getExportFilesIntervals = setInterval(() => {
      dispatch(getExportFilesList(office.pk));
    }, 3000);
    return () => clearInterval(getExportFilesIntervals);
  }, [dispatch, office.pk]);

  useEffect(() => {
    return () => {
      dispatch(resetGetExportFilesList());
    };
  }, [dispatch]);

  return (
    <div>
      <DashboardTop
        title={`Export Files`}
        back={true}
        backLink={`/office/${office.slug}/appointments/`}
      />

      <div className="middle-content main-content-wrapper">
        <div className="d-flex">
          <div className="col-7 automatic-msg-col">
            <div className="automatic-msg-main white-box">
              <h2>Export Files</h2>
              <div className="patients-table bulletin-table">
                <table>
                  <tbody>
                    <tr className="no-border">
                      <th>Title</th>
                      <th>Status</th>
                      <th>Initiated at:</th>
                      <th>Download</th>
                    </tr>
                    {exportFiles.map((file: any) => (
                      <tr className="no-border">
                        <td>{file.description}</td>
                        <td>{file.status}</td>
                        <td>{moment(file.initiated_at).format("LLLL")}</td>
                        <td>
                          {file.status === "complete" ? (
                            <button
                              className="common-btn blue-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(getExportFile(file.pk));
                              }}
                            >
                              Download
                            </button>
                          ) : file.status === "started" ? (
                            <ClipLoader size={18} />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
