import React, { useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import moment from "moment";
import {
  createNewPriorityOfficeHour,
  getOfficeHoursByOffice,
  resetCreateNewPriorityOfficeHour,
  setNewOfficeHours,
  setOfficeSettingsTab,
  submitOfficeHours,
  updateNewOfficeHours,
} from "../../../actions/officeSettingsActions";
import DatePicker from "react-datepicker";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useHistory } from "react-router-dom";

export const NewPriorityBookingTime = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const office = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.office
  );
  const isCreatingPriorityHour = useSelector(
    (state: RootStateOrAny) =>
      state.officeSettingsReducer.isCreatingPriorityHour
  );
  const hasCreatedPriorityHour = useSelector(
    (state: RootStateOrAny) =>
      state.officeSettingsReducer.hasCreatedPriorityHour
  );
  const { control, register, handleSubmit, watch, errors } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const isClosed = watch("closed", false);
  const hasLunch = watch("lunch", false);

  const formSubmit = (data: any) => {
    data.date = moment(data.date).format("YYYY-MM-DD");
    data.from_hour = moment(data.from_hour).format("HH:mm");
    data.to_hour = moment(data.to_hour).format("HH:mm");
    data.lunch_start = data.lunch_start
      ? moment(data.lunch_start).format("HH:mm")
      : "00:01";
    data.lunch_end = data.lunch_end
      ? moment(data.lunch_end).format("HH:mm")
      : "00:02";

    dispatch(createNewPriorityOfficeHour(office.pk, data));
  };

  useEffect(() => {
    return () => {
      dispatch(resetCreateNewPriorityOfficeHour());
    };
  }, [dispatch]);

  useEffect(() => {
    if (hasCreatedPriorityHour) {
      dispatch(setOfficeSettingsTab("priorityBookingTimes"));
    }
  }, [dispatch, hasCreatedPriorityHour]);

  return (
    <div className="white-box">
      <div className="app-setting-head section-head">
        <h4 className="w-100">Hours of Operation</h4>
        <p>
          Select which times you’d like patients to be able to start booking
          COVID-19 appointments and what time you’d like to stop booking. Be
          sure to add hours for each day that your office is open. If office is
          closed a certain day, don’t add any hours.
        </p>
      </div>
      <form className="w-100" onSubmit={handleSubmit(formSubmit)}>
        <div className="hours-slot">
          <div className="timeslot-box-row">
            <label className="timeslot-starttime"> Date</label>
            <div className="timeslot-box-time-piker">
              <div className="time-picker">
                <Controller
                  className="form-control  form-feild"
                  render={(
                    { onChange, onBlur, value, name, ref },
                    { invalid, isTouched, isDirty }
                  ) => (
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      showMonthDropdown
                      onChange={(e) => onChange(e)}
                      autoComplete="off"
                      selected={value}
                    />
                  )}
                  name="date"
                  control={control}
                />
              </div>
            </div>
          </div>
          <div className="timeslot-box-row">
            <label className="timeslot-starttime"> Closed?</label>
            <div className="timeslot-box-time-piker">
              <div className="time-picker">
                <label className="switch">
                  <input type="checkbox" name="closed" ref={register()} />
                  <span className="switch-btn" />
                </label>
              </div>
            </div>
          </div>
          {!isClosed && (
            <>
              <div className="timeslot-box-row">
                <label className="timeslot-starttime">
                  {" "}
                  Hours of Operations:{" "}
                </label>
                <div className="timeslot-box-time-piker">
                  <div className="time-picker">
                    <Controller
                      name="from_hour"
                      //   selected={moment(officeDay.from_hour, "hh:mm:ss").toDate()}
                      control={control}
                      render={(
                        { onChange, onBlur, value, name, ref },
                        { invalid, isTouched, isDirty }
                      ) => (
                        <DatePicker
                          showMonthDropdown
                          onChange={(e) => onChange(e)}
                          autoComplete="off"
                          className="form-control  form-feild"
                          showTimeSelect
                          showTimeSelectOnly
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          selected={value}
                        />
                      )}
                    />
                  </div>
                </div>
                <label className="timeslot-endtime"> to </label>
                <div className="timeslot-box-time-piker">
                  <div className="time-picker">
                    <Controller
                      name="to_hour"
                      control={control}
                      render={(
                        { onChange, onBlur, value, name, ref },
                        { invalid, isTouched, isDirty }
                      ) => (
                        <DatePicker
                          showMonthDropdown
                          onChange={(e) => onChange(e)}
                          autoComplete="off"
                          className="form-control  form-feild"
                          showTimeSelect
                          showTimeSelectOnly
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          selected={value}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="timeslot-box-row">
                <label className="timeslot-starttime"> Lunch?</label>
                <div className="timeslot-box-time-piker">
                  <div className="time-picker">
                    <label className="switch">
                      <input type="checkbox" name="lunch" ref={register()} />
                      <span className="switch-btn" />
                    </label>
                  </div>
                </div>
              </div>
              {hasLunch && (
                <div className="timeslot-box-row">
                  <label className="timeslot-starttime"> Lunch: </label>
                  <div className="timeslot-box-time-piker">
                    <div className="time-picker">
                      <Controller
                        name="lunch_start"
                        control={control}
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <DatePicker
                            showMonthDropdown
                            onChange={(e) => onChange(e)}
                            autoComplete="off"
                            className="form-control  form-feild"
                            showTimeSelect
                            showTimeSelectOnly
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            selected={value}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <label className="timeslot-endtime"> to </label>
                  <div className="timeslot-box-time-piker">
                    <div className="time-picker">
                      <Controller
                        name="lunch_end"
                        control={control}
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <DatePicker
                            showMonthDropdown
                            onChange={(e) => onChange(e)}
                            autoComplete="off"
                            className="form-control  form-feild"
                            showTimeSelect
                            showTimeSelectOnly
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            selected={value}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="w-100">
                <div className="timeslot-row w-100">
                  <label className="feild-label">Length of Time Slot:</label>
                  <select
                    name="appointment_interval"
                    className="select-jquery-ui"
                    defaultValue={"15"}
                    ref={register()}
                  >
                    <option value="5">5 Minutes </option>
                    <option value="10">10 Minutes </option>
                    <option value="15">15 Minutes (Default)</option>
                    <option value="20">20 Minutes</option>
                    <option value="25">25 Minutes</option>
                    <option value="30">30 Minutes</option>
                    <option value="35">35 Minutes</option>
                    <option value="40">40 Minutes</option>
                    <option value="45">45 Minutes</option>
                    <option value="50">50 Minutes</option>
                    <option value="55">55 Minutes</option>
                    <option value="60">60 Minutes</option>
                    <option value="65">65 Minutes</option>
                    <option value="70">70 Minutes</option>
                  </select>
                </div>

                <div className="timeslot-row w-100">
                  <label className="feild-label"> Patients per slot: </label>
                  <input
                    type="number"
                    className="select-jquery-ui"
                    name="num_appointments_interval"
                    ref={register()}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div className="timeslot-buttonbox">
          <button
            type="submit"
            className="btn btn-primary btn-sm text-capitalize"
          >
            Save changes
          </button>
        </div>
      </form>
    </div>
  );
};
