import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import DashboardTop from "../dashboardTop";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { getSearchPatients } from "../../actions/messagingActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { BounceLoader } from "react-spinners";
import {
  getMeetingsByOffice,
  createMeeting,
  resetHasCreatedRoom,
  createMeetingNow,
  resetHasCancelledMeeting,
  resetMeetingsByOffice,
} from "../../actions/meetingRoomActions";
import ReactTooltip from "react-tooltip";

export const MeetingRoom = (props) => {
  const [showPatient, setShowPatient] = useState(true);
  const [patientPK, setPatientPK] = useState(null);
  const [invitePatient, setInvitePatient] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const dispatch = useDispatch();

  const { control, register, handleSubmit, watch, errors } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "guests",
  });

  const profile = useSelector((state) => state.authenticateUser.profile);
  const guests = watch("guests", { nest: true });

  const formSubmit = (data) => {
    console.log(data);

    const guests = data.guests.filter((guest) => guest.email !== "");
    delete data.guests;
    data["guests"] = guests;
    dispatch(createMeeting(data, props.office.pk, profile.pk));
  };
  const isFetchingMeetings = useSelector(
    (state) => state.meetingRoomReducer.isFetchingMeetings
  );
  const isCreatingMeeting = useSelector(
    (state) => state.meetingRoomReducer.isCreatingMeeting
  );
  const isCreatingVideoParticipants = useSelector(
    (state) => state.meetingRoomReducer.isCreatingVideoParticipants
  );
  const createdMeeting = useSelector(
    (state) => state.meetingRoomReducer.createdMeeting
  );
  const hasCreatedMeeting = useSelector(
    (state) => state.meetingRoomReducer.hasCreatedMeeting
  );

  const adminPK = useSelector((state) => state.authenticateUser.profile);

  const meetings = useSelector((state) => state.meetingRoomReducer.meetings);
  useEffect(() => {
    dispatch(resetHasCancelledMeeting());
    // Get upcoming meetings by office
    dispatch(getMeetingsByOffice(props.office.slug));
    return () => {
      dispatch(resetMeetingsByOffice());
      dispatch(resetHasCreatedRoom());
    };
  }, []);

  useEffect(() => {
    if (firstTime) {
      append({
        email: profile.user.email,
        name: `${profile.first_name} ${profile.last_name}`,
      });
      setFirstTime(false);
    }
  }, [firstTime]);

  return (
    <div class="waittingroom-main">
      <div class="rightbar full-height" style={{ paddingLeft: 0 }}>
        <DashboardTop title={"Meeting Room"} />
        <div class="middle-content main-content-wrapper">
          <div class="patients-export-option">
            <a
              href="#"
              title=""
              class="start-meeting btns"
              onClick={(e) => {
                dispatch(createMeetingNow(props.office.pk, adminPK.pk));
              }}
            >
              <span>Start Meeting Now</span>
            </a>
          </div>
          <div class="left-sec">
            <div class="white-box meeting-details">
              <h2>Book a Meeting</h2>
              <form onSubmit={handleSubmit(formSubmit)}>
                <div class="meeting-option">
                  <div class="meeting-tooltip">
                    <label>
                      Is this a public meeting?{" "}
                      <a
                        href="#"
                        title=""
                        class="tooltip"
                        data-tip="Public Meeting"
                      ></a>
                    </label>
                    <ReactTooltip
                      place="right"
                      effect="solid"
                      className="arrow_box"
                    >
                      A public meeting means that whoever has the link can join
                      the call. Private requires email to authenticate into the
                      room.
                    </ReactTooltip>
                  </div>
                  <div class="p-info">
                    <div class="p-info-inner">
                      <input
                        type="radio"
                        id="radio1"
                        name="public"
                        value="Yes"
                        defaultChecked={true}
                        ref={register({ required: true })}
                      />
                      <label
                        for="radio1"
                        onClick={() => {
                          setShowPatient(true);
                        }}
                      >
                        <span>Yes</span>
                      </label>
                    </div>
                    <div class="p-info-inner">
                      <input
                        type="radio"
                        id="radio2"
                        name="public"
                        value="No"
                        ref={register({ required: true })}
                      />
                      <label
                        for="radio2"
                        onClick={() => {
                          setShowPatient(false);
                        }}
                      >
                        <span>No</span>
                      </label>
                    </div>
                  </div>
                </div>
                {!showPatient ? (
                  <div class="form-group patients-group">
                    <label>Patient:*</label>
                    <Controller
                      as={AsyncSelect}
                      control={control}
                      name="patient"
                      loadOptions={(input, callback) => {
                        getSearchPatients(input, callback, props.office.slug);
                      }}
                      placeholder="Search Patient..."
                      onChange={([selected]) => {
                        return selected;
                      }}
                      className=""
                      defaultValue={{}}
                    />
                    <div class="switch-main">
                      <span class="notify">
                        {invitePatient
                          ? "Patient Invited"
                          : "Patient Not Invited"}
                      </span>
                      <label class="switch">
                        <input
                          type="checkbox"
                          defaultChecked={false}
                          ref={register}
                          name="patientInvited"
                        />
                        <span
                          class="switch-btn"
                          onClick={() => setInvitePatient(!invitePatient)}
                        ></span>
                      </label>
                      <div class="tooltip-main">
                        <a
                          href="#"
                          title=""
                          class="tooltip"
                          data-tip="Patient Invite"
                        ></a>
                        <ReactTooltip
                          className="arrow_box"
                          effect="solid"
                          place="right"
                        >
                          If ‘Patient Invited’ is selected, patient will receive
                          invitation to meeting. If not selected, patient will
                          not receive invitation. You may want to choose this
                          when discussing about a patient that you don't them to
                          present.
                        </ReactTooltip>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div class="form-group">
                  <label>Title:</label>
                  <input
                    type="text"
                    class="common-input"
                    name="title"
                    ref={register({ required: true })}
                  />
                </div>
                <div class="form-group">
                  <label>Guest(s):</label>
                  {fields.map((item, index) => (
                    <div className="inner-fields-main">
                      <div
                        class="inner-fields"
                        key={item.id}
                        style={{
                          position: "relative",
                          marginTop: "20px ",
                        }}
                      >
                        <div class="fields-row-main">
                          <div class="fields-row half">
                            <label>Email Address</label>
                            <input
                              type="email"
                              placeholder=""
                              class="common-input"
                              ref={register()}
                              name={`guests[${index}].email`}
                              defaultValue={`${item.email}`}
                            />
                          </div>
                          <div class="fields-row half">
                            <label>Name</label>
                            <input
                              type="text"
                              placeholder=""
                              class="common-input"
                              ref={register()}
                              name={`guests[${index}].name`}
                              defaultValue={`${item.name}`}
                            />
                          </div>
                        </div>
                        <div class="fields-row-main">
                          <div class="fields-row half">
                            <label>Relation to Patient</label>
                            <select
                              ref={register()}
                              name={`guests[${index}].relation`}
                            >
                              <option>- Select Relation -</option>
                              <option value="patient">Patient</option>
                              <option value="specialist">Specialist</option>
                              <option value="support worker">
                                Support Worker
                              </option>
                              <option value="family">Family</option>
                              <option value="other">Other</option>
                            </select>
                          </div>
                          <div class="fields-row half">
                            <label>Job Title (if applicable)</label>
                            <input
                              type="text"
                              placeholder=""
                              class="common-input"
                              ref={register()}
                              name={`guests[${index}].job`}
                              defaultValue={item.job}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="guests-link">
                        <a
                          href="#"
                          title="Uninvite Guest"
                          onClick={(e) => {
                            e.preventDefault();
                            remove(index);
                          }}
                        >
                          Delete Guest
                        </a>
                      </div>
                    </div>
                  ))}
                  <div class="add-guest">
                    <a
                      href="#"
                      title=""
                      class="addguest"
                      onClick={(e) => {
                        e.preventDefault();
                        append({
                          email: "",
                          name: "",
                        });
                      }}
                    >
                      + Add Guest
                    </a>
                  </div>
                </div>
                <div class="form-group datetime-main">
                  <label>Date & Time:</label>
                  <Controller
                    as={DatePicker}
                    className="form-control datetimepicker-input"
                    showTimeSelect
                    valueName="selected"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    onChange={([selected]) => selected}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    showMonthDropdown
                    name="datetime"
                    control={control}
                  />
                </div>
                <div class="form-group">
                  <textarea
                    class="common-input"
                    placeholder="Add description of meeting or notes here"
                    name="description"
                    ref={register}
                  ></textarea>
                </div>
                {isCreatingMeeting || isCreatingVideoParticipants ? (
                  <BounceLoader color={"#052D64"} size={30} />
                ) : (
                  <button
                    href="#booked-meeting"
                    title="Schedule + Send Invitations"
                    class="btns fancybox"
                    type="submit"
                    style={{ border: "0" }}
                  >
                    Schedule + Send Invitations
                  </button>
                )}
              </form>
            </div>
          </div>
          <div class="right-sec meeting-right">
            <div class="white-box">
              <h5>Upcoming Meetings</h5>
              <div class="custom-scrl-content mCustomScrollbar">
                <ul>
                  {!isFetchingMeetings
                    ? meetings
                      ? meetings.map((meeting) => (
                          <Link
                            to={`/office/${props.office.slug}/meeting-room/${meeting.meeting_code}/`}
                          >
                            <li>
                              {meeting.name}
                              <span>
                                {moment(meeting.date_from).format(
                                  "MMMM DD YYYY | hh:mm a"
                                )}
                              </span>
                            </li>
                          </Link>
                        ))
                      : "No meetings yet."
                    : "Loading"}
                </ul>
              </div>
            </div>
          </div>

          <div
            id="booked-meeting"
            class="display-none full-height stage4-popup"
          >
            <div class="vertical-middle form-main">
              <div class="common-form">
                <div class="sub-heading">
                  <h3>Success!</h3>
                </div>
                <div class="inner-forgot-pass">
                  <h5>Your meeting has been booked. </h5>
                  <p>
                    {" "}
                    To cancel or make any changes to your meeting, visit the
                    Meeting Room tab.{" "}
                  </p>
                  <div class="login-submit">
                    <a href="#" title="ok" class="btns" data-fancybox-close="">
                      OK
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {hasCreatedMeeting ? (
        <Redirect
          to={`/office/${props.office.slug}/meeting-room/${createdMeeting.meeting_code}`}
        />
      ) : (
        ""
      )}
    </div>
  );
};
