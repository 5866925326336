import React from "react";
import { useSelector, useDispatch } from "react-redux";

export const SocketIndicator = (props) => {
  const socket = useSelector((state) => state.adminOfficeInteractions.socket);
  const socketStatus = useSelector(
    (state) => state.adminOfficeInteractions.socketStatus
  );

  return (
    <div className="socket-container">
      {socketStatus == "connected" ? (
        <div>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.3" cx="6" cy="6" r="5.5" stroke="white" />
            <circle cx="6" cy="6" r="3" fill="#6DCDB8" />
          </svg>

          <p>Connected</p>
        </div>
      ) : socketStatus == "disconnected" ? (
        <div>
          <svg
            width="8"
            height="9"
            viewBox="0 0 8 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.3" cx="6.5" cy="7.5" r="1.5" fill="white" />
            <circle cx="1.5" cy="4.5" r="1.5" fill="white" />
            <circle opacity="0.3" cx="6.5" cy="1.5" r="1.5" fill="white" />
          </svg>

          <p>Reconnecting</p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
