import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Modal from "react-modal";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import {
  getInternalMessageThreads,
  openChatWindow,
} from "../../actions/internalChatActions";
import { AdminMessageThread } from "./components/AdminMessageThread";
import { AdminMessageChat } from "./components/AdminMessageChat";
import { AdminNewThread } from "./components/AdminNewThread";

export const InternalChat = (props: any) => {
  const dispatch = useDispatch();
  const styles = {
    overlay: {
      backgroundColor: "rgba(56,65,124, 0.9)",
    },
    content: {
      background: "none",
      border: "none",
    },
  };

  const office = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.office
  );
  const profile = useSelector(
    (state: RootStateOrAny) => state.authenticateUser.profile
  );
  const internalMessageThreads = useSelector(
    (state: RootStateOrAny) => state.internalChatReducer.internalMessageThreads
  );
  const adminThread = useSelector(
    (state: RootStateOrAny) => state.internalChatReducer.adminThread
  );
  const isFetchingInternalMessageThreads = useSelector(
    (state: RootStateOrAny) =>
      state.internalChatReducer.isFetchingInternalMessageThreads
  );

  const socket = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.socket
  );

  const [newChat, openNewChat] = useState<boolean>(false);

  function listenMessage(e: any) {
    const response_data = JSON.parse(e.data);
    if (response_data.type == "refresh_admin_message_threads") {
      dispatch(getInternalMessageThreads(profile.pk, office.slug));
    }
  }

  useEffect(() => {
    dispatch(getInternalMessageThreads(profile.pk, office.slug));
  }, []);

  useEffect(() => {
    socket.addEventListener("message", listenMessage);
    return () => {
      socket.removeEventListener("message", listenMessage);
    };
  }, []);

  return (
    <Modal isOpen={true} style={styles}>
      <div id="internal-msg-popup" className="stage4-popup msgs-popups">
        <div className="vertical-middle form-main">
          <div className="msgshead">
            <h2>Internal Messaging</h2>
            <div className="backto-platforms">
              <a
                href="#"
                title="Back to Platform"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(openChatWindow(false));
                }}
              >
                <span>Back to Platform</span>
              </a>
            </div>
          </div>
          <div className="common-form">
            <div className="">
              <div className="d-flex">
                <div className="col-4">
                  <div className="eql-height">
                    <div className="inbox-info-left">
                      <div className="inbox-archive">
                        <ul>
                          <li>
                            <a
                              href="#"
                              title=""
                              className="inbox-icon"
                              onClick={(e) => {
                                e.preventDefault();
                                openNewChat(true);
                              }}
                            />
                          </li>
                        </ul>
                      </div>
                      <form action="#" className="search-form">
                        <input
                          placeholder="Search"
                          className="form-control"
                          name="s"
                          type="search"
                          style={{ paddingTop: "14px" }}
                        />
                        <input
                          type="submit"
                          className="fa fa-search"
                          value=""
                        />
                      </form>
                      <div className="inbox-msgs">
                        <div className="new-msg-info-main d-flex flex-wrap">
                          <div className="custom-scrl-content mCustomScrollbar">
                            {isFetchingInternalMessageThreads &&
                            internalMessageThreads.length === 0 ? (
                              <SkeletonTheme
                                color="#F8F8FD"
                                highlightColor="#9CA9E2"
                              >
                                <Skeleton
                                  count={5}
                                  height={33}
                                  duration={4}
                                  style={{ marginTop: "6px" }}
                                />
                              </SkeletonTheme>
                            ) : (
                              internalMessageThreads.map((thread: any) => (
                                <AdminMessageThread
                                  pk={thread.pk}
                                  threadName={thread.subject}
                                  timestamp={thread.last_updated}
                                  read={true}
                                  threadObject={thread}
                                />
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-8 right-sec">
                  {!newChat ? (
                    adminThread && (
                      <AdminMessageChat adminThread={adminThread} />
                    )
                  ) : (
                    <AdminNewThread openNewChat={openNewChat} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
