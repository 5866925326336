import React, { useEffect, useState } from "react";
import s from "./AgeDemographics.module.scss";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { VictoryChart, VictoryBar, VictoryAxis } from "victory";
import { getAgeDemographics } from "../../../actions/dashboardActions";

export const AgeDemographics = (props: any) => {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = (ev: any) => {
    setWidth(ev.target.innerWidth);
  };

  const office = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.office
  );
  const ageDemographics = useSelector(
    (state: RootStateOrAny) => state.dashboardReducer.ageDemographics
  );

  useEffect(() => {
    dispatch(getAgeDemographics(office.pk));
  }, []);

  return (
    <div className={s.whiteBox}>
      <div className="row">
        <div className="col-12">
          <h3 className={s.mainTitle}>Age Statistics</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h3 className={s.secondaryTitle}>Age-based Breakdown of Patients</h3>
        </div>
      </div>

      <div className={s.chartContainer}>
        {ageDemographics !== null && (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <VictoryChart
              domainPadding={{ x: 15 }}
              padding={{ left: 65, right: 40, top: 0, bottom: 0 }}
              style={{ parent: { height: "100%" } }}
            >
              <VictoryAxis
                tickValues={[
                  "0-10",
                  "11-20",
                  "21-30",
                  "31-40",
                  "41-50",
                  "51-60",
                  "61-70",
                  "71-80",
                  "81-90",
                  "90+",
                ]}
                style={{
                  tickLabels: {
                    fontSize: "20px",
                    fontWeight: "500",
                    lineHeight: "14px",
                    color: "#828282",
                    fontFamily: "ProximaSoft-Regular",
                  },
                }}
              />
              <VictoryBar
                horizontal
                data={ageDemographics}
                padding={0}
                animate={{
                  duration: 2000,
                  onLoad: { duration: 1000 },
                }}
                alignment={"middle"}
                x="age"
                y="count"
                style={{
                  data: {
                    fill: ({ index }) =>
                      +index % 2 === 0 ? "#4F5895" : "#9CA9E2",
                    width: 30,
                  },
                  parent: { maxHeight: "100%" },
                  labels: { height: "100%" },
                }}
                barRatio={0.7}
              />
            </VictoryChart>
          </div>
        )}
      </div>
    </div>
  );
};
