import launchToast from "../utils";

const initialState = {
  isFetchingMeetings: false,
  meetings: [],
  isFetchingMeeting: false,
  meeting: null,
  isFetchingParticipants: false,
  participants: [],
  isCreatingMeeting: false,
  createdMeeting: null,
  isCreatingVideoParticipants: false,
  hasCreatedMeeting: false,
  isCancellingMeeting: false,
  hasCancelledMeeting: false,
  isUpdatingMeeting: false,
};

const meetingRoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_HAS_CREATED_ROOM":
      return Object.assign({}, state, {
        hasCreatedMeeting: false,
      });
    case "RESET_HAS_CANCELLED_MEETING":
      return Object.assign({}, state, {
        hasCancelledMeeting: false,
      });
    case "RESET_MEETINGS_BY_OFFICE":
      return Object.assign({}, state, {
        meetings: [],
      });
    case "REQUEST_GET_MEETINGS_BY_OFFICE":
      return Object.assign({}, state, {
        isFetchingMeetings: true,
      });
    case "RECEIVE_GET_MEETINGS_BY_OFFICE":
      return Object.assign({}, state, {
        isFetchingMeetings: false,
        meetings: action.meetings,
      });
    case "ERROR_GET_MEETINGS_BY_OFFICE":
      return Object.assign({}, state, {
        isFetchingMeetings: false,
      });
    case "REQUEST_GET_MEETING_BY_CODE":
      return Object.assign({}, state, {
        isFetchingMeeting: true,
      });
    case "RECEIVE_GET_MEETING_BY_CODE":
      return Object.assign({}, state, {
        isFetchingMeeting: false,
        meeting: action.meeting,
      });
    case "ERROR_GET_MEETING_BY_CODE":
      return Object.assign({}, state, {
        isFetchingMeeting: false,
      });
    case "REQUEST_GET_PARTICIPANT_BY_CODE":
      return Object.assign({}, state, {
        isFetchingParticipants: true,
      });
    case "RECEIVE_GET_PARTICIPANT_BY_CODE":
      return Object.assign({}, state, {
        isFetchingParticipants: false,
        participants: action.participants,
      });
    case "ERROR_GET_PARTICIPANT_BY_CODE":
      return Object.assign({}, state, {
        isFetchingParticipants: false,
      });
    case "REQUEST_CREATE_MEETING":
      return Object.assign({}, state, {
        isCreatingMeeting: true,
      });
    case "RECEIVE_CREATE_MEETING":
      return Object.assign({}, state, {
        isCreatingMeeting: false,
        createdMeeting: action.createdMeeting,
      });
    case "ERROR_CREATE_MEETING":
      return Object.assign({}, state, {
        isCreatingMeeting: false,
      });
    case "REQUEST_CREATE_VIDEO_PARTICIPANTS":
      return Object.assign({}, state, {
        isCreatingVideoParticipants: true,
      });
    case "RECEIVE_CREATE_VIDEO_PARTICIPANTS":
      return Object.assign({}, state, {
        isCreatingVideoParticipants: false,
        hasCreatedMeeting: true,
      });
    case "ERROR_CREATE_VIDEO_PARTICIPANTS":
      return Object.assign({}, state, {
        isCreatingVideoParticipants: false,
      });
    case "REQUEST_CANCEL_MEETING":
      return Object.assign({}, state, {
        isCancellingMeeting: true,
      });
    case "RECEIVE_CANCEL_MEETING":
      return Object.assign({}, state, {
        isCancellingMeeting: false,
        hasCancelledMeeting: true,
      });
    case "ERROR_CANCEL_MEETING":
      return Object.assign({}, state, {
        isCancellingMeeting: false,
        hasCancelledMeeting: false,
      });
    case "REQUEST_UPDATE_MEETING":
      return Object.assign({}, state, {
        isUpdatingMeeting: true,
      });
    case "RECEIVE_UPDATE_MEETING":
      launchToast("Updated Meeting Successfully.");
      return Object.assign({}, state, {
        isUpdatingMeeting: false,
      });
    case "ERROR_UPDATE_MEETING":
      launchToast("There was an error updating your meeting");
      return Object.assign({}, state, {
        isUpdatingMeeting: false,
      });
    case "RESET_PARTICIPANTS":
      return Object.assign({}, state, {
        participants: action.participants,
      });
    default:
      return state;
  }
};

export default meetingRoomReducer;
