import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  submitSOAPNote,
  updateSOAPNote,
  updateTriageForm,
  updateGuestTriageForm,
} from "../../actions/waitingRoomActions";

function ExistingTriageForm(props) {
  const dispatch = useDispatch();
  const patient = useSelector((state) => state.waitingRoom.selectedUser.pk);

  const subjective = useRef();
  const objective = useRef();
  const assessment = useRef();
  const plan = useRef();
  return (
    <div class="patients-info-row">
      <div class="patients-title">
        <h4>Subjective</h4>
      </div>
      <div class="patients-info">
        <div>
          <ul>
            <li>
              <label></label>
              <div class="p-info">
                <div>
                  <label className="input-label">
                    <span className="input-span"></span>
                  </label>
                  <textarea
                    type="text"
                    name="radio-group"
                    className="form-input"
                    // onFocus={(e) => {
                    //   removeChoice(
                    //     question.pk,
                    //     `${choice.name}: ${e.target.value}`
                    //   );
                    // }}
                    ref={subjective}
                    defaultValue={props.triage.subjective}
                  ></textarea>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="patients-title">
        <h4>Objective</h4>
      </div>
      <div class="patients-info">
        <div>
          <ul>
            <li>
              <label></label>
              <div class="p-info">
                <div>
                  <label className="input-label">
                    <span className="input-span"></span>
                  </label>
                  <textarea
                    type="text"
                    name="radio-group"
                    className="form-input"
                    // onFocus={(e) => {
                    //   removeChoice(
                    //     question.pk,
                    //     `${choice.name}: ${e.target.value}`
                    //   );
                    // }}
                    ref={objective}
                    defaultValue={props.triage.objective}
                  ></textarea>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="patients-title">
        <h4>Assessment</h4>
      </div>
      <div class="patients-info">
        <div>
          <ul>
            <li>
              <label></label>
              <div class="p-info">
                <div>
                  <label className="input-label">
                    <span className="input-span"></span>
                  </label>
                  <textarea
                    type="text"
                    name="radio-group"
                    className="form-input"
                    // onFocus={(e) => {
                    //   removeChoice(
                    //     question.pk,
                    //     `${choice.name}: ${e.target.value}`
                    //   );
                    // }}
                    ref={assessment}
                    defaultValue={props.triage.assessment}
                  ></textarea>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="patients-title">
        <h4>Plan</h4>
      </div>
      <div class="patients-info">
        <div>
          <ul>
            <li>
              <label></label>
              <div class="p-info">
                <div>
                  <label className="input-label">
                    <span className="input-span"></span>
                  </label>
                  <textarea
                    type="text"
                    name="radio-group"
                    className="form-input"
                    // onFocus={(e) => {
                    //   removeChoice(
                    //     question.pk,
                    //     `${choice.name}: ${e.target.value}`
                    //   );
                    // }}
                    ref={plan}
                    defaultValue={props.triage.plan}
                  ></textarea>
                </div>
              </div>
            </li>
          </ul>
        </div>
        {props.isRegisteredPatient ? (
          <button
            className="blue-btn common-btn"
            onClick={() => {
              dispatch(
                updateTriageForm(
                  props.triage.pk,
                  {
                    subjective: subjective.current.value,
                    objective: objective.current.value,
                    assessment: assessment.current.value,
                    plan: plan.current.value,
                  },
                  patient
                )
              );
            }}
          >
            Submit
          </button>
        ) : (
          <button
            className="blue-btn common-btn"
            onClick={() => {
              dispatch(
                updateGuestTriageForm(
                  props.triage.pk,
                  {
                    subjective: subjective.current.value,
                    objective: objective.current.value,
                    assessment: assessment.current.value,
                    plan: plan.current.value,
                  },
                  patient
                )
              );
            }}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
}

export default ExistingTriageForm;
