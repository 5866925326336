import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

function Participant({ participant, isRemote }) {
  const dispatch = useDispatch();
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [trackDisable, setTrackDisable] = useState(false);
  const [audioDisable, setAudioDisable] = useState(false);
  const videoRef = useRef();
  const audioRef = useRef();

  const [isPortrait, setIsPortrait] = useState(false);

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  // Split identity
  const participantEmail = participant.identity.split("$")[0];
  const participantName = participant.identity.split("$")[1];
  // console.log(participant);
  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
        track.attach(videoRef.current);
      } else {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
        track.detach();
      } else {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    const trackEnabled = (trackPub) => {
      // console.log(`TRACK PUB: ${trackPub.kind}`);
      if (trackPub.kind == "video") {
        setTrackDisable(false);
        console.log(trackPub.track);
        trackPub.track.attach(videoRef.current);
      } else if (trackPub.kind == "audio") {
        setAudioDisable(false);
        trackPub.track.attach(audioRef.current);
        // trackPub.track.attach(videoRef.current);
      }
    };

    const trackDisabled = (trackPub) => {
      if (trackPub.kind == "video") {
        setTrackDisable(true);
      } else if (trackPub.kind == "audio") {
        setAudioDisable(true);
      }
      // trackPub.track.detach();
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    participant.on("trackEnabled", trackEnabled);
    participant.on("trackDisabled", trackDisabled);
    // console.log(participant);

    return () => {
      // setVideoTracks([]);
      // setAudioTracks([]);
      // participant.removeAllListeners();
      participant.removeListener("trackSubscribed", trackSubscribed);
      participant.removeListener("trackUnsubscribed", trackUnsubscribed);

      participant.removeListener("trackEnabled", trackEnabled);
      participant.removeListener("trackDisabled", trackDisabled);
    };
  }, [participant]);

  useEffect(() => {
    const videoStarted = (track) => {
      setVideoTracks((videoTracks) => [...videoTracks, track]);
      track.attach(videoRef.current);
    };
    const videoDisabled = (track) => {
      setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      track.detach();
    };
    const videoEnabled = (track) => {
      setVideoTracks((videoTracks) => [...videoTracks, track]);
      track.attach(videoRef.current);
    };
    const videoTrack = videoTracks[0];

    if (videoTrack) {
      videoTrack.on("started", videoStarted);
      videoTrack.on("disabled", videoDisabled);
      videoTrack.on("enabled", videoEnabled);
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach(videoRef.current);
        videoTrack.removeAllListeners();
      };
    }
  }, []);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];

    const audioTrackDisabled = () => {
      setAudioDisable(true);
    };

    const audioTrackEnabled = () => {
      // console.log("audio enabled");
      setAudioDisable(false);
    };
    if (audioTrack) {
      audioTrack.on("disabled", audioTrackDisabled);
      audioTrack.on("enabled", audioTrackEnabled);
      audioTrack.attach(audioRef.current);
      if (audioTrack.isEnabled) {
        setAudioDisable(false);
      } else {
        setAudioDisable(true);
      }
      return () => {
        // console.log("audio track useeffect exit");
        audioTrack.removeListener("disabled", audioTrackDisabled);
        audioTrack.removeListener("enabled", audioTrackEnabled);
      };
    }
  }, [audioTracks]);

  return (
    <div className="participant">
      <video
        ref={videoRef}
        autoPlay={true}
        style={{ display: trackDisable ? "none" : "block" }}
      />

      <audio ref={audioRef} autoPlay={true} />
    </div>
  );
}

export default Participant;
