import React, { useState, useRef, useEffect } from "react";
import prevImage from "../../../assets/images/prev-calendar.svg";
import nextImage from "../../../assets/images/next-calendar.svg";
import rightArrow from "../../../assets/images/select-right-arrow.png";
import { useSelector, useDispatch } from "react-redux";
import AppointmentItem from "./AppointmentItem";
import Calendar from "react-calendar";
import AppointmentSearch from "./AppointmentSearch";
import SimpleAppointmentstList from "./SimpleAppointmentstList";
import moment from "moment";
import {
  setViewType,
  setSearchResults,
  setSearchResultsActive,
  setSearchQuery,
  setFilterResults,
  appointmentFilterResults,
  setFilterResultsActive,
  setActiveTypeVisit,
} from "../../../actions/appointmentActions";
const CustomToolbar = (toolbar) => {
  const dispatch = useDispatch();
  const toolbarHeader = useRef();
  const { event: toolbars, updatedAppointment } = toolbar;
  const appointments = useSelector(
    (state) => state.appointmentReducer.appointments
  );
  const searchResults = useSelector(
    (state) => state.appointmentReducer.searchResults
  );
  const searchResultsActive = useSelector(
    (state) => state.appointmentReducer.searchResultsActive
  );
  const searchQuery = useSelector(
    (state) => state.appointmentReducer.searchQuery
  );
  const activeTypeOfVisit = useSelector(
    (state) => state.appointmentReducer.activeTypeOfVisit
  );
  const viewType = useSelector((state) => state.appointmentReducer.viewType);

  const eventsType = useSelector(
    (state) => state.appointmentReducer.eventTypes
  );
  const openSearch = () => {
    toolbarHeader.current.classList.add("search-active");
  };
  const closeSearch = () => {
    toolbarHeader.current.classList.remove("search-active");
    dispatch(setSearchQuery(""));
    if (searchResults.length) {
      dispatch(setSearchResults([]));
    }
    dispatch(setSearchResultsActive(false));
    dispatch(setViewType("colour"));
  };
  const dateHandler = (e) => {
    toolbars.onNavigate("", e);
  };

  //Retrieve SimpleAppointmentList from store
  const simpleAppointment = useSelector(
    (state) => state.appointmentReducer.simpleAppointments
  )

  return (
    <div className="rbc-toolbar">
      <div
        className={`${searchResultsActive ? "search-toolbar" : ""
          } toolbar-header`}
      >
        {searchResultsActive ? (
          <span className="rbc-toolbar-label">Calendar Search Results</span>
        ) : (
            <>
              <div className="rbc-btn-group">
                <div className="button-wrap">
                  <button
                    type="button"
                    className="noborder"
                    onClick={() => toolbars.onNavigate("PREV")}
                  >
                    <img src={prevImage} alt="" />
                  </button>
                  <button
                    className="noborder"
                    type="button"
                    onClick={() => toolbars.onNavigate("NEXT")}
                  >
                    <img src={nextImage} alt="" />
                  </button>
                </div>
                <span className="rbc-toolbar-label">
                  {moment(toolbars.date).format("MMMM, dddd DD")}
                </span>
              </div>

              <div className="right-btn">
                <span
                  className={`${viewType == "colour" ? "active" : ""}`}
                  onClick={(event) => {
                    dispatch(setViewType("colour"));
                  }}
                >
                  Colour
              </span>{" "}
              |{" "}
                <span
                  className={`${viewType == "simple" ? "active" : ""}`}
                  onClick={(event) => {
                    dispatch(setViewType("simple"));
                  }}
                >
                  Simple
              </span>
              </div>
            </>
          )}
      </div>
      {viewType == "simple" && (
        <SimpleAppointmentstList simpleAppointment={simpleAppointment} />
      )}
      {searchResultsActive && (
        <div className="toolbar-maincontent search-layout">
          <div className="inner">
            <div className="search-appointment-list">
              {searchResults.length
                ? searchResults.map((appointment, index) => {
                  return (
                    <AppointmentItem
                      appointment={appointment}
                      key={appointment.id}
                    />
                  );
                })
                : "No Appointments Found"}
            </div>
          </div>
        </div>
      )}
      <div class="toolbar-sidebar">
        <div
          class={`${(searchResults.length || searchQuery.trim().length) &&
            "search-active "} toolbar-subheader`}
          ref={toolbarHeader}
        >
          <span className="rbc-btn-group">
            <div className="month-wek-day-btn">
              <button
                type="button"
                onClick={() => toolbars.onView("week")}
                className={toolbars.view == "week" && "rbc-active"}
              >
                Week
              </button>
              <button
                type="button"
                onClick={() => toolbars.onView("day")}
                className={toolbars.view == "day" && "rbc-active"}
              >
                Day
              </button>
            </div>
            <button
              type="button"
              className="today"
              onClick={() => toolbars.onNavigate("TODAY")}
            >
              Today
            </button>
          </span>
          <div className="hidebtn" onClick={closeSearch}>
            <img src={rightArrow} alt="" />
          </div>
          <div className="search-wrapper">
            <AppointmentSearch focus={openSearch} closeSearch={closeSearch} />
          </div>
        </div>
        <div class="toolbar-heading"
          data-toggle="collapse"
          href="#collapseExample2"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample">
          <div>{searchResultsActive ? "Show" : "Type of Visit"}</div>
        </div>
        <div class="toolbar-lists collapse" id="collapseExample2">
          {searchResultsActive ? (
            <>
              <div>
                <div className="rectangle" />
                <span>Past</span>
              </div>
              <div>
                <div className="rectangle" />
                <span>Upcoming</span>
              </div>
            </>
          ) : (
              eventsType.map((item, index) => {
                return (
                  <div
                    className={`${activeTypeOfVisit && item.name == activeTypeOfVisit
                      ? "active"
                      : ""
                      }`}
                    key={index}
                    onClick={() => {
                      dispatch(
                        setActiveTypeVisit(
                          activeTypeOfVisit == item.name ? "" : item.name
                        )
                      );
                      if (activeTypeOfVisit == item.name) {
                        dispatch(setFilterResultsActive(false));
                      } else {
                        dispatch(setFilterResultsActive(true));
                      }
                    }}
                  >
                    <div className="rectangle" />
                    <span>{item.name}</span>
                  </div>
                );
              })
            )}
        </div>
        <div className="small-calendar">
          <Calendar
            onChange={dateHandler}
            value={toolbars.date}
            calendarType={"US"}
            next2Label={null}
            prev2Label={null}
            formatShortWeekday={(locale, value) =>
              ["S", "M", "T", "W", "T", "F", "S"][value.getDay()]
            }
          />
        </div>


      </div>
    </div >
  );
};

export default CustomToolbar;
