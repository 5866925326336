import React, { useState, useEffect } from "react";
import WaitingRoomChat from "./chat/WaitingRoomChat";
import Triage from "./triage";
import { useSelector, useDispatch } from "react-redux";
import { resetMessages } from "../../actions/waitingRoomActions";

function RightSection(props) {
  const [tab, changeTab] = useState("triage");
  const selectedUser = useSelector((state) => state.waitingRoom.selectedUser);
  const rightSetting = useSelector((state) => state.waitingRoom.rightSetting);
  const dispatch = useDispatch();
  useEffect(() => {
    changeTab(rightSetting);
    // return () => {
    //   dispatch(resetMessages());
    // };
  }, [selectedUser]);
  return selectedUser ? (
    <div class="right-sec">
      <div class="eql-height">
        <div class="waitting-tabbing">
          <ul>
            <li className={tab == "triage" ? "active-tabs" : ""}>
              <a
                href="#"
                title="Triage"
                onClick={(e) => {
                  e.preventDefault();
                  changeTab("triage");
                }}
              >
                Triage
              </a>
            </li>
            <li className={tab == "chat" ? "active-tabs" : ""}>
              <a
                href="#"
                title="Messenger"
                onClick={(e) => {
                  e.preventDefault();
                  changeTab("chat");
                }}
              >
                Messenger
              </a>
            </li>
          </ul>
        </div>
        {tab == "triage" ? (
          <Triage office={props.office} />
        ) : (
          <WaitingRoomChat />
        )}
      </div>
    </div>
  ) : (
    ""
  );
}

// onClick={() => dispatch(requestLogin())}

export default RightSection;
