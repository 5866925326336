import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ScrollToBottom from "react-scroll-to-bottom";
import moment from "moment";
import { getWaitingRoomThread } from "../../../actions/waitingRoomActions";
import Person from "../../../assets/images/person.svg";

function WaitingRoomChat(props) {
  const dispatch = useDispatch();
  const selectedUser = useSelector((state) => state.waitingRoom.selectedUser);
  const textMessage = useRef(null);
  const chatBox = useRef(null);
  const endChat = useRef(null);

  const userId = useSelector((state) => state.authenticateUser.profile.user.pk);
  const office = useSelector((state) => state.adminOfficeInteractions.office);
  const socket = useSelector((state) => state.adminOfficeInteractions.socket);

  function listenMessage(e) {
    const response_data = JSON.parse(e.data);
    if (response_data.type == "ping_selected_waiting_room_patient") {
      if (selectedUser) {
        dispatch(
          getWaitingRoomThread(
            selectedUser.pk,
            office.pk,
            selectedUser.unique_code
          )
        );
      }
    }
  }
  const waitingRoomThread = useSelector(
    (state) => state.waitingRoom.waitingRoomThread
  );
  useEffect(() => {
    dispatch(
      getWaitingRoomThread(selectedUser.pk, office.pk, selectedUser.unique_code)
    );

    socket.addEventListener("message", listenMessage);

    return () => {
      socket.removeEventListener("message", listenMessage);
    };
  }, [selectedUser]);

  useEffect(() => {
    if (waitingRoomThread) {
      socket.send(
        JSON.stringify({
          type: "SET_WAITING_ROOM_THREAD_READ_STATUS",
          threadId: waitingRoomThread.pk,
          read: true,
          office_slug: office.slug,
        })
      );
    }
  }, []);

  return waitingRoomThread ? (
    <div>
      <div class="patients-info-main white-box" id="chatBox">
        <div class="patients-info-heading">
          <h3>
            {selectedUser.first_name} {selectedUser.last_name}
          </h3>
        </div>
        <div ref={chatBox}>
          <ScrollToBottom className="chat-content chat">
            {waitingRoomThread.waiting_room_thread_messages
              ? waitingRoomThread.waiting_room_thread_messages.map((message) =>
                  message.sent_by ? (
                    message.sent_by.pk === userId ? (
                      <div class="chat-text right">
                        <div>
                          <div class="chatbox blue-bx">
                            <p>{message.message}</p>
                            <div class="short-name">o </div>
                          </div>
                          <span class="chat-time">
                            {moment(message.datetime).format(
                              "YYYY-MM-DD HH:mm"
                            )}{" "}
                            |{" "}
                            {message.sent_by.admin_profile
                              ? `${message.sent_by.admin_profile.first_name} ${message.sent_by.admin_profile.last_name}`
                              : `${message.sent_by.patient_profile.first_name} ${message.sent_by.patient_profile.last_name}`}
                            `
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="chat-text left">
                        <div>
                          <div class="chatbox grey-bx">
                            <p>{message.message}</p>
                            <div class="short-name">
                              <img src={Person} alt="" />{" "}
                            </div>
                          </div>

                          <span class="chat-time">
                            {message.sent_by.admin_profile
                              ? `${message.sent_by.admin_profile.first_name} ${message.sent_by.admin_profile.last_name}`
                              : `${message.sent_by.patient_profile.first_name} ${message.sent_by.patient_profile.last_name}`}
                            `|{" "}
                            {moment(message.datetime).format(
                              "YYYY-MM-DD HH:mm"
                            )}
                          </span>
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="chat-text left">
                      <div>
                        <div class="chatbox grey-bx">
                          <p>{message.message}</p>
                          <div class="short-name">
                            <img src={Person} alt="" />{" "}
                          </div>
                        </div>

                        <span class="chat-time">
                          {message.sent_by_guest}
                          {moment(message.datetime).format("YYYY-MM-DD HH:mm")}
                        </span>
                      </div>
                    </div>
                  )
                )
              : ""}
          </ScrollToBottom>
          <div
            className="chatEnd"
            ref={endChat}
            style={{ float: "left", clear: "both", marginTop: "60px" }}
          ></div>
        </div>
        <div class="type-msg">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              //   handleNewMessage(textMessage.current.value, chatChannel);
              socket.send(
                JSON.stringify({
                  type: "ADD_MESSAGE_TO_WAITING_ROOM_THREAD",
                  message: textMessage.current.value,
                  userId: userId,
                  threadId: waitingRoomThread.pk,
                  office_slug: office.slug,
                })
              );
              textMessage.current.value = "";
            }}
          >
            <input
              type="text"
              placeholder="Type a message..."
              class="common-input"
              ref={textMessage}
            />
          </form>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}

export default WaitingRoomChat;
