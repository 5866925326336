import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { attachTracks, detachTracks } from "../../utils/trackFunctions";

function LocalParticipant({ participant, isRemote }) {
  const dispatch = useDispatch();
  const localVideoTrack = useSelector(
    (state) => state.waitingRoom.localVideoTrack
  );
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const videoRef = useRef();
  const audioRef = useRef();

  const [isPortrait, setIsPortrait] = useState(false);

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      // setVideoTracks([]);
      // setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  // useEffect(() => {
  //   const videoTrack = videoTracks[0];

  //   if (videoTrack) {
  //     videoTrack.attach(videoRef.current);

  //     return () => {
  //       videoTrack.detach();
  //     };
  //   }
  // }, [videoTracks]);

  useEffect(() => {
    console.log(localVideoTrack);
    attachTracks([localVideoTrack], videoRef.current);
    return () => detachTracks([localVideoTrack]);
  }, [localVideoTrack]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <div className="participant" ref={videoRef}>
      {/* <video ref={videoRef} autoPlay={true} /> */}

      <audio ref={audioRef} autoPlay={true} />
    </div>
  );
}

export default LocalParticipant;
