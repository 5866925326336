import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setWaitingRoomStatus,
  getCurrentOffice,
  getWaitingRoomStatus,
} from "../actions/adminOfficeActions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import launchToast from "../utils";
import { useState } from "react";

export const WaitingRoomSettings = () => {
  const dispatch = useDispatch();

  const waitingRoomStatus = useSelector(
    (state) => state.adminOfficeInteractions.waitingRoomStatus
  );
  const office = useSelector((state) => state.adminOfficeInteractions.office);

  const [isChecked, setIsChecked] = useState(waitingRoomStatus);
  useEffect(() => {
    dispatch(getWaitingRoomStatus(office.slug));
    setIsChecked(waitingRoomStatus);
  }, []);

  return (
    <div
      id="#open-d-setting"
      class="archive-show"
      style={{ display: "block", padding: "0" }}
    >
      <ul>
        <li class="patients-only-bg">
          <div class="switch-main">
            <label class="switch">
              <input
                type="checkbox"
                defaultChecked={waitingRoomStatus}
                value={isChecked}
                onChange={(e) => {
                  setIsChecked(!isChecked);
                  dispatch(setWaitingRoomStatus(!isChecked, office.slug));
                }}
              />
              <span class="switch-btn"></span>
            </label>
            <span class="notify">Allow Guests</span>
          </div>
        </li>
        {isChecked ? (
          <li>
            <CopyToClipboard
              text={`${process.env.REACT_APP_WAITING_LINK}/${office.slug}`}
            >
              <a
                href="#"
                title="Copy Public Link"
                class="copy-public"
                onClick={(e) => {
                  e.preventDefault();
                  launchToast("Copied public link to clipboard.");
                }}
              >
                <span>Copy Public Link</span>
              </a>
            </CopyToClipboard>
          </li>
        ) : (
          ""
        )}
        {/* <li>
          <a href="#" title="Triage Settings">
            Triage Settings
          </a>
        </li> */}
      </ul>
    </div>
  );
};
