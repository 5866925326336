import React, { useEffect, useState } from "react";
import { VictoryLine, VictoryChart, VictoryAxis } from "victory";
import s from "./AppointmentsBooked.module.scss";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { getAppointmentsBooked } from "../../../actions/dashboardActions";

export const AppointmentsBooked = (props: any) => {
  const dispatch = useDispatch();
  const office = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.office
  );
  const appointmentsBooked = useSelector(
    (state: RootStateOrAny) => state.dashboardReducer.appointmentsBooked
  );
  useEffect(() => {
    dispatch(getAppointmentsBooked(office.pk));
  }, []);

  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = (ev: any) => {
    setWidth(ev.target.innerWidth);
  };

  // useEffect replaces `componentDidMount` and others
  useEffect(() => {
    window.addEventListener("resize", updateWidth);

    // Removes listener on unmount
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  return (
    <div className={s.whiteBox}>
      <div className="row">
        <div className="col-12">
          <h3 className={s.title}>Appointments Booked</h3>
        </div>
      </div>
      <div className="row" style={{ position: "relative" }}>
        <div className={s.chartContainer} style={{ width: "100%" }}>
          {appointmentsBooked && (
            <VictoryChart
              style={{
                parent: { height: "100%", width: "100%" },
              }}
              domainPadding={20}
              height={180}
              padding={{ left: 40, right: 40, top: 10, bottom: 50 }}
            >
              <VictoryAxis
                tickValues={[
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday",
                ]}
                style={{
                  tickLabels: {
                    fontSize: "11px",
                    fontWeight: "500",
                    lineHeight: "11px",
                    color: "#828282",
                    fontFamily: "ProximaSoft-Regular",
                  },
                }}
              />
              <VictoryAxis
                dependentAxis
                tickValues={[2, 4, 6, 8, 10]}
                style={{
                  tickLabels: {
                    fontSize: "11px",
                    fontWeight: "500",
                    lineHeight: "11px",
                    color: "#828282",
                    fontFamily: "ProximaSoft-Regular",
                  },
                }}
              />
              {appointmentsBooked.this_week && (
                <VictoryLine
                  animate={{
                    duration: 2000,
                    onLoad: { duration: 1000 },
                  }}
                  data={appointmentsBooked.this_week}
                  x="day"
                  y="count"
                  style={{
                    data: {
                      stroke: "#4F5895",
                      strokeWidth: "2px",
                    },
                  }}
                />
              )}
              {appointmentsBooked.last_week && (
                <VictoryLine
                  animate={{
                    duration: 2000,
                    onLoad: { duration: 1000 },
                  }}
                  data={appointmentsBooked.last_week}
                  x="day"
                  y="count"
                  style={{
                    data: {
                      stroke: "#9CA9E2",
                      strokeWidth: "2px",
                    },
                  }}
                />
              )}
            </VictoryChart>
          )}
        </div>
      </div>
    </div>
  );
};
