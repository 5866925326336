import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { setOfficeHourDetails } from "../../../actions/adminOfficeActions";
const HoursOfOperation = (props) => {
  const dispatch = useDispatch();
  const officeHoursOfOperation = useSelector(
    (state) => state.adminOfficeInteractions.officeHoursOfOperation
  );
  const daysNames = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const { register, control, handleSubmit, watch } = useForm({
    defaultValues: {
      days: officeHoursOfOperation && officeHoursOfOperation.days,
    },
  });
  const days = watch("days");
  const { fields, append } = useFieldArray({
    control,
    name: "days",
  });
  const onSubmit = (data) => {
    dispatch(setOfficeHourDetails(data));
    props.stepsHandler(3);
  };
  return (
    <div className="mt-44">
      <div className="content-group">
        <div className="content-wrapper">
          <div className="mt-26">
            <p>
              Select what time your office is open and closed at. If your office
              is closed during lunch break, be sure add it under each day.
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="weekdays-list">
            {fields.map((item, index) => {
              return (
                <div class="week-item-wrapper" key={index}>
                  <div className="week-item">
                    <span
                      class="notify"
                      style={{ paddingLeft: 0, paddingRight: "15px" }}
                    >
                      {daysNames[index]} closed?
                    </span>
                    <label class="switch">
                      <input
                        type="checkbox"
                        ref={register}
                        name={`days[${index}].closed`}
                      />
                      <span class="switch-btn" />
                    </label>
                  </div>
                  {!days[index].closed && (
                    <>
                      <div className="week-item">
                        <label className="feild-label field-left start">
                          {daysNames[index]}
                        </label>
                        <div className="form-fieldbox field-left timefield">
                          <Controller
                            render={({ onChange, value }) => (
                              <DatePicker
                                showTimeSelect
                                showTimeSelectOnly
                                autoComplete="off"
                                timeCaption="Time"
                                className="form-control form-feild timepicker-input"
                                placeholderText="00:00"
                                dateFormat="h:mm aa"
                                onChange={(date) => onChange(date)}
                                selected={value}
                              />
                            )}
                            name={`days[${index}].from_hour`}
                            control={control}
                          />
                        </div>
                        <label className="feild-label field-left end">to</label>
                        <div className="form-fieldbox field-left timefield">
                          <Controller
                            render={({ onChange, value }) => (
                              <DatePicker
                                showTimeSelect
                                showTimeSelectOnly
                                autoComplete="off"
                                timeCaption="Time"
                                className="form-control form-feild timepicker-input"
                                placeholderText="00:00"
                                dateFormat="h:mm aa"
                                onChange={(date) => onChange(date)}
                                selected={value}
                              />
                            )}
                            name={`days[${index}].to_hour`}
                            control={control}
                          />
                        </div>
                      </div>
                      <div className="week-item">
                        <span
                          class="notify"
                          style={{ paddingLeft: 0, paddingRight: "15px" }}
                        >
                          Break?
                        </span>
                        <label class="switch">
                          <input
                            type="checkbox"
                            ref={register}
                            name={`days[${index}].lunch`}
                          />
                          <span class="switch-btn" />
                        </label>
                      </div>
                      {days[index].lunch && (
                        <div className="week-item">
                          <label className="feild-label field-left start">
                            Break
                          </label>
                          <div className="form-fieldbox field-left timefield">
                            <Controller
                              render={({ onChange, value }) => (
                                <DatePicker
                                  showTimeSelect
                                  showTimeSelectOnly
                                  autoComplete="off"
                                  timeCaption="Time"
                                  className="form-control form-feild timepicker-input"
                                  placeholderText="00:00"
                                  dateFormat="h:mm aa"
                                  onChange={(date) => onChange(date)}
                                  selected={value}
                                />
                              )}
                              name={`days[${index}].lunch_start`}
                              control={control}
                            />
                          </div>
                          <label className="feild-label field-left end">
                            to
                          </label>
                          <div className="form-fieldbox field-left timefield">
                            <Controller
                              control={control}
                              name={`days[${index}].lunch_end`}
                              render={({ onChange, value }) => (
                                <DatePicker
                                  showTimeSelect
                                  showTimeSelectOnly
                                  autoComplete="off"
                                  timeCaption="Time"
                                  className="form-control form-feild timepicker-input"
                                  placeholderText="00:00"
                                  dateFormat="h:mm aa"
                                  onChange={(date) => onChange(date)}
                                  selected={value}
                                />
                              )}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </div>
          <div className="bottom-buttons flex-end">
            <a
              className="common-btn white-btn common-btn-updated mr-15"
              onClick={(e) => {
                e.preventDefault();
                props.stepsHandler(1);
              }}
            >
              Go Back
            </a>
            <input
              type="submit"
              value="Next"
              className="common-btn blue-btn common-btn-updated"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default HoursOfOperation;
