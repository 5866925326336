import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOfficeInfo,
  updateOffice,
  resetGetOfficeInfo,
} from "../../actions/adminOfficeActions";
import { BounceLoader } from "react-spinners";
import Modal from "react-modal";
import AddMember from "./AddMember";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    maxHeight: "100vh",
    marginRight: "-50%",
    overflowY: "auto",
    overflow: "hidden",
    transform: "translate(-50%, -50%)",
    padding: 0,
  },
};

function EditOffice(props) {
  const [office, selectOffice] = useState("");
  const [addMemberOpen, setAddMemberOpen] = useState(false);
  const dispatch = useDispatch();

  const isFetchingOffice = useSelector(
    (state) => state.adminOfficeInteractions.isFetchingOffice
  );
  const officeEdit = useSelector(
    (state) => state.adminOfficeInteractions.officeEdit
  );
  const isUpdatingOffice = useSelector(
    (state) => state.adminOfficeInteractions.isUpdatingOffice
  );
  const updateOfficeMessage = useSelector(
    (state) => state.adminOfficeInteractions.updateOfficeMessage
  );

  const open_at = useRef();
  const close_at = useRef();
  const office_type = useRef();
  const allow_inbox = useRef();
  const allow_waiting_room = useRef();
  const is_open = useRef();

  useEffect(() => {
    return () => dispatch(resetGetOfficeInfo());
  }, []);

  return (
    <div id="edit-office" class=" create-new-office-popup">
      <Modal
        isOpen={addMemberOpen}
        onRequestClose={() => setAddMemberOpen(false)}
        style={customStyles}
      >
        <AddMember office={office} profile={props.profile} />
      </Modal>
      <div class="form-main create-office-form big-popup">
        <div class="forgot-pass-form common-form box-with-shadow">
          <div class="sub-heading">
            <h3>Edit Office</h3>
            <div class="close-box">
              <a
                href="#"
                title=""
                class="fancybox-button fancybox-close-small"
                data-fancybox-close=""
              >
                <img src="images/close.png" alt="" />
              </a>
            </div>
          </div>
          <div class="inner-forgot-pass">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                dispatch(
                  updateOffice(
                    office,
                    open_at.current.value,
                    close_at.current.value,
                    office_type.current.value,
                    is_open.current.value,
                    allow_inbox.current.value,
                    allow_waiting_room.current.value
                  )
                );
              }}
            >
              <div class="office-name">
                <label>Office Name:</label>
                <select
                  class="common-input"
                  onChange={(value) => {
                    if (value !== "") {
                      selectOffice(value.target.value);
                      console.log(value.target.value);
                      dispatch(getOfficeInfo(value.target.value));
                    }
                  }}
                >
                  <option value="">- Select Office -</option>
                  {props.offices.map((office) => (
                    <option value={office.pk}>{office.name}</option>
                  ))}
                </select>
              </div>

              {!isFetchingOffice ? (
                officeEdit ? (
                  <React.Fragment>
                    <div class="invite-members">
                      <label>Edit Members:</label>
                      <div class="member-main-form member-main-table">
                        <div class="custom-scrl-content mCustomScrollbar">
                          <table>
                            {officeEdit.administrator_set.map((user) => (
                              <tr>
                                <td>
                                  {user ? user.first_name : ""}{" "}
                                  {user ? user.last_name : ""}
                                </td>
                                <td></td>
                                <td>{user ? user.role : ""}</td>
                                <td>
                                  <a
                                    href="#"
                                    class="close-member"
                                    title="close"
                                  ></a>
                                </td>
                              </tr>
                            ))}
                          </table>
                        </div>
                      </div>
                      {/* <div class="add-another">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setAddMemberOpen(true);
                          }}
                        >
                          <span>Add member</span>
                        </button>
                      </div> */}
                      <div class="office-name" style={{ marginTop: "20px" }}>
                        <label>Open at:</label>
                        <input
                          type="time"
                          placeholder=""
                          class="common-input"
                          defaultValue={officeEdit.opened_at}
                          ref={open_at}
                        />
                      </div>
                      <div class="office-name" style={{ marginTop: "20px" }}>
                        <label>Close at:</label>
                        <input
                          type="time"
                          placeholder=""
                          class="common-input"
                          defaultValue={officeEdit.closed_at}
                          ref={close_at}
                        />
                      </div>
                      <div class="office-name" style={{ marginTop: "20px" }}>
                        <label>Open Office?:</label>
                        <select
                          className="common-input"
                          defaultValue={officeEdit.is_open}
                          ref={is_open}
                        >
                          <option value="true">Open</option>
                          <option value="false">Close</option>
                        </select>
                      </div>
                      <div class="office-name" style={{ marginTop: "20px" }}>
                        <label>Office Type:</label>
                        <select
                          className="common-input"
                          defaultValue={officeEdit.availability}
                          ref={office_type}
                        >
                          <option value="public">Public</option>
                          <option value="private">Private</option>
                        </select>
                      </div>
                      <div class="office-name" style={{ marginTop: "20px" }}>
                        <label>Enable Inbox:</label>
                        <select
                          className="common-input"
                          defaultValue={officeEdit.allow_inbox}
                          ref={allow_inbox}
                        >
                          <option value="true">Enable</option>
                          <option value="false">Disable</option>
                        </select>
                      </div>
                      <div class="office-name" style={{ marginTop: "20px" }}>
                        <label>Enable Waiting Room:</label>
                        <select
                          className="common-input"
                          defaultValue={officeEdit.allow_waiting_room}
                          ref={allow_waiting_room}
                        >
                          <option value="true">Enable</option>
                          <option value="false">Disable</option>
                        </select>
                      </div>
                      <div class="login-submit">
                        {/* <a
                          href="#"
                          title="Delete Office"
                          class="common-btn border-btn"
                        >
                          Delete Office
                        </a> */}
                        <p>{updateOfficeMessage ? updateOfficeMessage : ""}</p>
                        {!isUpdatingOffice ? (
                          <input
                            type="submit"
                            class="sky-btn common-btn"
                            value="Submit Changes"
                          />
                        ) : (
                          <BounceLoader size={60} color={"#052D64"} />
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                )
              ) : (
                <BounceLoader size={60} color={"#052D64"} />
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditOffice;
