import React, { useEffect } from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import {
  getOfficeAdmins,
  inviteUsersToOffice,
  removeAdminFromOffice,
} from "../../../actions/officeSettingsActions";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { BounceLoader } from "react-spinners";

export const Users: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const office = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.office
  );

  const officeAdmins = useSelector(
    (state: RootStateOrAny) => state.officeSettingsReducer.officeAdmins
  );
  const isFetchingInvites = useSelector(
    (state: RootStateOrAny) => state.officeSettingsReducer.isFetchingInvites
  );

  const profile = useSelector(
    (state: RootStateOrAny) => state.authenticateUser.profile
  );

  const { control, register, handleSubmit, watch, errors } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "admins",
  });

  //Adding state accesor to listen for error Message
  const inviteErrorMessage = useSelector(
    (state: RootStateOrAny) => state.officeSettingsReducer.inviteErrorMessage
  );

  useEffect(() => {
    dispatch(getOfficeAdmins(office.pk));
  }, []);

  useEffect(() => {
    officeAdmins.map((admin: any) =>
      append({
        email: admin.user.email,
        first_name: `${admin.first_name}`,
        last_name: `${admin.last_name}`,
      })
    );
  }, [officeAdmins]);

  const formSubmit = (data: any) => {
    console.log(data);

    const admins = data.admins.filter((admin: any) => admin.email !== "");
    dispatch(inviteUsersToOffice(admins, office.pk, profile.pk));
  };

  return (
    <div className="white-box meeting-details">
      <div className="app-setting-head section-head">
        <h4 className="w-100">Admins in this Office</h4>
        <p>Manage the users who have access to this office.</p>
      </div>
      <form className="w-100" onSubmit={handleSubmit(formSubmit)}>
        <div className="form-field-space">
          <div className="w-100 feild-row form-group">
            {fields.map((item, index) => (
              <div className="inner-fields-main">
                <div
                  className="inner-fields"
                  key={item.id}
                  style={{
                    position: "relative",
                    marginTop: "20px ",
                  }}
                >
                  <div className="fields-row-main">
                    <div className="fields-row">
                      <label>Email Address</label>
                      <input
                        type="email"
                        placeholder=""
                        className="common-input"
                        ref={register()}
                        name={`admins[${index}].email`}
                        defaultValue={`${item.email}`}
                      />
                    </div>
                  </div>
                  <div className="fields-row-main">
                    <div className="fields-row half">
                      <label>First Name</label>
                      <input
                        type="text"
                        placeholder=""
                        className="common-input"
                        ref={register()}
                        name={`admins[${index}].first_name`}
                        defaultValue={`${item.first_name}`}
                      />
                    </div>
                    <div className="fields-row half">
                      <label>Last Name</label>
                      <input
                        type="text"
                        placeholder=""
                        className="common-input"
                        ref={register()}
                        name={`admins[${index}].last_name`}
                        defaultValue={`${item.last_name}`}
                      />
                    </div>
                  </div>
                </div>
                <div className="guests-link">
                  <a
                    href="#"
                    title="Uninvite Guest"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(removeAdminFromOffice(item.email, office.pk));
                      remove(index);
                    }}
                  >
                    Delete Admin
                  </a>
                </div>
              </div>
            ))}
            <div className="add-guest" style={{ marginLeft: 0 }}>
              <a
                href="#"
                title=""
                className="addguest"
                onClick={(e) => {
                  e.preventDefault();
                  append({
                    email: "",
                    first_name: "",
                    last_name: "",
                  });
                }}
              >
                + Add Admin
              </a>
            </div>
          </div>
        </div>
        <div className="timeslot-buttonbox">
          {!isFetchingInvites ? (
            <button
              type="submit"
              className="btn btn-primary btn-sm text-capitalize"
            >
              Save changes
            </button>
          ) : (
            <BounceLoader />
          )}

          <p className="error-message">{inviteErrorMessage}</p>
        </div>
      </form>
    </div>
  );
};
