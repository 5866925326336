import React from "react";

export const Account: React.FC = (props: any) => {
  return (
    <div className="white-box">
      <div className="app-setting-head section-head">
        <h4
          className="w-100"
          style={{ marginBottom: "30px", paddingBottom: "10px" }}
        >
          Account
        </h4>
        <hr style={{ marginTop: "30px" }} />
        <a
          style={{
            color: "blue",
          }}
          href="https://getcorigan.ca/wp-content/uploads/2020/08/23876453-v4-Bluewater-Privacy-Policy-1-converted.pdf"
          target="_blank"
        >
          Privacy Policy 1.2
        </a>
        <br />
        <a
          style={{
            color: "blue",
          }}
          href="https://getcorigan.ca/wp-content/uploads/2020/08/23875342-v3-Bluewater-Terms-Conditions-Patients-1-converted.pdf"
          target="_blank"
        >
          Terms and Conditions
        </a>
      </div>
      {/* <div className="app-setting-head section-head">
        <h4
          className="w-100"
          style={{ marginBottom: "30px", paddingBottom: "10px" }}
        >
          Deleting your Office
        </h4>

        <p>
          Deleting an office will permanently delete all your patients from your
          account. Patients will still have access to their accounts.
        </p>
      </div>
      <form className="w-100">
        <div className="timeslot-box" />

        <div className="timeslot-buttonbox">
          <button
            type="submit"
            className="btn btn-primary btn-sm text-capitalize"
          >
            Delete Office
          </button>
        </div>
      </form> */}
    </div>
  );
};
