import launchToast from "../utils";
import moment from "moment";
import CarIcon from "../assets/images/car_icon.png";
import OfficeIcon from "../assets/images/office_icon.png";
import VideoIcon from "../assets/images/video_icon.png";
import OfficeWhite from "../assets/images/person_office_icon.png";

const initialState = {
  isFetchingAppointments: false,
  isFetchingSimpleAppointments: false,
  appointments: [],
  simpleAppointments: [],
  isFetchingSymptoms: false,
  symptoms: [],
  selectedSymptoms: [],
  selectedImmunizations: [],
  isFetchingAppointment: false,
  appointment: null,
  covidInfo: null,
  userImmunization: null,
  isDeletingAppointment: false,
  hasDeletedAppointment: false,
  isFetchingAutomatedMessage: false,
  automatedMessage: null,
  automatedMessageError: "null",
  isSavingAutomaticMessage: false,
  newUser: null,
  printers: null,
  reservedSpots: [],
  isSubmittingAppointment: false,
  hasSubmittedAppointment: false,
  isFetchingAppointmentDateTimes: false,
  hasReceivedAppointmentDateTimes: false,
  dateTimes: [],
  isUpdatingData: false,
  hasUpdatedData: false,
  date: moment().toDate(),
  view: "day",
  patientAppointments: [],
  isRetrievingPatientAppointments: false,
  appointmentInterval: 15,
  isPrintingData: false,
  notes: "",
  exportFiles: [],
  isFetchingExportFiles: false,
  users: null,
  viewType: "colour",
  activeTypeOfVisit: null,
  searchResults: [],
  filterResults: [],
  searchResultsActive: null,
  filterResultsActive: null,
  searchQuery: "",
  eventTypes: [
    {
      name: "Car Appoinment",
      color: "#6DCDB8",
      src: CarIcon,
      imgWhite: OfficeWhite,
    },
    {
      name: "Walk In",
      color: "#308572",
      src: OfficeIcon,
    },
    {
      name: "Scheduled Appointment",
      color: "#C66F6F",
    },
    {
      name: "Virtual Appointment",
      color: "#6475BD",
      src: VideoIcon,
    },
  ],
};

const appointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_GET_EXPORT_FILES_LIST":
      return Object.assign({}, state, {
        isFetchingExportFiles: true,
      });
    case "SET_SEARCH_RESULTS_ACTIVE":
      return Object.assign({}, state, {
        searchResultsActive: action.value,
      });
    case "SET_FILTER_RESULTS_ACTIVE":
      return Object.assign({}, state, {
        filterResultsActive: action.value,
      });
    case "SET_SEARCH_RESULTS":
      return Object.assign({}, state, {
        searchResults: action.value,
      });
    case "SET_FILTER_RESULTS":
      return Object.assign({}, state, {
        filterResults: action.value,
      });
    case "SET_USER_ID":
      return Object.assign({}, state, {
        users: action.users,
      });
    case "SET_ACTIVE_TYPE_VISIT":
      return Object.assign({}, state, {
        activeTypeOfVisit: action.name,
      });
    case "SET_SEARCH_QUERY":
      return Object.assign({}, state, {
        searchQuery: action.value,
      });
    case "SET_VIEW_TYPE":
      return Object.assign({}, state, {
        viewType: action.viewtype,
      });
    case "RECEIVE_GET_EXPORT_FILES_LIST":
      return Object.assign({}, state, {
        exportFiles: action.exportFiles,
        isFetchingExportFiles: false,
      });
    case "ERROR_GET_EXPORT_FILES_LIST":
      return Object.assign({}, state, {
        isFetchingExportFiles: false,
      });
    case "RESET_GET_EXPORT_FILES_LIST":
      return Object.assign({}, state, {
        exportFiles: [],
      });
    case "REQUEST_PRINT_DATA":
      return Object.assign({}, state, {
        isPrintingData: true,
      });
    case "SET_APPOINTMENT_NOTES":
      return Object.assign({}, state, {
        notes: action.value,
      });
    case "RECEIVE_PRINT_DATA":
      return Object.assign({}, state, {
        isPrintingData: false,
      });
    case "ERROR_PRINT_DATA":
      return Object.assign({}, state, {
        isPrintingData: false,
      });
    case "RECEIVE_GET_APPOINTMENT_INTERVAL":
      return Object.assign({}, state, {
        appointmentInterval: action.appointmentInterval,
      });
    case "REQUEST_GET_APPOINTMENT_BY_OFFICE_AND_PATIENT":
      return Object.assign({}, state, {
        isRetrievingPatientAppointments: true,
      });
    case "RECEIVE_GET_APPOINTMENT_BY_OFFICE_AND_PATIENT":
      return Object.assign({}, state, {
        isRetrievingPatientAppointments: false,
        patientAppointments: action.patientAppointments,
      });
    case "RESET_PATIENT_APPOINTMENTS":
      return Object.assign({}, state, {
        patientAppointments: [],
      });
    case "SET_CALENDAR_DATE":
      return Object.assign({}, state, {
        date: action.date,
      });
    case "SET_CALENDAR_VIEW":
      return Object.assign({}, state, {
        view: action.view,
      });
    case "REQUEST_GET_APPOINTMENTS_BY_DATETIME":
      return Object.assign({}, state, {
        isFetchingAppointmentDateTimes: true,
        hasReceivedAppointmentDateTimes: false,
      });
    case "RECEIVE_GET_APPOINTMENTS_BY_DATETIME":
      return Object.assign({}, state, {
        isFetchingAppointmentDateTimes: false,
        hasReceivedAppointmentDateTimes: true,
        dateTimes: action.dateTimes,
      });
    case "ERROR_GET_APPOINTMENTS_BY_DATETIME":
      return Object.assign({}, state, {
        isFetchingAppointmentDateTimes: false,
        hasReceivedAppointmentDateTimes: false,
      });
    case "RESET_GET_APPOINTMENTS_BY_DATETIME":
      return Object.assign({}, state, {
        isFetchingAppointmentDateTimes: false,
        hasReceivedAppointmentDateTimes: false,
      });
    case "REQUEST_SUBMIT_APPOINTMENT":
      return Object.assign({}, state, {
        isSubmittingAppointment: action.isSubmittingAppointment,
      });
    case "RECEIVE_SUBMIT_APPOINTMENT":
      return Object.assign({}, state, {
        isSubmittingAppointment: false,
        hasSubmittedAppointment: true,
      });
    case "ERROR_SUBMIT_APPOINTMENT":
      return Object.assign({}, state, {
        isSubmittingAppointment: false,
      });
    case "RESET_SUBMITTED_APPOINTMENT":
      return Object.assign({}, state, {
        hasSubmittedAppointment: false,
      });
    case "RECEIVE_GET_RESERVED_SPOTS":
      return Object.assign({}, state, {
        reservedSpots: action.reservedSpots,
      });
    case "SET_PRINTERS":
      return Object.assign({}, state, {
        printers: action.printers,
      });
    case "SET_NEW_USER":
      return Object.assign({}, state, {
        newUser: action.newUser,
      });
    case "REQUEST_SAVE_AUTOMATED_MESSAGE":
      return Object.assign({}, state, {
        isSavingAutomaticMessage: action.isSavingAutomaticMessage,
      });

    case "RECEIVE_SAVE_AUTOMATED_MESSAGE":
      return Object.assign({}, state, {
        isSavingAutomaticMessage: action.isSavingAutomaticMessage,
      });
    case "ERROR_SAVE_AUTOMATED_MESSAGE":
      return Object.assign({}, state, {
        isSavingAutomaticMessage: action.isSavingAutomaticMessage,
      });

    case "REQUEST_AUTOMATED_MESSAGE":
      return Object.assign({}, state, {
        isFetchingAutomatedMessage: true,
      });
    case "RECEIVE_AUTOMATED_MESSAGE":
      return Object.assign({}, state, {
        automatedMessage: action.automatedMessage,
        isFetchingAutomatedMessage: false,
      });
    case "ERROR_AUTOMATED_MESSAGE":
      return Object.assign({}, state, {
        isFetchingAutomatedMessage: false,
        automatedMessageError: action.automatedMessageError,
      });
    case "REQUEST_DELETE_APPOINTMENT":
      return Object.assign({}, state, {
        isDeletingAppointment: action.isDeletingAppointment,
      });
    case "RECEIVE_DELETE_APPOINTMENT":
      launchToast("Deleted appointment successfully");
      return Object.assign({}, state, {
        isDeletingAppointment: action.isDeletingAppointment,
        hasDeletedAppointment: action.hasDeletedAppointment,
      });
    case "RESET_DELETE_APPOINTMENT":
      return Object.assign({}, state, {
        hasDeletedAppointment: action.hasDeletedAppointment,
      });
    case "RECEIVE_GET_COVID_INFO":
      return Object.assign({}, state, {
        covidInfo: action.covidInfo,
      });
    case "RECEIVE_GET_USER_IMMUNIZATION_DATA":
      return Object.assign({}, state, {
        userImmunization: action.userImmunization,
      });
    case "REQUEST_GET_APPOINTMENTS":
      return Object.assign({}, state, {
        isFetchingAppointments: true,
      });
    case "RECEIVE_GET_APPOINTMENTS":
      return Object.assign({}, state, {
        isFetchingAppointments: false,
        appointments: action.appointments,
      });
    case "REQUEST_SYMPTOMS_LIST":
      return Object.assign({}, state, {
        isFetchingSymptoms: true,
      });
    case "RECEIVE_SYMPTOMS_LIST":
      return Object.assign({}, state, {
        isFetchingSymptoms: false,
        symptoms: action.symptoms,
      });
    case "ERROR_SYMPTOMS_LIST":
      return Object.assign({}, state, {
        isFetchingSymptoms: false,
      });
    case "SELECT_SYMPTOM":
      return Object.assign({}, state, {
        selectedSymptoms: [...state.selectedSymptoms, action.symptomPk].filter(
          (v, i, a) => a.indexOf(v) === i
        ),
      });
    case "REMOVE_SYMPTOM":
      return Object.assign({}, state, {
        selectedSymptoms: state.selectedSymptoms.filter(
          (symptom) => symptom !== action.symptomPk
        ),
      });
    case "SELECT_IMMUNIZATION":
      return Object.assign({}, state, {
        selectedImmunizations: [
          ...state.selectedImmunizations,
          action.immunizationPk,
        ].filter((v, i, a) => a.indexOf(v) === i),
      });
    case "REMOVE_IMMUNIZATION":
      return Object.assign({}, state, {
        selectedImmunizations: state.selectedImmunizations.filter(
          (symptom) => symptom !== action.immunizationPk
        ),
      });
    case "RESET_SYMPTOM":
      return Object.assign({}, state, {
        selectedSymptoms: [],
      });
    case "RESET_IMMUNIZATIONS":
      return Object.assign({}, state, {
        selectedImmunizations: [],
      });
    case "RESET_NEW_USER":
      return Object.assign({}, state, {
        newUser: null,
      });
    case "REQUEST_GET_APPOINTMENT":
      return Object.assign({}, state, {
        isFetchingAppointment: true,
      });
    case "RECEIVE_GET_APPOINTMENT":
      return Object.assign({}, state, {
        isFetchingAppointment: false,
        appointment: action.appointment,
      });
    case "RESET_APPOINTMENT":
      return Object.assign({}, state, {
        appointment: null,
      });
    case "RESET_APPOINTMENT":
      return Object.assign({}, state, {
        appointments: [],
      });
    case "RESET_COVID_INFO":
      return Object.assign({}, state, {
        covidInfo: null,
      });
    case "RESET_IMMUNIZATION_DATA":
      return Object.assign({}, state, {
        userImmunization: null,
      });
    case "REQUEST_UPDATE_DATA":
      return Object.assign({}, state, {
        isUpdatingData: true,
      });
    case "RECEIVE_UPDATE_DATA":
      return Object.assign({}, state, {
        isUpdatingData: false,
        hasUpdatedData: true,
      });
    case "ERROR_UPDATE_DATA":
      return Object.assign({}, state, {
        isUpdatingData: false,
        hasUpdatedData: false,
      });
    case "RESET_UPDATE_DATA":
      return Object.assign({}, state, {
        isUpdatingData: false,
        hasUpdatedData: false,
      });
    //Added new SimpleListView Actions to reducer
    case "REQUEST_GET_SIMPLE_APPOINTMENTS":
        return Object.assign({}, state, {
          isFetchingSimpleAppointments: true,
        });
    case "RECEIVE_GET_SIMPLE_APPOINTMENTS":
        return Object.assign({}, state, {
          isFetchingSimpleAppointments: false,
          simpleAppointments: action.simpleAppointments,
        });
    default:
      return state;
  }
};

export default appointmentReducer;
