import React, { useEffect } from "react";
import styles from "./UpcomingList.module.scss";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { getDailyAppointments } from "../../../actions/appointmentActions";
import { UpcomingListItem } from "./UpcomingListItem/UpcomingListItem";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Link } from "react-router-dom";

export const UpcomingList = (props: any) => {
  const dispatch = useDispatch();
  const office = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.office
  );

  useEffect(() => {
    dispatch(getDailyAppointments(office.pk, Date.now(), 15));
  }, []);

  const appointments = useSelector(
    (state: RootStateOrAny) => state.appointmentReducer.appointments
  );

  const isFetchingAppointments = useSelector(
    (state: RootStateOrAny) => state.appointmentReducer.isFetchingAppointments
  );

  return (
    <div className={styles.whiteBox}>
      <div className="row">
        <div className="col-6">
          <h3 className={styles.todaysAppointment}>Today's Apppointments</h3>
        </div>
        <div className="col-6">
          <Link to={`/office/${office.slug}/appointments/`}>
            <button className={styles.primaryButton}>View Calendar</button>
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className={styles.upcomingDays}>
            {appointments.length > 0 && !isFetchingAppointments ? (
              appointments.map((appointment: any) =>{if(appointment.title == "Patient Deleted") 
                                                      return null
                                                    else return (
                                                      <UpcomingListItem appointment={appointment} office={office} />
                                                    )
                                                    } )
            ) : isFetchingAppointments ? (
              <div className={styles.skeletonContainer}>
                <SkeletonTheme color="#F8F8FD" highlightColor="#9CA9E2">
                  <Skeleton
                    count={5}
                    height={33}
                    duration={4}
                    style={{ marginTop: "6px" }}
                  />
                </SkeletonTheme>
              </div>
            ) : (
              <p>No Appointments today!</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
