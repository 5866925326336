import connectToChat from "./connectToChat";
import authenticateUser from "./authenticateUser";
import patientInteractions from "./patientInteractions";
import adminOfficeInteractions from "./adminOfficeInteractions";
import waitingRoom from "./waitingRoom";
import messagingReducer from "./messagingReducer";
import meetingRoomReducer from "./meetingRoomReducer";
import appointmentReducer from "./appointmentReducer";
import bulletinReducer from "./bulletinReducer";
import newAppointmentReducer from "./newAppointmentReducer";
import officeSettingsReducer from "./officeSettingsReducer";
import dashboardReducer from "./dashboardReducer";
import internalChatReducer from "./internalChatReducer";
import { combineReducers } from "redux";

const allReducers = combineReducers({
  connectToChat,
  patientInteractions,
  authenticateUser,
  adminOfficeInteractions,
  waitingRoom,
  messagingReducer,
  meetingRoomReducer,
  appointmentReducer,
  bulletinReducer,
  newAppointmentReducer,
  officeSettingsReducer,
  dashboardReducer,
  internalChatReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }
  return allReducers(state, action);
};

export default rootReducer;
