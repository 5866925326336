import moment from "moment";
import launchToast from "../utils";
const initialState = {
  officeHasLoaded: false,
  isFetchingOffice: false,
  waitingPosition: 0,
  waitingQueue: 0,
  office: {},
  patientList: [],
  isFetchingPatients: false,
  patientsHaveLoaded: false,
  isFetchingRequests: false,
  hasReceivedRequests: false,
  officeRequests: [],
  // SOCKET INFO
  socketIsConnecting: false,
  socketHasConnected: false,
  socketStatus: "disconnected",
  isFetchingApproval: false,
  isFetchingDecline: false,
  isFetchingInvites: false,
  hasSentInvite: false,
  isFetchingPastAppointments: false,
  pastAppointments: [],
  isFetchingOffice: true,
  fetchOfficeErrorMessage: "",
  officeEdit: null,
  pastAppointmentRequestNumber: 1,
  isUpdatingOffice: false,
  updateOfficeMessage: "",
  isSendingOfficeRequest: false,
  officeRequestMessage: "",
  isFetchingPatient: false,
  patient: null,
  fetchingPatientMessage: "",
  pastAppointment: null,
  isFetchingPastAppointment: false,
  isFetchingPastAppointmentMessage: "",
  menuSetting: "open",
  waitingRoomStatus: false,
  newOfficeDetails: null,
  officeHoursOfOperation: {
    days: new Array(7).fill().map((_, index) => ({
      from_hour: moment("09:00:00", "hh:mm:ss").toDate(),
      to_hour: moment("12:00:00", "hh:mm:ss").toDate(),
      closed: true,
      lunch: false,
      lunch_start: "",
      lunch_end: "",
    })),
  },
  officeNewEvents: {
    users: [],
  },
  officeTerms: false,
  isCreatingOffice: false,
  hasCreatedOffice: false,
};

const adminOfficeInteractions = (state = initialState, action) => {
  switch (action.type) {
    case "SET_NEW_OFFICE":
      return Object.assign({}, state, {
        newOfficeDetails: action.office,
      });
    case "SET_OFFICE_HOURS_DETAILS":
      return Object.assign({}, state, {
        officeHoursOfOperation: action.hours,
      });
    case "SET_OFFICE_NEW_EVENTS":
      return Object.assign({}, state, {
        officeNewEvents: action.events,
      });
    case "SET_OFFICE_TERMS":
      return Object.assign({}, state, {
        officeTerms: action.check,
      });
    case "SET_WAITING_LIST":
      return Object.assign({}, state, {
        waitingQueue: action.number,
      });
    case "SET_SOCKET_STATUS":
      return Object.assign({}, state, {
        socketStatus: action.socketStatus,
      });
    case "SET_WAITING_POSITION":
      return Object.assign({}, state, {
        waitingPosition: action.number,
      });
    case "REQUEST_OFFICE":
      return Object.assign({}, state, {
        isFetchingOffice: true,
      });
    case "RECEIVE_OFFICE":
      return Object.assign({}, state, {
        isFetchingOffice: false,
        officeHasLoaded: true,
        office: action.office,
      });
    case "REQUEST_ADD_USER_TO_OFFICE":
      return Object.assign({}, state, {
        isSendingOfficeRequest: true,
        officeRequestMessage: "",
      });
    case "RECEIVE_ADD_USER_TO_OFFICE":
      return Object.assign({}, state, {
        isSendingOfficeRequest: false,
        officeRequestMessage: action.officeRequestMessage,
      });
    case "ERROR_ADD_USER_TO_OFFICE":
      return Object.assign({}, state, {
        isSendingOfficeRequest: false,
        officeRequestMessage: action.officeRequestMessage,
      });
    case "INCREMENT_PAGE_NUM":
      return Object.assign({}, state, {
        pastAppointmentRequestNumber: action.pastAppointmentRequestNumber,
      });
    case "RESET_PAGE_NUM":
      return Object.assign({}, state, {
        pastAppointmentRequestNumber: 1,
      });
    case "RESET_PATIENT_LIST":
      return Object.assign({}, state, {
        patientList: [],
      });
    case "RESET_PAST_APPOINTMENTS":
      return Object.assign({}, state, {
        pastAppointments: [],
      });
    case "REQUEST_UPDATE_OFFICE":
      return Object.assign({}, state, {
        isUpdatingOffice: true,
      });
    case "RECEIVE_UPDATE_OFFICE":
      return Object.assign({}, state, {
        isUpdatingOffice: false,
        updateOfficeMessage: action.updateOfficeMessage,
      });
    case "ERROR_UPDATE_OFFICE":
      return Object.assign({}, state, {
        isUpdatingOffice: false,
        updateOfficeMessage: action.updateOfficeMessage,
      });
    case "REQUEST_PATIENT_LIST":
      return Object.assign({}, state, {
        isFetchingPatients: true,
      });
    case "RECEIVE_PATIENT_LIST":
      return Object.assign({}, state, {
        isFetchingPatients: false,
        patientsHaveLoaded: true,
        patientList: [...state.patientList, ...action.patientList],
      });
    case "ERROR_PATIENT_LIST":
      launchToast(action.errorMessage);
      return Object.assign({}, state, {
        isFetchingPatients: false,
      });
    case "REQUEST_OFFICE_REQUESTS":
      return Object.assign({}, state, {
        isFetchingRequests: true,
      });
    case "RECEIVE_OFFICE_REQUESTS":
      return Object.assign({}, state, {
        isFetchingRequests: false,
        hasReceivedRequests: true,
        officeRequests: action.officeRequests,
      });
    case "REQUEST_SOCKET_CONNECTION":
      return Object.assign({}, state, {
        socketIsConnecting: true,
      });
    case "RECEIVE_SOCKET_CONNECTION":
      return Object.assign({}, state, {
        socketIsConnecting: false,
        socketHasConnected: true,
        socket: action.socket,
      });
    case "ERROR_SOCKET_CONNECTION":
      launchToast(`Error connecting to socket: ${action.errorMessage}`);
      return Object.assign({}, state, {
        socketIsConnecting: false,
      });
    case "REQUEST_APPROVE_OFFICE_REQUEST":
      return Object.assign({}, state, {
        isFetchingApproval: true,
      });
    case "RECEIVE_APPROVE_OFFICE_REQUEST":
      return Object.assign({}, state, {
        isFetchingApproval: false,
      });
    case "REQUEST_DECLINE_OFFICE_REQUEST":
      return Object.assign({}, state, {
        isFetchingDecline: true,
      });
    case "RECEIVE_DECLINE_OFFICE_REQUEST":
      return Object.assign({}, state, {
        isFetchingDecline: false,
      });
    case "REQUEST_INVITE_USERS_TO_OFFICE":
      return Object.assign({}, state, {
        isFetchingInvites: true,
      });
    case "RECEIVE_INVITE_USERS_TO_OFFICE":
      return Object.assign({}, state, {
        isFetchingInvites: false,
        hasSentInvite: true,
      });
    case "REQUEST_GET_PAST_APPOINTMENTS":
      return Object.assign({}, state, {
        isFetchingPastAppointments: true,
      });
    case "RECEIVE_GET_PAST_APPOINTMENTS":
      return Object.assign({}, state, {
        isFetchingPastAppointments: false,
        pastAppointments: [
          ...state.pastAppointments,
          ...action.pastAppointments,
        ],
      });
    case "ERROR_RECEIVE_GET_PAST_APPOINTMENTS":
      launchToast(action.errorMessage);
      return Object.assign({}, state, {
        isFetchingPastAppointments: false,
      });
    case "REQUEST_GET_OFFICE_INFO":
      return Object.assign({}, state, {
        isFetchingOffice: true,
        fetchOfficeErrorMessage: "",
      });
    case "RECEIVE_GET_OFFICE_INFO":
      return Object.assign({}, state, {
        isFetchingOffice: false,
        officeEdit: action.officeEdit,
      });
    case "RESET_GET_OFFICE_INFO":
      return Object.assign({}, state, {
        isFetchingOffice: false,
        officeEdit: null,
      });
    case "ERROR_GET_OFFICE_INFO":
      return Object.assign({}, state, {
        isFetchingOffice: false,
        fetchOfficeErrorMessage: action.fetchOfficeErrorMessage,
      });
    case "REQUEST_GET_OFFICE_PATIENT":
      return Object.assign({}, state, {
        isFetchingPatient: true,
        patient: null,
        fetchingPatientMessage: "",
      });
    case "RECEIVE_GET_OFFICE_PATIENT":
      return Object.assign({}, state, {
        isFetchingPatient: false,
        patient: action.patient,
      });
    case "ERROR_GET_OFFICE_PATIENT":
      return Object.assign({}, state, {
        isFetchingPatient: false,
        fetchingPatientMessage: action.message,
      });
    case "REQUEST_GET_PAST_APPOINTMENT":
      return Object.assign({}, state, {
        isFetchingPastAppointment: true,
        isFetchingPastAppointmentMessage: "",
      });
    case "RECEIVE_GET_PAST_APPOINTMENT":
      return Object.assign({}, state, {
        isFetchingPastAppointment: false,
        pastAppointment: action.pastAppointment,
      });
    case "CHANGE_MENU_SETTING":
      return Object.assign({}, state, {
        menuSetting: action.menuSetting,
      });
    case "ERROR_GET_PAST_APPOINTMENT":
      return Object.assign({}, state, {
        isFetchingPastAppointment: false,
        isFetchingPastAppointmentMessage:
          action.isFetchingPastAppointmentMessage,
      });
    case "CHANGE_OFFICE_STATUS":
      return Object.assign({}, state, {
        waitingRoomStatus: action.waitingRoomStatus,
      });
    case "REQUEST_CREATE_OFFICE_AND_INVITE_USERS":
      return Object.assign({}, state, {
        isCreatingOffice: true,
        hasCreatedOffice: false,
      });
    case "RECEIVE_CREATE_OFFICE_AND_INVITE_USERS":
      return Object.assign({}, state, {
        isCreatingOffice: false,
        hasCreatedOffice: true,
      });
    case "ERROR_CREATE_OFFICE_AND_INVITE_USERS":
      return Object.assign({}, state, {
        isCreatingOffice: false,
        hasCreatedOffice: false,
      });
    default:
      return state;
  }
};

export default adminOfficeInteractions;
