import moment from "moment";
import React from "react";
import DashboardTop from "../dashboardTop";
import { AgeDemographics } from "./AgeDemographics/AgeDemographics";
import { AppointmentsBooked } from "./AppointmentsBooked/AppointmentsBooked";
import styles from "./Dashboard.module.scss";
import { Scheduled } from "./Scheduled/Scheduled";
import { UpcomingBooked } from "./UpcomingBooked/UpcomingBooked";
import { UpcomingList } from "./UpcomingList/UpcomingList";

export const Dashboard = (props: any) => {
  return (
    <div>
      <DashboardTop title={"Dashboard"} />
      <div className="date-container">
        <h3 className={styles.date}>{moment().format("dddd, MMMM Do")}</h3>
      </div>
      <div
        className="container-fluid"
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <div className="row">
          <div className="col-12 col-xl-9">
            <UpcomingList />
          </div>
          <div className="d-none d-xl-block col-xl-3">
            <AgeDemographics />
          </div>

          <div className="col-12 col-xl-5">
            <AppointmentsBooked />
          </div>
          <div className="col-6 col-xl-2">
            <UpcomingBooked />
          </div>
          <div className="col-6 col-xl-2">
            <Scheduled />
          </div>
        </div>
      </div>
    </div>
  );
};
