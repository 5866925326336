import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updateTriageNotes,
  getTriageQuestions,
  resetSelectedUser,
  getTriageForm,
} from "../../actions/waitingRoomActions";
import Copy from "../../assets/images/copy.svg";
import close from "../../assets/images/x.png";
import { BounceLoader } from "react-spinners";
import TriageQuestion from "./triageQuestion";
import ExistingTriageForm from "./existingTriageForm";

function Triage(props) {
  const isFetchingTriageForm = useSelector(
    (state) => state.waitingRoom.isFetchingTriageForm
  );
  const isUpdatingTriageMessage = useSelector(
    (state) => state.waitingRoom.isUpdatingTriageNoteMessage
  );

  const dispatch = useDispatch();
  const triageForm = useSelector((state) => state.waitingRoom.triageForm);
  const copyText = useRef(null);
  const nurseNote = useRef(null);
  const selectedUser = useSelector((state) => state.waitingRoom.selectedUser);
  const isUpdatingTriageNote = useSelector(
    (state) => state.waitingRoom.isUpdatingTriageNote
  );
  const triageQuestions = useSelector(
    (state) => state.waitingRoom.triageQuestions
  );
  const isFetchingTriageQuestions = useSelector(
    (state) => state.waitingRoom.isFetchingTriageQuestions
  );

  const socket = useSelector((state) => state.adminOfficeInteractions.socket);

  function listenMessage(e) {
    console.log(selectedUser.pk);
    const response_data = JSON.parse(e.data);
    if (response_data.type == "ping_selected_triage") {
      if (response_data.patient_pk === selectedUser.pk) {
        dispatch(getTriageForm(selectedUser.pk));
      } else {
        return;
      }
    }
  }

  useEffect(() => {
    // dispatch(getTriageQuestions(triageForm[0].symptoms.pk));
  }, [triageForm]);

  useEffect(() => {
    socket.addEventListener("message", listenMessage);
    return () => {
      socket.removeEventListener("message", listenMessage);
    };
  }, [selectedUser]);

  return (
    <div>
      <div class="patients-info-main white-box">
        <div class="patients-info-heading">
          <h3>
            {selectedUser.first_name
              ? `${selectedUser.first_name} ${selectedUser.last_name}`
              : `${selectedUser.name}`}
          </h3>
          <a
            href="#"
            class="copy"
            onClick={(e) => {
              e.preventDefault();
              dispatch(resetSelectedUser());
            }}
          >
            <img src={close} alt="" />
          </a>
        </div>
        {!isFetchingTriageForm ? (
          <div class="custom-scrl-content mCustomScrollbar">
            <div class="patients-info-row">
              <div class="patients-title">
                <h4>Patient</h4>
              </div>
              <div class="patients-info">
                <ul>
                  {triageForm[0].patient ? (
                    <li>
                      <label>1. Patient Info:</label>
                      <ul>
                        <li>
                          <span style={{ fontWeight: "bold" }}>Name:</span>{" "}
                          {triageForm[0].patient.first_name}{" "}
                          {triageForm[0].patient.last_name}
                        </li>
                        <li>
                          <span style={{ fontWeight: "bold" }}>Gender:</span>{" "}
                          {triageForm[0].patient.gender}
                        </li>
                        <li>
                          <span style={{ fontWeight: "bold" }}>
                            Date of Birth:
                          </span>{" "}
                          {triageForm[0].patient.date_of_birth}
                        </li>
                        <li>
                          <span style={{ fontWeight: "bold" }}>Phone:</span>{" "}
                          {triageForm[0].patient.phone}
                        </li>
                        <li>
                          <span style={{ fontWeight: "bold" }}>OHIP #:</span>{" "}
                          {triageForm[0].patient.ohip_num}
                        </li>
                        <li>
                          <span style={{ fontWeight: "bold" }}>OHIP VC:</span>{" "}
                          {triageForm[0].patient.ohip_vc}
                        </li>
                        <li>
                          <span style={{ fontWeight: "bold" }}>Address :</span>{" "}
                          {triageForm[0].patient.address}
                        </li>
                      </ul>
                    </li>
                  ) : triageForm[0].guest_patient ? (
                    <li>
                      <label>1. Patient Info:</label>
                      <ul>
                        <li>
                          <span style={{ fontWeight: "bold" }}>Name:</span>{" "}
                          {triageForm[0].guest_patient.name}
                        </li>
                        <li>This user has not registered for Corigan.</li>
                      </ul>
                    </li>
                  ) : (
                    ""
                  )}
                  {triageForm.symptoms ? (
                    <li>
                      <label>2. Symptoms:</label>
                      {triageForm[0].symptoms.name}
                    </li>
                  ) : (
                    ""
                  )}
                  {/* <div class="p-info">
                        <input
                          type="radio"
                          id={symptom.pk}
                          name="radio-group"
                        />
                        <label for={symptom.pk}>
                          <span>{symptom.name}</span>
                        </label>
                      </div> */}
                  {/* <li>
                    <label>3. Patient Details:</label>
                    <div class="p-info">
                      <textarea
                        class="common-input"
                        defaultValue={triageForm[0].patient_notes}
                        readOnly
                      ></textarea>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
            {!triageForm[0].triage_questions ? (
              <TriageQuestion
                symptom={triageForm[0].symptoms}
                triage={triageForm[0].pk}
                isRegisteredPatient={triageForm[0].patient ? true : false}
              />
            ) : (
              <ExistingTriageForm
                triage={triageForm[0].triage_questions}
                symptom={triageForm[0].symptoms}
                triagePK={triageForm[0].pk}
                isRegisteredPatient={triageForm[0].patient ? true : false}
              />
            )}
          </div>
        ) : (
          <BounceLoader color={"#052D64"} size={50} />
        )}
      </div>
    </div>
  );
}

// onClick={() => dispatch(requestLogin())}

export default Triage;
