import React from "react";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/style2.css";
import "./assets/css/style.css";
import "./assets/css/style3.css";
import "./assets/css/responsive.css";
import "./assets/css/fancybox.min.css";
import { useSelector } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import { ProtectedRoute } from "./components/protected.route";

import Home from "./containers/Home";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import Dashboard from "./containers/Dashboard";
import Office from "./containers/Office";
import { toast } from "react-toastify";
import ForgotPassword from "./components/ForgotPassword";
import PasswordReset from "./components/PasswordReset";
import AcceptInvite from "./components/AcceptInvite";
import AddOffice from "./components/office/AddOffice";

function App() {
  const authed = useSelector((state) => state.authenticateUser.isAuthenticated);
  toast.configure();
  return (
    <BrowserRouter>
      <Route path="/" exact component={Login} />
      {/* <Route path="/login" exact component={Login} /> */}
      <Route path="/SignUp" exact component={SignUp} />
      <Route path="/forgot-password/" exact component={ForgotPassword} />
      <Route path="/password-reset/" exact component={PasswordReset} />
      <Route path="/accept-invite" exact component={AcceptInvite} />
      <ProtectedRoute path="/dashboard" authed={authed} component={Dashboard} />
      <ProtectedRoute path="/office/:slug" authed={authed} component={Office} />
      <ProtectedRoute
        path="/add-office/"
        authed={authed}
        exact
        component={AddOffice}
      />
    </BrowserRouter>
  );
}

export default App;
