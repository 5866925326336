const initialState = {
  isCreatingBulletinPost: false,
  hasCreatedBulletinPost: false,
  createBulletinPostError: "",
  isFetchingBulletinPosts: false,
  bulletinPosts: [],
};

const bulletinReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_CREATE_BULLETIN_POST":
      return Object.assign({}, state, {
        isCreatingBulletinPost: true,
        hasCreatedBulletinPost: false,
        createBulletinPostError: "",
      });
    case "RECEIVE_CREATE_BULLETIN_POST":
      return Object.assign({}, state, {
        isCreatingBulletinPost: false,
        hasCreatedBulletinPost: true,
      });
    case "ERROR_CREATE_BULLETIN_POST":
      return Object.assign({}, state, {
        isCreatingBulletinPost: false,
        createBulletinPostError: "There was an error creating this post.",
      });
    case "REQUEST_GET_BULLETIN_POSTS":
      return Object.assign({}, state, {
        isFetchingBulletinPosts: true,
      });
    case "RECEIVE_GET_BULLETIN_POSTS":
      return Object.assign({}, state, {
        isFetchingBulletinPosts: false,
        bulletinPosts: action.bulletinPosts,
      });
    case "ERROR_GET_BULLETIN_POSTS":
      return Object.assign({}, state, {
        isFetchingBulletinPosts: false,
      });
    case "RESET_HAS_CREATED_BULLETIN":
      return Object.assign({}, state, {
        hasCreatedBulletin: false,
      });
    default:
      return state;
  }
};

export default bulletinReducer;
