import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../actions/authActions";
import { BounceLoader } from "react-spinners";

// images
import Logo from "../assets/images/logo.svg";

function Login() {
  const isFetching = useSelector((state) => state.authenticateUser.isFetching);
  const isAuthenticated = useSelector(
    (state) => state.authenticateUser.isAuthenticated
  );
  const profileReceived = useSelector(
    (state) => state.authenticateUser.profileReceived
  );
  const errorMessage = useSelector(
    (state) => state.authenticateUser.error_message
  );
  const dispatch = useDispatch();
  const email = useRef(null);
  const password = useRef(null);

  return (
    <div className="login-form-main full-height">
      <div className="form-bg">
        <div className="vertical-middle form-main">
          <div className="form-logo-main">
            <a href="#" title="My Docs" className="form-logo">
              <img src={Logo} alt="" style={{ width: "60%" }} />
            </a>
          </div>
          <div className="login-form common-form">
            <div className="form-head">
              <h2>Log into your account.</h2>
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
              <p>
                <input
                  type="text"
                  ref={email}
                  className="common-input"
                  placeholder="Email Address"
                />
              </p>
              <p>
                <input
                  type="password"
                  ref={password}
                  className="common-input"
                  placeholder="Password"
                />
              </p>
              <div className="forgot-pass">
                <Link to="/forgot-password/">Forgot your password</Link>
              </div>
              {errorMessage ? (
                <div className="wrong-credentials">
                  <p>{errorMessage}</p>
                </div>
              ) : (
                ""
              )}
              <div className="login-submit">
                {!isFetching ? (
                  <button
                    type="submit"
                    className="blue-btn common-btn"
                    onClick={() =>
                      dispatch(
                        loginUser({
                          email: email.current.value,
                          password: password.current.value,
                        })
                      )
                    }
                  >
                    Login
                  </button>
                ) : (
                  <BounceLoader color={"#052D64"} size={35} />
                )}
              </div>
            </form>
          </div>
          <footer>
            <ul>
              <li>
                <a
                  href="#privacy-policy"
                  className="fancybox"
                  title="Privacy Policy"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="#" title="Terms & Conditions">
                  {" "}
                  • Terms & Conditions
                </a>
              </li>
            </ul>
          </footer>
          <div id="privacy-policy" className="display-none">
            <div className="form-main privacy-policy-form">
              <div className="login-form forgot-pass-form common-form">
                <div className="sub-heading privacy-policy-head">
                  <a href="#" className="privacy-logo">
                    <img src="images/blue-logo.png" alt="" />
                  </a>
                  <h3>Privacy Policy</h3>
                  <div className="close-box">
                    <a
                      href="#"
                      title=""
                      className="fancybox-button fancybox-close-small"
                      data-fancybox-close=""
                    >
                      <img src="images/close.png" alt="" />
                    </a>
                  </div>
                </div>
                <div className="inner-forgot-pass privacy-inner">
                  <div id="cstm-scrlbar">
                    <div className="custom-scrl-content mCustomScrollbar">
                      <div className="policy-content">
                        <h2>Interpretation and Definitions</h2>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Praesent sed finibus lorem, vestibulum elementum
                          ex. Curabitur sit amet nulla nec arcu tincidunt
                          euismod sed nec nisi. Nulla ut porttitor purus, eget
                          gravida velit. Nulla a turpis vitae quam scelerisque
                          ultricies eu eget tellus.{" "}
                        </p>
                        <p>
                          Quisque lectus nunc, vulputate sit amet venenatis nec,
                          ultricies vel metus. Duis lacus dolor, pharetra eget
                          varius quis, egestas sed mi. Nunc aliquet lacus enim,
                          et semper nisi venenatis vitae. Aliquam euismod dictum
                          nulla eget elementum. Sed porttitor quis neque vel
                          tempus. Orci varius natoque penatibus et magnis dis
                          parturient montes, nascetur ridiculus mus. Praesent
                          eget quam lobortis, accumsan erat eget, vestibulum
                          leo.
                        </p>
                        <h2>Donec Non Tortor</h2>
                        <p>
                          Etiam id ullamcorper ligula, nec porttitor purus.
                          Pellentesque tincidunt velit felis, vitae facilisis
                          ligula fermentum vel. Vestibulum at maximus dolor.
                          Interdum et malesuada fames ac ante ipsum primis in
                          faucibus. Vivamus pharetra luctus odio, vel rutrum
                          sapien. Ut eget elit auctor, tincidunt ex vel,
                          placerat ante.
                        </p>
                        <p>
                          Donec eu purus et leo efficitur sollicitudin vitae
                          quis lectus. In egestas nisi sed felis consequat
                          efficitur. Sed a commodo arcu. Suspendisse nunc est,
                          efficitur ac feugiat et, lobortis eu quam. Proin
                          sodales ac tortor id finibus. Mauris viverra accumsan
                          vehicula. Sed accumsan nulla at purus laoreet,
                          pulvinar tincidunt sapien eleifend. Etiam ut ipsum in
                          nunc convallis pretium. In urna massa, ornare eu
                          sollicitudin ac, porta vitae libero. Curabitur
                          pharetra, tortor in faucibus convallis, dolor tellus
                          fermentum leo, eu gravida mauris libero sit amet leo.
                          Nullam dictum ipsum quis purus tincidunt lobortis.
                          Donec aliquam ultricies lectus at porta. Vivamus magna
                          felis, auctor id magna vitae, posuere suscipit sapien.
                        </p>
                        <p>
                          Etiam id ullamcorper ligula, nec porttitor purus.
                          Pellentesque tincidunt velit felis, vitae facilisis
                          ligula fermentum vel. Vestibulum at maximus dolor.
                          Interdum et malesuada fames ac ante ipsum primis in
                          faucibus. Vivamus pharetra luctus odio, vel rutrum
                          sapien. Ut eget elit auctor, tincidunt ex vel,
                          placerat ante.
                        </p>
                        <p>
                          Donec eu purus et leo efficitur sollicitudin vitae
                          quis lectus. In egestas nisi sed felis consequat
                          efficitur. Sed a commodo arcu. Suspendisse nunc est,
                          efficitur ac feugiat et, lobortis eu quam. Proin
                          sodales ac tortor id finibus. Mauris viverra accumsan
                          vehicula. Sed accumsan nulla at purus laoreet,
                          pulvinar tincidunt sapien eleifend. Etiam ut ipsum in
                          nunc convallis pretium. In urna massa, ornare eu
                          sollicitudin ac, porta vitae libero. Curabitur
                          pharetra, tortor in faucibus convallis, dolor tellus
                          fermentum leo, eu gravida mauris libero sit amet leo.
                          Nullam dictum ipsum quis purus tincidunt lobortis.
                          Donec aliquam ultricies lectus at porta. Vivamus magna
                          felis, auctor id magna vitae, posuere suscipit sapien.
                        </p>
                        <p>
                          Etiam id ullamcorper ligula, nec porttitor purus.
                          Pellentesque tincidunt velit felis, vitae facilisis
                          ligula fermentum vel. Vestibulum at maximus dolor.
                          Interdum et malesuada fames ac ante ipsum primis in
                          faucibus. Vivamus pharetra luctus odio, vel rutrum
                          sapien. Ut eget elit auctor, tincidunt ex vel,
                          placerat ante.
                        </p>
                        <p>
                          Donec eu purus et leo efficitur sollicitudin vitae
                          quis lectus. In egestas nisi sed felis consequat
                          efficitur. Sed a commodo arcu. Suspendisse nunc est,
                          efficitur ac feugiat et, lobortis eu quam. Proin
                          sodales ac tortor id finibus. Mauris viverra accumsan
                          vehicula. Sed accumsan nulla at purus laoreet,
                          pulvinar tincidunt sapien eleifend. Etiam ut ipsum in
                          nunc convallis pretium. In urna massa, ornare eu
                          sollicitudin ac, porta vitae libero. Curabitur
                          pharetra, tortor in faucibus convallis, dolor tellus
                          fermentum leo, eu gravida mauris libero sit amet leo.
                          Nullam dictum ipsum quis purus tincidunt lobortis.
                          Donec aliquam ultricies lectus at porta. Vivamus magna
                          felis, auctor id magna vitae, posuere suscipit sapien.
                        </p>
                        <p>
                          Etiam id ullamcorper ligula, nec porttitor purus.
                          Pellentesque tincidunt velit felis, vitae facilisis
                          ligula fermentum vel. Vestibulum at maximus dolor.
                          Interdum et malesuada fames ac ante ipsum primis in
                          faucibus. Vivamus pharetra luctus odio, vel rutrum
                          sapien. Ut eget elit auctor, tincidunt ex vel,
                          placerat ante.
                        </p>
                        <p>
                          Donec eu purus et leo efficitur sollicitudin vitae
                          quis lectus. In egestas nisi sed felis consequat
                          efficitur. Sed a commodo arcu. Suspendisse nunc est,
                          efficitur ac feugiat et, lobortis eu quam. Proin
                          sodales ac tortor id finibus. Mauris viverra accumsan
                          vehicula. Sed accumsan nulla at purus laoreet,
                          pulvinar tincidunt sapien eleifend. Etiam ut ipsum in
                          nunc convallis pretium. In urna massa, ornare eu
                          sollicitudin ac, porta vitae libero. Curabitur
                          pharetra, tortor in faucibus convallis, dolor tellus
                          fermentum leo, eu gravida mauris libero sit amet leo.
                          Nullam dictum ipsum quis purus tincidunt lobortis.
                          Donec aliquam ultricies lectus at porta. Vivamus magna
                          felis, auctor id magna vitae, posuere suscipit sapien.
                        </p>
                        <p>
                          Etiam id ullamcorper ligula, nec porttitor purus.
                          Pellentesque tincidunt velit felis, vitae facilisis
                          ligula fermentum vel. Vestibulum at maximus dolor.
                          Interdum et malesuada fames ac ante ipsum primis in
                          faucibus. Vivamus pharetra luctus odio, vel rutrum
                          sapien. Ut eget elit auctor, tincidunt ex vel,
                          placerat ante.
                        </p>
                        <p>
                          Donec eu purus et leo efficitur sollicitudin vitae
                          quis lectus. In egestas nisi sed felis consequat
                          efficitur. Sed a commodo arcu. Suspendisse nunc est,
                          efficitur ac feugiat et, lobortis eu quam. Proin
                          sodales ac tortor id finibus. Mauris viverra accumsan
                          vehicula. Sed accumsan nulla at purus laoreet,
                          pulvinar tincidunt sapien eleifend. Etiam ut ipsum in
                          nunc convallis pretium. In urna massa, ornare eu
                          sollicitudin ac, porta vitae libero. Curabitur
                          pharetra, tortor in faucibus convallis, dolor tellus
                          fermentum leo, eu gravida mauris libero sit amet leo.
                          Nullam dictum ipsum quis purus tincidunt lobortis.
                          Donec aliquam ultricies lectus at porta. Vivamus magna
                          felis, auctor id magna vitae, posuere suscipit sapien.
                        </p>
                        <p>
                          Etiam id ullamcorper ligula, nec porttitor purus.
                          Pellentesque tincidunt velit felis, vitae facilisis
                          ligula fermentum vel. Vestibulum at maximus dolor.
                          Interdum et malesuada fames ac ante ipsum primis in
                          faucibus. Vivamus pharetra luctus odio, vel rutrum
                          sapien. Ut eget elit auctor, tincidunt ex vel,
                          placerat ante.
                        </p>
                        <p>
                          Donec eu purus et leo efficitur sollicitudin vitae
                          quis lectus. In egestas nisi sed felis consequat
                          efficitur. Sed a commodo arcu. Suspendisse nunc est,
                          efficitur ac feugiat et, lobortis eu quam. Proin
                          sodales ac tortor id finibus. Mauris viverra accumsan
                          vehicula. Sed accumsan nulla at purus laoreet,
                          pulvinar tincidunt sapien eleifend. Etiam ut ipsum in
                          nunc convallis pretium. In urna massa, ornare eu
                          sollicitudin ac, porta vitae libero. Curabitur
                          pharetra, tortor in faucibus convallis, dolor tellus
                          fermentum leo, eu gravida mauris libero sit amet leo.
                          Nullam dictum ipsum quis purus tincidunt lobortis.
                          Donec aliquam ultricies lectus at porta. Vivamus magna
                          felis, auctor id magna vitae, posuere suscipit sapien.
                        </p>
                        <p>
                          Donec eu purus et leo efficitur sollicitudin vitae
                          quis lectus. In egestas nisi sed felis consequat
                          efficitur. Sed a commodo arcu. Suspendisse nunc est,
                          efficitur ac feugiat et, lobortis eu quam. Proin
                          sodales ac tortor id finibus. Mauris viverra accumsan
                          vehicula. Sed accumsan nulla at purus laoreet,
                          pulvinar tincidunt sapien eleifend. Etiam ut ipsum in
                          nunc convallis pretium. In urna massa, ornare eu
                          sollicitudin ac, porta vitae libero. Curabitur
                          pharetra, tortor in faucibus convallis, dolor tellus
                          fermentum leo, eu gravida mauris libero sit amet leo.
                          Nullam dictum ipsum quis purus tincidunt lobortis.
                          Donec aliquam ultricies lectus at porta. Vivamus magna
                          felis, auctor id magna vitae, posuere suscipit sapien.
                        </p>
                        <p>
                          Donec eu purus et leo efficitur sollicitudin vitae
                          quis lectus. In egestas nisi sed felis consequat
                          efficitur. Sed a commodo arcu. Suspendisse nunc est,
                          efficitur ac feugiat et, lobortis eu quam. Proin
                          sodales ac tortor id finibus. Mauris viverra accumsan
                          vehicula. Sed accumsan nulla at purus laoreet,
                          pulvinar tincidunt sapien eleifend. Etiam ut ipsum in
                          nunc convallis pretium. In urna massa, ornare eu
                          sollicitudin ac, porta vitae libero. Curabitur
                          pharetra, tortor in faucibus convallis, dolor tellus
                          fermentum leo, eu gravida mauris libero sit amet leo.
                          Nullam dictum ipsum quis purus tincidunt lobortis.
                          Donec aliquam ultricies lectus at porta. Vivamus magna
                          felis, auctor id magna vitae, posuere suscipit sapien.
                        </p>
                        <p>
                          Donec eu purus et leo efficitur sollicitudin vitae
                          quis lectus. In egestas nisi sed felis consequat
                          efficitur. Sed a commodo arcu. Suspendisse nunc est,
                          efficitur ac feugiat et, lobortis eu quam. Proin
                          sodales ac tortor id finibus. Mauris viverra accumsan
                          vehicula. Sed accumsan nulla at purus laoreet,
                          pulvinar tincidunt sapien eleifend. Etiam ut ipsum in
                          nunc convallis pretium. In urna massa, ornare eu
                          sollicitudin ac, porta vitae libero. Curabitur
                          pharetra, tortor in faucibus convallis, dolor tellus
                          fermentum leo, eu gravida mauris libero sit amet leo.
                          Nullam dictum ipsum quis purus tincidunt lobortis.
                          Donec aliquam ultricies lectus at porta. Vivamus magna
                          felis, auctor id magna vitae, posuere suscipit
                          sapien.aliquam ultricies lectus at porta. Vivamus
                          magna felis, auctor id magna vitae, posuere suscipit
                          sapien.aliquam ultricies lectus at porta. Vivamus
                          magna felis, auctor id magna vitae, posuere suscipit
                          sapien.aliquam ultricies lectus at porta. Vivamus
                          magna felis, auctor id magna vitae, posuere suscipit
                          sapien.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {profileReceived ? <Redirect to="/dashboard" /> : ""}
    </div>
  );

  // return (
  //   <div>
  //     {isFetching ? <p>Attempting login...</p> : ""}
  //     {errorMessage ? <p>{errorMessage}</p> : ""}
  //
  //
  //     <Link to="/">
  //       <button>Back to Home</button>
  //     </Link>
  //
  //   </div>
  // );
}

export default Login;
