import React, { useEffect, useRef, useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  logoutUser,
  getAdminProfile,
  createOffice,
  getOfficeInvites,
  acceptAdminOfficeRequest,
  denyAdminOfficeRequest,
} from "../actions/authActions";
import LeftBar from "../components/leftBar";

import OfficeSelection from "../components/office/officeSelection";
import { BounceLoader } from "react-spinners";
import CreateNewOffice from "../components/popup/CreateNewOffice";
import DashboardTop from "../components/dashboardTop";
import EditOffice from "../components/popup/EditOffice";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    maxHeight: "100vh",
    marginRight: "-50%",
    overflowY: "auto",
    overflow: "hidden",
    transform: "translate(-50%, -50%)",
    padding: 0,
  },
};

function Dashboard() {
  const dispatch = useDispatch();
  const doctorProfile = useSelector((state) => state.authenticateUser.profile);

  // $(".fancybox").fancybox();
  useEffect(() => {
    dispatch(getAdminProfile());
  }, []);

  useEffect(() => {
    if (doctorProfile) {
      dispatch(getOfficeInvites(doctorProfile.pk));
    }
  }, [doctorProfile]);

  const profileReceived = useSelector(
    (state) => state.authenticateUser.profileReceived
  );
  const isAuthenticated = useSelector(
    (state) => state.authenticateUser.isAuthenticated
  );

  const receivedInvites = useSelector(
    (state) => state.authenticateUser.receivedInvites
  );
  const invites = useSelector((state) => state.authenticateUser.invites);

  const officeName = useRef(null);

  const [createIsOpen, setCreateIsOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);

  return profileReceived ? (
    <div class="dashboard-main">
      <LeftBar />
      <div class="rightbar full-height">
        <DashboardTop title={"Offices"} />
        <div class="">
          <Modal
            isOpen={createIsOpen}
            style={customStyles}
            onRequestClose={() => setCreateIsOpen(false)}
          >
            <CreateNewOffice doctorProfile={doctorProfile} />
          </Modal>
          <Modal
            isOpen={editIsOpen}
            style={customStyles}
            onRequestClose={() => setEditIsOpen(false)}
          >
            <EditOffice
              offices={doctorProfile.office}
              profile={doctorProfile}
            />
          </Modal>
          <div class="dash-content-wrapper">
            <div class="select-office-main">
              <div
                class="dashboard-main-title"
                style={{ marginBottom: "40px" }}
              >
                <h2 style={{ float: "left" }}>Select your Office:</h2>
                <Link to={`/add-office/`}>
                  <button
                    className="create-new-office-button"
                    style={{ float: "right" }}
                  >
                    Create New Office
                  </button>
                </Link>
              </div>
              {!profileReceived ? (
                <BounceLoader color={"#052D64"} size={35} />
              ) : (
                <div class="white-box">
                  {doctorProfile.office.map((office) => (
                    <OfficeSelection
                      key={office.pk}
                      office={office.name}
                      officeLink={`/office/${office.slug}/dashboard`}
                    />
                  ))}
                </div>
              )}
            </div>
            {receivedInvites ? (
              <div class="invitation">
                <div class="dashboard-main-title">
                  <h2>
                    Invitations:{" "}
                    <span>You’ve been invited to join a new office.</span>
                  </h2>
                </div>
                {invites.map((invite) => (
                  <div class="white-box">
                    <h4>{invite.office ? invite.office.name : ""}</h4>
                    <div class="invist-btns">
                      <ul>
                        <li>
                          <a
                            href="#"
                            title="Deny"
                            class="light-blue common-btn"
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(
                                denyAdminOfficeRequest(
                                  invite.pk,
                                  doctorProfile.pk
                                )
                              );
                            }}
                          >
                            Deny
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            title="Join"
                            class="sky-btn common-btn"
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(
                                acceptAdminOfficeRequest(
                                  invite.pk,
                                  doctorProfile.pk
                                )
                              );
                            }}
                          >
                            Join
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
            <div class="create-new-office">
              <div class="white-box">
                {/* <a
                  // href="#create-new-office"
                  class="fancybox"
                  title="create new office"
                  onClick={(e) => {
                    e.preventDefault();
                    setCreateIsOpen(true);
                  }}
                >
                  <span>Create New Office</span>
                </a> */}
              </div>
              <div class="white-box">
                <a
                  // href="#edit-office"
                  class="fancybox"
                  title="Edit Existing Office"
                  onClick={(e) => {
                    e.preventDefault();
                    setEditIsOpen(true);
                  }}
                >
                  Edit Existing Office
                </a>
              </div>
            </div>

            <div
              id="create-new-office2"
              class="display-none create-new-office-popup"
            >
              <div class="form-main create-office-form big-popup">
                <div class="forgot-pass-form common-form box-with-shadow">
                  <div class="sub-heading">
                    <h3>Create Office</h3>
                    <div class="close-box">
                      <a
                        href="#"
                        title=""
                        class="fancybox-button fancybox-close-small"
                        data-fancybox-close=""
                      >
                        <img src="images/close.png" alt="" />
                      </a>
                    </div>
                  </div>
                  <div class="inner-forgot-pass">
                    <form>
                      <div class="office-name">
                        <label>Office Name:</label>
                        <input
                          type="text"
                          placeholder=""
                          class="common-input"
                        />
                      </div>
                      <div class="invite-members">
                        <label>Invite Members:</label>
                        <div class="member-main-form">
                          <div class="fields-row-main">
                            <div class="fields-row half">
                              <label>Email Address</label>
                              <input
                                type="email"
                                placeholder=""
                                class="common-input"
                              />
                            </div>
                            <div class="fields-row half">
                              <label>Name</label>
                              <input
                                type="text"
                                placeholder=""
                                class="common-input"
                              />
                            </div>
                          </div>
                          <div class="fields-row-main">
                            <div class="fields-row">
                              <label>Role</label>
                              <div class="btns-group">
                                <ul>
                                  <li class="no-border">
                                    <input
                                      type="radio"
                                      id="test5"
                                      name="radio-group"
                                      checked
                                    />
                                    <label for="test5">
                                      <span>Doctor</span>
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      type="radio"
                                      id="test6"
                                      name="radio-group"
                                    />
                                    <label for="test6">
                                      <span>nurse</span>
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      type="radio"
                                      id="test7"
                                      name="radio-group"
                                    />
                                    <label for="test7">
                                      <span>Nurse Practioner</span>
                                    </label>
                                  </li>
                                  <li>
                                    {" "}
                                    <input
                                      type="radio"
                                      id="test8"
                                      name="radio-group"
                                      checked
                                    />
                                    <label for="test8">
                                      <span>admin</span>
                                    </label>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="member-main-form add-another-fields">
                        <div class="close-fields">
                          <a href="#" title="close" class="close" />
                        </div>
                        <div class="fields-row-main">
                          <div class="fields-row half">
                            <label>Email Address</label>
                            <input
                              type="email"
                              placeholder=""
                              class="common-input"
                            />
                          </div>
                          <div class="fields-row half">
                            <label>Name</label>
                            <input
                              type="text"
                              placeholder=""
                              class="common-input"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="login-submit">
                        <a
                          href="#create-new-office3"
                          title="Create Office + Send Invitations"
                          class="sky-btn common-btn fancybox"
                        >
                          Create Office + Send Invitations
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="create-new-office3"
              class="display-none create-new-office-popup"
            >
              <div class="form-main create-office-form">
                <div class="forgot-pass-form common-form box-with-shadow">
                  <div class="sub-heading">
                    <h3>Create Office</h3>
                    <div class="close-box">
                      <a
                        href="#"
                        title=""
                        class="fancybox-button fancybox-close-small"
                        data-fancybox-close=""
                      >
                        <img src="images/close.png" alt="" />
                      </a>
                    </div>
                  </div>
                  <div class="inner-forgot-pass">
                    <div class="office-heading">
                      <h3>Office was created.</h3>
                      <p>+ Invitations have been sent out!</p>
                    </div>
                    <div class="login-submit ok">
                      <a href="#" title="ok" class="sky-btn common-btn">
                        ok
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    "LOADING"
  );
}

export default Dashboard;
