import axios, { AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { Action } from "redux";
import store from "store";

export function openChatWindow(openChat: boolean) {
  return {
    type: "OPEN_CHAT",
    openChat,
  };
}

function requestGetInternalMessageThreads() {
  return {
    type: "REQUEST_GET_INTERNAL_MESSAGE_THREADS",
    isFetchingInternalMessageThreads: true,
  };
}

function receiveGetInternalMessageThreads(internalMessageThreads: Array<any>) {
  return {
    type: "RECEIVE_GET_INTERNAL_MESSAGE_THREADS",
    isFetchingInternalMessageThreads: false,
    internalMessageThreads,
  };
}

function errorGetInternalMessageThreads() {
  return {
    type: "ERROR_GET_INTERNAL_MESSAGE_THREADS",
    isFetchingInternalMessageThreads: false,
  };
}

export function getInternalMessageThreads(adminPk: number, officeSlug: string) {
  const token = store.get("id_token");
  let config: AxiosRequestConfig = {
    method: "GET",
    url: `${
      process.env.REACT_APP_DEV_API
    }/messaging/get-internal-messages/${adminPk}/${officeSlug}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch: Dispatch<Action>) => {
    dispatch(requestGetInternalMessageThreads());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetInternalMessageThreads(data));
      })
      .catch((err) => {
        dispatch(errorGetInternalMessageThreads());
      });
  };
}

export function selectAdminThread(adminThread: any) {
  return {
    type: "SELECT_ADMIN_THREAD",
    adminThread,
  };
}

function requestGetAdminThreadMessages() {
  return {
    type: "REQUEST_GET_ADMIN_THREAD_MESSAGES",
    isFetchingAdminThreadMessages: true,
  };
}

function receiveGetAdminThreadMessages(threadMessages: Array<any>) {
  return {
    type: "RECEIVE_GET_ADMIN_THREAD_MESSAGES",
    isFetchingAdminThreadMessages: false,
    threadMessages,
  };
}

function errorGetAdminThreadMessages() {
  return {
    type: "ERROR_GET_ADMIN_THREAD_MESSAGES",
    isFetchingAdminThreadMessages: false,
  };
}

export function resetThread() {
  return {
    type: "RESET_THREAD",
  };
}

export function getInternalMessages(adminThreadPk: number) {
  const token = store.get("id_token");
  let config: AxiosRequestConfig = {
    method: "GET",
    url: `${
      process.env.REACT_APP_DEV_API
    }/messaging/get-admin-thread-messages/${adminThreadPk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch: Dispatch<Action>) => {
    dispatch(requestGetAdminThreadMessages());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetAdminThreadMessages(data));
      })
      .catch((err) => {
        dispatch(errorGetAdminThreadMessages());
      });
  };
}

export function addNewMessageToChat(chatMessage: any) {
  return {
    type: "ADD_NEW_MESSAGE_TO_CHAT",
    chatMessage,
  };
}
