import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  createNewOfficeAndInviteUsers,
  setOfficeTerms,
} from "../../../actions/adminOfficeActions";
import { BounceLoader } from "react-spinners";
const TermsAndCondition = (props) => {
  const dispatch = useDispatch();
  const officeTermsReC = useSelector(
    (state) => state.adminOfficeInteractions.officeTerms
  );
  const { register, control, handleSubmit } = useForm();

  const newOfficeDetails = useSelector(
    (state) => state.adminOfficeInteractions.newOfficeDetails
  );

  const officeHoursOfOperation = useSelector(
    (state) => state.adminOfficeInteractions.officeHoursOfOperation
  );

  const officeNewEvents = useSelector(
    (state) => state.adminOfficeInteractions.officeNewEvents
  );

  const hasCreatedOffice = useSelector(
    (state) => state.adminOfficeInteractions.hasCreatedOffice
  );

  const isCreatingOffice = useSelector(
    (state) => state.adminOfficeInteractions.isCreatingOffice
  );

  const adminPK = useSelector((state) => state.authenticateUser.profile.pk);

  useEffect(() => {
    if (hasCreatedOffice) {
      props.stepsHandler(5);
    }
  }, [hasCreatedOffice]);

  const onSubmit = (data) => {
    if (data.check) {
      dispatch(
        createNewOfficeAndInviteUsers(
          newOfficeDetails,
          officeHoursOfOperation,
          officeNewEvents,
          adminPK
        )
      );
    }
  };
  return (
    <div className="mt-44 terms-condition-section ">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content-group">
          <div className="content-wrapper">
            <div className="heading">Accept Terms and Conditions</div>
            <div className="subheading">Interpretation and Definitions</div>
            <div className="description">
              <a
                href="https://getcorigan.ca/wp-content/uploads/2020/08/23876453-v4-Bluewater-Privacy-Policy-1-converted.pdf"
                target="_blank"
              >
                <p>Please click here to view terms and conditions</p>
              </a>
            </div>

            <div class="checkbox-wrapper">
              <input
                type="checkbox"
                id="yes-accept"
                name="check"
                ref={register({ required: true })}
                defaultValue={officeTermsReC ? "checked" : ""}
              />
              <label for="yes-accept">
                <p>Yes, I accept the terms and conditions</p>
              </label>
            </div>
          </div>
        </div>
        <div className="bottom-buttons flex-end">
          <a
            className="common-btn white-btn common-btn-updated mr-15"
            onClick={() => props.stepsHandler(3)}
          >
            Go Back
          </a>
          {!isCreatingOffice ? (
            <button
              className="common-btn blue-btn common-btn-updated"
              type="submit"
            >
              Accept & Create Office
            </button>
          ) : (
            <BounceLoader size={30} />
          )}
        </div>
      </form>
    </div>
  );
};

export default TermsAndCondition;
