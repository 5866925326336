import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div>
      <h1>Home</h1>
      <h3>User is currently: </h3>
      <Link to="/login/">
        <button>Login</button>
      </Link>
      <Link to="/signup/">
        <button>SignUp</button>
      </Link>
    </div>
  );
}

// onClick={() => dispatch(requestLogin())}

export default Home;
