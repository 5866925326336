import React, { useState } from "react";
import { Link } from "react-router-dom";
import Close from "../../assets/images/close.png";

function InviteMembers(props) {
  const [checked, setChecked] = useState("Admin");
  return (
    <div class="invite-members">
      <label>Invite Members:</label>
      <div class="member-main-form">
        <div class="fields-row-main">
          <div class="fields-row half">
            <label>Email Address</label>
            <input
              type="email"
              placeholder=""
              class="common-input"
              value={props.emailObject.email_sent_to}
              onChange={e => props.handleChange(e, props.instance)}
            />
          </div>
          <div class="fields-row half">
            <label>Name</label>
            <input type="text" placeholder="" class="common-input" />
          </div>
        </div>
        <div class="fields-row-main">
          <div class="fields-row">
            <label>Role</label>
            <div class="btns-group">
              <ul>
                <li class="no-border">
                  <input
                    type="radio"
                    name="radio-group"
                    value="Doctor"
                    checked={checked === "Doctor"}
                    onClick={() => setChecked("Doctor")}
                  />
                  <label onClick={() => setChecked("Doctor")}>
                    <span>Doctor</span>
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="radio-group"
                    value="Nurse"
                    checked={checked === "Nurse"}
                    onClick={() => setChecked("Nurse")}
                  />
                  <label onClick={() => setChecked("Nurse")}>
                    <span>nurse</span>
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="radio-group"
                    value="Nurse Practitioner"
                    checked={checked === "Nurse Practitioner"}
                    onClick={() => setChecked("Nurse Practitioner")}
                  />
                  <label onClick={() => setChecked("Nurse Practitioner")}>
                    <span>Nurse Practitioner</span>
                  </label>
                </li>
                <li>
                  {" "}
                  <input
                    type="radio"
                    name="radio-group"
                    value="Admin"
                    checked={checked === "Admin"}
                    onClick={() => setChecked("Admin")}
                  />
                  <label onClick={() => setChecked("Admin")}>
                    <span>admin</span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// onClick={() => dispatch(requestLogin())}

export default InviteMembers;
