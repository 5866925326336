import React from "react";
import DashboardTop from "../dashboardTop";
import { AutomatedMessaging } from "./settings/AutomatedMessaging";
import { SettingsTabs } from "./SettingsTabs";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { ManageBookingTimes } from "./settings/ManageBookingTimes";
import { PriorityBookingTimes } from "./settings/PriorityBookingTimes";
import { NewPriorityBookingTime } from "./settings/NewPriorityBookingTime";
import { EditPriorityBookingTime } from "./settings/EditPriorityBookingTime";
import { Profile } from "./settings/Profile";
import { Patients } from "./settings/Patients";
import { Users } from "./settings/Users";
import { MyPlan } from "./settings/MyPlan";
import { Payments } from "./settings/Payments";
import { Support } from "./settings/Support";
import { Policies } from "./settings/Policies";
import { Account } from "./settings/Account";

export const SettingsContainer = (props: any) => {
  const selectedTab = useSelector(
    (state: RootStateOrAny) => state.officeSettingsReducer.selectedTab
  );
  return (
    <div>
      <DashboardTop
        title={
          props.appointmentSettings ? "Appointment Settings" : "Office Settings"
        }
      />
      <div className="middle-content main-content-wrapper">
        <div className="d-flex main-appointment-page">
          <SettingsTabs appointmentSettings={props.appointmentSettings} />

          <div className="col-7 app-setting-right">
            {selectedTab === "automaticMessaging" ? (
              <AutomatedMessaging />
            ) : selectedTab === "priorityBookingTimes" ? (
              <PriorityBookingTimes />
            ) : selectedTab === "manageBookingTimes" ? (
              <ManageBookingTimes />
            ) : selectedTab === "new-priority-tab" ? (
              <NewPriorityBookingTime />
            ) : selectedTab === "edit-priority-tab" ? (
              <EditPriorityBookingTime />
            ) : selectedTab === "profile" ? (
              <Profile />
            ) : selectedTab === "patients" ? (
              <Patients />
            ) : selectedTab === "users" ? (
              <Users />
            ) : selectedTab === "myPlan" ? (
              <MyPlan />
            ) : selectedTab === "payments" ? (
              <Payments />
            ) : selectedTab === "support" ? (
              <Support />
            ) : selectedTab === "policies" ? (
              <Policies />
            ) : selectedTab === "account" ? (
              <Account />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
