import React from "react";
import { Link } from "react-router-dom";

function OfficeSelection(props) {
  return (
    <div class="select-office">
      <h3>{props.office}</h3>
      <div class="goto-office">
        <Link to={props.officeLink} className="blue-btn common-btn">
          <span>Go to Office</span>
        </Link>
      </div>
    </div>
  );
}

// onClick={() => dispatch(requestLogin())}

export default OfficeSelection;
