import axios from "axios";
import {
  requestCreateOffice,
  receiveCreateOffice,
  getAdminProfile,
} from "./authActions";
import launchToast from "../utils";
import store from "store";
import { useSelector } from "react-redux";
import {
  getWaitingPatients,
  createTwilioClient,
  joinActiveCall,
  leaveActiveCall,
  getActiveCalls,
  updateActiveCalls,
  joiningActiveCall,
  receiveGetAllUnreadMessages,
} from "./waitingRoomActions";
import QueueSound from "../assets/images/queue.mp3";
import Marimba from "../assets/images/marimba.mp3";
import Click from "../assets/images/click.mp3";
import { receiveGetMessageThreads } from "./messagingActions";
import ReconnectingWebsocket from "reconnecting-websocket";
import moment from "moment";

let audio = new Audio(QueueSound);
let marimba = new Audio(Marimba);
let click = new Audio(Click);

function requestGetPastAppointments() {
  return {
    type: "REQUEST_GET_PAST_APPOINTMENTS",
    isFetchingPastAppointments: true,
  };
}

function receiveGetPastAppointments(pastAppointments) {
  return {
    type: "RECEIVE_GET_PAST_APPOINTMENTS",
    isFetchingPastAppointments: false,
    pastAppointments,
  };
}

function errorReceiveGetPastAppointments() {
  return {
    type: "ERROR_RECEIVE_GET_PAST_APPOINTMENTS",
    isFetchingPastAppointments: false,
    errorMessage:
      "Could not retrieve past appointments. Check your internet connection.",
  };
}

export function setNewOffice(office) {
  return {
    type: "SET_NEW_OFFICE",
    office,
  };
}
export function setOfficeHourDetails(hours) {
  return {
    type: "SET_OFFICE_HOURS_DETAILS",
    hours,
  };
}
export function setOfficeNewEvents(events) {
  return {
    type: "SET_OFFICE_NEW_EVENTS",
    events,
  };
}
export function setOfficeTerms(check) {
  return {
    type: "SET_OFFICE_TERMS",
    check,
  };
}

function setWaitingList(number) {
  return {
    type: "SET_WAITING_LIST",
    number,
  };
}

function setWaitingPosition(number) {
  return {
    type: "SET_WAITING_POSITION",
    number,
  };
}

function requestOffice() {
  return {
    type: "REQUEST_OFFICE",
    isFetchingOffice: true,
    officeHasLoaded: false,
    office: {},
  };
}

function receiveOffice(office) {
  return {
    type: "RECEIVE_OFFICE",
    isFetchingOffice: false,
    officeHasLoaded: true,
    office,
  };
}

function requestPatientList() {
  return {
    type: "REQUEST_PATIENT_LIST",
    isFetchingPatients: true,
  };
}

function receivePatientList(patientList) {
  return {
    type: "RECEIVE_PATIENT_LIST",
    isFetchingPatients: false,
    patientsHaveLoaded: true,
    patientList,
  };
}

function errorReceivePatientList() {
  return {
    type: "ERROR_PATIENT_LIST",
    isFetchingPatients: false,
    errorMessage:
      "There was an error retrieving patients from this office. Please check your internet connection.",
  };
}

function requestOfficeRequests() {
  return {
    type: "REQUEST_OFFICE_REQUESTS",
    isFetchingRequests: true,
  };
}

function receiveOfficeRequests(officeRequests) {
  return {
    type: "RECEIVE_OFFICE_REQUESTS",
    isFetchingRequests: false,
    hasReceivedRequests: true,
    officeRequests,
  };
}

function setSocketStatus(status) {
  return {
    type: "SET_SOCKET_STATUS",
    socketStatus: status,
  };
}

function requestSocketConnection() {
  return {
    type: "REQUEST_SOCKET_CONNECTION",
    socketIsConnecting: true,
  };
}

function receiveSocketConnection(socket) {
  return {
    type: "RECEIVE_SOCKET_CONNECTION",
    socketIsConnecting: false,
    socketHasConnected: true,
    socket,
  };
}

function errorSocketConnection(errorMessage) {
  return {
    type: "ERROR_SOCKET_CONNECTION",
    socketIsConnecting: false,
    socketHasConnected: false,
    errorMessage,
  };
}

function requestApproveOfficeRequest() {
  return {
    type: "REQUEST_APPROVE_OFFICE_REQUEST",
    isFetchingApproval: true,
  };
}

function receiveApproveOfficeRequest() {
  return {
    type: "RECEIVE_APPROVE_OFFICE_REQUEST",
    isFetchingApproval: false,
  };
}

function requestDeclineOfficeRequest() {
  return {
    type: "REQUEST_DECLINE_OFFICE_REQUEST",
    isFetchingDecline: true,
  };
}

function receiveDeclineOfficeRequest() {
  return {
    type: "RECEIVE_DECLINE_OFFICE_REQUEST",
    isFetchingDecline: false,
  };
}

// Changed these two actions to be exclusive to "Create New Office". duplication causing problems
function requestInviteUsersToNewOffice() {
  return {
    type: "REQUEST_INVITE_USERS_TO_OFFICE",
    isFetchingInvites: true,
  };
}

function receiveInviteUsersToNewOffice() {
  return {
    type: "RECEIVE_INVITE_USERS_TO_OFFICE",
    isFetchingInvites: false,
    hasSentInvite: true,
    InviteErrorMessage: "",
  };
}


function changeOfficeStatus(status) {
  return {
    type: "CHANGE_OFFICE_STATUS",
    waitingRoomStatus: status,
  };
}

function requestGetOfficeInfo() {
  return {
    type: "REQUEST_GET_OFFICE_INFO",
    isFetchingOffice: true,
    fetchOfficeErrorMessage: "",
  };
}

function receiveGetOfficeInfo(office) {
  return {
    type: "RECEIVE_GET_OFFICE_INFO",
    isFetchingOffice: false,
    officeEdit: office,
  };
}

export function resetGetOfficeInfo(office) {
  return {
    type: "RESET_GET_OFFICE_INFO",
    officeEdit: null,
  };
}

function errorGetOfficeInfo(err) {
  return {
    type: "ERROR_GET_OFFICE_INFO",
    isFetchingOffice: false,
    fetchOfficeErrorMessage: err,
  };
}

function requestAddUserToOffice() {
  return {
    type: "REQUEST_ADD_USER_TO_OFFICE",
    isSendingOfficeRequest: true,
    officeRequestMessage: "",
  };
}

function receiveAddUserToOffice(message) {
  return {
    type: "RECEIVE_ADD_USER_TO_OFFICE",
    isSendingOfficeRequest: false,
    officeRequestMessage: message,
  };
}

function errorReceiveAddUserToOffice(message) {
  return {
    type: "ERROR_ADD_USER_TO_OFFICE",
    isSendingOfficeRequest: false,
    officeRequestMessage: message,
  };
}

function requestUpdateOffice() {
  return {
    type: "REQUEST_UPDATE_OFFICE",
    isUpdatingOffice: true,
    updateOfficeMessage: "",
  };
}

function receiveUpdateOffice(message) {
  return {
    type: "RECEIVE_UPDATE_OFFICE",
    isUpdatingOffice: false,
    updateOfficeMessage: message,
  };
}

function errorReceiveUpdateOffice(message) {
  return {
    type: "ERROR_UPDATE_OFFICE",
    isUpdatingOffice: false,
    updateOfficeMessage: message,
  };
}

function incrementPageNum(page_num) {
  return {
    type: "INCREMENT_PAGE_NUM",
    pastAppointmentRequestNumber: page_num + 1,
  };
}

function requestGetOfficePatient() {
  return {
    type: "REQUEST_GET_OFFICE_PATIENT",
    isFetchingPatient: true,
    patient: null,
    message: "",
  };
}

function receiveGetOfficePatient(data) {
  return {
    type: "RECEIVE_GET_OFFICE_PATIENT",
    isFetchingPatient: false,
    patient: data,
  };
}

function errorGetOfficePatient(err) {
  return {
    type: "ERROR_GET_OFFICE_PATIENT",
    isFetchingPatient: false,
    message: err,
  };
}

function requestGetPastAppointment() {
  return {
    type: "REQUEST_GET_PAST_APPOINTMENT",
    isFetchingPastAppointment: true,
    isFetchingPastAppointmentMessage: "",
  };
}

function receiveGetPastAppointment(pastAppointment) {
  return {
    type: "RECEIVE_GET_PAST_APPOINTMENT",
    isFetchingPastAppointment: false,
    pastAppointment,
  };
}

function errorGetPastAppointment(err) {
  return {
    type: "ERROR_GET_PAST_APPOINTMENT",
    isFetchingPastAppointment: false,
    isFetchingPastAppointmentMessage: err,
  };
}

function changeMenuSetting(setting) {
  return {
    type: "CHANGE_MENU_SETTING",
    menuSetting: setting,
  };
}

export function resetPageNum() {
  return {
    type: "RESET_PAGE_NUM",
    pastAppointmentRequestNumber: 1,
  };
}

export function resetPastAppointments() {
  return {
    type: "RESET_PAST_APPOINTMENTS",
  };
}

export function resetPatientList() {
  return {
    type: "RESET_PATIENT_LIST",
  };
}

// DISPATCH METHODS
export function getWaitingPosition(number) {
  return (dispatch) => {
    dispatch(setWaitingPosition(number));
  };
}

export function getWaitingList(number) {
  return (dispatch) => {
    dispatch(setWaitingList(number));
  };
}

export function getCurrentOffice(slug) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/office/${slug}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(requestOffice());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveOffice(data));
      })
      .catch((err) => console.log(err));
  };
}

export function getPatientList(office_num, page_num) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${
      process.env.REACT_APP_DEV_API
    }/office_patients/${office_num}/?page=${page_num}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestPatientList());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receivePatientList(data.results));
        dispatch(incrementPageNum(page_num));
      })
      .catch((err) => {
        dispatch(errorReceivePatientList());
        console.log(err);
      });
  };
}

export function getOfficeRequests(office_num) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/office_requests/${office_num}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestOfficeRequests());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveOfficeRequests(data));
      })
      .catch((err) => console.log(err));
  };
}

export function getSocketConnection(office_slug, adminPk) {
  return (dispatch) => {
    dispatch(requestSocketConnection());
    const socket = new ReconnectingWebsocket(
      `${process.env.REACT_APP_DEV_SOCKET}/admins/${office_slug}/${adminPk}/`
    );
    socket.onopen = function() {
      dispatch(setSocketStatus("connected"));
      dispatch(receiveSocketConnection(socket));
      socket.send(
        JSON.stringify({
          type: "GET_ALL_MESSAGE_THREADS",
          office_slug: office_slug,
        })
      );
      launchToast("Successfully connected to the virtual office.");
    };

    socket.onmessage = (e) => {
      const response_data = JSON.parse(e.data);
      if (response_data.type == "detailed_waiting_list") {
        dispatch(getWaitingPatients(response_data.users));
      } else if (response_data.type == "admin_join_active_call") {
        dispatch(
          joinActiveCall(
            response_data.active_call,
            response_data.active_admin_details[0],
            response_data.all_active_calls
          )
        );
        dispatch(getActiveCalls(response_data.all_active_calls));
      } else if (response_data.type == "admin_joining_call") {
        dispatch(joiningActiveCall(response_data.patient));
      } else if (response_data.type == "admin_remove_active_call") {
        dispatch(
          leaveActiveCall(
            response_data.active_call,
            response_data.active_admin,
            response_data.all_active_calls
          )
        );
        dispatch(updateActiveCalls(response_data.all_active_calls));
      } else if (response_data.type == "admin_get_all_active_calls") {
        dispatch(updateActiveCalls(response_data.all_active_calls));
      } else if (response_data.type == "get_all_message_threads") {
        dispatch(receiveGetMessageThreads(JSON.parse(response_data.threads)));
      } else if (response_data.type == "notify_office") {
        launchToast(`New message in Inbox: ${response_data.subject}`);
        marimba.play();
      } else if (response_data.type == "notify_new_office_request") {
        launchToast(response_data.message);
        marimba.play();
      } else if (response_data.type == "new_message") {
        click.play();
      } else if (response_data.type == "ping_selected_waiting_room_patient") {
        dispatch(
          receiveGetAllUnreadMessages(JSON.parse(response_data.unread_threads))
        );
      } else {
        return;
      }
    };

    socket.onclose = function() {
      launchToast("You have disconnected from the virtual office.");
      dispatch(setSocketStatus("disconnected"));
    };

    socket.onerror = function(err) {
      dispatch(errorSocketConnection(err));
      launchToast(err);
    };
  };
}

export function reconnectSocketConnection(office_slug, adminPk) {
  return (dispatch) => {
    dispatch(requestSocketConnection());
    const socket = new ReconnectingWebsocket(
      `${process.env.REACT_APP_DEV_SOCKET}/office/${office_slug}/${adminPk}/`
    );

    socket.onopen = function() {
      dispatch(setSocketStatus("connected"));
      dispatch(receiveSocketConnection(socket));
      launchToast("Successfully connected to waiting room.");
      socket.send(
        JSON.stringify({
          type: "ADMIN_JOIN_WAITING_ROOM",
          is_patient: false,
          office: office_slug,
        })
      );
    };

    socket.onclose = function() {
      launchToast("You have disconnected from the waiting room.");
      setSocketStatus("disconnected");
    };

    socket.onerror = function(err) {
      setSocketStatus("disconnected");
      // dispatch(errorSocketConnection(err));
      // launchToast(err);
    };
  };
}

export function acceptOfficeRequest(pk, office_num) {
  const token = store.get("id_token");
  let config = {
    method: "patch",
    url: `${process.env.REACT_APP_DEV_API}/update_office_request/${pk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      is_approved: true,
    },
  };

  return (dispatch) => {
    dispatch(requestApproveOfficeRequest());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveApproveOfficeRequest(data));
      })
      .then(() => {
        dispatch(getOfficeRequests(office_num));
        dispatch(getPatientList(office_num));
      })
      .catch((err) => console.log(err));
  };
}

export function declineOfficeRequest(pk, office_num) {
  const token = store.get("id_token");
  let config = {
    method: "patch",
    url: `${process.env.REACT_APP_DEV_API}/update_office_request/${pk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      is_declined: true,
    },
  };

  return (dispatch) => {
    dispatch(requestDeclineOfficeRequest());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveDeclineOfficeRequest(data));
      })
      .then(() => {
        dispatch(getOfficeRequests(office_num));
      })
      .catch((err) => console.log(err));
  };
}

// Changed name of this function to ensure the proper actions are being called
function inviteUsersToNewOffice(invitedUsers) {
  const token = store.get("id_token");

  let config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/create_office_request/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: invitedUsers,
  };

  return (dispatch) => {
    dispatch(requestInviteUsersToNewOffice());
    return axios(config)
      .then((res) => (res.data))
      .then((data) => {
        dispatch(receiveInviteUsersToNewOffice());
      })
      .then(() => {
        dispatch(dispatch(getAdminProfile()));
      })
      .catch((err) => {
        console.log(err)
      })
  };
}



export function createOfficeAndInviteUsers(officeName, adminPK, invitedUsers) {
  const token = store.get("id_token");

  let officeConfig = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/create_offices/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      name: officeName,
      user_pk: adminPK,
    },
  };

  return (dispatch) => {
    dispatch(requestCreateOffice());
    return axios(officeConfig)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveCreateOffice());
        return data.pk;
      })
      .then((office_pk) => {
        const newInvitedUsers = invitedUsers.map((obj) => ({
          ...obj,
          office: office_pk,
        }));
        dispatch(inviteUsersToNewOffice(newInvitedUsers));
      });
  };
}

export function getPastAppointments(office_pk, page_num) {
  const token = store.get("id_token");

  let config = {
    method: "get",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/past-appointments/${office_pk}/?page=${page_num}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetPastAppointments());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetPastAppointments(data.results));
        dispatch(incrementPageNum(page_num));
      })
      .catch((err) => {
        console.log(err);
        dispatch(errorReceiveGetPastAppointments());
      });
  };
}

export function getOfficeInfo(office_pk) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/office/${office_pk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetOfficeInfo());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetOfficeInfo(data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(errorGetOfficeInfo(err.response));
      });
  };
}

export function updateOffice(
  office_pk,
  opened_at,
  closed_at,
  availability,
  is_open,
  allow_inbox,
  allow_waiting_room
) {
  const token = store.get("id_token");
  let config = {
    method: "patch",
    url: `${process.env.REACT_APP_DEV_API}/office_update/${office_pk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      opened_at,
      closed_at,
      availability,
      is_open: is_open === "true" ? true : false,
      allow_inbox: allow_inbox === "true" ? true : false,
      allow_waiting_room: allow_waiting_room === "true" ? true : false,
    },
  };

  return (dispatch) => {
    dispatch(requestUpdateOffice());
    return axios(config)
      .then((res) => res.data)
      .then((res) => {
        dispatch(receiveUpdateOffice("Successfully update office."));
      })
      .catch((err) => {
        console.log(err.response);
        dispatch(errorReceiveUpdateOffice(err.response));
      });
  };
}

export function addUserToOffice(email_sent_to, sent_by, office) {
  const token = store.get("id_token");
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/create_office_request/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      is_approved: false,
      is_declined: false,
      email_sent_to,
      sent_by,
      office,
    },
  };

  return (dispatch) => {
    dispatch(requestAddUserToOffice());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveAddUserToOffice("Sent request to user."));
      })
      .catch((err) => {
        dispatch(errorReceiveAddUserToOffice(err.response));
      });
  };
}

export function getOfficePatient(patientPk) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/get_single_patient/${patientPk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetOfficePatient());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetOfficePatient(data));
      })
      .catch((err) => {
        dispatch(errorGetOfficePatient(err));
      });
  };
}

export function getPastAppointment(pastAppointmentPk) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/past_appointment/${pastAppointmentPk}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestGetPastAppointment());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveGetPastAppointment(data));
      })
      .catch((err) => {
        dispatch(errorGetPastAppointment(err.response));
      });
  };
}

export function getSearchPastAppointments(input, callback, office) {
  const token = store.get("id_token");
  let config = {
    method: "get",
    url: `${
      process.env.REACT_APP_DEV_API
    }/appointments/search_past_appointments/?office=${office}&query=${input}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  axios(config)
    .then((res) => {
      const patients = res.data.map((entry) => ({
        value: entry.pk,
        label: `${entry.patient.first_name} ${entry.patient.last_name} at ${
          entry.date
        }`,
      }));
      callback(patients);
    })
    .catch((err) => {
      console.log(err);
    });
}

export function changeMenu(setting) {
  return (dispatch) => {
    dispatch(changeMenuSetting(setting));
  };
}

export function getWaitingRoomStatus(officeSlug) {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/office_waiting_room/${officeSlug}/`,
  };

  return (dispatch) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(changeOfficeStatus(data.open_waiting_room));
      });
  };
}

export function setWaitingRoomStatus(status, officeSlug) {
  const token = store.get("id_token");
  let config = {
    method: "patch",
    url: `${process.env.REACT_APP_DEV_API}/office/${officeSlug}/`,
    data: {
      open_waiting_room: status,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    return axios(config).then((res) => {
      dispatch(changeOfficeStatus(status));
      if (status) {
        launchToast(
          "Updated Waiting Room Status. Copy link and send to allow people to join without registering."
        );
      }
    });
  };
}

function requestCreateOfficeAndInviteUsers() {
  return {
    type: "REQUEST_CREATE_OFFICE_AND_INVITE_USERS",
    isCreatingOffice: true,
    hasCreatedOffice: false,
  };
}

function receiveCreateOfficeAndInviteUsers() {
  return {
    type: "RECEIVE_CREATE_OFFICE_AND_INVITE_USERS",
    isCreatingOffice: false,
    hasCreatedOffice: true,
  };
}

function errorCreateOfficeAndInviteUsers() {
  return {
    type: "ERROR_CREATE_OFFICE_AND_INVITE_USERS",
    isCreatingOffice: false,
    hasCreatedOffice: false,
  };
}

export function createNewOfficeAndInviteUsers(
  newOfficeDetails,
  officeHours,
  officeInvites,
  adminPK
) {
  const token = store.get("id_token");
  let config = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/create-office/`,
    data: {
      ...newOfficeDetails,
      office_hours: officeHours.days.map((item, index) => ({
        weekday: index + 1,
        from_hour: moment(item.from_hour).format("HH:mm"),
        to_hour: moment(item.to_hour).format("HH:mm"),
        closed: item.closed,
        lunch: item.lunch,
        lunch_start: item.lunch_start ? item.lunch_start : "00:00",
        lunch_end: item.lunch_end ? item.lunch_end : "00:00",
      })),
      office_invites:
        Object.keys(officeInvites).length === 0 &&
        officeInvites.constructor === Object
          ? []
          : officeInvites.users.map((item, index) => ({
              email_sent_to: item.email,
              sent_by: adminPK,
            })),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(requestCreateOfficeAndInviteUsers());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveCreateOfficeAndInviteUsers());
      })
      .catch((err) => {
        launchToast("Could not create office.");
        dispatch(errorCreateOfficeAndInviteUsers());
      });
  };
}
