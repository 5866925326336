import React, { useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  removePatientForms,
  setAppointmentDate,
  setAppointmentTime,
  setDependentsNumber,
  setDependentsOnly,
} from "../../../actions/newAppointmentActions";
import moment from "moment";
import {
  getAppointmentInterval,
  getAppointmentsByDateTime,
  resetGetAppointmentsByDateTime,
} from "../../../actions/appointmentActions";
import { BounceLoader } from "react-spinners";

export const NewAppointmentDetails = (props: any) => {
  const { control, register, handleSubmit, watch, errors } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const dispatch = useDispatch();
  const watchEventDate = watch("eventDate");
  const watchEventTime = watch("eventTime");
  const watchDependents = watch("dependents");
  const patientForms = useSelector(
    (state: RootStateOrAny) => state.newAppointmentReducer.patientForms
  );
  const dependentsNumber = useSelector(
    (state: RootStateOrAny) => state.newAppointmentReducer.dependentsNumber
  );

  useEffect(() => {
    if (watchDependents) {
      if (patientForms) {
        dispatch(
          removePatientForms(
            parseInt(dependentsNumber),
            parseInt(watchDependents),
            patientForms
          )
        );
      }
      dispatch(setDependentsNumber(parseInt(watchDependents)));
    }
  }, [dependentsNumber, dispatch, patientForms, watchDependents]);

  const office = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.office
  );

  const hasReceivedAppointmentDateTimes = useSelector(
    (state: RootStateOrAny) =>
      state.appointmentReducer.hasReceivedAppointmentDateTimes
  );
  const dateTimes = useSelector(
    (state: RootStateOrAny) => state.appointmentReducer.dateTimes
  );

  const isFetchingAppointmentDateTimes = useSelector(
    (state: RootStateOrAny) =>
      state.appointmentReducer.isFetchingAppointmentDateTimes
  );

  const appointmentInterval = useSelector(
    (state: RootStateOrAny) => state.appointmentReducer.appointmentInterval
  );

  const dependentsOnly = useSelector(
    (state: RootStateOrAny) => state.newAppointmentReducer.dependentsOnly
  );

  const newAppointmentTime = useSelector(
    (state: RootStateOrAny) => state.newAppointmentReducer.newAppointmentTime
  );
  const newAppointmentDate = useSelector(
    (state: RootStateOrAny) => state.newAppointmentReducer.newAppointmentDate
  );

  useEffect(() => {
    return () => {
      handleSubmit((e) => console.log(e));
    };
  }, [handleSubmit]);

  useEffect(() => {
    if (newAppointmentDate && newAppointmentTime) {
      dispatch(
        getAppointmentsByDateTime(
          office.pk,
          moment(newAppointmentDate).format("YYYY-MM-DD"),
          moment(newAppointmentTime).format("HH:mm:00")
        )
      );
    }
    return () => {
      dispatch(resetGetAppointmentsByDateTime());
    };
  }, [dispatch, newAppointmentDate, newAppointmentTime, office.pk]);

  useEffect(() => {
    if (newAppointmentDate) {
      console.log(moment(newAppointmentDate).weekday());
      dispatch(
        getAppointmentInterval(
          office.pk,
          moment(newAppointmentDate).format("MM-DD-YYYY")
        )
      );
    }
  }, [dispatch, newAppointmentDate, office.pk]);

  return (
    <div className="white-box sidebar-with-dropdown-innerbox">
      <div className="app-setting-head">
        <h4 className="mb-0"> Appointment Details</h4>
      </div>
      <div className="appoinment w-100">
        <form className="w-100 ">
          <div className="w-100 feild-row">
            <div className="half-field">
              <label className="feild-label field-left">Date:</label>

              <div className="form-fieldbox">
                <div className="timeslot-box-time-piker w-100">
                  <div className="time-picker">
                    <DatePicker
                      className={`form-control datetimepicker-input form-feild ${errors.eventDate ? "red-input" : ""
                        }`}
                      onChange={(date) => {
                        dispatch(setAppointmentDate(date));
                      }}
                      selected={newAppointmentDate}
                      dateFormat="MMMM d, yyyy"
                      showMonthDropdown
                      name="eventDate"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="half-field-second">
              <label className="feild-label field-left">time:</label>

              <div className="form-fieldbox">
                <div className="timeslot-box-time-piker w-100">
                  <div className="time-picker">
                    <DatePicker
                      className="form-control datetimepicker-input form-feild"
                      showTimeSelect
                      showTimeSelectOnly
                      autoComplete="off"
                      timeIntervals={appointmentInterval}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      selected={newAppointmentTime}
                      name="eventTime"
                      onChange={(date) => {
                        dispatch(setAppointmentTime(date));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isFetchingAppointmentDateTimes && (
            <BounceLoader color={"#052D64"} size={50} />
          )}
          {hasReceivedAppointmentDateTimes &&
            (dateTimes.length > 0 ? (
              <p>
                There are currently {dateTimes.length} appointments booked at
                this time.
              </p>
            ) : (
              <p>No appointments are booked at this time!</p>
            ))}

          <div className="w-100 feild-row">
            <div className="half-field">
              <label className="feild-label field-left">
                Added Dependents:
              </label>

              <div className="form-fieldbox">
                <input
                  type="number"
                  className="form-feild w-100"
                  name="dependents"
                  defaultValue={dependentsNumber}
                  ref={register}
                />
              </div>
            </div>
          </div>
          {dependentsNumber > 0 && (
            <div className="w-100 feild-row">
              <div className="half-field">
                <label className="feild-label field-left">
                  Dependents only?:
                </label>

                <div className="checkbox-item w-100">
                  <label className="checkbox-them" htmlFor="dependents_only">
                    <input
                      type="checkbox"
                      id="dependents_only"
                      name="dependents_only"
                      className=""
                      defaultChecked={dependentsOnly}
                      onClick={(e) => {
                        dispatch(setDependentsOnly(!dependentsOnly));
                      }}
                    />
                    <span className="checkbox-them-span">
                      Book only the dependents and NOT the admin
                    </span>
                  </label>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
