import React, { useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Redirect, Link } from "react-router-dom";
import DashboardTop from "../dashboardTop";
import {
  getMeetingByCode,
  getParticipantsByCode,
  cancelMeeting,
  resetHasCancelledMeeting,
  updateMeeting,
  resetParticipants,
  deleteParticipant,
  createVideoParticipants,
  resetHasCreatedRoom,
} from "../../actions/meetingRoomActions";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Modal from "react-modal";
import { BounceLoader } from "react-spinners";
import { CopyToClipboard } from "react-copy-to-clipboard";
import launchToast from "../../utils";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import DatePicker from "react-datepicker";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
  },
};

export const SingleMeetingRoom = (props) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const participants = useSelector(
    (state) => state.meetingRoomReducer.participants
  );
  const { control, register, handleSubmit, watch, errors, reset } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "guests",
  });

  useEffect(() => {
    // Get Meeting information
    dispatch(getMeetingByCode(props.match.params.pk));

    // Get meeting participants
    dispatch(getParticipantsByCode(props.match.params.pk));

    return () => dispatch(resetHasCreatedRoom());
  }, []);

  useEffect(() => {
    if (participants.length != 0) {
      reset({
        guests: participants.map((participant, index) => ({
          id: index,
          email: participant.email,
          name: participant.name,
          pk: participant.pk,
        })),
      });

      return () => dispatch(resetParticipants());
    }
  }, [participants]);

  const meeting = useSelector((state) => state.meetingRoomReducer.meeting);
  const isFetchingMeeting = useSelector(
    (state) => state.meetingRoomReducer.isFetchingMeeting
  );

  const isFetchingParticipants = useSelector(
    (state) => state.meetingRoomReducer.isFetchingParticipants
  );
  const hasCancelledMeeting = useSelector(
    (state) => state.meetingRoomReducer.hasCancelledMeeting
  );

  const formSubmit = (data) => {
    const guests = data.guests.filter((guest) => guest.email !== "");
    const newGuests = guests.filter((guest) => !guest.pk);
    delete data.guests;
    data["guests"] = newGuests;
    console.log(data.guests);
    dispatch(updateMeeting(meeting.pk, data));
  };

  return !isFetchingMeeting ? (
    meeting ? (
      <React.Fragment>
        <Modal isOpen={modalOpen} style={customStyles}>
          <div class="common-form">
            <div class="sub-heading">
              <h3>CANCEL MEETING</h3>
              <div class="close-box">
                <a
                  href="#"
                  title=""
                  class="fancybox-button fancybox-close-small"
                  data-fancybox-close=""
                >
                  <img src="images/close.png" alt="" />
                </a>
              </div>
            </div>
            <div class="inner-forgot-pass">
              <h5>Are you sure you’d like to cancel this meeting?</h5>
              <p>
                {" "}
                We’ll notify all your guests that your meeting has been
                cancelled if you choose to continue.
              </p>
              <div class="login-submit">
                <a
                  href="#"
                  title="Go Back"
                  class="border-btn btns"
                  onClick={(e) => {
                    e.preventDefault();

                    setModalOpen(false);
                  }}
                >
                  Go Back
                </a>
                <a
                  href="#meeting-cancelled"
                  title="Continue"
                  class="btns fancybox "
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(cancelMeeting(meeting.pk));
                  }}
                >
                  Cancel Meeting
                </a>{" "}
              </div>
            </div>
          </div>
        </Modal>

        <DashboardTop
          title={"Meeting Details"}
          back={true}
          backLink={`/office/${props.office.slug}/meeting-room`}
        />
        <form onSubmit={handleSubmit(formSubmit)}>
          <div
            class="middle-content main-content-wrapper"
            style={{ marginTop: "80px" }}
          >
            <div class="patients-export-option meeting-patients-de">
              <h2>{meeting.name}</h2>
              <div class="exports">
                <a
                  href="#cancel-meeting"
                  title="Cancel Meeting"
                  class="border-btn btns fancybox delete-app"
                  onClick={(e) => {
                    e.preventDefault();
                    // dispatch(cancelMeeting(meeting.pk));
                    setModalOpen(true);
                  }}
                >
                  Cancel Meeting
                </a>
                <button
                  href="#"
                  title="Save Changes"
                  class="btns fancybox"
                  type="submit"
                >
                  Save Changes
                </button>
              </div>
            </div>

            <div
              id="meeting-cancelled"
              class="display-none full-height stage4-popup"
            >
              <div class="vertical-middle form-main">
                <div class="common-form">
                  <div class="sub-heading">
                    <h3>cancel meeting</h3>
                  </div>
                  <div class="inner-forgot-pass">
                    <h5>Meeting has been cancelled.</h5>
                    <div class="login-submit">
                      <a
                        href="#"
                        title="Continue"
                        class="btns"
                        data-fancybox-close=""
                      >
                        Continue
                      </a>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="changes-saved"
              class="display-none full-height stage4-popup"
            >
              <div class="vertical-middle form-main">
                <div class="common-form">
                  <div class="sub-heading">
                    <h3>changes saved</h3>
                  </div>
                  <div class="inner-forgot-pass">
                    <h5>Changes have been saved.</h5>
                    <p>
                      An email will be sent if changes were made that affect
                      guests (i.e. date and time).
                    </p>
                    <div class="login-submit">
                      <a
                        href="#"
                        title="Continue"
                        class="btns"
                        data-fancybox-close=""
                      >
                        Continue
                      </a>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="left-sec">
              <div class="white-box meeting-details">
                <form>
                  <h5>Meeting Details</h5>
                  <div class="meeting-option">
                    <div class="meeting-tooltip">
                      <label>
                        Is this a public meeting?{" "}
                        <a href="#" title="" class="tooltip"></a>
                      </label>
                      <div class="arrow_box">
                        If ‘Patient Invited’ is selected, patient will receive
                        invitation to meeting. If not selected, patient will not
                        receive invitation.
                      </div>
                    </div>
                    <div class="p-info">
                      <div class="p-info-inner">
                        <input
                          type="radio"
                          id="radio1"
                          name="public"
                          value="Yes"
                          ref={register({ required: true })}
                          defaultChecked={
                            meeting.public === true ? true : false
                          }
                        />
                        <label for="radio1">
                          <span>Yes</span>
                        </label>
                      </div>
                      <div class="p-info-inner">
                        <input
                          type="radio"
                          id="radio2"
                          name="radio-group"
                          name="public"
                          value="No"
                          ref={register({ required: true })}
                          defaultChecked={
                            meeting.public === false ? true : false
                          }
                        />
                        <label for="radio2">
                          <span>No</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Title:</label>
                    <input
                      type="text"
                      class="common-input"
                      placeholder=""
                      name="title"
                      ref={register({ required: true })}
                      defaultValue={meeting.name}
                    />
                  </div>
                  {meeting.patient ? (
                    <div class="form-group patients-group">
                      <label>Patient:*</label>
                      <Link
                        to={`/office/${props.office.slug}/patient/${meeting.patient.pk}`}
                      >
                        <span class="patients-n">
                          {meeting.patient.first_name}{" "}
                          {meeting.patient.last_name}
                        </span>
                      </Link>
                      <div class="switch-main">
                        <span class="notify">Patient not invited</span>
                        <label class="switch">
                          <input
                            type="checkbox"
                            defaultChecked={meeting.patientInvited}
                            readOnly={true}
                          />
                          <span class="switch-btn"></span>
                        </label>
                        <div class="tooltip-main">
                          <a href="#" title="" class="tooltip"></a>
                          <div class="arrow_box ">
                            If ‘Patient Invited’ is selected, patient will
                            receive invitation to meeting. If not selected,
                            patient will not receive invitation.
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div class="form-group guest-form-group">
                    <label>Guest(s):</label>
                    {!isFetchingParticipants ? (
                      fields.map((item, index) => (
                        <div key={item.id} class="inner-fields-main">
                          <div class="inner-fields">
                            <div class="fields-row-main">
                              <div class="fields-row half">
                                <label>Email Address</label>
                                <input
                                  type="email"
                                  class="common-input"
                                  name={`guests[${index}].email`}
                                  defaultValue={`${item.email}`}
                                  ref={register()}
                                />
                              </div>
                              <div class="fields-row half">
                                <label>Name</label>
                                <input
                                  type="text"
                                  class="common-input"
                                  name={`guests[${index}].name`}
                                  defaultValue={`${item.name}`}
                                  ref={register()}
                                />
                                <input
                                  type="hidden"
                                  ref={register()}
                                  name={`guests[${index}].pk`}
                                  defaultValue={`${item.pk}`}
                                />
                              </div>
                            </div>
                          </div>
                          <div class="guests-link">
                            <a
                              href="#"
                              title="Uninvite Guest"
                              onClick={(e) => {
                                e.preventDefault();
                                if (item.pk) {
                                  dispatch(deleteParticipant(item.pk));
                                }
                                remove(index);
                              }}
                            >
                              {item.pk ? "Uninvite Guest" : "Delete Guest"}
                            </a>
                          </div>
                        </div>
                      ))
                    ) : (
                      <BounceLoader color={"#052D64"} size={30} />
                    )}
                    <div class="add-guest">
                      <a
                        href="#"
                        title=""
                        class="addguest"
                        onClick={(e) => {
                          e.preventDefault();
                          append({
                            email: "",
                            name: "",
                            pk: "",
                          });
                        }}
                      >
                        + Add Guest
                      </a>
                    </div>
                  </div>
                  <div class="form-group datetime-main">
                    <label>Date & Time:</label>
                    <Controller
                      as={DatePicker}
                      className="form-control datetimepicker-input"
                      showTimeSelect
                      valueName="selected"
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      onChange={([selected]) => selected}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy h:mm aa"
                      showMonthDropdown
                      name="datetime"
                      control={control}
                      defaultValue={moment(meeting.date_from).toDate()}
                    />
                    {/* <input
                    type="text"
                    class="form-control datetimepicker-input"
                    id="datetimepicker1"
                    datetimepicker-input
                    defaultValue={moment(meeting.date_from).format(
                      "MMMM DD YYYY | hh:mm a"
                    )}
                    data-toggle="datetimepicker"
                    data-target="#datetimepicker1"
                  /> */}
                  </div>
                  <div class="form-group">
                    <textarea
                      class="common-input"
                      placeholder="Add description of meeting or notes here"
                      defaultValue={meeting.description}
                    ></textarea>
                  </div>
                  {/* <a
                href="#booked-meeting"
                title="Schedule + Send Invitations"
                class="btns fancybox"
              >
                Schedule + Send Invitations
              </a> */}
                  {/* <button type="submit"></button> */}
                </form>
              </div>
            </div>
            <div class="right-sec meeting-right meeting-info">
              <div class="white-box">
                <h5>Meet Link</h5>
                <div class="meet-links-main">
                  <div class="meet-links">
                    <a
                      href={`${process.env.REACT_APP_MEET_LINK}/${meeting.meeting_code}`}
                      target="_blank"
                    >
                      <input
                        type="text"
                        placeholder="meet.corigan.com/546DD23"
                        defaultValue={`${process.env.REACT_APP_MEET_LINK}/${meeting.meeting_code}`}
                      />
                      <input
                        type="submit"
                        value=""
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </a>
                  </div>

                  <div class="meeting-id-main">
                    <label>Meeting ID:</label>
                    <span class="meeting-id">{meeting.meeting_code}</span>
                    <CopyToClipboard
                      text={`${process.env.REACT_APP_MEET_LINK}/${meeting.meeting_code}`}
                    >
                      <a
                        href="#"
                        class="id-copy"
                        onClick={(e) => {
                          e.preventDefault();
                          launchToast("Copied meeting code to clipboard.");
                        }}
                      >
                        Copy ID
                      </a>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="booked-meeting"
              class="display-none full-height stage4-popup"
            >
              <div class="vertical-middle form-main">
                <div class="common-form">
                  <div class="sub-heading">
                    <h3>Success!</h3>
                  </div>
                  <div class="close-box">
                    <a
                      href="#"
                      title=""
                      class="fancybox-button fancybox-close-small"
                      data-fancybox-close=""
                    >
                      <img src="images/close.png" alt="" />
                    </a>
                  </div>
                  <div class="inner-forgot-pass">
                    <h5>Your meeting has been booked. </h5>
                    <p>
                      {" "}
                      To cancel or make any changes to your meeting, visit the
                      Meeting Room tab.{" "}
                    </p>
                    <div class="login-submit">
                      <a
                        href="#"
                        title="ok"
                        class="btns"
                        data-fancybox-close=""
                      >
                        OK
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {hasCancelledMeeting ? (
                <Redirect to={`/office/${props.office.slug}/meeting-room`} />
              ) : (
                ""
              )}
            </div>
          </div>
        </form>
      </React.Fragment>
    ) : (
      "No meeting info"
    )
  ) : (
    <BounceLoader color={"#052D64"} size={30} />
  );
};
