import React, { useEffect } from "react";
import { Link, Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getOfficeRequests,
  acceptOfficeRequest,
  declineOfficeRequest,
} from "../../actions/adminOfficeActions";
import DashboardTop from "../../components/dashboardTop";
import { BounceLoader } from "react-spinners";
import moment from "moment";

function OfficeRequests(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOfficeRequests(props.office.pk));
  }, []);

  const isFetchingApproval = useSelector(
    (state) => state.adminOfficeInteractions.isFetchingApproval
  );
  const officeRequestsHaveLoaded = useSelector(
    (state) => state.adminOfficeInteractions.hasReceivedRequests
  );

  const officeRequests = useSelector(
    (state) => state.adminOfficeInteractions.officeRequests
  );

  return (
    <div>
      <DashboardTop title={`Patients Requests`} />
      <div class="tabbing-main">
        <ul>
          <li>
            <Link to={`/office/${props.currentOffice}/patient-list`} class="">
              Active
            </Link>
          </li>
          <li>
            <Link
              to={`/office/${props.currentOffice}/office-requests`}
              class="requested active-tab"
            >
              Requested <span></span>
            </Link>
          </li>
        </ul>
      </div>
      <div class="search-form-main">
        <form action="#" class="search-form">
          <input
            placeholder=" Search…"
            class="form-control"
            name="s"
            type="search"
          />
          <input type="submit" class="fa fa-search" value="" />
        </form>
      </div>
      <div class="middle-content main-content-wrapper">
        <div class="patients-table">
          <table>
            <tr class="no-border">
              <th>Name</th>
              <th>Gender</th>
              <th>D.O.B.</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Registered</th>
              <th>requested</th>
            </tr>
            {!officeRequestsHaveLoaded ? (
              <BounceLoader size={60} color={"#052D64"} />
            ) : (
              officeRequests.map((patient) => (
                <tr>
                  <td>
                    {patient.patient.first_name
                      ? patient.patient.first_name
                      : "N/A"}{" "}
                    {patient.patient.last_name
                      ? patient.patient.last_name
                      : "N/A"}
                  </td>
                  <td>{patient.patient.gender}</td>
                  <td>{patient.patient.date_of_birth}</td>
                  <td>{patient.patient.user.email}</td>
                  <td>{patient.patient.phone}</td>
                  <td>
                    {moment(patient.patient.user.date_joined).format(
                      "MMMM Do YYYY"
                    )}
                  </td>
                  <td>
                    {!isFetchingApproval ? (
                      <button
                        title="accept"
                        class="common-btn sky-btn"
                        onClick={() =>
                          dispatch(
                            acceptOfficeRequest(patient.pk, props.office.pk)
                          )
                        }
                      >
                        accept
                      </button>
                    ) : (
                      ""
                    )}
                    <button
                      title="delete"
                      class="common-btn border-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(
                          declineOfficeRequest(patient.pk, props.office.pk)
                        );
                      }}
                    >
                      delete
                    </button>
                  </td>
                </tr>
              ))
            )}
          </table>
        </div>
      </div>
    </div>
  );
}

export default OfficeRequests;
