import React, { useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { updateOffice } from "../../../actions/officeSettingsActions";

export const Profile: React.FC = (props: any) => {
  const dispatch = useDispatch();

  const { control, register, handleSubmit, watch, errors } = useForm({
    reValidateMode: "onBlur",
  });

  const office = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.office
  );

  const formSubmit = (data: FormData) => {
    console.log(data);

    dispatch(updateOffice(office.pk, data));
  };

  return (
    <div className="white-box profile-settings">
      <div className="app-setting-head section-head">
        <h4 className="w-100">Office Settings</h4>
        <p>
          Change your office settings. Once you hit save, office settings will
          take action immediately.
        </p>
      </div>
      <form
        className="w-100 app-setting-head"
        onSubmit={handleSubmit(formSubmit)}
      >
        <div className="">
          <div className="week-item">
            <label className="feild-label field-left start">Office Name</label>
            <div className="form-fieldbox field-left">
              <input
                type="text"
                name="name"
                ref={register}
                className="form-control form-feild "
                defaultValue={office.name}
              />
            </div>
          </div>
        </div>
        <div className="">
          <div className="week-item">
            <label className="feild-label field-left start">Availability</label>
            <div className="form-fieldbox field-left">
              <select
                name="availability"
                ref={register}
                defaultValue={office.availability}
                className="form-control form-feild "
              >
                <option value="public">Public</option>
                <option value="private">Private</option>
              </select>
            </div>
          </div>
        </div>

        <h5>Location:</h5>

        <div className="">
          <div className="week-item">
            <label className="feild-label field-left start">Address</label>
            <div className="form-fieldbox field-left">
              <input
                type="text"
                name="address"
                ref={register}
                className="form-control form-feild "
                defaultValue={office.address}
              />
            </div>
          </div>
        </div>
        <div className="">
          <div className="week-item">
            <label className="feild-label field-left start">Province</label>
            <div className="form-fieldbox field-left">
              <input
                type="text"
                name="province"
                ref={register}
                className="form-control form-feild "
                defaultValue={office.province}
              />
            </div>
          </div>
        </div>
        <div className="">
          <div className="week-item">
            <label className="feild-label field-left start">Postal Code</label>
            <div className="form-fieldbox field-left">
              <input
                type="text"
                name="postalcode"
                ref={register}
                className="form-control form-feild "
                defaultValue={office.postalcode}
              />
            </div>
          </div>
        </div>
        <div className="">
          <div className="week-item">
            <label className="feild-label field-left start">City</label>
            <div className="form-fieldbox field-left">
              <input
                type="text"
                name="city"
                ref={register}
                className="form-control form-feild "
                defaultValue={office.city}
              />
            </div>
          </div>
        </div>

        <h5>Contact:</h5>
        <div className="">
          <div className="week-item">
            <label className="feild-label field-left start">Phone</label>
            <div className="form-fieldbox field-left">
              <input
                type="text"
                name="phone"
                ref={register}
                className="form-control form-feild "
                defaultValue={office.phone}
              />
            </div>
          </div>
        </div>

        <h5>Other:</h5>


        <div className="">
          <div className="week-item">
            <label className="feild-label field-left start">Disclaimer</label>
            <div className="form-fieldbox field-left">
              <textarea
                name="disclaimer"
                ref={register}
                className="form-control form-feild "
                defaultValue={office.disclaimer}
              />
            </div>
          </div>
        </div>

        <div className="timeslot-buttonbox">
          <button
            type="submit"
            className="btn btn-primary btn-sm text-capitalize"
          >
            Save changes
          </button>
        </div>
      </form>
    </div>
  );
};
