import React, { useRef } from "react";
import { Redirect, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  signUpUser,
  resetPassword,
  resetPasswordConfirm,
} from "../actions/authActions";
import { BounceLoader } from "react-spinners";

function PasswordReset() {
  const dispatch = useDispatch();
  // Refs
  const password1 = useRef("");
  const password2 = useRef("");
  const isResettingPassword = useSelector(
    (state) => state.authenticateUser.isResettingPassword
  );

  const confirmationMessage = useSelector(
    (state) => state.authenticateUser.confirmationMessage
  );
  return (
    <div class="login-form-main full-height">
      <div class="form-bg">
        <div class="vertical-middle form-main">
          <div class="form-logo-main">
            <a href="#" title="My Docs" class="form-logo"></a>
          </div>
          <div class="login-form forgot-pass-form common-form">
            <div class="sub-heading">
              <h3>Password Reset</h3>
            </div>
            <div class="inner-forgot-pass">
              <div class="form-head forgot-pass-head">
                <h2>Enter New Password</h2>
                <p>Has to have at least 7 characters.</p>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const url_string = window.location.href;
                  const url = new URL(url_string);
                  const token = url.searchParams.get("token");
                  dispatch(
                    resetPasswordConfirm(password1.current.value, token)
                  );
                }}
              >
                <p>
                  <input
                    type="password"
                    placeholder="New Password"
                    class="common-input"
                    ref={password1}
                  />
                </p>
                <p>
                  <input
                    type="password"
                    placeholder="Retype Password"
                    class="common-input"
                    ref={password2}
                  />
                </p>
                {confirmationMessage ? <p>{confirmationMessage}</p> : ""}
                <div class="login-submit">
                  {!isResettingPassword ? (
                    <input
                      type="submit"
                      value="Send New Password"
                      class="blue-btn common-btn"
                    />
                  ) : (
                    <BounceLoader color={"#052D64"} size={50} />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordReset;
