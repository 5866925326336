import axios from "axios";

import store from "store";
// localStorage.removeItem("id_token");
store.remove("chat_token");

if (store.get("id_token")) {
  const token = store.get("id_token");
  let config = {
    method: "POST",
    url: `${process.env.REACT_APP_DEV_API}/api-token-verify/`,
    data: {
      token,
    },
  };

  axios(config)
    .then((res) => {
      return;
    })
    .catch((err) => {
      localStorage.removeItem("id_token");
    });
}

const initialState = {
  isFetching: false,
  isAuthenticated: store.get("id_token") ? true : false,
  profile: {},
  profileReceived: false,
  isCreatingOffice: false,
  hasCreatedOffice: false,
  isFetchingInvites: false,
  receivedInvites: false,
  invites: [],
  isFetchingApproval: false,
  isResettingPassword: false,
  confirmationMessage: "",
  isUpdatingPatient: false,
  updatePatientMessage: "",
  isFetchingOfficeInvite: false,
  officeInviteMessage: "",
  officeInvite: null,
  isSigningUpUser: false,
  signUpMessage: "",
  hasSignedUpUser: false,
};

const authenticateUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_CHANGE_PASSWORD":
      return Object.assign({}, state, {
        isSigningUpUser: true,
        signUpMessage: action.signUpMessage,
      });
    case "RECEIVE_CHANGE_PASSWORD":
      return Object.assign({}, state, {
        signUpMessage: action.signUpMessage,
      });
    case "ERROR_CHANGE_PASSWORD":
      return Object.assign({}, state, {
        isSigningUpUser: false,
        signUpMessage: action.signUpMessage,
      });
    case "REQUEST_UPDATE_ADMIN":
      return Object.assign({}, state, {
        signUpMessage: action.signUpMessage,
      });
    case "RECEIVE_UPDATE_ADMIN":
      return Object.assign({}, state, {
        signUpMessage: action.signUpMessage,
        isSigningUpUser: false,
        hasSignedUpUser: true,
      });
    case "ERROR_UPDATE_ADMIN":
      return Object.assign({}, state, {
        signUpMessage: action.signUpMessage,
      });
    case "REQUEST_RESET_PASSWORD":
      return Object.assign({}, state, {
        isResettingPassword: true,
        confirmationMessage: "",
      });
    case "RECEIVE_RESET_PASSWORD":
      return Object.assign({}, state, {
        isResettingPassword: false,
        confirmationMessage: action.confirmationMessage,
      });
    case "ERROR_RESET_PASSWORD":
      return Object.assign({}, state, {
        isResettingPassword: false,
        confirmationMessage: action.confirmationMessage,
      });
    case "REQUEST_RESET_PASSWORD_CONFIRM":
      return Object.assign({}, state, {
        isResettingPassword: true,
        confirmationMessage: "",
      });
    case "RECEIVE_RESET_PASSWORD_CONFIRM":
      return Object.assign({}, state, {
        isResettingPassword: false,
        confirmationMessage:
          "Password has been reset. You can now login to Corigan.",
      });
    case "LOGIN_USER":
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        user: action.creds.username,
      });
    case "LOGIN_SUCCESS":
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        email: action.email,
      });
    case "LOGOUT_REQUEST":
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: true,
      });
    case "LOGOUT_SUCCESS":
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        profile: {},
        profileReceived: false,
      });
    case "LOGIN_FAILURE":
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        error_message: action.error_message,
      });
    case "SIGNUP_REQUEST":
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        user: action.creds.email,
      });
    case "SIGNUP_RECEIVE":
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        hasSignedUp: true,
      });
    case "ADMIN_PROFILE_REQUEST":
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: true,
      });
    case "ADMIN_PROFILE_RECEIVE":
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        profile: action.profile,
        profileReceived: true,
      });
    case "REQUEST_CREATE_OFFICE":
      return Object.assign({}, state, {
        isCreatingOffice: true,
        hasCreatedOffice: false,
      });
    case "RECEIVE_CREATE_OFFICE":
      return Object.assign({}, state, {
        isCreatingOffice: false,
        hasCreatedOffice: true,
      });
    case "REQUEST_GET_OFFICE_INVITES":
      return Object.assign({}, state, {
        isFetchingInvites: true,
        receivedInvites: false,
      });
    case "RECEIVE_GET_OFFICE_INVITES":
      return Object.assign({}, state, {
        isFetchingInvites: false,
        receivedInvites: true,
        invites: action.invites,
      });
    case "REQUEST_APPROVE_ADMIN_OFFICE_REQUEST":
      return Object.assign({}, state, {
        isFetchingApproval: true,
      });
    case "RECEIVE_APPROVE_ADMIN_OFFICE_REQUEST":
      return Object.assign({}, state, {
        isFetchingApproval: false,
      });
    case "REQUEST_DENY_ADMIN_OFFICE_REQUEST":
      return Object.assign({}, state, {
        isFetchingApproval: true,
      });
    case "RECEIVE_DENY_ADMIN_OFFICE_REQUEST":
      return Object.assign({}, state, {
        isFetchingApproval: false,
      });
    case "REQUEST_SINGLE_OFFICE_INVITE":
      return Object.assign({}, state, {
        isFetchingOfficeInvite: true,
        officeInviteMessage: "",
      });
    case "RECEIVE_SINGLE_OFFICE_INVITE":
      return Object.assign({}, state, {
        type: "ERROR_SINGLE_OFFICE_INVITE",
        isFetchingOfficeInvite: false,
        officeInviteMessage: action.officeInviteMessage,
        officeInvite: action.officeInvite,
      });
    case "ERROR_SINGLE_OFFICE_INVITE":
      return Object.assign({}, state, {
        type: "ERROR_SINGLE_OFFICE_INVITE",
        isFetchingOfficeInvite: false,
        officeInviteMessage: action.officeInviteMessage,
      });
    default:
      return state;
  }
};

export default authenticateUserReducer;
