import React, { useState, useEffect } from "react";
import DashboardTop from "../dashboardTop";
import moment from "moment";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { useDispatch, useSelector } from "react-redux";
import { createBulletinPost } from "../../actions/bulletinActions";

export const ExistingPost = (props) => {
  const [sendEmail, setSendEmail] = useState(false);

  const { control, register, handleSubmit, watch, errors } = useForm();
  const adminPK = useSelector((state) => state.authenticateUser.profile.pk);
  const office = useSelector((state) => state.adminOfficeInteractions.office);
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const formSubmit = (data) => {
    dispatch(
      createBulletinPost(
        data,
        draftToHtml(convertToRaw(editorState.getCurrentContent())),
        office.pk,
        adminPK
      )
    );
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  return (
    <div>
      <DashboardTop
        title={"New Post"}
        back={true}
        backLink={`/office/${props.office.slug}/bulletin`}
      />

      <div class="middle-content main-content-wrapper">
        <form onSubmit={handleSubmit(formSubmit)}>
          <div className="left-sec">
            <div className="title-input-container">
              <input
                type="text"
                placeholder="Title Here"
                ref={register({ required: true })}
                name="title"
              />
            </div>
            <div className="content-area-container">
              <Controller
                as={
                  <Editor
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "list",
                        "textAlign",
                        "link",
                        "colorPicker",
                      ],
                      inline: { inDropdown: false },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                    }}
                    editorState={editorState}
                    onEditorStateChange={(e) => setEditorState(e)}
                  />
                }
                name="editorContent"
                control={control}
                ref={register()}
              />
            </div>
          </div>
          <div className="right-sec bulletin-right">
            <ul className="buttons">
              <li>
                <button className="cancel-button">Cancel</button>
              </li>
              <li>
                <button type="submit" className="save-button">
                  Post
                </button>
              </li>
            </ul>
            <div className="white-box">
              <h5>Date Created:</h5>
              <p>{moment().format("MM-DD-YYYY")}</p>
              <div class="switch-main">
                <span class="notify">
                  {sendEmail
                    ? "Email will be sent to all patients"
                    : "Patients will not be notified through email"}
                </span>
                <label class="switch">
                  <input
                    type="checkbox"
                    defaultChecked={false}
                    ref={register}
                    name="emailPatient"
                  />
                  <span
                    class="switch-btn"
                    onClick={() => setSendEmail(!sendEmail)}
                  ></span>
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
