const initialState = {
  offices: [],
  receivedOffices: false,
  isFetching: false
};

const patientInteractions = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_OFFICES":
      return Object.assign({}, state, {
        isFetching: true,
        receivedOffices: false,
        offices: []
      });
    case "RECEIVE_OFFICES":
      return Object.assign({}, state, {
        isFetching: false,
        receivedOffices: true,
        offices: action.offices
      });
    case "REQUEST_OFFICE_SUBMIT":
      return Object.assign({}, state, {
        isSubmitting: true,
        receivedOffices: true
      });
    case "RECEIVE_OFFICE_SUBMIT":
      return Object.assign({}, state, {
        isSubmitting: false,
        profile: action.profile
      });
    default:
      return state;
  }
};

export default patientInteractions;
