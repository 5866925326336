import React from "react";
import AppointmentItem from "./AppointmentItem";

//Changed component to parse simpleAppointment rather than updatedAppointment
const SimpleAppointmentstList = ({ simpleAppointment }) => {
  return (
    <div className="toolbar-maincontent colour-layout">
      <div className="inner">
        <div className="colour-appointment-list">
          {simpleAppointment.length
            ? simpleAppointment.map((simpleAppointment, index) => {
                return (
                  <AppointmentItem
                    appointment={simpleAppointment}
                    key={simpleAppointment.id}
                  />
                );
              })
            : "No Appointments Found"}
        </div>
      </div>
    </div>
  );
};

export default SimpleAppointmentstList;
