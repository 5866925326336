import React from "react";
import email from "../../../assets/images/email-support-icon.svg";
import phone from "../../../assets/images/phone-support-icon.svg";

export const Support: React.FC = (props: any) => {
  return (
    <div className="white-box">
      <div className="app-setting-head section-head">
        <h4
          className="w-100"
          style={{ marginBottom: "30px", paddingBottom: "10px" }}
        >
          Support
        </h4>
        <p>
          Access our support care. 24/7 access through email and specific hours
          through phone.
        </p>
        <p>
          You can access help at anytime by submitting a ticket using the blue
          "?" at the bottom right of the screen anytime.
        </p>
        <hr style={{ marginTop: "30px" }} />

        <h5>24/7 Access to Support Service</h5>
        <ul className="email">
          <li>
            <img src={email} alt="" />
          </li>
          <li>
            <a href="mailto:help@getcorigan.ca">help@getcorigan.ca</a>
          </li>
        </ul>

        <h5>Phone Hours (EST)</h5>
        <ul className="hours">
          <li>
            <span>Monday</span>
            <p>9:30am - 5:00pm</p>
          </li>
          <li>
            <span>Tuesday</span>
            <p>9:30am - 5:00pm</p>
          </li>
          <li>
            <span>Wednesday</span>
            <p>9:30am - 5:00pm</p>
          </li>
          <li>
            <span>Thursday</span>
            <p>9:30am - 5:00pm</p>
          </li>
          <li>
            <span>Friday</span>
            <p>9:30am - 5:00pm</p>
          </li>
        </ul>
        <ul>
          <li>
            <img src={phone} alt="" />
          </li>
          <li>
            <a href="tel:5193391590">519-339-1590</a>
          </li>
        </ul>
      </div>
    </div>
  );
};
