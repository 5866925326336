import React from "react";

export const Patients: React.FC = (props: any) => {
  return (
    <div className="white-box">
      <div className="app-setting-head section-head">
        <h4 className="w-100">Hours of Operation</h4>
        <p>
          Select which times you’d like patients to be able to start booking
          COVID-19 appointments and what time you’d like to stop booking. Be
          sure to add hours for each day that your office is open. If office is
          closed a certain day, don’t add any hours.
        </p>
      </div>
      <form className="w-100">
        <div className="timeslot-box" />

        <div className="timeslot-buttonbox">
          <button
            type="submit"
            className="btn btn-primary btn-sm text-capitalize"
          >
            Save changes
          </button>
        </div>
      </form>
    </div>
  );
};
