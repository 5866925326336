import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";
import { setOfficeNewEvents } from "../../../actions/adminOfficeActions";
const Users = (props) => {
  const officeNewEvents = useSelector(
    (state) => state.adminOfficeInteractions.officeNewEvents
  );
  const dispatch = useDispatch();
  const { register, control, handleSubmit, errors } = useForm({
    defaultValues: {
      users: officeNewEvents && officeNewEvents.users,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "users",
  });
  const onSubmit = (data) => {
    dispatch(setOfficeNewEvents(data));
    props.stepsHandler(4);
  };
  console.log(errors);
  return (
    <div className="mt-44">
      <div className="content-group">
        <div className="content-wrapper">
          <div className="mt-26">
            <p>
              A user receives the invite to join the office through email. Be
              sure to use the correct email that the user will want to use for
              their account.
            </p>
          </div>
        </div>
      </div>
      <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
        {fields.map((item, index) => {
          return (
            <div className="form-field-space" key={item.id}>
              <div className="w-100 feild-row">
                <label className="feild-label field-left">Email:</label>

                <div className="form-fieldbox">
                  <input
                    autoComplete={"off"}
                    type="email"
                    placeholder="peteroswald@gmail.com"
                    className="form-feild w-100"
                    name={`users[${index}].email`}
                    ref={register({ required: true })}
                  />
                </div>
              </div>
              <div className="w-100 feild-row">
                <label className="feild-label field-left">Role:</label>

                <div className="form-fieldbox">
                  <select
                    name={`users[${index}].role`}
                    ref={register()}
                    className="form-feild w-100"
                  >
                    <option value="admin">Staff</option>
                    <option value="doctor">Doctor</option>
                  </select>
                </div>
              </div>
            </div>
          );
        })}
        <div class="w-100 feild-row">
          <label class="feild-label field-left" />
          <div class="form-fieldbox">
            <a
              className="common-btn-updated yellow-btn"
              onClick={(e) => {
                e.preventDefault();
                append({
                  email: "",
                  role: "admin",
                });
              }}
            >
              + Add Another User
            </a>
            <a
              className="common-btn-updated yellow-btn"
              style={{ marginTop: "10px" }}
              onClick={(e) => {
                e.preventDefault();
                remove(fields.length - 1);
              }}
            >
              - Remove Last User
            </a>
          </div>
        </div>

        <div className="bottom-buttons flex-end">
          <a
            className="common-btn white-btn common-btn-updated mr-15"
            onClick={() => props.stepsHandler(2)}
          >
            Go Back
          </a>
          <input
            type="submit"
            value="Confirm Details"
            className="common-btn blue-btn common-btn-updated"
          />
        </div>
      </form>
    </div>
  );
};

export default Users;
