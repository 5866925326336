import React, { useEffect, useState } from "react";
import styles from "./Scheduled.module.scss";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { getAvailableAppointments } from "../../../actions/dashboardActions";
import CountUp from "react-countup";

export const Scheduled = (props: any) => {
  const dispatch = useDispatch();
  const [percent, setPercent] = useState<number | null>(null);
  const office = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.office
  );

  const availableAppointments = useSelector(
    (state: RootStateOrAny) => state.dashboardReducer.availableAppointments
  );

  useEffect(() => {
    dispatch(getAvailableAppointments(office.pk));
  }, []);

  useEffect(() => {
    if (availableAppointments) {
      setPercent(
        Math.round(
          (availableAppointments.total_booked_appointments /
            availableAppointments.total_appointments) *
            100
        )
      );
    }
  }, [availableAppointments]);

  return (
    <div className={styles.whiteBox}>
      <div className="counterContainer">
        <div className={styles.circle}>
          {percent && !isNaN(percent) ? (
            <div className={styles.percent}>
              <CountUp end={Math.round(percent)} duration={4} />%
            </div>
          ) : ""}
        </div>
        <div className="title-container">
          <h3 className={styles.scheduled}>Scheduled</h3>
          <p className={styles.finePrint}>*Upcoming Week</p>
        </div>
      </div>
    </div>
  );
};
