import React from "react";
import { Link } from "react-router-dom";

const OfficeCreated = () => {
  return (
    <div className="content-group">
      <div className="content-wrapper office-created-section">
        <div className="heading">Office Created!</div>
        <div className="subheading">
          Your one step closer to simplifying your online office!
        </div>
        <div style={{ paddingTop: 4 }}>
          <p>
            Go back to the office selection page and you’ll see your new office
            that you just created. To add more details to your online office,
            select edit office.
          </p>
        </div>
        <Link to={`/dashboard`}>
          <button class="common-btn blue-btn common-btn-updated">
            Office Selection
          </button>
        </Link>
      </div>
    </div>
  );
};

export default OfficeCreated;
