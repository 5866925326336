import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { convertToRaw, EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { stateFromHTML } from "draft-js-import-html";
import {
  getAutomatedMessage,
  saveAutomatedMessage,
} from "../../../actions/appointmentActions";
export const AutomatedMessageEditor = (props) => {
  const dispatch = useDispatch();

  const isFetchingAutomatedMessage = useSelector(
    (state) => state.appointmentReducer.isFetchingAutomatedMessage
  );
  const automatedMessage = useSelector(
    (state) => state.appointmentReducer.automatedMessage
  );

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onBlurAutomatedMessage = (pk, value) => {
    dispatch(
      saveAutomatedMessage(
        pk,
        draftToHtml(convertToRaw(value.getCurrentContent()))
      )
    );
  };
  useEffect(() => {
    if (automatedMessage) {
      let contentState = stateFromHTML(automatedMessage.message);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [automatedMessage]);

  return (
    <Editor
      wrapperClassName="wrapperClassName"
      onBlur={(e) => {
        onBlurAutomatedMessage(automatedMessage.pk, editorState);
      }}
      editorClassName="editorClassName"
      toolbar={{
        options: ["inline", "blockType", "list", "textAlign", "link"],
        inline: {
          inDropdown: false,
          options: ["bold", "italic", "underline"],
        },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true },
        history: { inDropdown: true },
      }}
      rawContentState={editorState}
      editorState={editorState}
      defaultValue={automatedMessage.message}
      onEditorStateChange={(e) => setEditorState(e)}
    />
  );
};
