import React from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { createPortal } from "react-dom";
import { printData, printLabels } from "../../../actions/appointmentActions";
import { BounceLoader } from "react-spinners";

export const PrintData = (props) => {
  const { control, register, handleSubmit, watch, errors } = useForm();
  const office = useSelector((state) => state.adminOfficeInteractions.office);
  const printers = useSelector((state) => state.appointmentReducer.printers);
  const isPrintingData = useSelector(
    (state) => state.appointmentReducer.isPrintingData
  );
  const dispatch = useDispatch();
  const formSubmit = (data) => {
    console.log(data);
    if (data.form) {
      dispatch(printData(data.start, data.end, office.pk));
    }

    if (data.label) {
      dispatch(printLabels(data.start, data.end, office.pk, printers));
    }
    props.setOpenPrint(false);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      maxHeight: "100vh",
      marginRight: "-50%",
      overflowY: "auto",
      overflow: "hidden",
      transform: "translate(-50%, -50%)",
      padding: 0,
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
    },
  };

  const CalendarContainer = ({ children }) =>
    children
      ? createPortal(
          React.cloneElement(children, {
            className: "datetimepicker-input react-datepicker-popper",
          }),
          document.body
        )
      : null;

  return (
    <Modal isOpen={true} style={customStyles}>
      <div
        id="export-data-popup"
        className=" stage4-popup fancybox-content  updated-popup-form"
        style={{
          display: "inline-block",
          width: "464px",
          height: "310px",
          overflowX: "hidden",
        }}
      >
        <form onSubmit={handleSubmit(formSubmit)}>
          <div>
            <div className="common-form">
              <div className="sub-heading common-subheading">
                <h3>Print Data</h3>
              </div>
              <div className="inner-forgot-pass inner-popup-content">
                <h4>Select a Timeframe</h4>
                <div className="snooze-datetime-main calendar-datetime">
                  <div className="snooze-date-time datetime-main form-group">
                    <label className="select-dt">Select Date(s):</label>
                    <div className="office-time o-date">
                      <Controller
                        control={control}
                        name="start"
                        control={control}
                        render={({ onChange, value }) => (
                          <DatePicker
                            dateFormat="MM-dd-yy"
                            popperContainer={CalendarContainer}
                            showMonthDropdown
                            onChange={(date) => onChange(date)}
                            selected={value}
                            className="form-control datetimepicker-input"
                          />
                        )}
                      />
                    </div>
                    <span className="to">to</span>
                    <div className="office-time o-date">
                      <Controller
                        control={control}
                        name="end"
                        control={control}
                        render={({ onChange, value }) => (
                          <DatePicker
                            dateFormat="MM-dd-yy"
                            popperContainer={CalendarContainer}
                            showMonthDropdown
                            onChange={(date) => onChange(date)}
                            selected={value}
                            className="form-control datetimepicker-input"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="print-checbk">
                  <label>Print:</label>
                  <span className="form-group">
                    <input
                      type="checkbox"
                      id="label-form"
                      name="label"
                      className="checkbox-custom"
                      value="label"
                      ref={register()}
                    />
                    <label
                      htmlFor="label-form"
                      className="checkbox-custom-label"
                    >
                      <span>Label</span>
                    </label>
                  </span>
                  <span className="form-group">
                    <input
                      type="checkbox"
                      id="form-form"
                      name="form"
                      value="form"
                      className="checkbox-custom"
                      ref={register()}
                    />
                    <label
                      htmlFor="form-form"
                      className="checkbox-custom-label"
                    >
                      <span>Form</span>
                    </label>
                  </span>
                </div>

                <div className="login-submit">
                  <a
                    href="#cancel"
                    title="Cancel"
                    className="popup-btn whitebtn"
                    data-fancybox-close=""
                    onClick={(e) => {
                      e.preventDefault();
                      props.setOpenPrint(false);
                    }}
                  >
                    Cancel
                  </a>
                  {!isPrintingData ? (
                    <button
                      type="submit"
                      title="Export Data"
                      className="popup-btn"
                    >
                      Print Data
                    </button>
                  ) : (
                    <BounceLoader />
                  )}
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            data-fancybox-close=""
            className="fancybox-button fancybox-close-small"
            title="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1"
              viewBox="0 0 24 24"
            >
              <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
            </svg>
          </button>
        </form>
      </div>
    </Modal>
  );
};
