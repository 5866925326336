import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import moment from "moment";
import {
  getOfficeHoursByOffice,
  getPriorityOfficeHoursByOffice,
  selectPriorityOfficeHour,
  setNewOfficeHours,
  setOfficeSettingsTab,
  submitOfficeHours,
  updateNewOfficeHours,
} from "../../../actions/officeSettingsActions";
import DatePicker from "react-datepicker";
import { PriorityBookingDeleteModal } from "./PriorityBookingDeleteModal";

export const PriorityBookingTimes = (props: any) => {
  const dispatch = useDispatch();
  const office = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.office
  );
  const priorityOfficeHours = useSelector(
    (state: RootStateOrAny) => state.officeSettingsReducer.priorityOfficeHours
  );
  const hasDeletedPriorityOfficeHour = useSelector(
    (state: RootStateOrAny) =>
      state.officeSettingsReducer.hasDeletedPriorityOfficeHour
  );

  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getPriorityOfficeHoursByOffice(office.pk));
  }, [hasDeletedPriorityOfficeHour]);

  // useEffect(() => {
  //   if (officeHours.length > 0) {
  //     dispatch(setNewOfficeHours(officeHours));
  //   }
  // }, [officeHours]);

  const changeBookingTime = (day: number, key: string, value: any) => {
    dispatch(updateNewOfficeHours(day, key, value));
  };

  return (
    <div className="white-box">
      {openModal && <PriorityBookingDeleteModal setOpenModal={setOpenModal} />}
      <div className="app-setting-head">
        <h4>Priority Booking Times</h4>
        <a
          href="#"
          title="Add New"
          className="common-btn blue-btn addnew"
          onClick={(e) => {
            e.preventDefault();
            dispatch(setOfficeSettingsTab("new-priority-tab"));
          }}
        >
          <span> + &nbsp;Add New </span>
        </a>
      </div>
      <div className="automatic-rpl">
        <p>
          Priority Booking Times will allow you to overwrite a certain day in
          case of quick changes. For example, if you set mondays to be between
          9:00am - 5:00pm. You can change Monday December the 13th to being
          3:00pm - 6:00pm.
        </p>

        <div className="automatic-rpl-box">
          {priorityOfficeHours &&
            priorityOfficeHours.map((priorityOfficeHour: any) => (
              <div className="automatic-rpl-row">
                <div className="automatic-rpl-title">
                  <span>
                    {" "}
                    {moment(priorityOfficeHour.date).format(
                      "MMMM Do YYYY"
                    )}{" "}
                  </span>
                </div>

                <div className="automatic-rpl-date">
                  <span>
                    {moment(priorityOfficeHour.from_hour, "hh:mm:ss").format(
                      "LT"
                    )}{" "}
                    -{" "}
                    {moment(priorityOfficeHour.to_hour, "hh:mm:ss").format(
                      "LT"
                    )}{" "}
                  </span>
                </div>

                <div className="automatic-rpl-edit">
                  <div className="edit-app">
                    <a
                      href="#"
                      title="edit"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(selectPriorityOfficeHour(priorityOfficeHour));
                        dispatch(setOfficeSettingsTab("edit-priority-tab"));
                      }}
                    />
                  </div>
                </div>

                <div className="close-app">
                  <a
                    href="#"
                    title="close"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(selectPriorityOfficeHour(priorityOfficeHour));
                      setOpenModal(true);
                    }}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
