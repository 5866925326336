import React, { useState, useRef, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { useDispatch, useSelector } from "react-redux";
import { getSearchPatients } from "../../actions/messagingActions";
import { getMessageThread } from "../../actions/messagingActions";
import launchToast from "../../utils";

function NewMessage(props) {
  const dispatch = useDispatch();
  const [selectedPatient, setSelectedPatient] = useState(null);
  const subject = useRef();
  const body = useRef();

  function listenMessage(e) {
    const response_data = JSON.parse(e.data);
    if (response_data.type == "switch_to_created_thread") {
      props.setThread(response_data.thread);
      props.setNewThread(false);
    }
  }

  useEffect(() => {
    // Listens for switch_to_created_thread and changes to the thread when initiates
    props.socket.addEventListener("message", listenMessage);
    return () => {
      props.socket.removeEventListener("message", listenMessage);
    };
  }, []);

  return (
    <div class="col-7 right-sec">
      <div class="white-box inbox-info-right">
        <div class="inbox-right-head">
          <div class="inbox-hd">
            <h3>
              <AsyncSelect
                loadOptions={(input, callback) => {
                  getSearchPatients(input, callback, props.office.slug);
                }}
                placeholder={"To:"}
                onChange={(e) => {
                  setSelectedPatient(e.value);
                }}
              />
            </h3>
            <h4>
              <input
                type="text"
                className=""
                placeholder="Subject"
                ref={subject}
              />
            </h4>
          </div>
          {/* <div class="conversations" id="archive-show3">
            <a href="javascript:void(0);" class="conversation">
              <img src="images/stage2-images/view-archive.png" alt="" />
            </a>
            <div class="archive-show">
              <ul>
                <li>
                  <a href="#" title="Follow Up" class="followup">
                    Follow Up
                  </a>
                </li>
                <li>
                  <a href="#" title="Important" class="important">
                    Important
                  </a>
                </li>
                <li>
                  <a href="#" title="Archive">
                    Archive
                  </a>
                </li>
                <li>
                  <a href="#" title="Delete">
                    Delete
                  </a>
                </li>
                <li>
                  <a href="#" title="view profile">
                    view profile
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
        <div class="type-msg">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              console.log("hio");
              if (!subject.current.value) {
                console.log("no subject");
                launchToast(
                  "Make sure you include Patient, Subject and Message"
                );
              } else if (!body.current.value) {
                launchToast("Make sure you include a Message");
              } else if (!selectedPatient) {
                launchToast("Make sure you include a patient to send to.");
              } else {
                props.socket.send(
                  JSON.stringify({
                    type: "ADMIN_CREATE_THREAD",
                    subject: subject.current.value,
                    body: body.current.value,
                    profile: selectedPatient,
                    office: props.office.pk,
                    user: props.profile.user.pk,
                    office_slug: props.office.slug,
                  })
                );
              }
            }}
          >
            <input
              type="text"
              placeholder="Type a message..."
              class="common-input"
              ref={body}
            />
          </form>
          <div class="copy-msg">
            <a href="javascript:void(0);" title="copy" class="copy"></a>
            <div class="copy-msg-open white-box">Copy Conversation</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewMessage;
