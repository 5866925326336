import moment from "moment";

const initialState = {
  appointmentsBooked: {},
  availableAppointments: {},
  upcomingBooked: {},
  ageDemographics: null,
};

const dashboardReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "RECEIVE_GET_APPOINTMENTS_BOOKED":
      return Object.assign({}, state, {
        appointmentsBooked: action.appointmentsBooked,
      });
    case "RECEIVE_GET_AVAILABLE_APPOINTMENTS":
      return Object.assign({}, state, {
        availableAppointments: action.availableAppointments,
      });
    case "RECEIVE_GET_UPCOMING_BOOKED":
      return Object.assign({}, state, {
        upcomingBooked: action.upcomingBooked,
      });
    case "RECEIVE_GET_AGE_DEMOGRAPHICS":
      return Object.assign({}, state, {
        ageDemographics: action.ageDemographics,
      });
    default:
      return state;
  }
};

export default dashboardReducer;
