import React from "react";
import moment from "moment";

type AdminSingleMessageProps = {
  message: string;
  timestamp: string;
  adminProfile: string;
  sentByUser: boolean;
};

export const AdminSingleMessage: React.FC<any> = (
  props: AdminSingleMessageProps
) => {
  return (
    <div
      className={`chat-text ${!props.sentByUser ? "chat-left" : "chat-right"}`}
    >
      <div className={`chatbox ${!props.sentByUser ? "grey-bx" : "blue-bx"}`}>
        <p>{props.message}</p>
      </div>
      <span className="chat-time">
        {props.adminProfile} | {moment(props.timestamp).format("LLL")}
      </span>
    </div>
  );
};
