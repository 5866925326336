import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { BounceLoader } from "react-spinners";
import {
  getImmunizationList,
  getSymptomsList,
  getUserEmail,
  removeSymptom,
  resetImmunizations,
  resetSymptom,
  selectSymptom,
} from "../../../actions/appointmentActions";
import {
  addSymptomsToForm,
  handleEmailFormChange,
  handleFormChange,
  resetForm,
  saveFormInstance,
  setForm,
  updateFormInstance,
  getDependents,
} from "../../../actions/newAppointmentActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from "react-select";
import { NewAppointmentSymptoms } from "./NewAppointmentSymptoms";
import { NewAppointmentImmunizations } from "./NewAppointmentImmunizations";
const InputMask = require("react-input-mask");

export const NewAppointmentPatient = (props: any) => {
  const dispatch = useDispatch();
  const newUser = useSelector(
    (state: RootStateOrAny) => state.appointmentReducer.newUser
  );
  const office = useSelector(
    (state: RootStateOrAny) => state.adminOfficeInteractions.office
  );
  const handleChange = (name: any, value: any) => {
    dispatch(handleFormChange(name, value));
    dispatch(saveFormInstance(props.instance, name, value));
  };
  const patientForm = useSelector(
    (state: RootStateOrAny) => state.newAppointmentReducer.patientForm
  );
  const newAppointmentTab = useSelector(
    (state: RootStateOrAny) => state.newAppointmentReducer.newAppointmentTab
  );
  const patientForms = useSelector(
    (state: RootStateOrAny) => state.newAppointmentReducer.patientForms
  );
  const dependentsOnly = useSelector(
    (state: RootStateOrAny) => state.newAppointmentReducer.dependentsOnly
  );
  const dependents = useSelector(
    (state: RootStateOrAny) => state.newAppointmentReducer.dependents
  );
  const symptoms = useSelector(
    (state: RootStateOrAny) => state.appointmentReducer.symptoms
  );
  const immunizations = useSelector(
    (state: RootStateOrAny) => state.newAppointmentReducer.immunizations
  );
  const selectedSymptoms = useSelector(
    (state: RootStateOrAny) => state.appointmentReducer.selectedSymptoms
  );

  const formEl = useRef<any>(null);
  const dateOfBirth = useRef<any>(null);
  const ohipNum = useRef<any>(null);

  useEffect(() => {
    dispatch(setForm(props.instance));
    // dispatch(setSymptom(props.instance));

    return () => {
      dispatch(resetForm());
      dispatch(resetSymptom());
      dispatch(resetImmunizations());
      formEl.current.reset();
    };
  }, [props.instance]);

  useEffect(() => {
    if (patientForm.email) {
      const isNewUser = setTimeout(() => {
        dispatch(getUserEmail(patientForm.email));
      }, 500);
      return () => clearTimeout(isNewUser);
    }
  }, [dispatch, patientForm.email]);

  useEffect(() => {
    if (newUser) {
      dispatch(getDependents(newUser.userPk));
    }
  }, [dispatch, newUser]);

  // useEffect(() => {
  //   if (selectedSymptoms.length > 0) {
  //     dispatch(addSymptomsToForm(props.instance, selectedSymptoms));
  //   }
  // }, [dispatch, props.instance, selectedSymptoms]);

  useEffect(() => {
    if (office.immunization_clinic) {
      dispatch(getImmunizationList());
    } else {
      dispatch(getSymptomsList());
    }
  }, []);

  // useEffect(() => {
  //   if (newUser) {
  //     dispatch(handleEmailFormChange(newUser));
  //   }
  // }, [newUser]);

  useEffect(() => {}, [props.instance]);

  return patientForm ? (
    <div className="white-box sidebar-with-dropdown-innerbox">
      <div className="app-setting-head mb-0">
        <h4>
          {" "}
          Patient {props.instance == 0
            ? "(Admin)"
            : props.instance} Details{" "}
        </h4>
      </div>
      <div className="appoinment w-100">
        <form className="w-100" ref={formEl}>
          <div className="form-field-space">
            <div className="w-100 feild-row">
              {props.instance === 0 ? (
                <div className="half-field">
                  <label className="feild-label field-left">Email:</label>

                  <div className="form-fieldbox">
                    <input
                      autoComplete={"off"}
                      type="text"
                      placeholder="email"
                      className="form-feild w-100"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      name="email"
                      defaultValue={patientForm.email}
                    />
                  </div>
                </div>
              ) : props.instance > 0 && newUser ? (
                <div className="half-field">
                  <label className="feild-label field-left">
                    Existing Dependents:
                  </label>

                  <div className="form-fieldbox">
                    <select
                      placeholder="Existing Dependents:"
                      onChange={(e) => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      name="patientPk"
                      value={patientForm.patientPk}
                      className="form-feild w-100"
                    >
                      <option value="">- Create New Dependent -</option>
                      {dependents &&
                        dependents.map((dependent: any) => (
                          <option value={dependent.value}>
                            {dependent.label}
                          </option>
                        ))}
                    </select>
                    {/* <Select
                      defaultOptions
                      options={dependents}
                      placeholder={"Existing Dependents:"}
                      onChange={(e: any) => {
                        handleChange("patientPk", e.value);
                        handleChange("patientPkName", e.label);
                      }}
                      defaultValue={{
                        value: patientForm.patientPk,
                        label: patientForm.patientPkName,
                      }}
                    /> */}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          {props.instance === 0 && newUser && (
            <p>
              This user already has a Corigan account. Their information will be
              saved when submitting the form.
            </p>
          )}
          {props.instance === 0 && newUser ? (
            ""
          ) : patientForm.patientPk ? (
            <p>
              This user's information will be saved when appointment is created.
            </p>
          ) : (
            <>
              <div className="inner-header">
                <h5> Personal Details </h5>
              </div>

              <div className="form-field-space">
                <div className="w-100 feild-row">
                  <div className="half-field">
                    <label className="feild-label field-left">
                      First name on OHIP:
                    </label>

                    <div className="form-fieldbox">
                      <input
                        autoComplete={"off"}
                        type="text"
                        placeholder="Jennifer"
                        className="form-feild w-100"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        name="first_name"
                        defaultValue={patientForm.first_name}
                      />
                    </div>
                  </div>

                  <div className="half-field-second">
                    <label className="feild-label field-left">
                      Last name on OHIP:
                    </label>

                    <div className="form-fieldbox">
                      <input
                        autoComplete={"off"}
                        type="text"
                        placeholder="Braun"
                        className="form-feild w-100"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        name="last_name"
                        defaultValue={patientForm.last_name}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-100 feild-row">
                  <div className="half-field">
                    <label className="feild-label field-left">
                      OHIP Card #:
                    </label>

                    <div className="form-fieldbox">
                      <input
                        autoComplete={"off"}
                        placeholder="OHIP #"
                        name="ohipNum"
                        className="form-feild w-100"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        // defaultValue={patientForm.ohipNum}
                        maxLength={10}
                        defaultValue={patientForm.ohipNum}
                      />
                    </div>
                  </div>

                  <div className="half-field-second">
                    <label className="feild-label field-left">
                      OHIP 2 Letters:
                    </label>

                    <div className="form-fieldbox">
                      <input
                        autoComplete={"off"}
                        type="text"
                        placeholder="VV"
                        className="form-feild w-100"
                        name="ohip_vc"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        defaultValue={patientForm.ohip_vc}
                        maxLength={2}
                      />
                    </div>
                  </div>
                </div>

                <div className="w-100 feild-row">
                  <div className="half-field">
                    <label className="feild-label field-left">
                      Date of Birth:
                    </label>

                    <div className="form-fieldbox">
                      <div className="timeslot-box-time-piker w-100">
                        <DatePicker
                          selected={
                            patientForm.date_of_birth
                              ? patientForm.date_of_birth !== ""
                                ? moment(patientForm.date_of_birth).toDate()
                                : null
                              : null
                          }
                          name="date_of_birth"
                          key={"date_of_birth"}
                          placeholderText="MM/DD/YYYY"
                          className={"form-feild w-100"}
                          onChange={(e: any) => {
                            if (e) {
                              handleChange(
                                "date_of_birth",
                                moment(e).format("MM/DD/YYYY")
                              );
                            }
                          }}
                        />
                        {/* <input autoComplete={"off"}Mask
                          className={"form-feild w-100"}
                          valueName="selected"
                          mask="99/99/9999"
                          placeholder="MM/DD/YYYY"
                          autoComplete="off"
                          name="date_of_birth"
                          onChange={e => handleChange(e.target.name, e.target.value)}
                          defaultValue={patientForm.date_of_birth}
                          ref={dateOfBirth}
                        /> */}
                      </div>
                    </div>
                  </div>

                  <div className="half-field-second">
                    <label className="feild-label field-left">Gender:</label>

                    <div className="form-fieldbox">
                      <select
                        name="gender"
                        className="form-feild w-100"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        value={patientForm.gender}
                      >
                        <option value="">- Select -</option>
                        <option value="male">male</option>
                        <option value="female">female</option>
                        <option value="other">other</option>
                        <option value="prefer not to say">
                          prefer not to say
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="w-100 feild-row">
                  <div className="half-field">
                    <label className="feild-label field-left">Dr/NP:</label>

                    <div className="form-fieldbox">
                      <input
                        autoComplete={"off"}
                        type="text"
                        placeholder="Lacroix"
                        className="form-feild w-100"
                        name="doctor"
                        defaultValue={patientForm.doctor}
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="half-field-second">
                    <label className="feild-label field-left">Address:</label>

                    <div className="form-fieldbox">
                      <input
                        autoComplete={"off"}
                        type="text"
                        name="address"
                        className="form-feild w-100"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        defaultValue={patientForm.address}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-100 feild-row">
                  <div className="half-field">
                    <label className="feild-label field-left">
                      Postal Code:
                    </label>

                    <div className="form-fieldbox">
                      <input
                        autoComplete={"off"}
                        type="text"
                        placeholder="Postal Code"
                        className="form-feild w-100"
                        name="postal_code"
                        defaultValue={patientForm.postal_code}
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        maxLength={7}
                      />
                    </div>
                  </div>
                  <div className="half-field-second">
                    <label className="feild-label field-left">Phone:</label>

                    <div className="form-fieldbox">
                      <input
                        autoComplete={"off"}
                        type="text"
                        name="phone"
                        className="form-feild w-100"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        defaultValue={patientForm.phone}
                      />
                    </div>
                  </div>
                </div>
                {office.vaccination_clinic && (
                  <>
                    <div className="w-100 feild-row">
                      <div className="half-field">
                        <label className="feild-label field-left">
                          Alternative ID
                        </label>

                        <div className="form-fieldbox">
                          <select
                            name="infoLabel"
                            id=""
                            className="form-feild w-100"
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                          >
                            <option value="">Please select option.</option>
                            <option value="Birth Certificate">
                              Birth Certificate.
                            </option>
                            <option value="Employee ID">Employee ID.</option>
                            <option value="First Nation">First Nation.</option>
                            <option value="Passport">Passport.</option>
                            <option value="MRN">MRN.</option>
                            <option value="Out of Province Health Card #">
                              Out of Province Health Card #.
                            </option>
                            <option value="Driver's License">
                              Driver's License.
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="half-field-second">
                        <label className="feild-label field-left">
                          Alternative ID #
                        </label>

                        <div className="form-fieldbox">
                          <input
                            autoComplete={"off"}
                            type="text"
                            name="infoValue"
                            className="form-feild w-100"
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                            defaultValue={patientForm.infoValue}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-100 feild-row">
                      <div className="half-field">
                        <label className="feild-label field-left">Dose #</label>

                        <div className="form-fieldbox">
                          <select
                            name="dose"
                            id=""
                            className="form-feild w-100"
                            defaultValue={patientForm.dose}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                          >
                            <option value="First Dose">First Dose</option>
                            <option value="Second Dose">Second Dose</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {office.requires_student_number && (
                  <div className="w-100 feild-row">
                    <div className="half-field">
                      <label className="feild-label field-left">
                        Student Card #:
                      </label>

                      <div className="form-fieldbox">
                        <input
                          autoComplete={"off"}
                          type="text"
                          className="form-feild w-100"
                          name="studentNumber"
                          defaultValue={patientForm.studentNumber}
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          maxLength={7}
                        />
                      </div>
                    </div>
                    <div className="half-field-second">
                      <label className="feild-label field-left">
                        Green Shield #:
                      </label>

                      <div className="form-fieldbox">
                        <input
                          autoComplete={"off"}
                          type="text"
                          name="greenshieldNumber"
                          className="form-feild w-100"
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          defaultValue={patientForm.greenshieldNumber}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="w-100 feild-row">
                  <div className="half-field">
                    <label className="feild-label field-left">City:</label>

                    <div className="form-fieldbox">
                      <input
                        autoComplete={"off"}
                        type="text"
                        placeholder="City"
                        className="form-feild w-100"
                        name="city"
                        defaultValue={patientForm.city}
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {props.instance === 0 && dependentsOnly ? (
            ""
          ) : office.immunization_clinic ? (
            <>
              <div className="app-setting-head mb-0">
                <h4> Immunization Questionnaire </h4>
              </div>
              <div className="form-field-space">
                <div className="field-full w-100">
                  <label className="feild-label w-100">
                    Is this for someone physically attending school?{" "}
                  </label>

                  <div className="w-100 fieldvalue-full">
                    <div className="w-100">
                      <label
                        className="radiobuttonbox-label"
                        htmlFor="attendyes"
                      >
                        <input
                          autoComplete={"off"}
                          type="radio"
                          id="attendyes"
                          name="is_attending_school"
                          className=""
                          value="yes"
                          onClick={(e: any) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          defaultChecked={
                            patientForm.is_attending_school === "yes"
                              ? true
                              : false
                          }
                        />
                        <span>yes</span>
                      </label>

                      <label
                        className="radiobuttonbox-label"
                        htmlFor="attendno"
                      >
                        <input
                          autoComplete={"off"}
                          type="radio"
                          id="attendno"
                          name="is_attending_school"
                          className=""
                          value="no"
                          onClick={(e: any) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          defaultChecked={
                            patientForm.is_attending_school === "no"
                              ? true
                              : false
                          }
                        />
                        <span>no</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="field-full w-100">
                  <label className="feild-label w-100">
                    {" "}
                    If yes, select what city and school:{" "}
                  </label>

                  <div className="w-100 fieldvalue-full">
                    <input
                      autoComplete={"off"}
                      type="text"
                      className="form-feild w-100"
                      placeholder="City"
                      name="city"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      defaultValue={patientForm.city}
                    />
                  </div>

                  <div className="w-100 fieldvalue-full">
                    <input
                      autoComplete={"off"}
                      type="text"
                      className="form-feild w-100"
                      placeholder="School"
                      name="school"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      defaultValue={patientForm.school}
                    />
                  </div>
                </div>
              </div>
              <div className="form-field-space">
                <div className="field-full w-100">
                  <label className="feild-label w-100">
                    I am providing consent for my child to receive the following
                    immunization(s):
                  </label>
                  <div className="checkboxitems">
                    {patientForm && (
                      <NewAppointmentImmunizations
                        symptoms={immunizations}
                        patientForm={patientForm}
                        instance={props.instance}
                        fluClinic={true}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="form-field-space">
                <div className="field-full w-100">
                  <label className="feild-label w-100">
                    I verbally consent to provide information regarding my child
                    related to their immunizations, and I consent to the
                    selected Immunizations being given to my child.
                  </label>

                  <div className="w-100 fieldvalue-full">
                    <div className="w-100">
                      <label
                        className="radiobuttonbox-label"
                        htmlFor="consentyes"
                        style={{ float: "left" }}
                      >
                        <input
                          autoComplete={"off"}
                          type="radio"
                          id="consentyes"
                          name="consent"
                          className=""
                          value="yes"
                          onClick={(e: any) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          defaultChecked={
                            patientForm.consent === "yes" ? true : false
                          }
                        />
                        <span>yes</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="app-setting-head mb-0">
                <h4> COVID-19 Questionnaire </h4>
              </div>

              <div className="form-field-space">
                <div className="field-full w-100">
                  <label className="feild-label w-100">
                    Have you or someone in your bubble have been exposed to
                    COVID-19?{" "}
                  </label>

                  <div className="w-100 fieldvalue-full">
                    <div className="w-100">
                      <label
                        className="radiobuttonbox-label"
                        htmlFor="testingyes"
                      >
                        <input
                          autoComplete={"off"}
                          type="radio"
                          id="testingyes"
                          name="contact"
                          className=""
                          value="YES"
                          onClick={(e: any) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          defaultChecked={
                            patientForm.contact === "YES" ? true : false
                          }
                        />
                        <span>yes</span>
                      </label>

                      <label
                        className="radiobuttonbox-label"
                        htmlFor="testingno"
                      >
                        <input
                          autoComplete={"off"}
                          type="radio"
                          id="testingno"
                          name="contact"
                          className=""
                          value="NO"
                          onClick={(e: any) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          defaultChecked={
                            patientForm.contact === "NO" ? true : false
                          }
                        />
                        <span>no</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="field-full w-100">
                  <label className="feild-label w-100">
                    {" "}
                    If yes, where or who were you exposed to?{" "}
                  </label>

                  <div className="w-100 fieldvalue-full">
                    {/* <select name="number" className="form-feild w-100"> */}
                    <input
                      autoComplete={"off"}
                      type="text"
                      className="form-feild w-100"
                      onChange={(e: any) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      name="contactDescription"
                      defaultValue={patientForm.contactDescription}
                    />
                    {/* </select> */}
                  </div>
                </div>
              </div>

              <div className="form-field-space">
                <div className="field-full w-100">
                  <label className="feild-label w-100">
                    {" "}
                    Are you here for required testing?{" "}
                  </label>

                  <div className="w-100 fieldvalue-full">
                    <div className="w-100">
                      <label
                        className="radiobuttonbox-label"
                        htmlFor="exposedyes"
                      >
                        <input
                          autoComplete={"off"}
                          type="radio"
                          id="exposedyes"
                          name="requiredTesting"
                          className=""
                          value="yes"
                          onClick={(e: any) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          defaultChecked={
                            patientForm.requiredTesting === "yes" ? true : false
                          }
                        />
                        <span>yes</span>
                      </label>

                      <label
                        className="radiobuttonbox-label"
                        htmlFor="exposedno"
                      >
                        <input
                          autoComplete={"off"}
                          type="radio"
                          id="exposedno"
                          name="requiredTesting"
                          className=""
                          value="no"
                          onClick={(e: any) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          defaultChecked={
                            patientForm.requiredTesting === "no" ? true : false
                          }
                        />
                        <span>no</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="field-full w-100">
                  <label className="feild-label w-100">
                    {" "}
                    If yes, select what reason:{" "}
                  </label>

                  <div className="w-100 fieldvalue-full">
                    <input
                      autoComplete={"off"}
                      type="text"
                      className="form-feild w-100"
                      name="requiredTestingDescription"
                      defaultValue={patientForm.requiredTestingDescription}
                      onChange={(e: any) =>
                        handleChange(e.target.name, e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="form-field-space">
                <div className="field-full w-100">
                  <label className="feild-label w-100">
                    Is this test for someone physically attending school?{" "}
                  </label>

                  <div className="w-100 fieldvalue-full">
                    <div className="w-100">
                      <label
                        className="radiobuttonbox-label"
                        htmlFor="attendyes"
                      >
                        <input
                          autoComplete={"off"}
                          type="radio"
                          id="attendyes"
                          name="is_attending_school"
                          className=""
                          value="yes"
                          onClick={(e: any) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          defaultChecked={
                            patientForm.is_attending_school === "yes"
                              ? true
                              : false
                          }
                        />
                        <span>yes</span>
                      </label>

                      <label
                        className="radiobuttonbox-label"
                        htmlFor="attendno"
                      >
                        <input
                          autoComplete={"off"}
                          type="radio"
                          id="attendno"
                          name="is_attending_school"
                          className=""
                          value="no"
                          onClick={(e: any) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          defaultChecked={
                            patientForm.is_attending_school === "no"
                              ? true
                              : false
                          }
                        />
                        <span>no</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="field-full w-100">
                  <label className="feild-label w-100">
                    {" "}
                    If yes, select what city and school:{" "}
                  </label>

                  <div className="w-100 fieldvalue-full">
                    <input
                      autoComplete={"off"}
                      type="text"
                      className="form-feild w-100"
                      placeholder="City"
                      name="city"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      defaultValue={patientForm.city}
                    />
                  </div>

                  <div className="w-100 fieldvalue-full">
                    <input
                      autoComplete={"off"}
                      type="text"
                      className="form-feild w-100"
                      placeholder="School"
                      name="school"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      defaultValue={patientForm.school}
                    />
                  </div>
                </div>
              </div>

              <div className="form-field-space">
                <div className="field-full w-100">
                  {/* <label className="feild-label w-100">
                {" "}
                Are you symptomatic?{" "}
              </label> */}

                  {/* <div className="w-100 fieldvalue-full">
                <div className="w-100">
                  <label
                    className="radiobuttonbox-label"
                    htmlFor="symptomaticyes"
                  >
                    <input autoComplete={"off"}
                      type="radio"
                      id="symptomaticyes"
                      name="symptomatic"
                      onChange={e => handleChange(e.target.name, e.target.value)}
                      className=""
                      value="yes"
                      defaultChecked={
                        patientForm.symptomatic === "yes" ? true : false
                      }
                    />
                    <span>yes</span>
                  </label>
                  <label
                    className="radiobuttonbox-label"
                    htmlFor="symptomaticno"
                  >
                    <input autoComplete={"off"}
                      type="radio"
                      id="symptomaticno"
                      name="symptomatic"
                      onChange={e => handleChange(e.target.name, e.target.value)}
                      className=""
                      value="no"
                      defaultChecked={
                        patientForm.symptomatic === "no" ? true : false
                      }
                    />
                    <span>no</span>
                  </label>
                </div>
              </div> */}
                </div>
              </div>

              <div className="form-field-space">
                <div className="field-full w-100">
                  <label className="feild-label w-100">
                    Check off all symptoms that apply:
                  </label>
                  <div className="checkboxitems">
                    {patientForm && (
                      <NewAppointmentSymptoms
                        symptoms={symptoms}
                        patientForm={patientForm}
                        instance={props.instance}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="form-field-space mb-0">
                <div className="field-full w-100 mb-0">
                  <label className="feild-label w-100">
                    When did symptoms start?
                  </label>

                  <div className="w-100 fieldvalue-full">
                    <div className="w-100">
                      <select
                        name="sympton_startedfrom"
                        className="form-feild w-100"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        value={patientForm.sympton_startedfrom}
                      >
                        <option value="">
                          Select Date Range if Applicable
                        </option>
                        <option value="Today">Today</option>
                        <option value="Past 1-3 days">Past 1-3 days</option>
                        <option value="Past week">Past week</option>
                        <option value="2-3 weeks ago">2-3 weeks ago</option>
                        <option value="1 Month Ago">1 Month Ago</option>
                        <option value="2-6 Months ago">2-6 Months Ago</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  ) : (
    <BounceLoader />
  );
};
