import { toast, Slide } from "react-toastify";

export default function launchToast(message) {
  toast(message, {
    className: "toast-background",
    bodyClassName: "grow-font-size",
    progressClassName: "fancy-progress-bar",
    autoClose: 5000,
    transition: Slide,
  });
}
