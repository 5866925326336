import React, { useEffect } from "react";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useSelector, useDispatch } from "react-redux";
import {
  getAppointments,
  setPrinters,
  createReservedSpot,
  getReservedSpots,
  setDate,
  appointmentFilterResults,
  setView,
  getWeeklyAppointments,
  getDailyAppointments,
  resetAppointments,
  getAppointmentInterval,
  getSimpleAppointments,
} from "../../../actions/appointmentActions";
import { Popup } from "./Popup";
import { Event } from "./Event";
import CustomToolbar from "./CustomToolbar";
const localizer = momentLocalizer(moment);

export const AppointmentCalendar = (props) => {
  const dispatch = useDispatch();
  const appointments = useSelector(
    (state) => state.appointmentReducer.appointments
  );
  const simpleAppointments = useSelector(
    (state) => state.appointmentReducer.simpleAppointments
  );

  let updatedAppointment = null;
  const date = useSelector((state) => state.appointmentReducer.date);
  const view = useSelector((state) => state.appointmentReducer.view);
  const activeTypeOfVisit = useSelector(
    (state) => state.appointmentReducer.activeTypeOfVisit
  );
  const searchResults = useSelector(
    (state) => state.appointmentReducer.searchResults
  );
  const searchResultsActive = useSelector(
    (state) => state.appointmentReducer.searchResultsActive
  );
  const filterResultsActive = useSelector(
    (state) => state.appointmentReducer.filterResultsActive
  );
  const filterResults = useSelector(
    (state) => state.appointmentReducer.filterResults
  );
  const appointmentInterval = useSelector(
    (state) => state.appointmentReducer.appointmentInterval
  );
  const reservedSpots = useSelector(
    (state) => state.appointmentReducer.reservedSpots
  );
  const viewType = useSelector((state) => state.appointmentReducer.viewType);
  const handleSelect = ({ start, end }) => {
    dispatch(createReservedSpot(start, end, props.office.pk));
  };
  useEffect(() => {
    document.body.classList.add("calendar-view");
  }, []);
  useEffect(() => {
    try {
      const printers = window.dymo.label.framework.getPrinters();
      dispatch(setPrinters(printers));
    } catch (err) {
      console.log(err);
    }
  }, [dispatch]);

  //Dispatch actions when calendar is opened
  useEffect(() => {
    dispatch(getReservedSpots(props.office.pk)); //office reserved spots
    dispatch(getDailyAppointments(props.office.pk, date, appointmentInterval)); //daily appointments
    dispatch(getSimpleAppointments(props.office.pk, date)); //simple list appointments
  }, [
    appointmentInterval,
    date,
    dispatch,
    props.office.pk,
    searchResults,
    filterResults,
  ]);

  useEffect(() => {
    dispatch(appointmentFilterResults());
  }, [activeTypeOfVisit, dispatch]);
  if (searchResultsActive) {
    updatedAppointment = searchResults;
  } else if (filterResultsActive) {
    updatedAppointment = filterResults;
  } else {
    updatedAppointment = appointments;
  }

  // useEffect(() => {
  //   if (appointmentInterval) {
  //     if (view === "day") {

  //       const getAppointmentIntervals = setInterval(() => {
  //         // dispatch(getReservedSpots(props.office.pk));
  //         dispatch(
  //           getDailyAppointments(props.office.pk, date, appointmentInterval)
  //         );
  //       }, 35000);
  //       return () => clearInterval(getAppointmentIntervals);
  //     } else if (view === "week") {
  //       dispatch(getWeeklyAppointments(props.office.pk, date));

  //       const getAppointmentIntervals = setInterval(() => {
  //         // dispatch(getReservedSpots(props.office.pk));
  //         dispatch(getWeeklyAppointments(props.office.pk, date));
  //       }, 35000);
  //       return () => clearInterval(getAppointmentIntervals);
  //     } else {
  //       dispatch(getAppointments(props.office.pk, date));

  //       const getAppointmentIntervals = setInterval(() => {
  //         // dispatch(getReservedSpots(props.office.pk));
  //         dispatch(getAppointments(props.office.pk, date));
  //       }, 35000);
  //       return () => clearInterval(getAppointmentIntervals);
  //     }
  //   }
  // }, [view, date, appointmentInterval, dispatch, props.office.pk]);

  useEffect(() => {
    if (date) {
      // console.log(date);
      console.log(moment(date).weekday())
      dispatch(
        getAppointmentInterval(props.office.pk, moment(date).format("MM-DD-YYYY"))
      );
    }
  }, [date, dispatch, props.office.pk]);
  useEffect(() => {
    return () => dispatch(resetAppointments());
  }, [dispatch]);

  useEffect(() => {
    let bodyClass = document.body.classList;
    if (viewType == "search") {
      bodyClass.add("search-view");
      bodyClass.remove("simple-view", "colour-view");
    } else if (viewType == "colour") {
      bodyClass.add("colour-view");
      bodyClass.remove("simple-view", "search-view");
    } else {
      bodyClass.add("simple-view");
      bodyClass.remove("search-view", "colour-view");
    }
  }, [viewType]);
  return (
    <div>
      <Calendar
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        components={{
          event: Event,
          toolbar: (event) => CustomToolbar({ event, updatedAppointment }),
        }}
        date={date}
        events={
          reservedSpots
            ? updatedAppointment.concat(reservedSpots)
            : updatedAppointment
        }
        popup
        popupOffset={{ x: 30, y: 20 }}
        selectable
        step={15}
        onSelectSlot={(event) => handleSelect(event)}
        min={moment("7:00 AM", "LT").toDate()}
        max={moment("9:00 PM", "LT").toDate()}
        onSelectEvent={(e) => {
          console.log(e);
        }}
        onNavigate={(selectedDate) => {
          dispatch(setDate(selectedDate));
        }}
        timeslots={1}
        // scrollToTime={date}
        longPressThreshold={1000}
        style={{ height: "610px" }}
        onView={(view) => {
          if (view === "week") {
            dispatch(
              setDate(
                moment(date)
                  .startOf("week")
                  .toDate()
              )
            );
          } else if (view === "month") {
            dispatch(
              setDate(
                moment(date)
                  .startOf("month")
                  .toDate()
              )
            );
          }
          dispatch(setView(view));
        }}
        view={view}
      />
    </div>
  );
};
