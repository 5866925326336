import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Modal from "react-modal";
import { setNewOffice } from "../../../actions/adminOfficeActions";

const General = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      maxWidth: 464,
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      padding: 0,
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  };
  const dispatch = useDispatch();
  const newOfficeDetails = useSelector(
    (state) => state.adminOfficeInteractions.newOfficeDetails
  );
  const adminOfficeInteractions = useSelector(
    (state) => state.adminOfficeInteractions
  );

  const generalForm = useRef();
  const menuSetting = adminOfficeInteractions.menuSetting;
  const { register, control, handleSubmit, watch, errors } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    // setModalOpen(true);
    dispatch(setNewOffice(data));
    props.stepsHandler(2);
  };
  return (
    <>
      <Modal isOpen={modalOpen} style={customStyles}>
        <div
          className="login-form forgot-pass-form common-form add-new-office-popup"
          style={{}}
        >
          <div className="sub-heading">
            <h3>New Office</h3>
          </div>
          <div className="inner-forgot-pass inner-popup-content">
            <div className="form-head forgot-pass-head">
              <h4>Office was created.</h4>
            </div>
            <p style={{ padding: 0, lineHeight: "120%" }}>
              Would you like to exit without saving your changes? If you exit
              now your office will be deleted.
            </p>
            <div className="login-submit">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setNewOffice(null));
                  setModalOpen(false);
                }}
                title="Cancel"
                className="common-btn white-btn common-btn-updated"
              >
                Cancel
              </a>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  props.stepsHandler(2);
                  setModalOpen(false);
                }}
                title="Continue"
                className="common-btn blue-btn common-btn-updated"
              >
                Continue
              </a>
            </div>
          </div>
        </div>
      </Modal>
      <form
        className="content-group"
        style={{ marginTop: 34 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="form-field-space">
          <div className="w-100 feild-row">
            <label className="feild-label field-left">Office Name:</label>

            <div className="form-fieldbox">
              <input
                autoComplete={"off"}
                type="text"
                placeholder="Name of Office"
                className={`${
                  errors.office ? "error form-feild w-100" : "form-feild w-100"
                }`}
                ref={register({ required: true })}
                name="name"
                defaultValue={newOfficeDetails && newOfficeDetails.office}
              />
            </div>
          </div>
          <div className="w-100 feild-row">
            <label className="feild-label field-left">Type of Office:</label>

            <div className="form-fieldbox">
              <select
                name="type_of_office"
                defaultValue={
                  newOfficeDetails && newOfficeDetails.type_of_office
                }
                className={`${
                  errors.type_of_office
                    ? "error form-feild w-100"
                    : "form-feild w-100"
                }`}
                ref={register({ required: true })}
              >
                <option value="doctor">Doctor</option>
                <option value="covid">Covid Testing Center</option>
                <option value="flu">Flu Clinic</option>
                <option value="assessment">Assessment Center</option>
                <option value="immunization">Immunization Clinic</option>
              </select>
            </div>
          </div>
        </div>
        <div class="inner-header">
          <h5> Location:</h5>
        </div>
        <div className="form-field-space">
          <div className="w-100 feild-row">
            <label className="feild-label field-left">Address:</label>

            <div className="form-fieldbox">
              <input
                autoComplete={"off"}
                type="text"
                defaultValue={newOfficeDetails && newOfficeDetails.address}
                ref={register({ required: true })}
                className={`${
                  errors.address ? "error form-feild w-100" : "form-feild w-100"
                }`}
                name="address"
              />
            </div>
          </div>
          <div className="w-100 feild-row">
            <label className="feild-label field-left">City:</label>

            <div className="form-fieldbox">
              <input
                autoComplete={"off"}
                type="text"
                ref={register({ required: true })}
                className={`${
                  errors.city ? "error form-feild w-100" : "form-feild w-100"
                }`}
                name="city"
              />
            </div>
          </div>
          <div className="w-100 feild-row">
            <label className="feild-label field-left">Postal Code:</label>

            <div className="form-fieldbox">
              <input
                autoComplete={"off"}
                ref={register({ required: true })}
                defaultValue={newOfficeDetails && newOfficeDetails.postalcode}
                type="text"
                className={`${
                  errors.postalcode
                    ? "error form-feild w-100"
                    : "form-feild w-100"
                }`}
                name="postalcode"
              />
            </div>
          </div>
          <div className="w-100 feild-row">
            <label className="feild-label field-left">Province:</label>

            <div className="form-fieldbox">
              <input
                autoComplete={"off"}
                ref={register({ required: true })}
                defaultValue={newOfficeDetails && newOfficeDetails.province}
                type="text"
                className={`${
                  errors.postal_code
                    ? "error form-feild w-100"
                    : "form-feild w-100"
                }`}
                name="province"
              />
            </div>
          </div>
        </div>
        <div class="inner-header">
          <h5> Contact </h5>
        </div>
        <div className="form-field-space mb-23">
          <div className="w-100 feild-row">
            <label className="feild-label field-left">Phone:</label>
            <div className="form-fieldbox phonenumber">
              <input
                autoComplete={"off"}
                type="text"
                defaultValue={newOfficeDetails && newOfficeDetails.phone}
                ref={register({ required: true })}
                maxLength="10"
                minLength="10"
                onKeyPress={(event) => {
                  if (event.which < 48 || event.which > 57) {
                    event.preventDefault();
                  }
                }}
                className={`${
                  errors.phone ? "error form-feild w-100" : "form-feild w-100"
                }`}
                name="phone"
              />
            </div>
          </div>
        </div>
        <div className="bottom-buttons flex-end">
          <a className="common-btn white-btn common-btn-updated mr-15 disabled">
            Go Back
          </a>
          <button className="common-btn blue-btn common-btn-updated">
            Next
          </button>
        </div>
      </form>
    </>
  );
};

export default General;
