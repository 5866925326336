import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Popup from "./Popup";

const AppointmentItem = (props) => {
  const [popup, setPopup] = useState(false);
  const searchResultsActive = useSelector(
    (state) => state.appointmentReducer.searchResultsActive
  );
  const eventsType = useSelector(
    (state) => state.appointmentReducer.eventTypes
  );
  const { event_type, title, start, end } = props.appointment;
  const img =
    event_type &&
    eventsType.find((item) => {
      return item.name == event_type;
    });
  return (
    <div
      className="appointment-item"
      onClick={searchResultsActive ? null : () => setPopup(!popup)}
    >
      {searchResultsActive && (
        <div class="top-title">{moment(start).format("MMMM, dddd DD")}</div>
      )}

      <div>
        {img && <img src={img.src} />}
        <span className="title">{title}</span>
        <span className="time">
          {moment(start).format("h:mm A")} - {moment(end).format("h:mm A")}
        </span>
      </div>
      {popup && <Popup event={props} setPopup={setPopup} reserved={false} />}
    </div>
  );
};

export default AppointmentItem;
