import React, { useEffect, useState, useRef } from "react";
import DashboardTop from "../../components/dashboardTop";
import { getMessageThread } from "../../actions/messagingActions";
// import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import launchToast from "../../utils";
import Threads from "./Threads";

import ThreadChat from "./threadChat";
import NewMessage from "./NewMessage";
import ArchivedThreads from "./ArchivedThreads";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    maxHeight: "100vh",
    marginRight: "-50%",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
  },
};

function Inbox(props) {
  // Selectors for Inbox

  // const selectedThread = useSelector(
  //   (state) => state.messagingReducer.selectedThread
  // );
  const dispatch = useDispatch();
  const office = useSelector((state) => state.adminOfficeInteractions.office);
  const profile = useSelector((state) => state.authenticateUser.profile);
  const socket = useSelector((state) => state.adminOfficeInteractions.socket);

  const [thread, setThread] = useState(null);
  const [newThread, setNewThread] = useState(false);
  const [threadToggle, setThreadToggle] = useState("threads");

  useEffect(() => {
    if (!office.allow_inbox) {
      launchToast(
        "You have your Inbox disabled. You can still view messages that have been sent. Patients will not have the ability to message you back or view the messages."
      );
    }
  }, []);

  return (
    <div>
      <DashboardTop title={`Inbox`} />

      <div class="middle-content main-content-wrapper">
        <div class="d-flex">
          {threadToggle === "threads" ? (
            <Threads
              setThread={setThread}
              setNewThread={setNewThread}
              newThread={newThread}
              thread={thread}
              setThreadToggle={setThreadToggle}
            />
          ) : (
            <ArchivedThreads
              setThread={setThread}
              setNewThread={setNewThread}
              newThread={newThread}
              thread={thread}
              setThreadToggle={setThreadToggle}
            />
          )}
          {thread ? (
            <ThreadChat
              thread={thread}
              office={office}
              profile={profile}
              socket={socket}
              setThread={setThread}
              setNewThread={setNewThread}
            />
          ) : (
            ""
          )}
          {newThread ? (
            <NewMessage
              office={office}
              socket={socket}
              profile={profile}
              setThread={setThread}
              setNewThread={setNewThread}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default Inbox;
