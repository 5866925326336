import React, { useEffect } from "react";
import DashboardTop from "../dashboardTop";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBulletinPosts } from "../../actions/bulletinActions";
import moment from "moment";

export const Bulletin = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBulletinPosts(props.office.pk));
  }, []);

  const bulletinPosts = useSelector(
    (state) => state.bulletinReducer.bulletinPosts
  );
  return (
    <div>
      <DashboardTop title={"Bulletin"} />
      <div class="addnew-post-main">
        <Link
          to={`/office/${props.office.slug}/bulletin/new-post`}
          title="Add New"
          className={"common-btn blue-btn addnew"}
        >
          <span> + &nbsp;Add New </span>
        </Link>
      </div>
      <div class="middle-content main-content-wrapper">
        <div class="patients-table bulletin-table">
          <table>
            <tbody>
              <tr class="no-border">
                <th>Title</th>
                <th>Date Posted</th>
                <th>Location of Post</th>
                <th>&nbsp;</th>
              </tr>
              {bulletinPosts.length > 0 ? (
                bulletinPosts.map((post) => (
                  <tr>
                    <td>{post.title}</td>
                    <td>{moment(post.date_posted).format("YYYY-MM-DD")}</td>
                    <td>Bulletin {post.email_office ? "| Email" : ""}</td>
                    <td class="post-delete">
                      <a href="#" title="delete-post" class="delete-post"></a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr class="no-border">
                  <td>No Posts Found</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
